<template>
  <form class="form-inline header-font" style="display: flex; aling-items: baseline">

    <multi-selector
      :items="selectorPeriods"
      :selectedItem="selectedPeriod"
      :itemLabel="$t('dashboard.label.period')"
      @update="updateSelectedPeriod"
      />
      <div id="custom-date-container"
          v-show="period == 'custom'">
          <date-picker-wrapper 
            :shortcuts="shortcuts"
            :datepicker_ref='datepicker_ref'
            :range=true
            :value="periodRange"
            format='DD-MM-YYYY'
            :forceOpen="forceOpen"
            :period="period"
            v-on:new-date="periodRange = $event"
          ></date-picker-wrapper>
        </div>
  </form>
</template>

<script type="text/javascript">
  import DatePickerWrapper from '@/components/datePickerWrapper'
  import multiSelector from '@/layout/multiSelector';
  
  import dateRanges from '@/dateRanges'
  export default {
    components: {DatePickerWrapper, multiSelector},
    props: {
      'group': {},
      'periods': {
        type: Array,
        default: () => [
          { name: this.$t('dashboard.filter.period.last7days'), symbol: "last7days"},
          { name: this.$t('dashboard.filter.period.last30days'), symbol: "last30days"},
          { name: this.$t('dashboard.filter.period.last3months'), symbol: "last3months"},
          { name: this.$t('dashboard.filter.period.last6months'), symbol: "last6months"},
          { name: this.$t('dashboard.filter.period.last9months'), symbol: "last9months"},
          { name: this.$t('dashboard.filter.period.last12months'), symbol: "last12months"},
          { name: this.$t('dashboard.filter.period.thismonth'), symbol: "thismonth"},
          { name: this.$t('dashboard.filter.period.thisweek'), symbol: "thisweek"},
          { name: this.$t('dashboard.filter.period.custom'), symbol: "custom"}
        ]
      },
      'datepicker_ref': "",
      selectorPeriods: {},
    },
    methods: {
      convertDates(val) {
        //Updating the Vuex module with dates, it will trigger ajax request there
        let dates = [];
        dates[0] = moment(val[0]).format('DD-MM-YYYY');
        dates[1] = moment(val[1]).format('DD-MM-YYYY');
        return dates;
      },
      openEmptyDatepicker(){
        if (this.periodRange.length == 0 || this.periodRange.includes(null)) {
          this.$refs[this.datepicker_ref].openPopup()
        }
      },
      updateSelectedPeriod(newValue) {
        // Triggered by update in multiselector
        this.selectedPeriod = newValue;
        this.period = newValue.symbol;
      }
    },
    computed: {
      userOptionsLoaded() {
        return this.$store.state.userOptions.options.userOptionsLoaded
      },
      period: {
        get: function () {
          return this.$store.state.userOptions.options['selected_period_' + this.group]
        },
        set: function (val) {
          //Save stuff in user options
            this.$store.dispatch('userOptions/setOption', {
            option: 'selected_period_' + this.group,
            value: val
          });
        }
      },
      periodRange: {
        get: function () {
          return this.$store.state.userOptions.options['dates_' + this.group]
        },
        set: function (val) {
          //Only save dates when we have a "custom" ones and in original format for vue2-datepicker
          this.$store.dispatch('userOptions/setOption', {
            option: 'dates_' + this.group,
            value: val
          });
        }
      },
      forceOpen(){
        // this.period == 'custom' ? true : false
        if (this.period == 'custom') {
          return true
        } else { 
          return false 
        }
      },
      // SelectedPeriod is the object { name, symbol } matchin the symbol "period"
      selectedPeriod: {
        get: function () {
        return this.selectorPeriods.find((periodObj) => {
          return (periodObj.symbol == this.period ? periodObj : null)
        })},
        set: function (val) {
          this.period = val.symbol;
        }
      }
    },
    //Actual update of vuex module in watcher, so it will always execute no mater what direction data is came
    // through (changed here or from vuex). This will trigger ajax request to backend.
    watch: {
      periodRange (val, old) {
        if (old!=val && this.period == 'custom' && (this.periodRange.length != 0 && !this.periodRange.includes(null))) {
          let dates = this.convertDates(val);
          this.$store.dispatch(this.group + '/updateDates', dates);
        }
      },
      period (val) {
        if (val != 'custom') {
          this.$store.dispatch(this.group + '/updateDates', dateRanges[val]);
        } else if (this.periodRange.length != 0 && !this.periodRange.includes(null)){
          this.$store.dispatch(this.group + '/updateDates', this.convertDates(this.periodRange));
        }
      },
      //If we have the default value, then our watchers didn't trigger and nothing will load, this is a workaround to trigger initial load
      userOptionsLoaded (val) {
        this.$store.dispatch(this.group + '/updateDates', dateRanges[this.period]);
      }
    },
    data() {
      return {
        dateRanges:dateRanges,
        selectedTypePeriod: '',
        shortcuts: [
          {
            text: this.$t('dashboard.filter.period.today'),
            daysStart: 0,
            daysEnd: 0
          }
        ]
      }
    },
    mounted() {
      //915 h4x0r in case initial route is not dashboard, the userOptionsLoaded is already fired (see above)
      //of course this shouldn't be here in the first place
      this.$store.dispatch(this.group + '/updateDates', dateRanges[this.period]);
    }
  }
</script>

<style>

</style>