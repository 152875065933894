<template>
    <div class="row" id="pagination">
        <div class="col-md-6">
            <div class="pagination" style="display:block">
                {{ $t('dashboard.pagination.showing') }}
                <span v-if="rows && rows.length > 0">
                    {{ selectedTableRows * page + 1}} {{ $t('dashboard.pagination.to') }} {{ lastRow }} {{ $t('dashboard.pagination.of') }}
                </span>
                {{ rows ? rows.length : 0 }} {{ $t('dashboard.pagination.rows') }} <select v-model="selectedTableRows"
                                                               class="form-control"
                                                               style="max-width:80px; display:inline-block;">
                <option v-for="(rowsPerPage, index) in [10,25,50,100,500]" v-bind:value="rowsPerPage" v-bind:key="index">
                    {{ rowsPerPage }}
                </option>
            </select> {{ $t('dashboard.pagination.records_per_page') }}
            </div>
        </div>
        <div class="col-md-6">
            <ul class="pagination pull-right">

                <li v-if="page > 3 && length > 6">
                    <a href="#" @click.stop.prevent="updatePageNumber(0)">1</a>
                </li>

                <li v-if="page > 3 && length > 6" class="disabled">
                    <a href="#">...</a>
                </li>

                <li v-for="pageNumber in numbers" :class="{'active' : pageNumber == page}">
                    <a href="#" @click.stop.prevent="updatePageNumber(pageNumber)">{{ pageNumber + 1 }}</a>
                </li>

                <li v-if="page < length-4 && length > 6" class="disabled">
                    <a href="#">...</a>
                </li>

                <li v-if="page < length-4 && length > 6">
                    <a href="#" @click.stop.prevent="updatePageNumber(length-1)">{{ length }}</a>
                </li>

            </ul>
        </div>
    </div>
</template>

<script type="text/javascript">
    export default {
        data() {
            return {
                rowsPerPage: 0,
                pageNumber: 0
            }
        },

        props: ['rows', 'page', 'tableName'],

        mounted() {
            this.$nextTick(() => {
                this.rowsPerPage = this.selectedTableRows;
            });
        },

        computed: {
            /**
             * Get pages
             */
            length() {
                if (typeof this.rows == 'undefined') {
                    return 0;
                }
                return Math.ceil(this.rows.length / this.selectedTableRows);
            },

            /**
             * Get min page
             */
            min() {
                if (this.page < 4) {
                    return 0;
                } else if (this.page > this.length - 4) {
                    return (this.length - 5) >= 0 ? this.length - 5 : 0;
                } else {
                    return (this.page - 2) >= 0 ? this.page - 2 : 0;
                }
            },

            /**
             * Get max page
             */
            max() {
                if (this.length < 6) {
                    return this.length;
                } else if (this.page < 4) {
                    return 6;
                } else if (this.page > this.length - 5) {
                    return this.length;
                } else {
                    return this.page + 3;
                }
            },

            /**
             * Get numbers to display
             */
            numbers() {
                let temp = [];

                for (let i = this.min; i < this.max; i++) {
                    temp.push(i);
                }

                return temp
            },
            
            lastRow() {
                // (this.rows.length>0) ? "a": "b";
                if (this.rows) {
                    return Math.min(this.selectedTableRows * this.page + this.selectedTableRows , this.rows.length)
                } else {
                    return 0
                }
            },
            selectedTableRows: {
                get() {
                    return this.$store.state.userOptions.options['rowsPerPage_'+(this.tableName || 'shared')];
                },
                set(val) {
                    this.$store.dispatch('userOptions/setOption', {
                        option: 'rowsPerPage_'+(this.tableName || 'shared'), 
                        value: val
                    });
                }
            }
        },

        methods: {
            /**
             * Emit new page number
             * @param pageNumber
             */
            updatePageNumber(pageNumber) {
                this.$emit('update-page-number', pageNumber);
            },
        }
    };
</script>