<template>
    <div>
        <div class="top-filters-pc">
            <div class="top-filters-button-pc" @click="dropdown=!dropdown">{{ $t('dashboard.modal.display_options') }}  <span class="caret"></span></div>
            <div class="top-filters-dropdown-pc" v-show="dropdown" @mouseleave="dropdown=false">
            <label for="showPatientDataCheckbox" class="text-normal">
                <input type="checkbox" id="showPatientDataCheckbox" v-model="showPatientDataCheckbox" style="width:auto">
                {{ $t('dashboard.modal.show_patient') }}
            </label>
            <br>
            <label for="showUserDataCheckbox" class="text-normal">
                <input type="checkbox" id="showUserDataCheckbox" v-model="showUserDataCheckbox" style="width:auto">
                {{ $t('dashboard.modal.show_user') }}
                <font-awesome-icon v-if="showUserDataSpinner" :icon="['fad', 'spinner']" size="lg" pulse/>
            </label>
            <br>
            <label for="showApiDataCheckbox" class="text-normal">
                <input type="checkbox" id="showApiDataCheckbox" v-model="showApiDataCheckbox" style="width:auto">
                {{ $t('dashboard.modal.show_api') }}
                <font-awesome-icon v-if="showApiDataSpinner" :icon="['fad', 'spinner']" size="lg" pulse/>
            </label>
            </div>
        </div>
        <table v-if="record.rows && record.rows.length > 0"
            class="table table-condensed table-striped notifications-table">
            <transition name="list">
                <transition-group name="list" tag="tbody">
                    <template v-for="(row, index) in recordSeparated">
                        <component v-if="row.from" v-bind:is="'row-'+row.from" :row="row" :key="index"></component>
                    </template>
                </transition-group>
            </transition>
        </table>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import RowActivityLog from './rowActivityLog'
    import RowAppointments from './rowAppointments'
    import RowComments from './rowComments'
    import RowNotifications from './rowNotifications'
    import RowRelatedIcs from './rowRelatedIcs'
    import RowStatuses from './rowStatuses'

    export default {
        components: {RowActivityLog, RowAppointments, RowComments, RowNotifications, RowStatuses, RowRelatedIcs},
        data() {
            return {
                showPatientDataCheckbox: true,
                dropdown: false,
            }
        },
        computed: {
            ...mapGetters({
                record: 'consentModal/selectedRecord',
                showUserDataSpinner: 'consentModal/getUserSpinner',
                showApiDataSpinner: 'consentModal/getApiSpinner',
            }),
            showUserDataCheckbox: {
                get(){
                    return this.$store.state.consentModal.showUserDataCheckbox
                },
                set (newStatus){
                    this.$store.commit('consentModal/toggleShowUserDataCheckbox', newStatus);
                    this.$store.commit('consentModal/toggleUserDataSpinner', true);
                    this.$store.dispatch('consentModal/updateModal');
                }
            },
            showApiDataCheckbox: {
                get(){
                    return this.$store.state.consentModal.showApiDataCheckbox
                },
                set (newStatus){
                    this.$store.commit('consentModal/toggleShowApiDataCheckbox', newStatus);
                    this.$store.commit('consentModal/toggleApiDataSpinner', true);
                    this.$store.dispatch('consentModal/updateModal');
                }
            },
            recordSeparated() {
                let rows = this.record.rows;

                // rewrote this functionality, but we need actual specifications for "showPatientDataCheckbox"
                // right now it's jsut the opposite of user data and API data
                if (!this.showUserDataCheckbox) rows = rows.filter(row => row.log_name != "open_patientcard");
                if (!this.showApiDataCheckbox) rows = rows.filter(row => !row.log_name?.startsWith("api_"));
                if (!this.showPatientDataCheckbox) rows = rows.filter((row) => {
                     return row.log_name == "open_patientcard" || row.log_name?.startsWith("api_");
                })

                return rows;
            },
        },
    }
</script>

<style>
    .top-filters-pc {
        position:relative;
        display:flex;
        align-items:baseline;
        justify-content: flex-end;
        height:32px;
        line-height: 20px;
        margin-left: 0px;

    }

    .top-filters-button-pc {
        cursor: pointer;
        font-weight: 500;
        font-family: Raleway;
    }

    .top-filters-dropdown-pc {
        position:absolute;
        top:34px;
        right:0px;
        background:white;
        z-index: 1000;
        -webkit-box-shadow: inset 0px -3px 3px 0 hsla(0,0%,90%,.5);
        -moz-box-shadow:  inset 0px -3px 3px 0 hsla(0,0%,90%,.5);
        box-shadow:  inset 0px -3px 3px 0 hsla(0,0%,90%,.5);
        padding:10px;
        white-space: nowrap;
    }
    .list-enter-active {
    /* transition: all 1s; */
    transition: opacity .5s;
    }
    
    .list-enter{
    opacity: 0;
    }

    .patient-date-cell {
        width: 154px;
    }

    .notification-label {
        padding: .2em .6em .3em;
        font-size: 75%;
        font-weight: bold;
        line-height: 1;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
    }

    .label-comment {
        color: cornflowerblue;
    }

    .label-appointment {
        color: #ced136;
    }

    .label-invitation {
        color: lightgreen;
    }

    .label-followup {
        color: mediumpurple;
    }

    .label-visibility {
        color: indianred;
    }

    .label-reminder {
        color: darkslategrey;
    }

    .label-notification {
        color: #ddd1f7;
    }

</style>
