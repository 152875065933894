export const passesSideFilters = function (store, record) {
    //If we have "Hide EHR's including" checkbox filter them out
    if (store.rootState.sideFilters.filters.hideTypedEPDS) {
        let found = false;
        store.rootState.sideFilters.filters.hiddenEPDs.forEach(function (epd) {
            if (record.epd.indexOf(epd) != -1) {
                found = true;
            }
        })
        if (found) {
            return false;
        }
    }
    //New record can't be already hidden so it's safe just to filter everything out if in this mode
    // (same goes for "deleted" checkbox, we dont need any logic for it)
    if (store.rootState.sideFilters.filters.showOnlyHiddenRecords) {
        return false;
    }
    return true;
}

export const passesDateRange = function (store, record) {
    const recordDate = moment(record.start_at, 'YYYY-MM-DD HH:mm:ss');
    const startDate = moment(store.state.dates[0], 'DD-MM-YYYY');
    const endDate = moment(store.state.dates[1], 'DD-MM-YYYY');
    if (startDate<recordDate<endDate) {
        return true;
    }
}