import axios from 'axios'
import Vue from 'vue'
// Initial state as a function so this can be reused and state wouldn't be shared
// We can add another state for another editable table just by
// adding it as newStoreName:editingTable in store/index.js
const state = () => ({
  data: [],
  loading: false,
  editing: '',
  module: ''
})

// actions
const actions = {
  load ({ state, commit, dispatch }, data ) {
    if (data.module) {
      commit('setModule', data.module) // Set it on first request to backend, we can omit it from that point
    }
    // Don't load twice
    if (state.data.length===0 || data.force) {
      dispatch('requestBackend')
    }
  },
  reload({dispatch}) {
    dispatch('requestBackend')
  },
  requestBackend({ state, commit, getters, rootState}) {
    commit('setLoading', true)
    const params = {};
    params.org = rootState.userOptions.options.selectedOrganizationSymbol;
    if(getters.getDepIfRequired) params.departmentSymbol = getters.getDepIfRequired;
    return axios.get(`/api/admin/${state.module}`,{params}).then(({ data }) => {
      commit('setLoading', false)
      commit('setData', data)
    }).catch((response) => {
      commit('setLoading', false)
      console.log('Cant load '+state.module, response)
    })
  },
  newItem ({state, commit, getters}, form) {
    return new Promise((resolve, reject) => {
      axios.post('/api/admin/' + state.module, getters.getOrgIfRequired ? {org: getters.getOrgIfRequired, ...form} : form ).then(({ data }) => {
        commit('addData', data.data)
        resolve(data)
      }).catch(({response}) => {
        console.log('Error creating a new '+state.module, response)
        reject(response)
      })
    })
  },
  editItem ({state, commit, getters}, form) {
    return new Promise ((resolve, reject) => {
      axios.put('/api/admin/' + state.module + '/' + form.id, getters.getOrgIfRequired ? {org: getters.getOrgIfRequired, ...form} : form).then((response) => {
        commit('updateData', form)
        resolve(response)
      }).catch(function ({response}) {
        console.log('Error editing '+state.module, response)
        reject(response)
      })
    })
  },
  deleteItem({state, commit, getters}, id) {
    return axios.delete(`/api/admin/${state.module}/${id}${getters.getOrgIfRequired ? `?org=${getters.getOrgIfRequired}` : ''}`).then((response) => {
      if (response.status===204) { // Deleted
        commit('deleteData', id)
        return ""
      } else { // Hidden
        commit('deletedAt', response.data)
        return ""
      }
    }).catch((response) => {
      console.log('Error deleting ' + state.module, response)
    })
  },
  resetstate({commit}){
    commit('setData', [])
  },
}

// mutations
const mutations = {
  setData (state, data) {
    state.data = data
  },
  addData (state, data) {
    state.data.push(data)
  },
  updateData (state, data) {
    for (const recordKey in state.data) {
      if (state.data[recordKey].id === data.id) {
        Vue.set(state.data, recordKey, data)
      }
    }
  },
  deleteData (state, id) {
    for (const recordKey in state.data) {
      if (state.data[recordKey].id === id) {
        state.data.splice(recordKey,1)
      }
    }
  },
  deletedAt (state, {id, deleted_at}) {
    for (const recordKey in state.data) {
      if (state.data[recordKey].id === id) {
        Vue.set(state.data, recordKey, { ...state.data[recordKey], deleted_at })
      }
    }
  },
  enableData (state, id) {
    for (const recordKey in state.data) {
      if (state.data[recordKey].id === id) {
        Vue.set(state.data, recordKey, {...state.data[recordKey], enabled:1})
      } else {
        Vue.set(state.data, recordKey, {...state.data[recordKey], enabled:0})
      }
    }
  },
  setLoading (state, loading) {
    state.loading = loading
  },
  setEditing (state, value) {
    state.editing = value
  },
  setModule(state, value) {
    state.module = value
  }
}

// getters
const getters = {
  editingData (state) {
    if (state.editing !== '' || state.editing !== 'add') {
      for (const record of state.data) {
        if (record.id === state.editing) {
          return record
        }
      }
    }
  },
  //TODO: get rid of org-isch (and dep-isch) dependency (all setup menu's org combined yo):
  //Resolve in MED-I987 (v8)
  getOrgIfRequired (state, __, rootState) {      
    if(['whitelistIp', 'whitelistDomain', 'thirdParty', 'diff', 'user'].indexOf(state.module) > -1) {
      return rootState.userOptions.options.selectedOrganizationSymbol;
    }
  },
  // Always required, there was an exception for diffchecker but diff also needs dep. Kept function in case we want 
  // to use in future/only remove when getOrgIfRequired also removed
  getDepIfRequired(state, __, rootState) {
    return rootState.userOptions.options.selectedDepartmentSymbol;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
