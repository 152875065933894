<template>
    <div style="padding-top: 10px; float: left; margin-left: 15px;">
        <modal v-bind:name="getModalName()" width="75%" height="auto" :scrollable="true" style="z-index: 23000">
          <div class="modal-content">
            <div class="modal-header">
              <button style="margin-bottom: 10px;" type="button" class="close" @click="closeModal()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <table class="table table-condensed table-striped">
                <thead>
                    <tr>
                      <td>Hash</td>
                      <td>Answer</td>
                    </tr>
                </thead>
                <tbody>
                  <tr v-for="(value, key) in modalAnswers">
                    <td>{{key}}</td>
                    <td>{{value}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </modal>
        <div>
          <div style="height:400px;">
          <table class="table table-condensed table-striped">
          <tbody>
            <tr v-for="[hash, answer] in Object.entries(modalAnswers).slice((Math.max(0, activeAnswers - 1)) * 10, activeAnswers * 10)">
              <td>{{answer}}</td>
            </tr>
          </tbody>
        </table>
        </div>
          <nav v-if="Object.keys(modalAnswers).length > 10" aria-label="Page navigation for real">
            <ul class="pagination">
              <li class="page-item"><a :style="styles.getAStyle(NaN, activeAnswers, true)" class="page-link" v-on:click="decreaseActiveIndex(-1)"><</a></li>
              <li v-if="activeAnswers > 3" class="page-item"><a :style="styles.getAStyle(NaN, activeAnswers)" class="page-link" v-on:click="setActiveIndex(minAnswers)">{{minAnswers}}</a></li>
              <li v-if="activeAnswers > 3" class="page-item"><a :style="styles.getAStyle(NaN, activeAnswers)" class="page-link">...</a></li>
              <!-- &zwnj; is an invisible sign. TODO: use something else man, this will be done in tandem with the redesign -->
              <li v-for="index in getRange(activeAnswers - 2, activeAnswers + 2)" :key="index" class="page-item"><a :style="styles.getAStyle(index, activeAnswers)" class="page-link" v-on:click="setActiveIndex(index)">{{index > 0 ? index : "&zwnj;"}}</a></li>
              <li v-if="activeAnswers < maxAnswers - 2" class="page-item"><a :style="styles.getAStyle(NaN, activeAnswers)" class="page-link">...</a></li>
              <li v-if="activeAnswers < maxAnswers - 2" class="page-item"><a :style="styles.getAStyle(NaN, activeAnswers)" class="page-link" v-on:click="setActiveIndex(maxAnswers)">{{maxAnswers}}</a></li>
              <li class="page-item"><a :style="styles.getAStyle(NaN, activeAnswers, true)" class="page-link" v-on:click="increaseActiveIndex(1)">></a></li>
            </ul>
          </nav>
        </div>
        <div>
          <button v-if="!editMode && userHasExportPermissions" v-on:click="openModal()" :style="styles.openQuestionModalButton">{{translate('report.overview.individual_answers')}}</button>
        </div>
    </div>
</template>

<script type="text/javascript">
    export default {
        name: 'open-question',
        props: ['answers', 'uniqueId', 'editMode', 'userHasExportPermissions'],
        methods: {
          translate(key){
            return this.$t(key);
          },
          increaseActiveIndex(term){
            // Math.min so we can never go over the max possible
            this.activeAnswers = Math.min(this.maxAnswers, this.activeAnswers + term);
          },
          decreaseActiveIndex(term){
            // Math.max so we can never go under the minimum possible
            this.activeAnswers = Math.max(this.minAnswers, this.activeAnswers + term);
          },
          setActiveIndex(index){
            if (Number(index) && index > 0)
              this.activeAnswers = index;
          },
          openModal(){
              this.$modal.show(this.getModalName());
          },
          closeModal(){
              this.$modal.hide(this.getModalName());
          },
          getModalName(){
              return String(this.uniqueId);
          },
          getRange(min, max){
              // TODO: fix efficiency and also logic
              // why oh why is there not a range() function in JS
              let arrayRange = [];

              const minDiff = this.minAnswers - min;
              if (minDiff > 0)
                for (let i = 0; i < minDiff; i++) arrayRange.push(-Math.random())

              // can't let the loop go below 0 or above how many answers we actually have
              let actualMin = Math.max(this.minAnswers, min);
              let actualMax = Math.min(max, this.maxAnswers)

              for (let i = actualMin; i <= actualMax; i++){
                  arrayRange.push(i);
              }

              const maxDiff = this.maxAnswers - max;
              if (maxDiff < 0)
                for (let i = 0; i < Math.abs(maxDiff); i++) arrayRange.push(-Math.random())

              return arrayRange;
          }
        },
        data() {
            return {
                activeAnswers: 1,
                minAnswers: 1,
                maxAnswers: 1,
                styles: {
                    openQuestionModalButton: {
                        height: "30px",
                        position: "relative",
                        backgroundColor: "#FF6600",
                        color: "white",
                        marginTop: "auto",
                        marginBottom: "auto",
                        paddingTop: "auto",
                        paddingBottom: "auto",
                        borderRadius: "4px"
                    },
                    getAStyle(index = NaN, activeAnswer, specialCase){                 
                      return {
                        width: specialCase ? "60px" : '40px',
                        margin: '0 auto',
                        textAlign: 'center',
                        border: index === activeAnswer ? "1px solid #000" : ""
                      }
                    },
                    getPosStyle(state){
                      return {
                        visibility: state ? "visible" : "hidden",
                        width: "40px",
                        margin: "0 auto",
                        textAlign: "center"
                      }
                    }
                }
            }
        },
        computed: {
            modalAnswers: function(){
                // for now just removing 'undefined' if in the set
                let data = this.answers;
                delete data['undefined'];
                this.maxAnswers = Math.ceil(Object.entries(data).length / 10);
                return data;
            }
        }
    };
</script>