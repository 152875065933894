import axios from 'axios'
import Cookies from 'js-cookie'
import {throttle} from "lodash";

import Echo from "laravel-echo"
import io from 'socket.io-client'
window.io = io;

export const state = () => ({
  user: false,
  loading: false,
  lastSuccessfulRequest: '',
  checkAuthInterval: ''
})

export const mutations = {
  setUser (state, data) {
    state.user = data
  },
  setLoading (state, value) {
    state.loading = value
  },
  setLastSuccessfulRequest (state) {
    state.lastSuccessfulRequest = Date.now()
  },
  setCheckAuthInterval (state, interval) {
    state.checkAuthInterval = interval
  }
}

export const actions = {
  login ({ commit }, form) {
    return new Promise((resolve, reject) => {
      axios.get('/auth/sanctum/csrf-cookie').then(() => {
        axios.post('/auth/login', form).then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
      }).catch((error) => {
        reject(error)
      })
    })
  },
  email ({ commit }, form) {
    return new Promise((resolve, reject) => {
      axios.get('/auth/sanctum/csrf-cookie').then(() => {
        axios.post('/auth/password/email', form).then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
      }).catch((error) => {
        reject(error)
      })
    })
  },
  reset ({ commit }, form) {
    return new Promise((resolve, reject) => {
      axios.get('/auth/sanctum/csrf-cookie').then(() => {
        axios.post('/auth/password/reset', form).then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
      }).catch((error) => {
        reject(error)
      })
    })
  },
  pinLogin ({ commit }, form) {
    return new Promise((resolve, reject) => {
      axios.get('/auth/sanctum/csrf-cookie').then(() => {
        axios.post('/auth/pin_login', form).then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
      }).catch((error) => {
        reject(error)
      })
    })
  },
  async logout ({ commit }) {
    let modules = ['organization', 'role', 'user', 'whitelistDomain', 'whitelistIp', 'thirdParty', 'reports', 'diff']; // diff, analytics, tags?
    modules.forEach(module => this.dispatch(module+'/resetstate'));
    await axios.post('/auth/logout').then((response) => {
      commit('setUser', false)
    })
  },
  async checkAuth (context) {
    const { commit, dispatch } = context;
    commit('setLoading', true)
    await axios.get(`/api/user?timestamp=${new Date().getTime()}`).then(async (response) => {
      // Now we have CSRF token, but ECHO knows nothing of it, let's set it manually
      // The suggested way of doing it from the laravel docs don't work
      // We do this before everything else to ensure it would be executed before all subscribtions
      // And subscribe to user ECHO channel
      if (response.data.id) {
        window.Echo = new Echo({
          broadcaster: 'socket.io',
          host: window.location.protocol + '//' + window.location.host
        });
        window.Echo.connector.options.auth = {
          headers: {
            'x-xsrf-token': Cookies.get('XSRF-TOKEN')
          }
        }
        commit('setUser', response.data)
        // After logging in load user options
        await dispatch('userOptions/loadOptions',{},{root:true})
        dispatch('setAuthCheckInterval')
        //check if org and departments are set, if not select the first available one:
        let {selectedOrganizationSymbol, selectedDepartmentSymbol} = context.rootState.userOptions.options;
        if(!(context.state.user.organizations && context.state.user.organizations.length)) {
          context.dispatch('logout');
          throw new Error('Fatal: no org available (maybe due to IP-whitelist restriction');
        }
        if(selectedOrganizationSymbol) {
          //check if valid selected org (might not be the case because of ip whitelisting yo)
          if(!context.state.user.organizations.find(({symbol}) => symbol == selectedOrganizationSymbol)) {
            selectedOrganizationSymbol = null;
            selectedDepartmentSymbol = null;
          } else if(!context.state.user.departments.find(({symbol, organizations}) => symbol == selectedDepartmentSymbol && organizations.find(({symbol}) => symbol === selectedOrganizationSymbol))) {
            selectedOrganizationSymbol = null;
            selectedDepartmentSymbol = null;
          }
        }
        if(!(selectedOrganizationSymbol && selectedDepartmentSymbol)) {
          //set the first one:
          const orgToBeSelected = context.state.user.organizations[0];
          await dispatch('userOptions/setOption', { option: 'selectedOrganizationSymbol', value: orgToBeSelected.symbol}, {root:true});
          const departmentToBeSelected = context.state.user.departments.find(({organizations}) => {
            return organizations.find(({symbol: orgSymbol}) => orgSymbol == orgToBeSelected.symbol);
          });
          await dispatch('userOptions/setOption', { option: 'selectedDepartmentSymbol', value: departmentToBeSelected.symbol}, {root:true});
          //checkcheck dubbel check:
          let {selectedOrganizationSymbol, selectedDepartmentSymbol} = context.rootState.userOptions.options;
          if(!(selectedOrganizationSymbol && selectedOrganizationSymbol.length && selectedDepartmentSymbol && selectedDepartmentSymbol.length)) {
            throw new Error(`Fatal: Unable to set Org (${selectedOrganizationSymbol}) / Dep (${selectedDepartmentSymbol})`);
          }
        }
        commit('setLoading', false);
      } else {
        throw new Error(`User not authorized (${response.status};${response.statusText})`); //h4x0r
      }
    }).catch((e) => {
      console.log(e.message);
      commit('setUser', false);
      commit('setLoading', false);
    })
  },
  throttledCheckAuth: throttle(({dispatch}) => {
    dispatch("checkAuth");
  }, 60000),

  setAuthCheckInterval ({ commit, state, dispatch }) {
    if (!state.checkAuthInterval) {
      const interval = window.setInterval(function () {
        const cookieTimeout = 121 * 60 * 1000 // Laravel session has 120 min, so check after 121 minutes
        if (Date.now() - state.lastSuccessfulRequest > cookieTimeout) {
          dispatch('checkAuth')
          clearInterval(interval)
          commit('setCheckAuthInterval', null)
        }
      }, 10000)
      commit('setCheckAuthInterval', interval)
    }
  }
}

export const getters = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
