<template>
    <div class="modal fade in" tabindex="-1" role="dialog" id="pin-modal">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                            aria-hidden="true">&times;</span></button>
                    <h4 class="modal-title">{{$t('dashboard.modal.pin_confirm', {epd})}}</h4>
                </div>
                
                <div class="modal-body">
                    <div class="form-group" v-bind:class="{ 'has-error': wrong_pin }">

                        <div style="display:flex; justify-content: space-between; max-width: 200px">
                            <label>{{$t('activity.unlock_all_steps')}}</label>
                            <input type="checkbox" v-model="unlockAllSteps"/>
                        </div>

                        <input v-show="unlockAllSteps" class="form-control" :placeholder="$t('activity.unlock_reason_placeholder')" name="unlock_reason" type="text" v-model="unlockReason">
                        
                        <br/>

                        <input class="form-control" :placeholder="$t('editing_table.auth.pin')" name="pin" type="password"
                               inputmode="numeric" v-model="pin">
                        <span class="help-block" v-show="wrong_pin">
                              <strong>{{$t('editing_table.auth.failed')}}</strong>
                        </span>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" @click="doRedirect()">{{$t('dashboard.modal.redirect_to_questionnaire')}}</button>
                </div>
            </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->
</template>

<script type="text/javascript">

  import { mapState } from 'vuex'
  
  export default {
        data() {
            return {
                epd: '',
                hash: '',
                checked: '',
                title: '',
                pin: '',
                wrong_pin: false,
                unlockAllSteps: false,
                unlockReason: ''
            }
        },
        methods: {
            doRedirect() {
                let self = this;
                const {unlockAllSteps, unlockReason} = this;

                if(unlockAllSteps && (!unlockReason || !unlockReason.length)) return alert(this.$t('activity.unlock_reason_required_msg'));

                if (self.pin) {
                    self.axios.post('/api/intake/redirect', {
                        hash: self.hash,
                        no_step: self.checked,
                        pin: self.pin,
                        departmentSymbol: self.department,
                        unlockAllSteps,
                        unlockReason,
                        org: this.selectedOrganizationSymbol
                    }).then((result) => {
                        window.location = result.data;
                    }, response => {
                        self.wrong_pin = true;
                    });
                }
            }
        },
        created() {
            let self = this;
            self.$eventHub.$on('show-pin-modal', (data) => {
                self.department = data.department;
                self.epd = data.epd;
                self.hash = data.hash;
                self.checked = data.checked;
                $('#pin-modal').modal('show');
            });
        },
        computed: {
            ...mapState('userOptions', {
                selectedOrganizationSymbol: state => state.options.selectedOrganizationSymbol
            })
        }
    };
</script>
