<template>
    <div class="actions clearfix">
        <div class="row">
            <div class="col-md-12">
                <ul class="nav nav-tabs">
                    <li class="pull-right" :class="{ 'active': tab=='comment' }"
                        @click="selectTab('comment')">
                        <a :href="'#add-comment-' + record.id"> {{ $t('dashboard.modal.comment') }}</a>
                    </li>
                    <li class="pull-right" :class="{ 'active': tab=='appointment' }"
                        @click="selectTab('appointment')"
                        v-show="canSeeAppointmentButton()">
                        <a :href="'#set-appointment-' + record.id" id="set_appointment">{{
                            $t('dashboard.modal.appointment_tab') }}</a>
                    </li>
                </ul>

                <div class="tab-content clearfix" v-show="tab">
                    <comment-tab :class="{ 'active': tab=='comment' }"></comment-tab>
                    <appointment-tab :class="{ 'active': tab=='appointment' || tab=='editAppointment' }"></appointment-tab>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters, mapState} from 'vuex'
    import CommentTab from './commentTab'
    import AppointmentTab from './appointmentTab'

    export default {
        components: {CommentTab, AppointmentTab},
        computed: {
            ...mapState({
                tab: state => state.consentModal.selectedTab
            }),
            config() {
                const {orgSymbol, department: departmentSymbol} = this.record;
                return this.$store.getters['config/getConfig']({orgSymbol, departmentSymbol})
            },
            ...mapGetters({
                record: 'consentModal/selectedRecord',
                appointment: 'consentModal/getAppointment',
            })
        },
        methods: {
            //If we don't have appointment and have a followup with matching content show the tab
            canSeeAppointmentButton() {
                if (this.record && (!this.appointment || this.appointment.status == 'canceled') && this.config.hasOwnProperty('followups')) {
                    for (let group_id in this.config.followups) {
                        if (Array.isArray(this.config.followups[group_id].content) &&
                            this.config.followups[group_id].content.indexOf(this.record.content) > -1) {
                            return true;
                        }
                    }
                }
                if (this.record && (!this.appointment || this.appointment.status == 'canceled') && this.config.enable_appointments) {
                    return true;
                }
            },
            selectTab(tab) {
                this.$store.commit('consentModal/selectTab', tab);
            }
        }
    }
</script>