<template>
  <div class="clearfix">
    <div class="navbar-header">
      <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <a class="navbar-brand" :href="weblink ? false : '/'">
        <img style="width: 100px;margin-left:10px" src="/img/medify.png"/>
      </a>
    </div>
    <!-- /.navbar-header -->
    <div class="client-folder-form clearfix header-font">
      <div class="row">
        <org-selector/>
      </div>
    </div>
    <!-- Right Side Of Navbar -->
    <ul class="nav navbar-top-links navbar-right pull-right header-font right-navbar">
      <lang-selector/>
      <user-dropdown v-if="!weblink"/>
    </ul>
  </div>
</template>

<script>

import UserDropdown from '@/layout/userDropdown'
import langSelector from '@/layout/langSelector'
import orgSelector from '@/layout/orgSelector'

export default {
  components: { UserDropdown, langSelector, orgSelector},
  computed: {
    weblink() {
      return window.location.pathname.split('?')[0] === '/weblink'
    }
  },
}
</script>

<style scoped>
 .right-navbar {
   display: flex;
   align-items: center;
   justify-content: flex-end;
   width: 120px;
   margin: 20px 30px 0px 10px;
 }
</style>