<template>
  <li>
    <select name="language" class="form-control" v-model="locale">
      <option value="en">EN</option>
      <option value="nl">NL</option>
      <option value="de">DE</option>
      <option value="es">ES</option>
      <option value="pt">PT</option>
    </select>
  </li>
</template>

<script>
export default {
  computed: {
    locale: {
      get: function () {
        return this.$store.state.userOptions.options.locale
      },
      set: function (newValue) {
        this.$store.dispatch('userOptions/setOption',{option: 'locale', value: newValue})
      }
    }
  },
  watch: {
    locale: {
      immediate: true,
      handler: function(newValue) {
        this.$i18n.locale = newValue
      }
    }
  }
}
</script>