<template>
    <div style="width: 100%;">
        <modal name="patientInfo" @before-open="beforeOpen" @before-close="beforeClose" width="75%" height="auto" :scrollable="true" style="z-index: 20000; top:0px;">
          <div v-if="modalLoading" style="width: 100%; height: 300px;" class="modal-content">
            <p style="margin-top: 130px; margin-left: 45%;">{{translate('report.modal.loading')}}</p>
            <font-awesome-icon icon="spinner" size="3x" pulse fixed-width style="color: black; position: relative; margin-left: 48%;"></font-awesome-icon>
          </div>
          <div v-else class="modal-content">
            <div style="display: flex; flex-direction: row; width:100%;" class="modal-header">
              <div style="display: flex; flex-direction: row; width:100%;">
              </div>
              <button :style="styles.exportButton" @click="exportAsCsv">Exporteer als CSV</button>
              <font-awesome-icon v-show="loadingCSV" icon="spinner" size="3x" pulse></font-awesome-icon>
              <font-awesome-icon icon="times" @click="hide" style="cursor: pointer;"></font-awesome-icon>
            </div>
            <div class="modal-body" style="overflow: scroll;">
              <table class="table table-condensed table-striped">
                <thead>
                    <tr>
                      <td>Hash</td>
                      <td v-for="q in tableData.questions">
                        {{q._id + "). " + q.question}}
                      </td>
                    </tr> 
                </thead>
                <tbody>
                  <!-- TODO: make this modal work with orFilters -->
                  <tr v-if="activeFilters.length">
                    <td>
                      <filter-tag v-if="getSummaryTag()"
                        :filter="getSummaryTag()"
                        :inModal="true"
                      >
                       </filter-tag>
                    </td>
                    <!-- start on the filtering patient information modal -->
                    <td v-for="answer in tableData.patients[Object.keys(tableData.patients)[0]]">
                      <filter-tag v-if="getTag(answer)"
                        :filter="getTag(answer)"
                        :inModal="true"
                      >
                       </filter-tag>
                    </td>
                  </tr>
                  <tr v-for="p in Object.keys(tableData.patients)">
                    <td>{{p}}</td>
                    <td v-for="a in tableData.patients[p]">
                      {{getAnswers(a)}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </modal>
    </div>
</template>

<script type="text/javascript">
    import FilterTag from './tag.vue';
    import Vue from 'vue'

    export default {
        name: "patient-modal",
        components: {
          FilterTag
        },
        props: ["patients", "activeQuestionnaires", "activeFilters", "namespaceInstance"],
        data() {
          return {
            loadingCSV: false,
            modalLoading: false,
            tableData: {
              patients: {}
            },
            filteredTableData: {},
            styles:{
              exportButton: {
                width: "80px",
                position: "relative",
                backgroundColor: "#FF6600",
                color: "white",
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "15px",
                borderRadius: "4px",
                borderColor: "transparent"
              }
            }
          }
        },
        methods: {
          getTag(answer){
            return this.activeFilters.find(filter => filter.uniqueId === answer[0].uniqueId);
          },
          getSummaryTag(){
            return this.activeFilters.find(af => af.type === 'summary');
          },
          getTableData(){
              // if there's no active questionnaire or patients, we return empty data so an empty modal shows up
              if (!this.activeQuestionnaires || !this.patients || !Object.keys(this.activeQuestionnaires).length){
                  return {
                    'questions': [],
                    'patients': []
                  };
              }

              // get all active questions, and filter out those that don't have any answers
              const questions = Object.values(this.activeQuestionnaires).flat().filter(q => !Object.values(q._answers).every(item => item === 0));

              // patients up next, if we're here through a click on a table, pick the tableData, else the report data
              let patients = {};
              let copyPatients = Object.keys(this.filteredTableData).length ? this.filteredTableData.newSubmit : this.patients;

              Object.keys(copyPatients).forEach((patient) => {
                let answers = [];

                // check the answer to every question per patient, add an X instead of the actual
                // answer if the question was not filled in
                // TODO: This should be rebuild probably, drawing out logic before coding
                // Also Bram what's your opinion about adding fake answers and then filtering out people with only fake answers?
                questions.forEach((question) => {
                  let answer;
                  if (copyPatients[patient][question.form_id]) {
                    answer = copyPatients[patient][question.form_id].orderedQuestions.filter((oq) => {
                      return oq.uniqueId === question.uniqueId;
                    });

                    if (!answer.length) {
                      answer = [{"answer": "X", "groupId": "88"}];
                    } else {
                      answer.answer.replace("\n", ""); 
                    }
                  } else {
                    answer = [{"answer": "X", "groupId": "88"}];
                  }
                  answers.push(answer);
                });

                // and now we kick out patients with only X's
                if (answers.filter(answer => answer[0].answer !== "X").length) {
                  patients[patient] = answers;
                }
              })

              return {
                questions: questions,
                patients: patients
              }
            },
          show() {
            this.$modal.show('patientInfo');
          },
          hide() {
            let self = this;
            self.loadingCSV = true;
            setTimeout(function() {
              self.$modal.hide('patientInfo');
              self.loadingCSV = false;
            }, 40);
          },
          beforeOpen(data){
            this.modalLoading = true;
            if (data.params){
              this.filteredTableData = data.params.data;
            }

            setTimeout(() => {
              this.tableData = this.getTableData();
              this.modalLoading = false;
            }, 80);
          },
          beforeClose(){
            this.filteredTableData = {};
          },
          exportAsCsv(event){
            Vue.set(this, 'loadingCSV', true);
            let csvContent = "data:text/csv;charset=utf-8,";

            const summaryFilter = (this.activeFilters.find(af => af.type === 'summary'));

            csvContent += '"hash' + (summaryFilter ? " (Totaal: " + summaryFilter.answer + ")," : '",');
            this.tableData.questions.forEach((q) => {
              const filterAddition = q.filter ? " (" + this.getAnswers(q.filter) + ")" : "";
              csvContent += '"' + String(q.question).trim() + filterAddition + '",';
            })
            // remove last comma
            csvContent = csvContent.slice(0, -1);
            csvContent += "\n";

            console.log('csv content after q: ', csvContent);
            
            console.log('p?', this.tableData.patients);
            
            for (let patient in this.tableData.patients){
              csvContent += '"' + patient + '",';
              this.tableData.patients[patient].forEach((q) => {
                csvContent += '"' + this.getAnswers(q) +  '",';
              })
              // remove last comma
              csvContent = csvContent.slice(0, -1);
              csvContent += "\n";
            }

            const data = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", data);
            link.setAttribute("download", "export.csv");
            link.click();
            Vue.set(this, 'loadingCSV', false);

            this.$modal.hide('patientInfo');
          },
          getAnswers(answerArray){
            // change an array of answer objects to one string of answers
            // TODO: Implement the learnings from this method to the CSVdata method
            return answerArray.map(a => a.answer).join("; ");
          },
          translate(key){
            return this.$t(key);
          }
        }
    }
</script>
