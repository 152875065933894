<template>
    <div :style="styles.container">        
        <!-- adding/removing all questions of questionnaire section -->
        <div :style="styles.labelButtonContainer">
            <h3 :style="styles.label">{{getLabel(questionnaire.id)}}</h3>
            <button v-if="editMode" :style="styles.entireQuestionnaireButton" 
            v-on:click="changeQuestionnaireSelection()">
                {{(selected ? translate('report.questionnaire_selection.remove_all_questions') : translate('report.questionnaire_selection.add_all_questions'))}}
            </button>
        </div>

        <!-- Question entries section-->
        <div
            v-if="isVisible(questionAnswerPair._id, questionAnswerPair._answers)"
                :style="styles.questionAnswerContainer"
                v-bind:key="questionAnswerPair._id"
                v-for="questionAnswerPair in questionnaire.questionAnswerPairs"
        >
            <div v-bind:style="getFlexDirection(questionAnswerPair.input.type)">
                <div style="display: flex; padding-top:10px;">
                    <p :style="styles.question">
                        <span style="margin-top:auto; margin-bottom:auto;">
                            {{questionAnswerPair._id + '). ' + (questionAnswerPair.question).replace(/(<([^>]+)>)/ig, '')}}
                        </span>
                        <span v-if="filtersPerQuestion(questionAnswerPair._id).length > 0" :style="styles.filterText">
                            {{translate('report.overview.active_filters') + ": "}}
                        </span>
                        <filter-tag v-for="(filter, index) in filtersPerQuestion(questionAnswerPair._id)"
                            :filter="filter"
                            :index="index"
                            :isNotFinalTag="isItNotFinalTag(questionAnswerPair._id, filter)"
                            @remove="removeFilter"
                            inModal=True
                            :key=index
                        ></filter-tag>
                    </p>
                    
                    <div v-if="showRaw">{{editMode}} ????</div>
                    <font-awesome-icon v-show="!immutable && editMode && selected" icon="minus-circle" size="lg" style="color: grey" @click="onSelectionModified(questionnaire.id, questionAnswerPair._id)"></font-awesome-icon>
                    <font-awesome-icon v-show="!immutable && editMode &&!selected" icon="plus" size="lg" style="color: grey" @click="onSelectionModified(questionnaire.id, questionAnswerPair._id)"></font-awesome-icon>
                </div>

                <div v-if="showRaw">_id = {{questionAnswerPair._id}}</div>
                <div v-if="showRaw">Type = {{questionAnswerPair.input.type}}</div>
                <div v-if="showRaw" v-for="option in questionAnswerPair.input.options" v-bind:key="option.answer">
                    Option: {{option.answer}} (description: {{option.description}}) (groupId: {{option.groupId}})
                </div>
                   
                <div v-if="showRaw" v-bind:key="groupId" v-for="(hashes, groupId) in questionAnswerPair._groupsHashes">
                    <div>{{ groupId }}: {{ Object.keys(hashes).length }}</div>
                </div>

                <!-- TODO: minimise/reduce/remove redundancy in chart-togglers -->
                <div v-if="selected && ['radio','checkbox','scoring'].includes(questionAnswerPair.input.type)">
                    <div style="display: flex">
                        <div :style="styles.chartContainer(selection[questionAnswerPair._id].categoryViewInfo.chart)">
                            <h5 style="display: flex; justify-content: center; transform: rotate(-90deg);" v-show="editMode || selection[questionAnswerPair._id].questionViewInfo.chart">
                                {{translate('report.overview.answers')}}</h5>
                            <!-- leave :type="'type'", if not there all visualisations update incorrect -->
                            <chart-toggler
                                    :data=questionAnswerPair._answers
                                    :options="getOptions(questionAnswerPair)"
                                    :viewInfo="selection[questionAnswerPair._id].questionViewInfo"
                                    :config="categoryConfig"
                                    :loadingState=false
                                    :editMode="editMode"
                                    :translate=translate
                                    :type="'column'"
                                    :reportType="questionAnswerPair.input.reportType"
                                    :target="questionAnswerPair.input.target"
                                    :filterData="{questionnaireId: questionnaire.id, type: 'answer', uniqueId: questionAnswerPair.uniqueId, questionId: questionAnswerPair._id}"
                                    :answers="true"
                                    :namespaceInstance="namespaceInstance"
                            >
                            </chart-toggler>
                        </div>
                        <div v-show="editMode || selection[questionAnswerPair._id].categoryViewInfo.chart || selection[questionAnswerPair._id].categoryViewInfo.table" :style="styles.chartContainer(true)">
                            <h5 style="display: flex; justify-content: center; transform: rotate(-90deg);" v-show="editMode || selection[questionAnswerPair._id].categoryViewInfo.chart">
                                {{translate('report.overview.categories')}}</h5>
                            <chart-toggler
                                    :data=questionAnswerPair._groupsHashes
                                    :options="getOptions(questionAnswerPair)"
                                    :viewInfo="selection[questionAnswerPair._id].categoryViewInfo"
                                    :config="categoryConfig"
                                    :loadingState=false
                                    :editMode="editMode"
                                    :translate=translate
                                    :type="'pie'"
                                    :reportType="questionAnswerPair.input.reportType"
                                    :target="questionAnswerPair.input.target"
                                    :filterData="{questionnaireId: questionnaire.id, type: 'groupId', uniqueId: questionAnswerPair.uniqueId, questionId: questionAnswerPair._id}"
                                    :answers="false"
                                    :namespaceInstance="namespaceInstance"
                            >
                            </chart-toggler>
                        </div>
                    </div>
                    <div v-if="showRaw" v-bind:key="answer" v-for="(count, answer) in questionAnswerPair._answers">
                        <div>{{ answer }}: {{ count }}</div>
                    </div>
                </div>
                <div v-if="selected && questionAnswerPair.input.type === 'textArea'">
                    <open-question
                        :answers="questionAnswerPair._answers"
                        :uniqueId="questionAnswerPair.uniqueId"
                        :editMode="editMode"
                        :userHasExportPermissions="userHasExportPermissions"
                    >
                    </open-question>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
    import OpenQuestion from './views/openQuestion.vue';
    import ChartToggler from './views/chart-toggler.vue';
    import FilterTag from './views/tag.vue';

    export default {
        name: 'questionnaire-selection',
        props: ['questionnaire', 'questionnaires', 'showRaw', 'selected', 'selection', 'onSelectionModified', 'onQuestionnaireSelectionModified', 'immutable', 'editMode', 'translate', 'report', 'namespaceInstance', 'userHasExportPermissions'],
        components: {ChartToggler, OpenQuestion, FilterTag},
        methods: {
            getOptions(questionAnswerPair){
                // if scoring, the options need to be mapped differently
                if (questionAnswerPair.input.type === "scoring"){
                    return questionAnswerPair.input.options.map((option) => {
                                return {
                                    'answer': option.answerPossibilities[0],
                                    'groupId': option.groupId
                                }
                            })
                } 
                return questionAnswerPair.input.options
            },
            getLabel(id) {
                let avForm = this.report.availableForms.find(({available_form: {form_id} = {}}) => form_id === id);
                if (!avForm) return id;
                return avForm.alias || avForm.available_form.default_alias;
            },
            getChartTogglerData(questionAnswerPair){
                return questionAnswerPair._answers;
            },
            getChartData(questionnaireId, questionAnswerPairId, type) {
                let spec = type === "hashes" ? "_groupsHashes" : "_answers";
                return this.questionnaires[questionnaireId].questionAnswerPairs[questionAnswerPairId][spec];
            },
            getFlexDirection(type){
                return type === "textArea" ? "display: flex; flex-direction: column;" : "";
            },
            changeQuestionnaireSelection(){
                this.onQuestionnaireSelectionModified(this.questionnaire.id, this.selected);
            },
            isVisible(id, answers){
                const selected = this.selected;
                const selection = this.selection;

                // Only show if we are looking at the selected section and the question is selected
                const selectedSectionCheck = selected && selection[id] && selection[id].selected;

                // Or if we are looking at the non-selected section and the question is not selected
                const unselectedSectionCheck = !selected && selection[id] && !selection[id].selected;

                // Additionally, the question should contain answers
                const answerCheck = !(Object.values(answers).every(item => item === 0));

                return ((selectedSectionCheck || unselectedSectionCheck) && answerCheck);
            },
            async removeFilter(filter){
                await this.setReportLoading(true);
                await this.$store.dispatch(this.namespaceInstance + '/removeFilter', filter);
                await this.setReportLoading(false);
            },
            isItNotFinalTag(id, filter){
                let all_filters = this.$store.getters[this.namespaceInstance + '/activeFilters']
                let filters_for_the_question = all_filters.filter( (v) => v.questionId == id)
                let index_of_filter_in_question = filters_for_the_question.findIndex( v => v.answer == filter.answer);
                return index_of_filter_in_question != filters_for_the_question.length-1;
            },
            filtersPerQuestion(question_id){
                if (this.$store.getters[this.namespaceInstance +'/activeFilters']) {
                    let target = this.$store.getters[this.namespaceInstance + '/activeFilters'].filter(v => {
                        return v.questionId == question_id 
                    })
                    return target
                } else {
                    return []
                }
            }
        },
        data() {
            return {
                categoryConfig: {
                    0: {"description": "Overig", "color": "#f0f0f0", "borderColor": "#cccccc"},
                    1: {"description": "Kritiek", "color": "#ff9999", "borderColor": "#ff0000"},
                    2: {"description": "Afwijkend", "color": "#ffcc99", "borderColor": "#ff8000"},
                    3: {"description": "Normaal", "color": "#8fcc8f", "borderColor": "#20c020"}
                },
                styles: {
                    container: {
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start"
                    },
                    labelButtonContainer: {
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        justifyContent: "space-between"
                    },
                    label: {
                        marginLeft: "15px"
                    },
                    questionAnswerContainer: {
                        width: "100%",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        backgroundColor: "white",
                        borderRadius: "2px",
                        marginTop: "auto",
                        marginBottom: "auto",
                        display: "flex",
                        flexDirection: "column",
                        // paddingBottom: "10px",
                        boxShadow: "0px 2px 5px #d3d3d3"
                    },
                    question: {
                        display: "flex",
                        paddingTop: "10px",
                        paddingLeft: "15px",
                        paddingRight: "10px",
                        backgroundColor: "white",
                        borderRadius: "10px",
                        width: "100%",
                        resize: "none",
                        border: "unset",
                        outline: "unset",
                        overflow: "auto"
                    },
                    chartContainer(categoryActive) {
                        return {
                            width: categoryActive ? "50%" : "100%",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row"
                        }
                    },
                    entireQuestionnaireButton: {
                        height: "30px",
                        position: "relative",
                        backgroundColor: "#FF6600",
                        color: "white",
                        marginRight: "10px",
                        marginTop: "20px",
                        marginBottom: "10px",
                        borderRadius: "4px"
                    },
                    filterText: {
                        marginLeft: "15px",
                        marginTop: "auto",
                        marginBottom: "auto"
                    },
                }
            }
        },
    };
</script>
