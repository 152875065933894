<template>
    <div style="width: 100%;">
        <!-- POC don't @ me -->
        <!-- TODO: also clean up, this component :is a monster now -->
        <component :is="getComponentType()"
            :key="type + '_' +answers"
            :data="formattedData"
            :name="answers"
            :colors="colors"
            :backgroundColor="'white'"
            :legendWidth="legendWidth"
            :answers="(answers === 'Antwoorden')"
            :tableActive="getTableActive()"
            :summary="summary"
            @addFilter="addFilter"
            @openFilteredModal="openFilteredModal"
            @addOrFilter="addOrFilter"
            :labelFormatter="labelFormatter"
            :half="type === 'halfPie'"
            :stacking="type === 'percentBar' ? 'percent' : false"
            :min="0"
            :max="formattedData.max"
            :votes="formattedData.votes"
            :value="formattedData.value"
            :export-button="exportButton"
            >
        </component>

        <div v-show="addingFilter"
                style="position:absolute;width:35%;height:35%;text-align:center;overflow:visible;background:#FFF;opacity:0.5;z-index:10000;color:black;padding-top:6%;"
                >
            <font-awesome-icon icon="spinner" size="3x" pulse fixed-width></font-awesome-icon>
        </div>
    </div>
</template>

<script type="text/javascript">
  import NpsView from "./npsView";
  import PieChart from './pieChart.vue';
  import ColumnChart from './columnChart.vue';
  import BarChart from './barChart.vue';
  import TableChart from './tableChart.vue';
  import GaugeChart from './gaugeChart.vue';

    export default {
        props: [
            'type', 'data', 'loadingState', 'answers', 'colors', 'legendWidth', 'width', 'config', 'target', 
            'rawData', 'options', 'summary', 'tableActive', 'namespaceInstance', 'exportButton'
        ],
        components: {PieChart, ColumnChart, BarChart, TableChart, NpsView, GaugeChart},
        data() {
            return {
                addingFilter: false,
                colourScheme: ['#a6cee3','#33a02c','#ffff99','#1f78b4','#fb9a99','#e31a1c','#fdbf6f','#ff7f00','#6a3d9a','#b2df8a','#cab2d6','#b15928']
            }
        },
        methods: {
            getComponentType(){
                const type = this.type;

                switch(type){
                    case "pie":
                    case "column": 
                    case "bar": 
                    case "table": {
                        return type + "-chart";
                        break;
                    }
                    case "halfPie": {
                        return "pie-chart";
                        break;
                    }
                    case "percentBar": {
                        return "bar-chart";
                        break;
                    }
                    case "gradeGauge": {
                        return "gauge-chart";
                        break;
                    }
                    case "nps": {
                        return "nps-view";
                        break;
                    }
                }
            },
            openFilteredModal(answer){
                // we can't show modal when edit mode is active
                if (this.$store.getters[this.namespaceInstance + '/editMode']){
                    return;
                }

                // set up information needed to filter
                let filterInfo = {
                    'answer': answer,
                    'type': this.rawData.type,
                    'questionnaireId': this.rawData.questionnaireId,
                    'uniqueId': this.rawData.uniqueId,
                    'questionId': this.rawData.questionId
                };

                // get filtered data and open modal containing said data
                this.$store.dispatch(this.namespaceInstance + '/getFilteredData', filterInfo).then((data) => {
                    if (data) this.$modal.show('patientInfo', {'data': data});
                })
            },
            addFilter(answer, edgeCase = false) {
                // we can't filter while edit mode is active
                if (this.$store.getters[this.namespaceInstance + '/editMode']){
                    return;
                }

                // every filter gets the answer and type (summary, answer or groupId), additionally non summary filters
                // get questionnaireId, uniqueId and questionId
                let filterInfo = {
                    'answer': answer,
                    'type': edgeCase || this.rawData.type,
                    'questionnaireId': edgeCase !== 'summary' ? this.rawData.questionnaireId : "",
                    'uniqueId': edgeCase !== 'summary' ? this.rawData.uniqueId : "",
                    'questionId': edgeCase !== 'summary' ? this.rawData.questionId : ""
                };

                this.addingFilter = true;

                this.$store.dispatch(this.namespaceInstance + '/createFilter', filterInfo).then(() => {
                    this.addingFilter = false;
                });
            },
            addOrFilter(answer, edgeCase = false){
                // merge with addFilter TODO

                let filter = {
                        'answer': answer,
                        'type': edgeCase || this.rawData.type,
                        'questionnaireId': edgeCase !== 'summary' ? this.rawData.questionnaireId : "",
                        'uniqueId': edgeCase !== 'summary' ? this.rawData.uniqueId : "",
                        'questionId': edgeCase !== 'summary' ? this.rawData.questionId : ""
                };     

                this.$store.dispatch(this.namespaceInstance + '/addToOrFilter', filter);
            },
            labelFormatter(name){
                if (name.length > 20) {
                    return name.slice(0, 17) + "...";
                }
                return name;

                // return name.slice(0, 17) + (name.length > 20 ? "..." : "");
            },
            getTableActive(){
                return this.tableActive;
            },
            sortData(data){
                // If every answer starts with a number or is a number, sort them by the number they start with
                // this makes sense in cases where the answers are "1 (very unlikely), 2, 3 .... 10 (very likely)"
                // used .search instead of .indexOf since .search allows for RegEx
                data.sort((a, b) => {
                    const toCheckA = Number(a.name) ? Number(a.name) : Number(a.name.substr(0, a.name.search(/\D/)));
                    const toCheckB = Number(b.name) ? Number(b.name) : Number(b.name.substr(0, b.name.search(/\D/)));
                    return toCheckA - toCheckB;
                })
            }
        },
        computed: {
            formattedData() {
                if (!this.type) return;
                if (Array.isArray(this.data)) return this.data;

                // if we're looking at answers, 
                if (this.answers){
                    // get answers in active questionnaire, filter the answers of the question
                    let answersQuestion = this.options.map(option => option.answer);
                    let correctAnswersResponse = Object.keys(this.data).filter(answer => answersQuestion.includes(answer));
                    for (const answer in this.data){
                        if (!correctAnswersResponse.includes(answer)) delete this.data[answer];
                    }
                }

                let data = Object.keys(this.data).map((key, index) => {
                    let y;
                    if (typeof this.data[key] === 'number') y = this.data[key];
                    else if (Array.isArray(this.data[key])) y = this.data[key].length;
                    else if (typeof this.data[key] === 'object') y = Object.keys(this.data[key]).length;
                    else y = 0;
                    const color = this.rawData.type === 'groupId' ? (this.config && this.config[key] && this.config[key].color) : this.colourScheme[index % this.colourScheme.length];
                    const name = this.rawData.type === 'groupId' ? this.config[key].description : key;

                    let res = this.options.find(option => option.answer === name);

                    const groupName = res ? this.config[res.groupId].description : null;
                    const groupColor = res ? this.config[res.groupId].color : null;
                    return {name, color, y, groupName, groupColor}
                });

                // check if all answers start with a number
                if (data.every((d) => { return d.name.match(/^\d/) })) {
                    this.sortData(data);
                }

                switch (this.type) {
                    case 'nps': 
                    case 'halfPie':
                    case 'table':
                    case 'pie': {
                        return data;
                        break;
                    }
                    case 'gradeGauge' : {
                        //Calculating the max and avg values
                        let max = data.length;
                        let sum = 0;
                        let votes = 0;
                        data.forEach((d) => {
                            votes += d.y;
                            sum += d.y * +d.name.match(/^\d+/);
                        });
                        return {
                            max: max,
                            value: +(sum / votes).toFixed(2), //2 decimals and back to number by plus trick
                            votes: votes
                        };
                        break;
                    }
                    case 'percentBar': {
                        data.forEach((d) => {
                            d.data = [d.y];
                        })
                        return data;
                        break;
                    }
                    case 'bar':
                    case 'column': {
                        return {
                            showInLegend: false,
                            colorByPoint: true,
                            data
                        }
                    }
                }
            }
        }
    };
</script>
