<template>
  <div>
    <div class="input-group">
      <input class="form-control" :type="showPassword ? 'text' : 'password'" v-model="model.password">
      <span class="input-group-btn">
            <button type="button" class="btn btn-success" @click="generatePassword(model)"><font-awesome-icon
                icon="key"/> Generate</button>
            <button type="button" class="btn btn-danger" @click="showPassword=!showPassword"><font-awesome-icon
                :icon="showPassword? 'lock-open' : 'lock'"/> Show</button>
          </span>
    </div>
    <password style="max-width:100%" :strength-meter-only="true" v-model="model.password"
              @score="passwordScore=$event" @feedback="passwordFeedback=$event"/>
    <div v-if="passwordFeedback.suggestions.length" v-for="suggestion in passwordFeedback.suggestions"
         class="alert alert-info" role="alert">{{ suggestion }}
    </div>
    <div v-if="passwordFeedback.warning" class="alert alert-warning" role="alert">{{ passwordFeedback.warning }}</div>
  </div>
</template>

<script>
import Password from 'vue-password-strength-meter'
import Vue from 'vue'

export default {
  components: { Password },
  props: ['value'],
  data () {
    return {
      model: '',
      showPassword: false,
      passwordScore: 0,
      passwordFeedback: {
        suggestions: [],
        warning: ''
      },
    }
  },
  methods: {
    generatePassword (model) {
      var length = 12
      var chars = 'abcdefghijklmnopqrstuvwxyz@#$%^!&*+=ABCDEFGHIJKLMNOP1234567890'
      var pass = ''
      for (var x = 0; x < length; x++) {
        var i = Math.floor(Math.random() * chars.length)
        pass += chars.charAt(i)
      }
      Vue.set(model, 'password', pass)
      Vue.set(model, 'password_confirmation', pass)
    }
  },
  watch: {
    model: {
      deep: true,
      handler($newModel) {
        this.$emit('input', $newModel)
      }
    }
  },
  created() {
    this.model = this.value
  }
}
</script>

<style scoped>

</style>