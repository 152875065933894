export default {
    methods: {
        hasPermission(permission, orgSymbol) {
            try {
                if(this.$store.state.auth.user.admin == '1') return true;     
                if(!orgSymbol) orgSymbol = this.$store.state.userOptions.options.selectedOrganizationSymbol;
                if(!orgSymbol) return false;
                // console.log(this.$store.state.auth.user.roles[0]);
                return this.$store.state.auth.user.roles.find(({organization: {symbol: roleOrgSymbol}, permissions}) => {
                    if(roleOrgSymbol !== orgSymbol) return false;
                    return permissions.find(({name}) => name === permission);
                })
            } catch(e) {
                console.error(`Fatal error while checking permissions: ${e}`);
            }
        }
    }
};
