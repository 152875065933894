<template>
    <span class="dashboard-tag-container" v-if="tag" :class="'tag_'+id" v-click-outside="clickOutside">
        <div class="dashboard-tag" :style="getStyle(tag.color, tag.group.color)">
            <div v-if="tag.group.color" class="buttons-before-container"
                 v-bind:style="{backgroundColor: tag.group.color}">
                <slot name="before-label"></slot>
            </div>
            <div v-if="!tag.groupColor" v-bind:style="{height: '18px'}"></div>
            <!-- tag content -->
            <div class="tag-content">
                <div class="tag-label" @mousedown="clicked" v-on:dblclick="dblclicked"
                    v-show="!showEdit" :style="checkCursor()"><span>{{tag.name}}</span></div>
                <template v-if="editMode">
                    <input v-show="showEdit" ref="tag_input" type="text" v-model="localName" @keyup.enter="saveNewName"
                        maxlength="16" v-autowidth="{comfortZone: 10}"/>
                </template>
                <font-awesome-icon v-if="showReplace" icon="caret-down" aria-hidden="true" @mousedown.stop="showSelectTag()" class="icon" style="margin-top: 4px;"></font-awesome-icon>
            </div>
            <!-- after tag onhover delete button (and others potentially) -->
            <div class="buttons-after-container">
                <div v-if="showDelete" class="delete" :style="buttonsStyle(groupColor,'#fff')">
                    <font-awesome-icon @mousedown.stop="deleteTag" icon="times" class="icon"></font-awesome-icon>
                </div>
                <slot name="custom-buttons-after"></slot>
            </div>
        </div>
        <template v-if="editMode">
            <div class="tag-dropdown" v-show="showDropdown">
                <button class="btn btn-default btn-block" @click.stop="quickEdit">{{ $t('tags.edit') }}</button>
            </div>
        </template>
    </span>
</template>
<script>
    import tagColors from '@/mixins/tagColors';
    import dropdownTools from "@/mixins/dropdownTools"

    import HasPermission from "@/mixins/hasPermission";
    import Vue from 'vue'

    export default {
        mixins: [tagColors, dropdownTools, HasPermission],
        data() {
            return {
                showDropdown: false,
                showEdit: false,
                localName: this.name
            };
        },
        props: {
            id: {},
            name: {},
            color: {},
            groupColor: {},
            externalId: {},
            disabled_at: {
                default: false
            },
            showDelete: {
                default: true
            },
            groupId: {
                default: false
            },
            editMode: {
                default: false
            },
            canReplace: {
                default: false
            },
            //tagEdit or groupEdit
            type: {
                default: 'tagEdit'
            },
            orgSymbol: {
                default: undefined
            }
        },
        methods: {
            checkCursor(){
                if (this.editMode || this.showReplace){
                    return 'cursor: pointer';
                }
                return "";
            },
            getStyle(color, groupColor) {
                if (!groupColor) {
                    groupColor = color;
                }
                let style = 'background-color:' + color + ';color:' + this.overlayColor(color);
                return style;
            },
            deleteTag() {
                this.$eventHub.$emit('delete-tag-modal', this.disabled_at ? 'disabledTag' : 'tag', this.id)
            },
            saveNewName() {
                // If it's the first save, don't show modal
                if (this.localName != this.tag.name) {
                    this.$emit('updated', this.id, this.localName, this.externalId);
                }
                this.showEdit = false;
            },
            clicked(event) {
                if (this.editMode) {
                    event.stopPropagation()
                    this.showDropdown = !this.showDropdown;
                } else if(this.showReplace) {
                    event.stopPropagation()
                    this.showSelectTag()
                }
            },
            dblclicked() {
                if (this.editMode) {
                    this.quickEdit();
                }
            },
            editText() {
                let self = this;
                this.showDropdown = false;
                this.showEdit = true;
                Vue.nextTick(function () {
                    self.$refs.tag_input.focus()
                })
            },
            clickOutside(event) {
                this.showDropdown = false;
                let colorDropdownElm = document.getElementById('color-dropdown-container');
                if (this.eventOutsideElement(event, colorDropdownElm)) {
                    this.showEdit = false;
                }
            },
            showSelectTag() {
                this.$emit('tagDropdown', this);
            },
            quickEdit() {
                this.showDropdown = false;
                this.editText();
                this.$eventHub.$emit('show-color-dropdown', this.type, this.id,
                    //In our UI we use group color for groups
                    this.type.substr(0, 3) == 'tag' ? this.color : this.tag.group.color,
                    this.externalId,
                    this.$el);
            }
        },
        created() {
            this.$eventHub.$on('show-tag-dropdown', (id) => {
                if (id != this.id) {
                    this.showDropdown = false;
                }
            });
            //Go right in to edit mode if we have group edit type
            if (this.type == 'groupEdit' && !this.tag.name) {
                this.editText();
            }

            this.$eventHub.$on('color-modal-save', (color, group, id, externalId, email_filename) => {
                if (group == 'tagEdit' && this.id == id) {
                    this.saveNewName();
                }
            });
        },
        computed: {
            tag() {
                //If name are set, return props
                if (typeof this.name != 'undefined' || this.type == 'groupEdit') {
                    let data = {...this.$props};
                    data.group = {color: this.groupColor, id: this.groupId};
                    return data;
                    //Or grab it by id from store
                } else {
                    return this.$store.getters['tags/getTagById']({id: this.id, orgSymbol: this.orgSymbol});
                }
            },
            showReplace() {
                return this.canReplace && this.tag && this.tag.group && !this.tag.group.is_no_group;
            }
        },
        watch: {
            //Any changes to the this.row would result in backend request so we will update it on server
            localName: {
                handler() {
                    this.localName = this.localName.toLowerCase();
                },
                deep: true
            }
        },
    };
</script>
<style>
    .dashboard-tag-container {
        position: relative;
        display: inline-block;
        margin-bottom: 1px;
        margin-right: 9px;
    }

    .dashboard-tag {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        vertical-align: top;
        border-radius: 2px;
    }

    .dashboard-tag .icon {
        cursor: pointer;
    }

    .dashboard-tag .delete, .dashboard-tag .tag-move-handle {
        visibility: hidden;
        padding: 0px;
    }

    .buttons-after-container {
        display: flex;
        flex-direction: row;
        height: 18px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        width: 11px;
        overflow: visible;
        position: absolute;
        right: -7px;
    }

    .buttons-before-container {
        height: 18px;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        width: 11px;
        overflow: hidden;
        position: relative;
        background-color: transparent;
    }

    .dashboard-tag:hover .delete, .dashboard-tag:hover .tag-move-handle {
        width: 100%;
        visibility: visible;
    }

    .dashboard-tag .tag-move-handle {
        cursor: move;
    }

    .dashboard-tag input {
        width: 50px;
        height: 100%;
        border: none;
        background: transparent;
    }

    .dashboard-tag input:focus {
        outline: none;
    }

    .tag-content {
        display: flex;
        flex-direction: row;
        height: 18px;
        padding-right: 5px;
    }

    .tag-label {
        min-height: 1em;
        margin-left: 3px;
        margin-right: 3px;
        display: flex;
        align-items: center;
    }

    .tag-dropdown {
        position: absolute;
        top: 20px;
        left: 0px;
        background: #fff;
        width: 140px;
        padding: 5px;
        z-index: 10000;
    }

    .icon {
        margin-left: 2px;
        font-size: 10px;
        margin-bottom: 2px;

    }

    .dashboard-tag:hover .delete {
        padding: 0px 0px;
    }

</style>