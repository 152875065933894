// TODO: activeFilters should just be a reference to selectedOverview.filters or something

// importing all helper functions
import * as helpers from '../helpers/customReportsHelpers';
import Vue from 'vue'

// initial state
const state = {
    activeFilters: [],
    editMode: false,
    globalLoadingState: false,
    initialHandledHashes: {},
    initialHandledQuestionnaires: [],
    initialQuestionnaires: [],
    initialSubmittedQuestionnaires: [],
    overviews: [],
    orFilterBuilding: [],
    selectableQuestionnairesQuestions: {},
    selectedOverview: {},
    questionnaires: {}
}

// getters
const getters = {
    activeFilters: state => state.activeFilters,
    editMode: state => state.editMode,
    globalLoadingState: state => state.globalLoadingState,
    initialQuestionnaires: state => state.initialQuestionnaires,
    initialHandledHashes: state => state.initialHandledHashes,
    overviews: state => state.overviews,
    orFilterBuilding: state => state.orFilterBuilding,
    selectableQuestionnairesQuestions: state => state.selectableQuestionnairesQuestions,
    selectedOverview: state => state.overviews.find(({selected}) => selected)
}

// actions
const actions = {
    addOverview({commit}, overview){
        commit('addOverview', overview);
    },
    addToOrFilter({commit}, filter){
        commit('addToOrFilter', filter);
    },
    changeEditMode({commit}, editMode){
        commit('setEditMode', editMode);
    },
    changeQuestionnaireSelection({getters, commit, dispatch}, data){
        const selectedView = getters.selectedOverview;
        const questionnaireId = data.questionnaireId;

        // TODO: comment dit ff hier
        for (const question in selectedView.selectedQuestionairesQuestions[questionnaireId]){
            commit('setSelectednessQuestion', {
                selectedView: selectedView,
                questionnaireId: questionnaireId,
                question: question,
                selectedness: data.selectedness
            });
        }

        dispatch('recalcCatAct', selectedView);
    },
    changeQuestionSelection({commit, getters, dispatch}, info){
        // add selectedView to the information needed and change status of selected-ness
        const selectedView = getters.selectedOverview;
        info.selectedOverview = selectedView;
        commit('swapQuestionSelection', info);

        dispatch('recalcCatAct', selectedView);
    },
    createFilter({getters, dispatch}, filter){
        const filteredData = helpers.addFilter(filter, getters.selectedOverview.submittedQuestionnairesByHash, getters.selectedOverview.submittedQuestionnaires, getters.selectedOverview.hashesGroupId);
        // if filtering returned nothing, don't filter (see helpers.addFilter for the cases in which this happens)
        if (!filteredData){
            return;
        }

        dispatch('effectuateFilter', {filter: filter, filteredData: filteredData});
    },
    createOrFilter({dispatch, commit, getters}, orFilters){
        // TODO: fix refiltering to work with or filters ---> Possibly fixed, test later with extra data
        // TODO: Box thingy for adding the subfilters in front end --> done but probably ugly so Rene + Mirja

        // if no filters, no filtering 
        if (!orFilters.length){
            return;
        }

        // if only one filter in selection, it's the same as a "normal" filter
        if (orFilters.length === 1){
            dispatch('createFilter', orFilters[0]).then(() => {
                commit('resetOrFilterBuilding');
            });
            return;
        }

        const filteredData = helpers.addOrFilter(orFilters, getters.selectedOverview.submittedQuestionnairesByHash, getters.selectedOverview.submittedQuestionnaires, getters.selectedOverview.hashesGroupId);
        if (!filteredData){
            return;
        }

        dispatch('effectuateFilter', {filter: orFilters, filteredData: filteredData});
    },
    createOverview({state, dispatch}, payload){
        const newOverview = helpers.createOverview(state, payload.dep, payload.label);

        // set all other overviews to not selected, as the new one will be standard selected
        const overviews = state.overviews;
        overviews.forEach((ov) => {
            ov.selected = false;
        });

        return dispatch('addOverview', newOverview).then(() => {
            return newOverview;
        });
    },
    effectuateFilter({state, getters, commit}, data){
        const {filter, filteredData}  = data;
        // otherwise, set the questionnaires and fill them with filtered data
        const resetAndHandled = helpers.setQuestionnairesAndAnswers(state.initialQuestionnaires, filteredData.submittedQuestionnaires);
        const newCatDivision = helpers.getCategoriesDivision({
            overview: getters.selectedOverview,
            questionnaireIdsQuestionIds: getters.selectedOverview.selectedQuestionairesQuestions,
            questionnaires: resetAndHandled.handledData.questionnaires
        });

        filter.patientsLeft = Object.keys(filteredData.newSubmit).length;
      
        // mutate data to be filtered, and add a filter
        commit('recalcFilteredData', {
            overview: getters.selectedOverview,
            data: resetAndHandled.handledData,
            submittedForms: filteredData.submittedQuestionnaires,
            newHashes: filteredData.newSubmit,
            newCatDivision: newCatDivision.resultArray
        });
        commit('addFilter', {overview: getters.selectedOverview, filter: filter});
    },
    getFilteredData({commit, getters}, filter){
        const filteredData = helpers.addFilter(filter, getters.selectedOverview.submittedQuestionnairesByHash, getters.selectedOverview.submittedQuestionnaires, getters.selectedOverview.hashesGroupId);

        if (!filteredData){
            return;
        }

        return filteredData;
    },
    recalcCatAct({commit}, selectedView){
        // TODO: rename recalcCatAct
        // after that, get new categoriesdivision and adjust the selected view accordingly
        const res = helpers.getCategoriesDivision({
            questionnaireIdsQuestionIds: selectedView.selectedQuestionairesQuestions,
            questionnaires: selectedView.questionnaires
        });
        commit('adjustCategoriesDivision', {result: res, view: selectedView});

        // also, change the active population amount if changed
        const activeQuestionnaires = helpers.getActiveQuestionnaires(selectedView.selectedQuestionairesQuestions);

        const activePopulation = helpers.getActivePopulation(activeQuestionnaires, selectedView.submittedQuestionnairesByHash);
        if (activePopulation !== selectedView.activePopulation){
            commit('mutateActivePopulation', {view: selectedView, activePopulation: activePopulation})
        }
    },
    removeFilter({commit, getters, state, dispatch}, filter){
        // if there's only one filter, behaviour is the same as remove all filters button
        if (state.activeFilters.length === 1){
            dispatch('resetFilters');
            return;
        }

        // filter the filters so only the filters needed remain in activeFilters
        const remainingFilters = state.activeFilters.filter((activeFilter) => {
            return filter !== activeFilter;
        });

        // refiltering is shabby, but POC first beautiful later (possible performance issue though)
        const resultRefiltering = helpers.refilter(remainingFilters, state.initialHandledHashes, state.initialSubmittedQuestionnaires, getters.selectedOverview.hashesGroupId);

        // reset questionnaires and handle the result from refiltering
        const resetAndHandled = helpers.setQuestionnairesAndAnswers(state.initialQuestionnaires, resultRefiltering.submittedQuestionnaires);
        const newCatDivision = helpers.getCategoriesDivision({
            overview: getters.selectedOverview,
            questionnaireIdsQuestionIds: getters.selectedOverview.selectedQuestionairesQuestions,
            questionnaires: resetAndHandled.handledData.questionnaires
        });

        commit('recalcFilteredData', {
            overview: getters.selectedOverview,
            data: resetAndHandled.handledData,
            submittedForms: resultRefiltering.submittedQuestionnaires,
            newHashes: resultRefiltering.newSubmit,
            newCatDivision: newCatDivision.resultArray
        });
        commit('setFilters', {overview: getters.selectedOverview, filters: remainingFilters});
    },
    removeOrFilter({commit}, filter){
        commit('removeOrFilter', filter);
    },
    removeOverview({commit, state}, overviewId){
        const overviews = state.overviews;
        const ovs = overviews.filter((ov) => {
            return ov.id !== overviewId;
        })
        commit('clearActiveFiltes');
        commit('setOverviews', ovs);
    },
    resetFilters({commit, getters, state}){
        // first get selected overview and remove the filters
        const selectedOverview = getters.selectedOverview;
        commit('removeFilters', selectedOverview);

        // reset the questionnaires
        commit('setQuestionnaires', state.initialHandledQuestionnaires);

        const newCatDivision = helpers.getCategoriesDivision({
            overview: selectedOverview,
            questionnaireIdsQuestionIds: selectedOverview.selectedQuestionairesQuestions,
            questionnaires: state.initialHandledQuestionnaires
        });

        commit('restoreCategoryDivision', {
            overview: selectedOverview,
            newCatDivision: newCatDivision.resultArray
        });

        // and then we restore the data to the initial state 
        commit('restoreData', selectedOverview);
    },
    resetOverviews({commit}){
        commit('resetOverviews');
    },
    resetOrFilterBuilding({commit}){
        commit('resetOrFilterBuilding');
    },
    setGlobalLoading({commit}, status){
        commit('setGlobalLoading', status);
    },
    setOverviews({commit}, overviews){
        commit('setOverviews', overviews);
    },
    setSelectedOverview({commit, state}, overviewId){
        // if clicked on the already active overview, nothing has to be done
        if (state.selectedOverview.id === overviewId){
            return;
        }

        state.overviews.forEach((ov) => {
            const selected = ov.id === overviewId;
            commit('setSelectednessOverview', {
                overview: ov,
                state: selected
            });
            if (selected){
                commit('setActives', ov);
            }
        });
    },
    storeInitialData({commit, dispatch, state}, data){
        // This function stores: initial data, sets questionnaires, sets overviews
        const processedData = helpers.preprocess(data);
        commit('storeInitialData', $.extend(true, {}, processedData));

        const resetAndHandled = helpers.setQuestionnairesAndAnswers(processedData.questionnaires, processedData.submittedQuestionnaires);
        commit('setQuestionnaires', resetAndHandled.resettedQuestionnaires);
        let handledData = resetAndHandled.handledData;

        commit('storeInitialHandledData', JSON.parse(JSON.stringify(handledData)));

        // fill the selectables
        const selectable = helpers.getSelectableQuestionnairesQuestions({}, resetAndHandled.resettedQuestionnaires);
        commit('setSelectable', selectable);

        // for every overview available, add categoryDivision, filters and save
        // TODO: idk maybe move this to helpers? TODO: MERGE WITH createOverview in helpers, can be done should be done
        // TODO: oh lord it's a mess, clean up!
        if (!data.overviews || !data.overviews.length){
            return;
        }
        
        // speciaal voor bram, deze is extra belangrijk! (echt heel belangrijk)
        data.overviews.forEach((overview) => {
            // skip if already in state
            if (state.overviews.find(ele => ele.id == overview.id)) return;

            let res = helpers.getCategoriesDivision({
                overview: overview,
                questionnaireIdsQuestionIds: overview.selectedQuestionairesQuestions,
                questionnaires: resetAndHandled.resettedQuestionnaires
            });

            // get active questionnaires, and population present in these questionnaires
            const activeQuestionnaires = helpers.getActiveQuestionnaires(overview.selectedQuestionairesQuestions);

            const initialPopulation = helpers.getActivePopulation(activeQuestionnaires, handledData.submittedQuestionnairesGroupedByHash);
            overview.activePopulation = initialPopulation;

            let resultRefiltering;

            // check if saved filters exist in DB return
            // TODO: Waarom setquestionnaires eigenlijk?
            if (overview.filters && overview.filters.length){
                resultRefiltering = helpers.refilter(overview.filters, state.initialHandledHashes, state.initialSubmittedQuestionnaires, res.hashesGroupId);
                handledData = helpers.setQuestionnairesAndAnswers(processedData.questionnaires, resultRefiltering.submittedQuestionnaires);
                res = helpers.getCategoriesDivision({
                    overview: overview,
                    questionnaireIdsQuestionIds: overview.selectedQuestionairesQuestions,
                    questionnaires: handledData.resettedQuestionnaires
                });
            }

            overview.filteredPopulation = resultRefiltering ? Object.keys(handledData.handledData.submittedQuestionnairesGroupedByHash).length : initialPopulation;
            
            overview.categoryDivision = res.resultArray;
            overview.hashesGroupId = res.hashesGroupId;
            overview.filters = overview.filters ? overview.filters : [];
            overview.submittedQuestionnairesByHash = resultRefiltering ? handledData.handledData.submittedQuestionnairesGroupedByHash : handledData.submittedQuestionnairesGroupedByHash;
            overview.questionnaires = resultRefiltering ? handledData.handledData.questionnaires : handledData.questionnaires;
            overview.submittedQuestionnaires = resultRefiltering ? resultRefiltering.submittedQuestionnaires : state.initialSubmittedQuestionnaires;
            dispatch('addOverview', overview);
        });
    }
}

// mutations
const mutations = {
    addFilter(state, data){
        // This looks bad, but pushing to state and overview results in duplicate filters added
        const previousActiveFilters = $.extend(true, [], state.activeFilters);
        previousActiveFilters.push(data.filter);
        Vue.set(state, 'activeFilters', previousActiveFilters);
        Vue.set(data.overview, 'filters', previousActiveFilters);
    },
    addOverview(state, overview){
        // emergency break if overview already exists, we do not add it
        if (state.overviews.find(ele => ele.id == overview.id)) return;
        state.overviews.push(overview);
        if (overview.selected) {
            Vue.set(state, 'selectedOverview', overview);
            Vue.set(state, 'activeFilters', overview.filters);
        }
    },
    addToOrFilter(state, filter){
        const previousOrFilters = $.extend(true, [], state.orFilterBuilding);
        const filterAlreadyThere = previousOrFilters.filter(filt => filt.uniqueId === filter.uniqueId && filt.answer === filter.answer);
        if (!filterAlreadyThere.length){
            previousOrFilters.push(filter);
            Vue.set(state, 'orFilterBuilding', previousOrFilters);
        }
    },
    adjustCategoriesDivision(state, result){
        Vue.set(result.view, 'categoryDivision', result.result.resultArray);
        Vue.set(result.view, 'hashesGroupId', result.result.hashesGroupId);
    },
    clearActiveFiltes(state){
        Vue.set(state, 'activeFilters', []);
    },
    swapQuestionSelection(state, info){
        // swap selectedness of question
        const selectedness = info.selectedOverview.selectedQuestionairesQuestions[info.questionnaireId][info.questionId].selected;
        Vue.set(info.selectedOverview.selectedQuestionairesQuestions[info.questionnaireId][info.questionId], 'selected', !selectedness);
    },
    mutateActivePopulation(state, data){
        Vue.set(data.view, 'activePopulation', data.activePopulation);
        // can't have more filtered people then people in the selection I guess? @bram
        if (data.view.activePopulation < data.view.filteredPopulation){
            Vue.set(data.view, 'filteredPopulation', data.activePopulation);
        }
    },
    recalcFilteredData(state, data){
        Vue.set(data.overview, 'submittedQuestionnaires', data.submittedForms);
        Vue.set(data.overview, 'questionnaires', $.extend(true, {}, data.data.questionnaires));
        Vue.set(data.overview, 'submittedQuestionnairesByHash', data.newHashes);
        Vue.set(data.overview, 'categoryDivision', data.newCatDivision);
        Vue.set(data.overview, 'filteredPopulation', Object.keys(data.newHashes).length);
    },
    removeFilters(state, overview){
        Vue.set(state, 'activeFilters', []);
        Vue.set(overview, 'filters', []);
    },
    removeOrFilter(state, filter){
        const previousOrFilters = $.extend(true, [], state.orFilterBuilding);
        const otherFilters = previousOrFilters.filter(filt => !(filt.uniqueId === filter.uniqueId && filt.answer === filter.answer));
        Vue.set(state, 'orFilterBuilding', otherFilters);
    },
    resetOverviews(state){
        Vue.set(state, 'overviews', []);
    },
    resetOrFilterBuilding(state){
        Vue.set(state, 'orFilterBuilding', []);
    },
    restoreCategoryDivision(state, data){
        Vue.set(data.overview, 'categoryDivision', data.newCatDivision);
    },
    restoreData(state, overview){
        Vue.set(overview, 'submittedQuestionnaires', state.initialSubmittedQuestionnaires);
        Vue.set(overview, 'questionnaires', state.questionnaires);
        Vue.set(overview, 'submittedQuestionnairesByHash', state.initialHandledHashes);
        Vue.set(overview, 'activePopulation', Object.keys(state.initialHandledHashes).length);
        Vue.set(overview, 'filteredPopulation', overview.activePopulation);
    },
    setActives(state, overview) {
        Vue.set(state, 'activeFilters', $.extend(true, [], overview.filters));
        Vue.set(state, 'selectedOverview', overview);
    },
    setEditMode(state, editMode){
        Vue.set(state, 'editMode', editMode);
    },
    setFilters(state, data){
        const filters = $.extend(true, [], data.filters)
        Vue.set(state, 'activeFilters', filters);
        Vue.set(data.overview, 'filters', filters);
    },
    setGlobalLoading(state, status){
        Vue.set(state, 'globalLoadingState', status);
    },
    setOverviews(state, overviews){
        Vue.set(state, 'overviews', overviews);
    },
    setQuestionnaires(state, questionnaires){
        Vue.set(state, 'questionnaires', questionnaires);
    },
    setSelectable(state, selectable){
        Vue.set(state, 'selectableQuestionnairesQuestions', selectable);
    },
    setSelectednessOverview(state, data){
        Vue.set(data.overview, 'selected', data.state);
    },
    setSelectednessQuestion(state, data){
        Vue.set(data.selectedView.selectedQuestionairesQuestions[data.questionnaireId][data.question], 'selected', !data.selectedness);
    },
    storeInitialData(state, data){
        Vue.set(state, 'initialQuestionnaires', data.questionnaires);
        Vue.set(state, 'initialSubmittedQuestionnaires', data.submittedQuestionnaires);
    },
    storeInitialHandledData(state, handledData){
        Vue.set(state, 'initialHandledQuestionnaires', handledData.questionnaires);
        Vue.set(state, 'initialHandledHashes', handledData.submittedQuestionnairesGroupedByHash);
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}