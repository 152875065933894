// initial state
import axios from 'axios'

const state = {
  activeProvider: "",
  activeProviderUrl: ""
}

// getters
const getters = {}

// actions
const actions = {
  loadParties (context) {
    const org = context.rootState.userOptions.options.selectedOrganizationSymbol;
    const departmentSymbol = context.rootState.userOptions.options.selectedDepartmentSymbol;
    return new Promise((resolve, reject) => {
      axios.get('/api/consult/available', {
        params: {
          org,
          departmentSymbol
      }}).then((result) => {
        context.commit('setParties', result.data)
        resolve(result);
      })
      .catch(function(error) {
        reject(error);
        console.log('Couldnt load parties', error)
      })
    });
  },
}

// mutations
const mutations = {
  setParties (state, data) {
    state.data = data
    let activeProvider = data.find((d) => d.enabled);
    state.activeProvider = activeProvider.provider;
    state.activeProviderUrl = activeProvider.url;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
