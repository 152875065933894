require('bootstrap-sass/assets/javascripts/bootstrap')
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// @ts-ignore
import VueI18n from 'vue-i18n'
// @ts-ignore
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)
axios.defaults.withCredentials = true;
Vue.config.devtools = true

// If we request some route and don't have access to it, let's check that we are still logged in
axios.interceptors.response.use(function (response) {
  store.commit('auth/setLastSuccessfulRequest')
  return response
}, function (error) {
  if (error.response.status === 419) {
    localStorage.setItem('error_code', '419');
    store.dispatch('auth/throttledCheckAuth')
  } else if (error.response.status === 401){
    store.dispatch('auth/logout');
  }
  return Promise.reject(error);
});

// @ts-ignore
import VueInputAutowidth from 'vue-input-autowidth';
// @ts-ignore
import vClickOutside from 'v-click-outside'
Vue.use(VueInputAutowidth)
Vue.use(vClickOutside)
Vue.use(VueI18n)

// @ts-ignore
import messages from './lang/'
// VueI18n instance
const i18n = new VueI18n({
  locale: navigator.language.split('-')[0],
  fallbackLocale: 'en',
  messages
})

import { library } from '@fortawesome/fontawesome-svg-core'
import { faTachometerAlt, faLock, faTimes, faQuestionCircle, faSpinner, faArrowRight,
  faCopy, faBan, faCaretLeft, faCaretRight, faCaretDown, faExclamationCircle, faExclamationTriangle,
  faCheckCircle, faTimesCircle, faMinusCircle, faPlus, faTrash, faEdit, faCheck, faEye,
  faSave, faPlusCircle,
  faChartPie,
  faMagnet,
  faChartBar,
  faPercent,
  faBars,
  faRecycle,
  faKey,
  faSync,
  faUser,
  faCircle as fasCircle,
  faChevronLeft,
  faUserTag,
  faNotEqual,
  faTags,
  faLockOpen
} from '@fortawesome/free-solid-svg-icons'
import { faPencil, faTachometer, faSignOut } from '@fortawesome/pro-solid-svg-icons'
import { faFilePdf, faCopy as falCopy, faEye as falEye} from '@fortawesome/pro-light-svg-icons'
import { faEye as farEye, faCircle as farCircle } from '@fortawesome/pro-regular-svg-icons'
import { faUserSecret, faPaperPlane, faUserHeadset, faWebcam, faCogs, faSparkles,
  faChartLine, faTabletAndroidAlt, faArrowCircleLeft, faInfoCircle, faSpinnerThird, faSpinner as test } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faUserSecret, faTachometerAlt, faUserHeadset, faPaperPlane, faWebcam, faCogs, faLock, faTimes, faQuestionCircle, faSpinner, faArrowRight, faCopy, faPencil, faBan,
    faCaretLeft,faCaretRight, faCaretDown, faExclamationCircle, faExclamationTriangle, faCheckCircle, faTimesCircle, faMinusCircle, faPlus, faTrash, faEdit, faCheck, faEye, faSave,
    faPlusCircle, faChartPie, faMagnet, faChartBar, faPercent, faTachometer, faBars, faRecycle, faKey, faSync, faUser, faSignOut, faSparkles, fasCircle, farCircle, farEye, falEye,
    faFilePdf, faChevronLeft, faChartLine, faUserTag, faNotEqual, faTags, faLockOpen, faTabletAndroidAlt, faArrowCircleLeft, faInfoCircle, faSpinnerThird, test)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false
Vue.prototype.$eventHub = new Vue(); //Should get rid of this once we stop using this anti-pattern :)

/**
 * Parse, validate, manipulate, and display dates in JavaScript.
 */
window.moment = require('moment-timezone');

import Highcharts from 'highcharts'
// @ts-ignore
window.highcharts = Highcharts
import HighchartsVue from 'highcharts-vue'
Vue.use(HighchartsVue)

// @ts-ignore
import hasPermission from '@/mixins/hasPermission'
Vue.mixin(hasPermission)

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// Hot updates for translations
if (module.hot) {
  module.hot.accept(['./lang/'], function () {
    i18n.setLocaleMessage('en', require('./lang/en').default)
    i18n.setLocaleMessage('de', require('./lang/de').default)
    i18n.setLocaleMessage('es', require('./lang/es').default)
    i18n.setLocaleMessage('nl', require('./lang/nl').default)
  })
}

require('../sass/app.scss')