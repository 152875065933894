<template>
    <highcharts class="chart" style="width: 100%;" :style="getMargin()" :options="chartOptions"></highcharts>
</template>

<style>
    .gaugeChart {
        /*margin-bottom:-30% !important;
        margin-top:10px;*/
    }
</style>

<script type="text/javascript">
import Vue from 'vue'
import Highcharts from 'highcharts'
import highchartsMore from 'highcharts/highcharts-more';
import solidGauge from 'highcharts/modules/solid-gauge'
highchartsMore(Highcharts);
solidGauge(Highcharts);
import {Chart} from 'highcharts-vue'
    export default {
        components: { highcharts: Chart },
        props: {
            'value': {
                default: 0,
            },
            'min': {
                default: 0
            },
            'max': {
                default: 10
            },
            'name': {
                default: ''
            },
            'target': {
                default: ''
            },
            'votes': {
                default: 0
            },
            'NPS': {
                default: false
            }
        },
        data() {
            return {
                chartOptions: {
                    chart: {
                        type: 'solidgauge',
                        backgroundColor: 'none',
                        className: 'gaugeChart'
                    },
                    pane: {
                        startAngle: -90,
                        endAngle: 90,
                        background: {
                            backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || '#EEE',
                            innerRadius: '60%',
                            outerRadius: '100%',
                            shape: 'arc'
                        },
                    },
                    credits: {
                        enabled: false
                    },
                    title: null,
                    subtitle: null,
                    yAxis: {
                        plotBands: this.drawTarget(),
                        stops: [
                            [0.5, '#DF5353'],
                            [this.stopTarget(), '#DDDF0D'],
                            [1, '#55BF3B'],
                        ],
                        lineColor: '#fff',
                        tickColor: '#fff',
                        minorTickColor: '#fff',
                        lineWidth: 1,
                        tickPixelInterval: 30,
                        minTickInterval: 1,
                        minorTickInterval: null,
                        //tickAmount: 2,
                        tickLength: 20,
                        minorTickLength: 20,
                        title: {
                            text: String(this.votes) + " reacties",
                            style: {
                                color: '#000',
                                fontWeight: 'normal',
                                fontSize: '20px',
                                lineHeight: '10px'
                            },
                            y: 75
                        },
                        min: parseInt(this.min),
                        max: parseInt(this.max),
                        labels: {
                            distance: 20,
                            rotation: 'auto'
                        },
                        endOnTick: false
                    },
                    legend: {
                        verticalAlign: 'top',
                        y: 0,
                        floating: true
                    },
                    //For some reason without this predefined structure newly loaded data do not apply
                    series: [{
                        data: [this.value],
                        dataLabels: {
                            borderWidth: 0,
                            style: {
                                fontSize: "30px"
                            },
                            y: -70
                        },
                    }],
                }
            }
        },
        //Force update when prop changes
        watch: {
            value(value) {
                // if value changes (due to like filtering) change the data and label
                Vue.set(this.chartOptions.series[0], 'data', [value]);
                Vue.set(this.chartOptions.yAxis.title, 'text', String(this.votes) + " " + this.$t('report.overview.reactions'))
            },
            votes(votes){
                // if value changes (due to like filtering) change the data and label
                Vue.set(this.chartOptions.series[0], 'data', [this.value]);
                Vue.set(this.chartOptions.yAxis.title, 'text', String(votes) + " " + this.$t('report.overview.reactions'))
            }
        },
        methods: {
            translate(key){
                return window.$t(key);
            },
            drawTarget() {
                if (this.target) {
                    return [{
                        from: this.target,
                        to: this.target,
                        borderColor: "rgba(100,255,100,0.8)",
                        borderWidth: 3,
                        innerRadius: "58%",
                        outerRadius: "102%",
                        zIndex: 5
                    }];
                } else {
                    return undefined;
                }
            },
            stopTarget() {
                if (this.target) {
                    let diff = Math.abs(this.min - this.max);
                    let target=  Math.abs(this.min-this.target);
                    return (target / diff);
                } else {
                    return 0.6;
                }
            },
            getMargin(){
              return {
                'margin-bottom': this.NPS ? "-40%" : ""
              }
            }
        },
        beforeDestroy: function() {
            // TOOD: fix so gauges are also destroyed
            // console.log(this);
            // this.highChart.destroy();
        }
    }
</script>