export default {
  menu: [
    {
      title: 'menu.dashboard',
      route: '/',
      icon: ['fas','tachometer-alt'],
      screenshot: '/img/menu/dashboard.png',
      description: 'menu.description.dashboard',
      permission: 'view_dashboard_charts',
    },
    {
      title: 'menu.intake',
      route: '/invite',
      icon: ['fad', 'paper-plane'],
      permission: 'send_invite',
      screenshot: '/img/menu/invite.png',
      description: 'menu.description.invite'
    },
    {
      title: 'menu.support',
      route: '/support',
      icon: ['fad','user-headset'],
      screenshot: '/img/menu/support.png',
      description: 'menu.description.support'
    },
    // {
    //     title: 'menu.webinars',
    //     route: 'soon',
    //     icon: 'fa-graduation-cap'
    // },
    // {
    //     title: 'menu.bookings',
    //     route: 'soon',
    //     icon: 'fa-calendar-check'
    // },
    // {
    //     title: 'menu.projects',
    //     route: 'soon',
    //     icon: 'fa-project-diagram'
    // },
    // {
    //   title: 'menu.releases',
    //   route: 'releases',
    //   icon: ['fad', 'sparkles'],
    //   screenshot: '/menu/releases.png',
    //   description: 'menu.description.releases'
    // },
    {
      title: 'dashboard.modal.label.remote_consult',
      route: '/consult',
      icon: ['fad','webcam'],
      permission: 'remote_consult',
      screenshot: '/img/menu/remote_consult.png',
      description: 'menu.description.remote_consult'
    },
    {
      title: 'menu.reports',
      route: '/reports',
      icon: ['fad','chart-line'],
      screenshot: '/img/menu/reports.png',
      permission: 'view_report_charts',
      description: 'menu.description.reports'
    },
    {
      title: 'menu.settings',
      route: 'settings',
      icon: ['fad','cogs'],
      permission: 'settings',
      screenshot: '/img/menu/settings.png',
      description: 'menu.description.settings'
    },
    {
      title: 'menu.about',
      route: '/about',
      icon: ['fad','info-circle'],
      screenshot: '/img/menu/about.png',
      description: 'menu.description.about'
    }
  ],
  submenu: [
    {
      title: 'menu.organizations',
      route: '/settings/organization',
      permission: 'admin'
    },
    {
      title: 'menu.roles',
      route: '/settings/role',
      permission: 'edit_role'
    },
    {
      title: 'menu.users',
      route: '/settings/user',
      permission: 'view_user'
    },

    {
      title: 'menu.reports',
      route: '/settings/reports',
      permission: 'edit_report_charts'
    },
    {
      title: 'menu.tags',
      route: '/settings/tags',
      permission: 'edit_tags'
    },
    {
      title: 'menu.whitelist',
      route: '/settings/whiteLists',
      permission: 'admin'
    },
    {
      title: 'menu.third_party',
      route: '/settings/thirdParty',
      permission: 'admin'
    },
    {
      title: 'menu.diff',
      route: '/settings/diff',
      permission: 'view_diff_checker'
    }
  ]
}
