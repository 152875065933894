<template>
    <div>
        <div class="clearfix u-mb-20">
            <div class="row" style="margin-right: inherit; margin-left: inherit">
                <slot name="buttons"></slot>
                <button class="btn btn-primary header-font pull-right"
                    style="padding:6px; margin-right: 12px; margin-bottom:0px" @click="clearFilters()"
                    v-if="filtersValues!=0 && showClearButton">
                    {{ $t('dashboard.table.btn_clear') }}
                </button>
            </div>
        </div>
        <div v-show="loading">Loading...</div>
        <table class="table table-hover table-striped table-condensed filtered-table" v-show="!loading">
            <thead>
                <tr>
                    <th class="header-font" v-for="(column, field) in columns" :key="field">
                        <span style="overflow:hidden;whiteSpace:nowrap;textOverflow:ellipsis" @click="sortBy(field)">
                            {{ column.label }} <span class="sort" :class="sortArrowClass(field)"></span>
                        </span>
                        <slot :name="field + '_filter'" v-bind:filters="filters"
                            v-bind:options="getOptions(column, field)"
                            v-if="!column.datepickerOption && showHeaderFilters">
                            <input v-if="!hasOptions(column)" v-model="filters[field]" class="form-control"
                                :class="field + '_filter'">
                            <v-select :multiple="isMultipleFilter(column)" v-if="hasOptions(column)"
                                v-model="filters[field]" :options="getOptions(column, field)" :ref="field + '-select'"
                                :class="field + '_filter'">
                            </v-select>
                        </slot>
                        <date-picker-wrapper v-if="column.datepickerOption && showHeaderFilters"
                            :booleanShortcuts="column.addBoolOptions" :addShortcuts="column.addOptions" :range=true
                            format='DD-MM-YYYY' v-on:new-date="updateFilters(field, $event)"
                            v-model="filters[field]"></date-picker-wrapper>
                    </th>
                </tr>
            </thead>
            <tbody>
            <tr v-for="(row, index) in paginatedRows" :key="index" v-on:click="rowClick(row, preventModal)" :class="rowClass(row)">
                <td v-for="(column, field) in columns" 
                    v-on:click="cellClick(row, field)" 
                    :class="cellClass(row, field)"
                    :title="cellTitle(row, field)" 
                    :style="cursorPointer ? 'cursor:pointer': ''"
                    @mouseover="hoveringField=field; hoveringIndex=index" 
                    @mouseleave="hoveringField=null; hoveringIndex=null">
                    <slot :name="field+'_column'" v-bind:row="row">
                        {{cellData(row, field)}}
                        <transition name="fade">
                            <span v-if="field==copiableField && hoveringField==copiableField && hoveringIndex==index" 
                                @mouseover="switchModalPrevention(true, field)" 
                                @mouseleave="switchModalPrevention(false, field)">
                                <copy-icon :copiable-str="cellData(row, field)"> </copy-icon>
                            </span>
                        </transition>
                    </slot>
                </td>
            </tr>
            </tbody>
        </table>
        <pagination :rows="filteredRows" :page="page" :tableName="tableName" v-on:update-page-number="page = $event"
            v-show="!loading"></pagination>
    </div>
</template>

<style lang="scss">
    .sort {
        display: inline-flex;
        vertical-align: middle;
        width: 0;
        height: 0;
        opacity: 0.5;
        margin: 5px;
        border-left: 5px solid #f9f9f9;
        border-right: 5px solid #f9f9f9;
    }

    th:hover .sort {
        opacity: 0.8;
    }

    .down {
        border-bottom: 5px solid #333;
    }

    .up {
        border-top: 5px solid #333;
    }

    .sorted {
        opacity: 1;
    }


    /* Latest version of datepicker with ranges */
    .filtered-table {
        .mx-datepicker {
            width: 100%;
            display: block;
        }
    }

    .v-select input {
        height: 34px;
    }

    .dropdown-toggle {
        height: 34px;
        white-space: nowrap !important;
    }
    .v-select {
        height: 34px !important;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .15s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }

</style>


<script>
    import Pagination from '../components/pagination_new.vue';
    import vSelect from "vue-select"
    import DatePickerWrapper from '@/components/datePickerWrapper'
    import CopyIcon from "@/components/copyIcon.vue"
    import Vue from 'vue'

    export default {
        props: {
            columns: Object,
            rows: Array,
            rowClick: {
                type: Function,
                default(row, preventModal) {
                }
            },
            cursorPointer:{
                default: false
            },
            rowClass: {
                type: Function,
                default(row) {
                }
            },
            loading: {
                default: false
            },
            generateRows: {
                default: false
            },
            defaultSortColumn: {
                default: undefined
            },
            tableName: {
              type: String,
              default: ''
            },
            showClearButton: {
                type: Boolean,
                default: true
            },
            copiableField: {
                type: String,
                default: ''
            },
            showHeaderFilters: {
                type: Boolean,
                default: true
            },
        },

        components: {
            DatePickerWrapper,
            'pagination': Pagination,
            'v-select': vSelect,
            CopyIcon,
        },
        data() {
            return {
                'sort': this.defaultSortColumn,
                'direction': 0,
                'filters': {},
                'page': 0,
                'hoveringField': null,
                'hoveringIndex': null,
                'preventModal': false,
            };
        },
        methods: {
            isMultipleFilter: ({multipleFilter}) => multipleFilter || false,
            sortArrowClass(field) {
                if (this.sort === field) {
                    return this.direction ? 'sorted up' : 'sorted down';
                } else {
                    return 'up';
                }
            },
            //Toggle sort
            sortBy(field) {
                this.sort = field;
                this.direction ^= true;
            },
            //Calling this on every filter fields change
            updateFilters(field, value) {
                Vue.set(this.filters, field, value);
                this.page = 0;
            },
            clearFilters(){
                for (const filter in this.filters){
                    this.filters[filter] = ""
                }
            },
            //If we have options, get
            cellData(row, field) {
                if (typeof this.columns[field].value == "function") {
                    return this.columns[field].value(row, this.filters);
                }
                else if (typeof this.columns[field] != 'undefined' &&
                    'options' in this.columns[field] && typeof this.columns[field].options == 'object') {
                    if (row[field] in this.columns[field].options) {
                        return this.columns[field].options[row[field]];
                    }
                    //If we have predefined value
                }  else if (typeof this.columns[field] != 'undefined' && 'value' in this.columns[field]) {
                    return this.columns[field].value;
                }
                return row[field];
            },
            cellTitle(row, field) {
                if (typeof this.columns[field].title == 'function') {
                    return this.columns[field].title(row);
                }
            },
            getOptions(column, field) {
                if (column) {
                    if ('options' in column) {
                        //If column options is set just return it
                        if (Array.isArray(column.options)) {
                            return column.options
                        } else if (typeof column.options == 'object') {
                            return column.options;
                        } else if (typeof column.options == 'function') {
                            return column.options(this.rows, this.filters)
                        } else if (column.options === true) {
                            //Create array, sort it, then transform it to object
                            let valuesArray = [];
                            let values = {};
                            let objectsArray = {};
                            for (let key in this.rows) {
                                valuesArray.push(this.rows[key][field]);
                            }
                            return valuesArray.sort();
                        }
                    }
                }
            },
            hasOptions(column) {
                if (column) {
                    if ('options' in column) {
                        return true;
                    }
                }
            },
            //We need this to pass vue-select in the format it wants
            convertOptions(options) {
                let formatedOptions = [];
                if (typeof options != 'undefined') {
                    for (let option in options) {
                        formatedOptions.push({'label': options[option], value: option})
                    }
                }
                return formatedOptions;
            },
            cellClick(row, field) {
                if (typeof this.columns[field] != 'undefined' &&
                    'click' in this.columns[field]) {
                    if (typeof this.columns[field].click == 'function') {
                        this.columns[field].click(row, field, this);
                    }
                }
            },
            cellClass(row, field) {
                if (typeof this.columns[field] != 'undefined' &&
                    'class' in this.columns[field]) {
                    if (typeof this.columns[field].class == 'string') {
                        return this.columns[field].class;
                    } else if (typeof this.columns[field].class === "function") {
                        return this.columns[field].class(row, this.filters);
                    }
                }
            },
            switchModalPrevention(newValue, field){
                if (field == this.copiableField){
                    this.preventModal = newValue;
                }
            }
        },
        computed: {
            newDates: function() {
                var order = this.direction ? 1 : -1;
                this.sort.sort(function(a, b) {
                    a = new Date(a.date);
                    b = new Date(b.date);
                    var results = a > b ? -1 : a < b ? 1 : 0;
                    return results * order;
                });
                }, 

            filtersValues(){
                return Object.values(this.filters).flat().filter(v => v!=null&&v.length!=0).length;
            },
            generatedRows() {
                if (this.generateRows) {
                    let generatedRows = [];
                    for (let key in this.rows) {
                        let row = this.rows[key];
                        let generatedRow = {...row}; //Clone, not reference the object so VueX would generate warning
                        for (let col in this.columns)  {
                            generatedRow[col] = this.cellData(row, col);
                        }
                        generatedRows.push(generatedRow);
                    }
                    return generatedRows;
                }
            },
            filteredRows() {
                let filteredRows = [];

                let data = [];
                if (this.generateRows) {
                    data = this.generatedRows;
                } else {
                    data = this.rows;
                }

                for (let key in data) {
                    let row = data[key];
                    let passesFilters = 1;

                    //Loop through all the filters and if we don't have match, set var to 0 to skip row
                    for (let filter in this.filters) {
                        //Check that we should process this filter at all
                        if (typeof this.columns != 'undefined' && filter in this.columns && this.filters[filter] && this.filters[filter].length) {
                            //Function callback for the ones
                            if (typeof this.columns[filter].filter == 'function') {
                                passesFilters = this.columns[filter].filter(row, this.filters);
                                //Date rangers
                            } else if (Array.isArray(this.filters[filter]) && this.columns[filter].datepickerOption) {
                              //Our silly date picker wraps the has-option and no-option in array so we need this duplicated code here :(
                                if (this.filters[filter][0] == 'has-option'  && row[filter]) {
                                  passesFilters = 1;
                                  continue;
                                } else if (this.filters[filter][0] == 'no-option' && !row[filter]) {
                                  passesFilters = 1;
                                  continue;
                                }
                                else if (this.filters[filter][0]===null) {
                                    passesFilters = true;
                                } else {
                                    passesFilters = moment.utc(row[filter], 'YYYY-MM-DD HH:mm:ss').isBetween(
                                        moment(this.filters[filter][0]),
                                        moment(this.filters[filter][1]).endOf("day"),
                                        null, '[]');
                                }

                                //Exact match for the rows that has predefined or generated options
                            } else if ('options' in this.columns[filter] && this.columns[filter].options) {
                                //If we have one of this filter values selected
                                if (this.filters[filter] == 'has-option'  && row[filter]) {
                                    passesFilters = 1;
                                    continue;
                                } else if (this.filters[filter] == 'no-option' && !row[filter]) {
                                    passesFilters = 1;
                                    continue;
                                }

                                if(this.columns[filter].multipleFilter) {
                                    if(this.filters[filter].indexOf(row[filter]) === -1) passesFilters = 0;
                                } else if ((row[filter] != this.filters[filter])) {
                                    passesFilters = 0;
                                }

                            }
                            //Simple search inside objects/arrays
                            else if (typeof row[filter] === 'object') {
                              if (!JSON.stringify(row[filter]).toLowerCase().includes(this.filters[filter].toLowerCase())){
                                passesFilters = 0;
                              }
                            }
                            //Search for regular strings and things not caught earlier
                            else {
                                if (!row[filter] || typeof row[filter] != 'string' || !row[filter].toLowerCase().includes(this.filters[filter].toLowerCase())) {
                                    passesFilters = 0;
                                }
                            }
                        }
                        //So if any of the filters fails we don't need to check other, and this row doesn't fit
                        if (!passesFilters) {
                            break;
                        }
                    }

                    if (passesFilters) {
                        filteredRows.push(row);
                    }
                }


                return filteredRows;
            },

            sortedRows() {
                let self = this;
                let sortedRows = [];
                if (self.sort) {
                    sortedRows = this.filteredRows.sort((a, b) => {
                        let outcome;
                        if(!a[self.sort]) return -1;
                        if(!b[self.sort]) return 1;

                        if (self.columns[self.sort] && typeof self.columns[self.sort].datepickerOption!='undefined' && self.columns[self.sort].datepickerOption) {
                            // For dates
                            outcome = moment(a[self.sort], 'YYYY-MM-DD HH:mm:ss').unix()-moment(b[self.sort], 'YYYY-MM-DD HH:mm:ss').unix();
                        } else if(typeof a[self.sort] === 'string' && typeof b[self.sort] === 'string') {
                            // Strings
                            outcome = a[self.sort].localeCompare(b[self.sort]);
                        } else {
                            // Integers
                            outcome = b[self.sort] - a[self.sort];
                        }

                        if(!outcome) return 0;
                        else return outcome > 0 ? 1 : -1;
                    });
                    if (this.direction == 0) {
                        sortedRows.reverse();
                    }
                } else {
                    sortedRows = this.filteredRows;
                }
                return sortedRows;
            },

            paginatedRows() {
                if (typeof this.sortedRows != 'undefined') {
                    let paginatedRows = this.sortedRows.slice(this.page * this.perPage, (this.page + 1) * this.perPage);
                    return paginatedRows;
                }
            },
            perPage: {
                get() {
                    return this.$store.state.userOptions.options['rowsPerPage_'+(this.tableName || 'shared')];
                },
                set(val) {
                    this.$store.dispatch('userOptions/setOption', {
                        option: 'rowsPerPage_'+(this.tableName || 'shared'), 
                        value: val
                    });
                }
            }
        },

        watch: {
            paginatedRows(newValue) {
                if ((this.page * this.perPage + newValue.length) > this.sortedRows.length) {
                    this.page = Math.floor(this.sortedRows.length / this.perPage);
                }
            }
        },
    };
</script>
