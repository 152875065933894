<template>
    <div class="tag-dropdown-container"
         v-click-outside="debouncedHide">
        <div v-for="tag in tags" v-on:click="replaceTag(tag)">
            <tag :showDelete="false" :id="tag.id" :orgSymbol="orgSymbol"></tag>
        </div>
    </div>
</template>
<script>
    import Tag from "@/components/tag";
    import dropdownTools from "@/mixins/dropdownTools"

    let debounce = require('lodash.debounce');

    export default {
        components: {Tag},
        mixins: [dropdownTools],
        props: [
            'selectedTag',
            'selected',
            'orgSymbol'
        ],
        data() {
            return {
                debouncedHide: debounce(this.hide, 200)
            };
        },
        computed: {
            tags() {
                if (this.selectedTag && this.selectedTag.id) {
                    return this.$store.getters['tags/getTagsFromSameGroupById']({id: this.selectedTag.id, orgSymbol: this.orgSymbol});
                }
            },
        },
        methods: {
            replaceTag(tag) {
                // Emit to tagSelect.vue
                this.$emit('replaceTag', this.selectedTag.id, tag.id);
                this.hide();
            }
        },
        watch: {
            selectedTag(val) {
                if (val) {
                    this.debouncedHide.cancel();
                    if (val) {
                        this.align(val.$el);
                        this.show();
                    }
                }
            }
        }
    }
</script>
<style>
    .tag-dropdown-container {
        position: absolute;
        z-index: 1000000;
        disaplay: none;
    }

    .tag-dropdown-container .dashboard-tag-container {
        cursor: pointer;
    }
</style>
