<template>
    <span @click="copyUrl()">
        <font-awesome-icon icon="copy" class="copy-icon"></font-awesome-icon>
    </span>
</template>

<script>
export default {
    props: ['copiableStr'],
    methods: {
        copyUrl() {
            const el = document.createElement('textarea');
            el.value = this.copiableStr;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
        },
    }
}
</script>

<style>
    .copy-icon{
        cursor: pointer;
        margin-left: 2px; 
        margin-right: 2px
    }
</style>