<template>
    <div>
        <modal name="consent" class="consent-modal" :width="'80%'" :height="'auto'" :scrollable="true"
               @before-close="beforeClosed" @closed="closed" style="z-index: 1000">
              <consent-content-loading v-if="!record"></consent-content-loading>
              <consent-content v-else v-bind:isParentModal="true"></consent-content>
        </modal>

    </div>
</template>
<script>
    import VModal from 'vue-js-modal';
    import Vue from 'vue'
    import { mapGetters, mapState } from 'vuex';
    import consentContent from './consentContent'
    import consentContentLoading from './consentContentLoading'
    Vue.use(VModal);

    export default {
        components: {
            consentContent,
            consentContentLoading,
        },
        watch: {
            modalRequested: function(requested){
                if (requested){
                    this.$modal.show('consent');
                }
            },
            record: function (val) {
                if (!val) {
                    this.$modal.hide('consent');
                }
            }
        },
        computed: {
          ...mapGetters({
            record: 'consentModal/selectedRecord',
            modalRequested: 'consentModal/getModalRequested',
          }),
          ...mapState({
            selectedOrganizationSymbol: state => state.userOptions.options.selectedOrganizationSymbol,
            preventingModalClose: state => state.consentModal.preventingModalClose,
          })
        },
        methods: {
            closed() {
                this.$store.dispatch('consentModal/toggleModalPrevent', false);
                this.$store.dispatch('consentModal/hideModal');
            },
            beforeClosed(event){
                if (this.preventingModalClose){
                    this.$store.dispatch('consentModal/toggleTagsErrorAnimation', true);
                    event.cancel();
                }
            },
        },
    }
</script>

<style>
    .consent-modal .close {
        position: absolute;
        top: 0px;
        right: 5px;
        z-index: 10002;
    }

    .close-modal {
        border: none;
    }

    .consent-modal .v-select {
        border: none;
    }

    .consent-modal .v-select .open-indicator:before {
        border: none;
        content: "\f067";
        transform: none;
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
    }

    .consent-modal.v--modal-overlay .v--modal-box  {
        overflow:visible;
    }
</style>
