<template>
    <div>
        <template  v-for="tag in tags">
            <tag :showDelete="showDelete" :orgSymbol="orgSymbol" :id="tag"/>
        </template>
    </div>
</template>

<script>
  import Tag from "@/components/tag";

  export default {
        props: {
            showDelete: {
                default: false
            },
            tags:{
                default:[]
            },
            orgSymbol: {
                default: null
            }
        },
        components: {Tag}

    }
</script>
