<template>
<div>
<div class="col-md-6">
  <multi-selector
      :selectedItem="selectedOrganization"
      :items="organizations"
      :itemLabel = "$t('layout.label.organization')"
      @update="updateSelectedOrganization"
  />
  </div>
</div>
</template>

<script>
import multiSelector from '@/layout/multiSelector'
import { mapState } from 'vuex'

export default {
  components: {
    multiSelector,
  },
  computed: {
    ...mapState('auth', {
      organizations: state => state.user.organizations
    }),
    // Based on selected organization
    availableDepartments () {
      let self = this
      if (self.selectedOrganization) {
        return this.$store.state.auth.user.departments.filter(function (value) {
          return value.organizations[0].symbol === self.selectedOrganization.symbol
        }).sort((a,b) => a.name.localeCompare(b.name));
      }
    },
    // Let's return it as object so vue-select would be happy but save only symbol in userOptions because this makes sense
    selectedOrganization: {
      get () {
        let selected = this.$store.state.userOptions.options.selectedOrganizationSymbol
        return this.organizations.find(element => element.symbol === selected);
      },
      async set (newValue) {
        await this.$store.dispatch('userOptions/setOption', { option: 'selectedOrganizationSymbol', value: newValue.symbol })
        // Select the first available department first, then user can change it
        this.$store.dispatch('userOptions/setOption', {
          option: 'selectedDepartmentSymbol',
          value: this.availableDepartments.length > 0 ? this.availableDepartments[0].symbol: '' //If there are no departments, set empty
        })
      }
    },
  },
  watch: {
    selectedOrganization(newValue) {
      this.selectedOrganization = newValue;
    }
  },
  methods: {
    updateSelectedOrganization(newValue) {
      this.selectedOrganization = newValue;
    }
  },
}
</script>
