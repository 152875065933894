<template>
    <div v-show="futureNotifications.length">
        <h3>{{ $t('dashboard.modal.future') }}</h3>
        <table class="table table-condensed table-striped">
            <tbody>
            <tr v-for="notification in futureNotifications">
                <td v-if="!notification.dates">{{ notification.date }}</td>
                <td v-if="notification.dates">{{ notification.dates.join(', ') }}</td>
                <td>{{ notification.group_id ? notification.group_id: notification.label }}</td>
                <td>
                    <span :class="'pointer light-grey-text show-'+notification.type"
                          @click="toggleNotification(notification)"
                          v-show="!notification.enabled">{{ $t('dashboard.modal.disabled') }}</span>
                    <span :class="'pointer light-blue-text hide-'+notification.type"
                          @click="toggleNotification(notification)"
                          v-show="notification.enabled">{{ $t('dashboard.modal.disable') }}</span>
                </td>
                <td align="right">
                    <span :class="'notification-label label-' + notification.type ">{{ $t('dashboard.modal.notificationType.'+notification.type) }}</span>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
    import {mapState, mapGetters} from 'vuex';
    import CronParser from 'cron-parser';
    import DateFormatMethods from '@/mixins/dateFormatMethods'

    export default {
        mixins: [DateFormatMethods],
        computed: {
            futureNotifications() {

                if(!this.record.allow_communication_email) return [];

                let self = this;
                let results = [];

                this.config.followups.forEach(function (item) {
                    if(item && item.recurring) {
                        item = self.processRecurring(item);
                    } else {
                        item = self.processNotification(item, 'followup');
                    }

                    if (item) {
                        results.push(item);
                    }
                });

                if (this.record.status != 1) { //No reminders if form already submited
                    this.config.reminders.forEach(function (item) {
                        item = self.processNotification(item, 'reminder');
                        if (item) {
                            results.push(item);
                        }
                    });
                }


                results = results.sort((a, b) => {
                    const aDateMoment = (a.dates && a.dates.length) ? moment(a.dates[0], "DD-MM-YYYY") : moment(a.date, "DD-MM-YYYY HH:mm:ss");
                    const bDateMoment = (b.dates && b.dates.length) ? moment(b.dates[0], "DD-MM-YYYY") : moment(b.date, "DD-MM-YYYY HH:mm:ss");
                    if (aDateMoment.isBefore(bDateMoment)) {
                        return -1;
                    }
                    if (aDateMoment.isAfter(bDateMoment)) {
                        return 1;
                    }
                    return 0;
                });

                return results;
            },
            ...mapState({
                hash: state => state.consentModal.selectedEpdHash
            }),
            config() {
                const {orgSymbol, department: departmentSymbol} = this.record;
                return this.$store.getters['config/getConfig']({orgSymbol, departmentSymbol})
            },
            ...mapGetters({
                record: 'consentModal/selectedRecord',
            }),
        },
        methods: {
            processRecurring(item) {
                if(!this.record.appointment_date) return;
                //1 check if required completed form and if this is the case:
                if(item.activateAfterFormCompleted && !item.activateAfterFormCompleted.formNames.filter(formName => this.record.completedForms.includes(formName)).length) {
                    //no required completed form found
                    return;
                }
                //2 check if it's still active given the appointment date:
                const deactivateDaysBeforeAppointment = item.deactivateDaysBeforeAppointment || 0;
                const appointmentMoment = moment.utc(this.record.appointment_date, 'YYYY-MM-DD HH:mm:ss');
                //3 so when will it be send out next?
                let baseMoment;
                if(item.isPreAppointment) {
                    const deactivationMoment = moment().add(appointmentMoment.diff(moment(), 'days') - deactivateDaysBeforeAppointment, 'days');
                    if(deactivationMoment.isBefore(moment())) {
                        return;
                    }
                    this.record.rows.forEach(({from, status, form, timestamp, legacy_type, created_at}) => {
                        if(!baseMoment && from == 'statuses') {
                            //if not been send out it's based on the form completed date if activateAfterFormCompleted is set, and else based on send out date
                            if(item.activateAfterFormCompleted) {
                                if(status == 1 && item.activateAfterFormCompleted.formNames.indexOf(form.form_id) > -1) {
                                    baseMoment = moment.utc(timestamp, 'YYYY-MM-DD HH:mm:ss');
                                    //baseMoment set on form completed moment
                                }
                            } else if(status == -1) {
                                baseMoment = moment.utc(timestamp, 'YYYY-MM-DD HH:mm:ss');
                                //baseMoment set on moment of invite
                            }
                        } else if(from == 'notifications' && legacy_type == item.legacy_type) {
                            //if it has been send out already, it's based on that moment
                            baseMoment = moment.utc(created_at)
                            //baseMoment set on send out moment
                        } 
                    })
                } else {
                    baseMoment = appointmentMoment;
                    //this case currently only exist for mumc post intervention recurring follow up. 
                    //activateAfter (day on frontend for some reason) is an array with numbers; each number represents the amount of days
                    item.dates = [];
                    item.day.forEach(activateAfterDays => {
                        const followupMoment = moment(baseMoment).add(activateAfterDays, 'days');
                        if(followupMoment.isAfter(moment())) { //check if it's future
                            item.dates.push(followupMoment.format('DD-MM-YYYY'));
                        }
                    });
                    if(!item.dates.length) return;
                }
                if(!baseMoment) return;
                item.type = 'followup';
                item.enabled = this.getNotificationState(item.legacy_type);
                item.date = this.stdDateTimeFormat(baseMoment.add(item.recurring.amount, item.recurring.unit));
                return item;
            },
            //Common logic for both reminder and followup
            processNotification(item, type) {
                //Test that content match so it relates to this record
                if (Array.isArray(item.content) &&
                    item.content.indexOf(this.record.content) == -1) {
                    return false;
                }

                //Calculate date

                //If it's followup we need to have an appointment and we start counting from it's date
                let notificationSendDate;
                if (type == 'followup') {
                    let baseMoment;
                    if(item.isPreAppointment) {
                        baseMoment = moment.utc(this.record.created); 
                    } else if (this.record.appointment_date && this.record.appointment_status != "canceled") {
                        baseMoment = moment.utc(this.record.appointment_date, 'YYYY-MM-DD HH:mm:ss')
                    }
                    if(!baseMoment) return;
                    notificationSendDate = baseMoment.add(item.day, 'days').local();
                    if (item.cron) {

                        var cronArr = item.cron.split(' ');
                        cronArr[1] = '23';
                        var cronStr = cronArr.join(' ');

                        // A cron is defined, which means we need to add the next occurrence of the cron/batch processing after the current followUpDate as the new followUpDate
                        let cronParser = CronParser.parseExpression(cronStr, {currentDate: notificationSendDate});  // We use the normal appointment date here because we need to take the next cron occurrance taken after this date
                        notificationSendDate = cronParser.next()._date;
                    }
                } else { //type is reminder
                    //For reminder we add the days to the creation of the record
                    notificationSendDate = moment.utc(this.record.created, 'YYYY-MM-DD HH:mm:ss').add(item.day, 'days').local();
                    //never send reminders after the appointment date:
                    if(moment(this.record.appointment_date).isBefore(notificationSendDate)) return false 
                }

                // If it was already sent we don't need to show it. We don't actually check DB for that, just check that it supposed to be already sent
                // TODO: We DO need to check the database for whether it has been sent.
                if (notificationSendDate < moment()) {
                    //For negative future apointments with neg. followups in the past, we make sure the followup will be sent in the first batch after the creation date
                    if (item.day < 0 && moment().isBefore(moment(this.record.appointment_date))){
                        notificationSendDate = moment(this.record.created).add(1, 'days').local();
                        notificationSendDate = notificationSendDate.set({'hour': 11, 'minute': 0, 'second': 0});
                    } else {
                        return false;
                    }
                }

                // reminders with remoteConsult=true should always be hidden for appointments without RC
                if (item.type == 'reminder' && item.remoteConsult && !this.record.has_remote_consult){
                    return false;
                }

                item.type = type;
                item.date = this.stdDateTimeFormat(notificationSendDate);
                item.enabled = this.getNotificationState(item.legacy_type);
                return item;
            },
            getNotificationState(legacy_type) {
                let state = true;
                this.record.rows.forEach((sentNotification) => {
                    if (sentNotification.from == 'notifications' &&
                        sentNotification.legacy_type == legacy_type &&
                        sentNotification.channel == 'stop') {
                        state = false;
                    }
                });
                return state;
            },
            toggleNotification(notification) {
                this.$store.dispatch('consentModal/toggleNotification', {notification}).then(response => {

                }, error => {
                    this.validationError = error;
                })
            }
        }
    }
</script>
