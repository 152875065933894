<template>
  <div class="container-fluid">
    <!-- Buttons to chose "create new intake" or "launch existing intake" -->
    <div v-if="choice.length == 0">
      <div class="row header">
        {{$t('pin_login.what')}}
      </div>
        <div class="row options">
          <div class="col-md-3 option" @click="$emit('selection','invitation')">
            <div class="option-group">
              <div class="row">
                <p class="col-md-12 text-center pagination-centered">
                  <font-awesome-icon :icon='["fad", "paper-plane"]' size="5x"/> 
                </p>
              </div>
              <div class="row">
                <div class="col-md-12 text-center pagination-centered  option-text" style="color: #333333;">
                  {{$t('pin_login.create_intake')}}
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3 option" @click="$emit('selection','intakes')">
            <div class="option-group">
              <div class="row">
                <p class="col-md-12 text-center pagination-centered">
                  <font-awesome-icon :icon='["fad", "tablet-android-alt"]' size="5x"/> 
                </p>
              </div>
              <div class="row">
                <div class="col-md-12 text-center pagination-centered option-text" style="color: #333333;">
                  {{weblink ? $t('pin_login.launch_intake_weblink') : $t('pin_login.launch_intake')}}
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      choice: ''
    }
  },
  //can we use this to check if the user has permissions for the invite page?
  computed: {
    ...mapState('auth', [
      'user'
    ]),
    weblink() {
      return window.location.pathname.split('?')[0] === '/weblink'
    }
  },
}
</script>

<style scoped>
  * {
    font-family: "Raleway", sans-serif;
  }

  .header{
    font-size: 22pt;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 33px;
    margin-top: 70px;
  }

  .options {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .option {
    box-shadow: 0 1px 3px;
    margin-right: 10px;
    margin-left: 10px;
    padding: 50px 20px;
    cursor: pointer;
    border-radius: 2%;
  }

  .option-text {
    margin-top: 10pt;
  }

  .option-group{
    color: #0083b9 !important;
  }
  
  .option-group:hover{
    color: #ff6000 !important;
    font-weight: bold;
  }
  .container-fluid{
    padding: 0px 50px;
  }
</style>