const state = {
    excludedColumns : {
        'conversionTable': [],
        'weblink' : ['epd', 'date']
    }
}

function filterObject(obj, target){
    return Object.keys(obj)
    .filter((key) => !(target.includes(key)))
    .reduce((cur, key) => { return Object.assign(cur, { [key]: obj[key] })}, {});
}

const getters = {
    columns: (state, getters, rootState, rootGetters) => (
        tableOptions, 
        dashboardTranslations, 
        formatDate, 
        context = null, 
        selectedOrganizationSymbol = null
    ) => { 
        let cols = {
            epd: {
                label: dashboardTranslations.table.epd,
            },
            relatedIcs: {
                label: dashboardTranslations.table.related_intake,
                value: (row) => {
                    return row.relatedIcs && row.relatedIcs.length ? 'Yes' : 'No';
                },
                options: ['Yes', 'No'],
                filter: (row, filters) => {
                    if(filters && filters.relatedIcs) {
                        const hasRelatedIcs = row.relatedIcs && row.relatedIcs.length;
                        return (hasRelatedIcs && filters.relatedIcs === 'Yes') || (!hasRelatedIcs && filters.relatedIcs === 'No')
                    }
                    return true;
                }
            },
            fullname: {
                label: dashboardTranslations.table.fullname,
            },
            content: {
                label: dashboardTranslations.table.content,
                multipleFilter: true,
                options: tableOptions && tableOptions['content'] ? tableOptions['content'] : ''
            },
            status: {
                label: dashboardTranslations.table.status,
                multipleFilter: true,
                options: [
                    dashboardTranslations.status['-1'],
                    dashboardTranslations.status['0'],
                    dashboardTranslations.status['1'],
                    dashboardTranslations.status['-2']
                ],
                // Maybe we need to built in key=>value object handling to filteredTable
                // so we wouldn't need this manual value/filter but for now this works
                value: (row) => {
                    return dashboardTranslations.status[row.status];
                },
                filter: (row, filters) => {
                    if(filters && filters.status) {
                    // Translate the row and check that it's in filters array (it's already translated)
                    return filters.status.includes(dashboardTranslations.status[row.status])
                    }
                    return true;
                }
            },
            date: context == 'conversionTable' ? {
                label: dashboardTranslations.table.date,
                options: tableOptions && tableOptions['date'] ? tableOptions['date'] : '',
                title: function (row) {
                    return formatDate(row.date, 'YYYY-MM-DD HH:mm:ss', 'DD-MM-YYYY HH:mm:ss')
                },
                datepickerOption: true,
                value: function (row) {
                    return formatDate(row.date, 'YYYY-MM-DD HH:mm:ss', 'DD-MM-YYYY')
                }
            } : { 
                // for the pinLogin/weblink view
                // todo: unifying these two would make the code simpler, align if functionally it's also desirable and do it
                label: dashboardTranslations.table.date,
                title: function (row) {
                    return row.date+' '+row.time;
                },
                datepickerOption: true
            },
            firstCompletedFormCreatedDate: {
                label: dashboardTranslations.table.intake_completed_date,
                datepickerOption: true,
                addOptions:['last7days', 'last30days'],
                value: function (row) {
                  return formatDate(row.first_completed_intake_date, 'YYYY-MM-DD HH:mm:ss', 'DD-MM-YYYY')
                },
                title: function (row) {
                  if (row.firstCompletedFormCreatedDate) {
                    return formatDate(row.first_completed_intake_date, 'YYYY-MM-DD HH:mm:ss', 'DD-MM-YYYY HH:mm:ss')
                  }
                }
            },
            firstCompletedFormGroup: {
                label: dashboardTranslations.table.risk,
                multipleFilter: true,
                options: [
                  dashboardTranslations.danger['0'],
                  dashboardTranslations.danger['1'],
                  dashboardTranslations.danger['2'],
                  dashboardTranslations.danger['3'],
                  dashboardTranslations.danger['4']
                ],
                value: function (row) {
                  return dashboardTranslations.danger[row.firstCompletedFormGroup];
                },
                filter: (row, filters) => {
                  if (filters && filters.firstCompletedFormGroup) {
                    // Translate the row and check that it's in filters array (it's already translated)
                    return filters.firstCompletedFormGroup.includes(dashboardTranslations.danger[row.firstCompletedFormGroup])
                  }
                  return true;
                }
            },
            location: {
                label: dashboardTranslations.table.location,
                options: ['home', 'hospital']
            },
            appointment_date: {
                label: dashboardTranslations.table.appointment,
                options: tableOptions && tableOptions['appointment_date'] ? tableOptions['appointment_date'] : '',
                addBoolOptions: true,
                title: function (row) {
                  if (row.appointment_date) {
                    return formatDate(row.appointment_date, 'YYYY-MM-DD HH:mm:ss', 'DD-MM-YYYY HH:mm:ss')
                  }
                },
                datepickerOption: true,
                value: function (row) {
                  return formatDate(row.appointment_date, 'YYYY-MM-DD HH:mm:ss', 'DD-MM-YYYY')
                }
            },
            phone_number_full: {
                label: dashboardTranslations.table.phone_number_full,
            },
        };

        // TODO: extend excludedColumns to orgs
        if(selectedOrganizationSymbol && selectedOrganizationSymbol.indexOf('gza') !== 0) {
            delete cols.relatedIcs;
        }

        //TODO: make generic
        if(selectedOrganizationSymbol && (selectedOrganizationSymbol.indexOf('csh') === 0 || selectedOrganizationSymbol.indexOf('demohospitalstaging') === 0 || selectedOrganizationSymbol.indexOf('cmrc') === 0)) {
            cols.phone_number_full = {
                label: dashboardTranslations.table.phone_number_full
            }
        }

        if (tableOptions && tableOptions['tags'] && tableOptions['tags'].length) {
            const tagsMappedByName = rootGetters["tags/getTagsMappedByName"]();
            cols.tags = {
                label: dashboardTranslations.table.tags,
                options: tableOptions['tags'],
                filter: (row, filters) => {
                    if(!filters.tags || !filters.tags.length) return true;
                    if(!row.tags || !row.tags.length) return false;
                    return filters.tags.every((tagId) => {
                        let tag = rootGetters["tags/getTagById"]({id: tagId});
                        return row.tags.some(id => {
                            return tagsMappedByName[tag.name].some(id2 => id2 === `${id}_${row.orgSymbol}`);
                        })
                    });
                }
            }
        }

        if (tableOptions && tableOptions['department'] && tableOptions['department'].length>1) {
            cols.department = {
                label: dashboardTranslations.table.department,
                options: tableOptions['department'],
            }
        }

        if (tableOptions && !tableOptions['fullname']) {
            delete cols.fullname;
        }

        // add appointment type if both hospital and video exist
        if (tableOptions && tableOptions['appointment_type'] && tableOptions['appointment_type'].length > 1){
            cols.appointment_type = {
                multipleFilter: true,
                label: dashboardTranslations.table.appointment_type,
                options: tableOptions['appointment_type'],
                value: function (row) {
                  return row.appointment_type;
                }
            }
        }

        // if hospital saves serviceType and specialty, add cols
        if (tableOptions && tableOptions['service_type'] && tableOptions['service_type'].length > 0){
            cols.service_type = {
                multipleFilter: true,
                label: dashboardTranslations.table.service_type,
                options: tableOptions['service_type'],
                value: function (row) {
                  return row.service_type;
                }
            }
        }

        if (tableOptions && tableOptions['specialty'] && tableOptions['specialty'].length > 0){
            cols.specialty = {
                multipleFilter: true,
                label: dashboardTranslations.table.specialty,
                options: tableOptions['specialty'],
                value: function (row) {
                  return row.specialty;
                }
            }
        }

        if ((selectedOrganizationSymbol && selectedOrganizationSymbol.indexOf("meander") == 0) || window.location.href.indexOf('medify-sandbox.com') > 0 || context != "weblink") {
            cols.date_sent = {
                label: dashboardTranslations.table.date_sent,
                title: function (row) {
                    return formatDate(row.date_sent, 'YYYY-MM-DD HH:mm:ss', 'DD-MM-YYYY HH:mm:ss')
                },
                datepickerOption: true,
                value: function (row) {
                    return formatDate(row.date_sent, 'YYYY-MM-DD HH:mm:ss', 'DD-MM-YYYY')
                }
            };
        }

        if ((selectedOrganizationSymbol && selectedOrganizationSymbol.indexOf("polidirect") == 0)) { 
            cols.origin = {
                label: dashboardTranslations.table.origin,
                options: tableOptions && tableOptions['origin'] ? tableOptions['origin'] : '',
            }
        }

        // Remove excluded cols based on context:
        if (!context || !(context in state.excludedColumns)){
            console.warn(`tableColumns store, generating the columns object, checking excluded columns based on context:
            failed to find context "${context}" in excludedColumns ${Object.keys(state.excludedColumns)}, ignoring exclusions`)
            return cols;
        } else {
            let filteredCols = filterObject(cols, state.excludedColumns[context]);
            return filteredCols;
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
}