<template>
  <form v-on:submit.prevent="save">
    <h3 v-if="title()"> {{title()}} </h3>
    <div v-for="(field, i) of fields" class="form-group" :key="i">
      <slot :name="field+'_field'" v-bind:field="field" v-bind:model="model" v-bind:errors="errors">
        <!-- Todo: translations-->
        <label>{{ getFieldLabel(field) }}</label>
        <slot :name="getField(field)+'_input'" v-bind:field="getField(field)" v-bind:model="model" v-bind:errors="errors">
          <input :type="getType(field)" :disabled="getFieldDisabled(field)" class="form-control" v-model="model[getField(field)]" :class="{ 'has-errors': errors[field]}" 
            :id="typeof field == 'object' ? field['name'] : field"
          >
        </slot>
      </slot>
      <p v-if="hint(field)" class="help-block" v-html="hint(field)"></p>
      <div v-if="errors && errors[field]" v-for="error of errors[field]" class="alert alert-danger">{{$t(getError(error, field), {
        'attribute': getFieldLabel(field).toLowerCase(),
        ...extraMsgs[field]
      })}}</div>
      <div v-for="valError of valErrors[fieldsArray[i]]" class="alert alert-danger" :key="valError">{{$t('validation.'+valError, {
        'attribute': getFieldLabel(field).toLowerCase(),
        ...validationExtraMsgs[field]
      })}}</div>
    </div>
    <slot name="submitButton" v-bind:save="save">
      <button type="submit" class="btn btn-default">Save</button>
    </slot>
  </form>
</template>

<script>
import isEqual from 'lodash.isequal'
import * as Validator from 'validatorjs'
import validationRules from './validationRules.json'
export default {
  props: {
    fields: Array,
    validationKeys: Object,
    validationExtras: Object,
    validationExtraMsgs: {
      type: Object,
      default: () => {return {}}
    },
    value: [Array, Object],
    errors: {
      type: Object,
      default: () => {return {}}
    },
    module: String
  },
  data () {
    return {
      model: {},
      valRules: validationRules.rules,
      valMsgs: validationRules.msgKeys,
      valErrors: {}, 
      extraMsgs: {},
    }
  },
  mounted () {
    this.initValErrors()
  },
  created () {
    this.model = {...this.value}
  },
  watch: {
    model: {
      handler (newVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    value: {
      handler (newVal, oldVal) {
        if (!isEqual(newVal, oldVal)) {
          this.model = {...newVal}
        }
      },
      deep: true
    }
  },
  methods: {
    hint (field) {
      let trans = 'editing_table.' + this.module + '.' + field + '_hint'
      // It returns the trans string if translation not found so let's check is it set
      if (this.$te(trans)) {
        return this.$t(trans)
      }
    },
    title() {
      let trans = 'editing_table.' + this.module + '.modal_title'
      // It returns the trans string if translation not found so let's check is it set
      if (this.$te(trans)) {
        return this.$t(trans)
      }
    },
    initValErrors(){
      //this populates valErrors with the aprorpiate keys to avoid reactivity issues
      this.valErrors = this.fieldsArray.reduce((acc,curr)=> (acc[typeof curr == "object" ? curr["name"] : curr]=[],acc),{});
    },
    save() {
      if (this.validationKeys && typeof this.fieldRules != 'undefined'){
        this.initValErrors()
        let validation = new Validator(this.model, this.fieldRules, this.valMsgs);
        if (validation.passes()){
          this.$emit('save')
        } else {
          for (const [field, error] of Object.entries(validation.errors.all())) {
            this.valErrors[field] = error;
          }
        }
      } else {
        this.$emit('save')
      }
    },
    getField(field) {
      if (typeof field === 'object') {
        return field.name
      } else {
        return field
      }
    },
    getType(field) {
      if (typeof field === 'string') {
        return field.includes('password') || field.includes('pin')  ? 'password' : 'text'
      }
    },
    //todo: translations
    getFieldLabel (field) {
      const fieldName = typeof field === 'object' ? field.name : field
      let trans = 'editing_table.' + this.module + '.' + fieldName
      return this.$te(trans) ? this.$t(trans) : fieldName
    },
    getFieldDisabled (field) {
      // So this is a silly workaround for now.
      // If we have ID then we are using this to create a new thing and no fields should be disabled
      // todo: Make this in a better way from architectural standpoint. Maybe somehow pass the mode (new or editing).
      // Or just change "disabled" in the prop outside of this component. But then we need to have the info is this disabled or not there.
      if (this.model.id === undefined) {
        return false
      }
      if (typeof field === 'object') {
        return field.disabled
      } else {
        return false
      }
    },
    getError(error, field = "") {
      if (typeof error === 'object') {
        const message = Object.keys(error)[0]
        return this.$t(message, error[message])
      } else {
        if (error.charAt(0) == '[') {
          let parsedError = JSON.parse(error);
          if (!field in Object.keys(this.extraMsgs)){
            this.extraMsgs[field] = {}
          }
          this.extraMsgs[field] = parsedError[1]
          return 'validation.' + parsedError[0];
        }
        return error
      }
    }
  },
  computed: {
    fieldsArray() {
      if (Array.isArray(this.fields)) {
        return this.fields.map(v => typeof v==='object' ? v.name : v);
      } else {
        return Object.keys(this.fields);
      }
    },
    fieldRules(){
      if (this.validationKeys){
        let result = {};
        this.fieldsArray.forEach(field => {
          if (field in this.validationKeys) {
            let valKey = this.validationKeys[field];
            result[field] = this.valRules[valKey]
            if (this.validationExtras && field in this.validationExtras) {
              for (const [rule, secondField] of Object.entries(this.validationExtras[field])) {
                result[field] += '|' + rule + ':' + secondField;
              }
            }
          }
        })
        return result;
      } 
    },
  },
}
</script>
