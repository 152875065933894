<template>
    <table class="table table-striped" style="width: 100%;">
        <thead>
            <tr>
                <th>{{ $t('Answer') }}</th>
                <th>{{ $t('N')}}</th>
                <th>{{ $t('%')}}</th>
                <th v-if="answers">Category</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="row in convertedData">
                <td @click="click(row, $event)" :style="styles.pointer(row.color)">{{ row.question }}</td>
                <td @click="modalClick(row)" :style="styles.nonPointer">{{ row.answers }}</td>
                <td @click="modalClick(row)" :style="styles.nonPointer">{{ row.percentage}}%</td>
                <td @click="catClick(row, $event)" v-if="answerTable" :style="styles.pointer(row.groupColor)"> {{row.groupName}}</td>
            </tr>
        </tbody>
        <tfoot>
            <tr v-bind:style="{'border-top': '3px solid #000'}">
                <td>{{$t('Total')}}</td>
                <td colspan=10>{{ total }}</td>
            </tr>
        </tfoot>
    </table>
</template>

<script type="text/javascript">
    export default {
        props: ['data', 'name', 'answers'],

        data() {
            return {
                rows: [],
                answerTable: Boolean(this.data[0].groupColor),
                total: 0,
                convertedData: [],
                styles: {
                    nonPointer: {
                        'width': '65px',
                        'maxWidth': '65px',
                        'cursor': 'pointer',
                        'overflow': 'hidden',
                        'text-overflow': 'ellipsis',
                        'whiteSpace': 'nowrap',
                        'textDecoration': 'underline'
                    },
                    pointer(bgcolor) {
                        return {
                            'backgroundColor': bgcolor ? bgcolor : "",
                            'cursor': 'pointer',
                            'width': '50px',
                            'maxWidth': '50px',
                            'overflow': 'hidden',
                            'text-overflow': 'ellipsis',
                            'whiteSpace': 'nowrap'
                        }
                    }
                }
            }
        },

        mounted() {
            this.$nextTick(() => {
                this.setTableData();
            });
        },

        methods: {
            setTableData() {
                // Sometimes, a patient answered a question with an answer which is not in the current question anymore. In this case,
                // val.groupName and val.groupColor will be null. This will be fixed once uniqueIds are not generated in JS anymore but 
                // actually exist
                if (typeof this.data !== 'undefined') {
                    this.rows = this.data.length;
                    this.total = 0;
                    for (let key in this.data){
                        this.total += this.data[key].y;
                    }
                    this.convertedData = [];
                    for (let key in this.data) {
                        let val = this.data[key];
                        this.convertedData.push({
                            'question': val.name,
                            'answers': val.y,
                            'percentage': val.y == 0 ? 0 : Math.round((val.y / this.total) * 100),
                            'groupName': val.groupName,
                            'groupColor': val.groupColor,
                            'color': val.color
                        });
                    }
                }
            },
            click(row, event) {
                if (event.shiftKey){
                    this.$emit('addOrFilter', row.question);
                    return;
                }
                this.$emit('addFilter', row.question);
            },
            catClick(row, event){
                if (event.shiftKey){
                    this.$emit('addOrFilter', row.groupName, 'groupId');
                    return;
                }
                this.$emit('addFilter', row.groupName,'groupId');
            },
            modalClick(row){
                this.$emit('openFilteredModal', row.question);
            }
        },
        watch: {
            data() {
                this.setTableData();
            }
        }
    };

</script>
