import Vue from 'vue';
import axios from 'axios'
// initial state
const state = {
  options: {
    selected_period_conversion: 'last30days',
    selected_period_appointments: 'next30days',
    dates_conversion:[],
    dates_appointments:[],
    dashboardActiveTab:'conversion',
    rowsPerPage_conversion: 10,
    rowsPerPage_appointments: 10,
    rowsPerPage_shared: 10,
    showAppointmentTab: true,
    selectedOrganizationSymbol: '',
    selectedDepartmentSymbol: '',
    locale: 'en',
    userOptionsLoaded: false //This one is to keep track that initial load already happened
  },
  sessionId: 0,

}

// getters
const getters = {
  getTagById: (state) => (id) => {
    return state.tags.find(tag => tag.id === id)
  },
  getSessionId: (state) => {
    return state.sessionId;
  },
}

// actions
const actions = {
  setOption(context, {option, value}) {
    return axios.put( '/api/options/' + option, {
      value: value
    }).then(function () {
      context.commit('setOption', {option: option, value: value});
    })
  },
  loadOptions(context) {
    return axios.get( '/api/options').then(function(result){
      context.commit('setOptions', result.data);
      context.commit('setOption', {option: 'userOptionsLoaded', value: true});
    })
  },
  initSessionId(context){
    if (context.state.sessionId == 0){
      context.commit('updateSessionId', Math.floor(Math.random() * 1000000))
    }
  },
}

// mutations
const mutations = {
  setOptions(state, options) {
    //Update only what we have
    for (const option in options) {
      Vue.set(state.options, option, options[option]);
    }
  },
  setOption(state, {option, value}) {
    Vue.set(state.options, option, value);
  },
  updateSessionId(context, value){
    state.sessionId = value;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
