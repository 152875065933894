<template>
    <tr>
        <td class="patient-date-cell">
            {{ row.created_at | stdDateTimeFormat }}
        </td>
        <td>
            {{row.user.name + ': ' + row.body }}
        </td>
        <td align="right">
            <span class="notification-label label-comment">{{ $t('dashboard.modal.label.comment') }}</span>
        </td>
    </tr>
</template>

<script>
  import RowMixin from "./rowMixin";

  export default {
        mixins:[RowMixin]
    }
</script>
