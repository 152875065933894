<template>
    <div class="column-chart" style="width: 100%"></div>
</template>

<script type="text/javascript">
    import Highcharts from 'highcharts';
    import exportingInit from 'highcharts/modules/exporting'
    import offlineExportingInit from 'highcharts/modules/offline-exporting'
    import exportDataInit from "highcharts/modules/export-data";

    exportingInit(Highcharts)
    offlineExportingInit(Highcharts)
    exportDataInit(Highcharts);

    import {icon} from '@/downloadIcon.js'
    Highcharts.SVGRenderer.prototype.symbols.download = icon.iconPath

    export default {
        props: ['data', 'loadingState', 'name', 'colors', 'width', 'backgroundColor', 'labelFormatter'],

        data() {
            return {
                highChart: null
            }
        },

        mounted() {
            this.$nextTick(() => {
                this.renderChart();
                this.setChartData();
            });
        },

        methods: {
            renderChart() {
                let self = this;
                let chartOptions = {
                    chart: {
                        renderTo: self.$el,
                        type: 'column',
                        backgroundColor: this.backgroundColor
                    },
                    credits: {
                        enabled: false
                    },
                    title: {
                        text: ''
                    },
                    subtitle: {
                        text: ''
                    },
                    xAxis: {
                        type: 'category'
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: 'Aantal'
                        }
                    },
                    tooltip: {
                        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            borderWidth: 0,
                            events: {
                                click: function(event) {
                                    event.preventDefault();
                                    if (event.shiftKey){
                                        self.$emit('addOrFilter', event.point.name);
                                        return;
                                    }
                                    self.$emit('addFilter', event.point.name);
                                }
                            }
                        },
                        series: {
                            cursor: "pointer"
                        }
                    },
                    series: [],
                    exporting: {
                        chartOptions: { // specific options for the exported image
                            chart: {
                                backgroundColor: '#FFFFFF'
                            }
                        },
                        fallbackToExportServer : false,
                        buttons: {
                            contextButton: {
                                enabled: this.exportButton,
                                symbol: "download",
                                symbolStrokeWidth: 1, //default: 3
                                menuItems: [
                                    'downloadCSV',
                                    'downloadPDF',
                                    'downloadPNG',
                                    'downloadJPEG',
                                ]
                            },
                        }
                    },
                    legend: {
                        labelFormatter: function() {
                            return self.labelFormatter(this.name);
                        }
                    }
                };

                if (self.colors && self.colors.length)
                {
                    chartOptions.colors = self.colors;
                }

                self.highChart = new Highcharts.chart(chartOptions);
            },

            removeChartData() {
                if (this.highChart.series) {
                    for (let i = this.highChart.series.length - 1; i >= 0; i--) {
                        this.highChart.series[i].remove();
                    }
                }
            },

            setChartData() {
                let self = this;
                self.highChart.addSeries(this.data);
            }
        },

        watch: {
            /**
             * Watch chart data to trigger set series and redraw the chart if it hasn't been drawed yet
             */
            data() {
                let self = this;
                self.removeChartData();
                self.setChartData();
                debounce(() => {
                    self.highChart.redraw();
                }, 200)();
            },

            /**
             * Check if data is in loading process to show/hide loading message on chart
             */
            loadingState(loading) {
                if (loading) {
                    this.highChart.showLoading();
                } else {
                    this.highChart.hideLoading();
                }
            }
        },
        beforeDestroy: function() {
            this.highChart.destroy();
        }
    };

</script>
