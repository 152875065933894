import axios from 'axios'
// initial state
const state = {
    tags: {
        _default: []

    },
    _orgSymbols: [],
    tag_activated_emails: {
        _default: []
    }
}

// getters
const getters = {
    loadedOrgs: (state)=> () => {
        return state._orgSymbols;
    },
    getTagById: (state) => ({id: pseudoId, orgSymbol = '_default'}) => {
        //so pseudoId can be of format {tagId}_{orgSymbol}.
        let [id, potentialOrgSymbol] = pseudoId.toString().split('_');
        if(potentialOrgSymbol) orgSymbol = potentialOrgSymbol;
        id = parseInt(id);
        if(!state._orgSymbols.some((symbol) => symbol === orgSymbol)) {
            return null;
        }
        return state.tags[orgSymbol].find(tag => tag.id === id)
    },
    getTagsFromSameGroupById: (state, getters) => ({id, orgSymbol}) => {
        const excludedTag = getters.getTagById({id, orgSymbol});
        if (excludedTag.group) {
            return state.tags[orgSymbol].filter(tag => tag.group.id === excludedTag.group.id && tag.id !== excludedTag.id)
        }
    },
    getAllIds: state => (orgSymbol) => {
            return !state._orgSymbols.some((symbol) => symbol === orgSymbol) ? [] : state.tags[orgSymbol].map(tag => tag.id);
    },
    //Promise version, just in case.
    // getAllIds: state => (orgSymbol) => {
    //     return new Promise((resolve) => {
    //         setTimeout(() => {
    //             resolve(!state._orgSymbols.some((symbol) => symbol === orgSymbol) ? [] : state.tags[orgSymbol].map(tag => tag.id));
    //         }, 0);
    //     });
    // },

    getTagsMappedByName: state => () => {
        const tagsMappedByName = {};
        state._orgSymbols.forEach(orgSymbol => {
            state.tags[orgSymbol].forEach(({id, name}) => {
                tagsMappedByName[name] = tagsMappedByName[name] || [];
                tagsMappedByName[name].push(`${id}_${orgSymbol}`);
            });
        });
        return tagsMappedByName;
    },
    getDefaultTags: state => (orgSymbol) => {
      const defaultTags = [];
      if (!state.tags[orgSymbol]) {
        return []
      }
      for (const tag of state.tags[orgSymbol]) {
        if (tag['is_default']) {
          defaultTags.push(tag.id)
        }
      }
      return defaultTags
    },
    getTagActivatedEmails: state => (orgSymbol) => {
        return state.tag_activated_emails[orgSymbol];
    },
    getAlterableIds: state => (orgSymbol) => {
        let alterables = [];
        for (const tag of state.tags[orgSymbol]) {
            if (tag['group']['alterable']==1) {
               alterables.push(tag.id)
            }
        }
        return alterables;
    }
}

// actions
const actions = {
    async loadTags(context, {orgSymbol} = {}) {
        const params = {};
        
        if(orgSymbol) {
            params.org = orgSymbol;
        }

        await axios.get('/api/tag/get', {
            params
        }).then((result) => {
            context.commit('setTags', {tags: result.data, orgSymbol});
        }).catch((response) => {
            console.log('Couldnt load available tags', response);
            });
    },
    async requestTagActivatedEmails(context, {orgSymbol = "", departmentSymbol = ""}) {
        const params = {};
        if(orgSymbol) {
            params.org = orgSymbol;
        }

        if(departmentSymbol) {
            params.dpt = departmentSymbol;
        }

        await axios.get('/api/tag-activated-emails', {
            params
        }).then((result) => {
            context.commit('setTagActivatedEmails', {tag_activated_emails: result.data, orgSymbol, departmentSymbol});
            return result;
        }).catch((response) => {
            console.log('Couldnt load available tag-activated emails', response);
        });
    }
}

// mutations
const mutations = {
    setTags(state, {tags, orgSymbol = '_default'}) {
        if(orgSymbol !== '_default' && state._orgSymbols.indexOf(orgSymbol) === -1) state._orgSymbols.push(orgSymbol);
        state.tags[orgSymbol] = tags;
    },
    setTagActivatedEmails(state, {tag_activated_emails, orgSymbol = '_default'}) {
        state.tag_activated_emails[orgSymbol] = tag_activated_emails;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
