import axios from 'axios'
// initial state
const state = {
    selectedOrganization:'',
    selectedDepartment:'',
    _orgDepSymbols: []
};

// getters
const getters = {
    getConfig: (state, getters, rootState) => ({orgSymbol, departmentSymbol}) => {
        //fallback for records without department
        //TODO: check if there are still records without a department specified
        if (!departmentSymbol) {
            departmentSymbol = rootState.userOptions.options.selectedDepartmentSymbol
        }
        return state._orgDepSymbols.indexOf(`${orgSymbol}_${departmentSymbol}`) > -1 && state[orgSymbol][departmentSymbol]; 
    },
    getCurrentConfig: (state, getters, rootState) => () => {
        const orgSymbol = rootState.userOptions.options.selectedOrganizationSymbol
        const departmentSymbol = rootState.userOptions.options.selectedDepartmentSymbol
        return getters['getConfig']({ orgSymbol, departmentSymbol })
    }
}

// actions
const actions = {
    loadConfig(context, {orgSymbol, departmentSymbol}) {
        if (!departmentSymbol) {
            departmentSymbol = ''
        }
        //check if we have not yet loaded them, not sure if we should use an action for this anyway...
        const data = context.getters.getConfig({orgSymbol, departmentSymbol});
        if(!data) {
            return axios.get('/api/config/notification-types', {params: {org: orgSymbol, departmentSymbol: departmentSymbol}}).then(({data}) => {
                context.commit('setConfig', {orgSymbol, departmentSymbol, data});
            }).catch((response) => {
                console.log('Couldnt load available notification types', response);
            });
        }
    }
}

// mutations
const mutations = {
    setConfig(state, {orgSymbol, departmentSymbol, data}) {
        state._orgDepSymbols.push(`${orgSymbol}_${departmentSymbol}`);
        state[orgSymbol] = state[orgSymbol] || {};
        state[orgSymbol][departmentSymbol] = state[orgSymbol][departmentSymbol] || {};
        state[orgSymbol][departmentSymbol] = data;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}