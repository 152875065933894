<template>
  <div>
    <lang-selector style="width:70px;float:right;list-style: none; margin: 1em"/>
    <div class="container">
      <div class="row">
        <div class="col-md-4 col-md-offset-4">
          <img src="/img/medify.png" style="width:70%;margin:25% 15% 15% 15%">
          <div class="login-panel panel panel-default" style="margin-top: 5%;">
            <div class="panel-heading">
              <h3 class="panel-title">{{ $t('editing_table.auth.send_reset_email_title') }}</h3>
            </div>
            <div class="panel-body" v-if="emailSent">
              <div class="alert alert-success" role="alert">
                {{ $t('editing_table.auth.sent') }}
              </div>
            </div>
            <div class="panel-body" v-else>
              <div v-if="$route.query.password_expired" class="alert alert-success">
                {{ $t('editing_table.auth.password_expired')}}
              </div>
              <universal-form :fields="['email']" module="auth" @input="model=$event" :errors="errors">
                <template v-slot:submitButton="{ submit }">
                  <input @click="emailReset(model)" type="submit" :value="$t('editing_table.auth.send_password_link')" class="btn btn-lg btn-success btn-block">
                </template>
              </universal-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import universalForm from '@/components/universalForm'
import LangSelector from '@/layout/langSelector'
export default {
  components: { universalForm, LangSelector },
  data() {
    return {
      'model': {},
      'emailSent': false,
      'errors': {}
    }
  },
  methods: {
    ...mapActions('auth', [
        'email'
    ]),
    emailReset(form) {
      let self = this
      this.email(form).then(function() {
        self.emailSent = true
      }).catch(({response}) => {
        if (response.data && response.data.errors) {
          this.errors = response.data.errors
        }
      })
    }
  }
}
</script>