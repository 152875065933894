<template>
  <div>
    <lang-selector style="width:70px;float:right;list-style: none; margin: 1em"/>
    <div class="container">
      <div class="row">
        <div class="col-md-4 col-md-offset-4">
          <img src="/img/medify.png" style="width:70%;margin:25% 15% 15% 15%">
          <div class="login-panel panel panel-default" style="margin-top: 5%;">
            <div class="panel-heading">
              <h3 class="panel-title">{{ $t('editing_table.auth.reset_password_title') }}</h3>
            </div>
            <div class="panel-body">
              <!--autocomplete ser to nope because => https://stackoverflow.com/questions/12374442/chrome-ignores-autocomplete-off -->
              <universal-form :fields="['password', 'password_confirmation']" module="auth" @input="model=$event" :errors="errors" autocomplete="nope">
                <template v-slot:submitButton="{ submit }">
                  <div v-if="errors.token" v-for="token of errors.token" class="alert alert-danger">{{$t(token)}}</div>
                  <input id="login-password-submit" @click="doReset(model)" type="submit" :value="$t('editing_table.auth.reset_password')" class="btn btn-lg btn-success btn-block">
                </template>
                <template v-slot:password_input="{model}">
                  <password-input v-model="model"/>
                </template>
              </universal-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import universalForm from '@/components/universalForm'
import passwordInput from '@/components/passwordInput'
import LangSelector from '@/layout/langSelector'
export default {
  components: { universalForm, passwordInput, LangSelector },
  data() {
    return {
      'model': {},
      'errors': {}
    }
  },
  methods: {
    ...mapActions('auth', [
        'reset', 'checkAuth'
    ]),
    doReset(form) {
      let self = this
      form.email = this.$route.query.email
      form.token = this.$route.params.resetToken
      this.reset(form).then(function() {
        self.checkAuth().then(function() {
          self.$router.push('/')
        })
      }).catch(({response}) => {
        if (response.data && response.data.errors) {
          this.errors = response.data.errors
        }
      })
    }
  }
}
</script>