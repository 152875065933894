<template>
  <div>
    <div class="locked-overlay" v-show="locked">
      <div v-if="record" class="alert alert-info locked-message" role="alert">
        <font-awesome-icon icon="lock" aria-hidden="true"></font-awesome-icon>
                  {{ $t('dashboard.modal.locked', record.editor) }}
                  <font-awesome-icon icon="times" style="cursor: pointer;" @click="closed()"></font-awesome-icon>
                </div>
              </div>
              <div v-if="record && config" class="modal-body clearfix" style="min-height: 400px;  overflow: visible;">

                <div class="clearfix consent-header-container">
                  <h2 v-show="record.content" class="modal-procedure-title pull-left">
                    <small>{{ record.fullname.length > 2 ? record.fullname : $t('dashboard.modal.epd') }}: {{
                      record.epd.epd
                    }}
                    </small>
                    <small v-if="record.origin in origins">({{ record.origin }})</small>
                    <br>
                    {{ record.content }}
                    {{ record.hidden ? $t('dashboard.modal.invitation_hidden') : '' }}
                    <br>
                    <small>{{ record.department }}</small>
                  </h2>
                  <div v-if="userAdmin">
                    <div class="input-wrapper">
                      <input type="text" id="analyticsInput" placeholder="Report #">
                    </div>
                  </div>
                  <div v-if="userAdmin" class="analytics-input-container">
                    <button class="btn btn-sm btn-primary resend-button" @click="showAnalytics">Patient Analytics</button>
                  </div>
                  <div>
                    <button class="btn btn-sm btn-default resend-button" @click="triggerResendModal">
                      {{ $t('dashboard.modal.resend') }}
                    </button>
                  </div>
                  <div>
                    <hide-ic-popover></hide-ic-popover>
                  </div>

                  <div>
                    <button v-show="isParentModal" class="close-modal" type="button" @click="$modal.hide('consent')">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                </div>


                <div class="button-container">
                  <div style="display:flex;align-items:center;margin-bottom:5px" v-if="config.intake_location">
                    <b style="width: 61px">{{ $t('dashboard.modal.intake') }}</b>

                    <!-- Home / hospital switch -->
                    <div class="location-block">
                      <button type="button" :class="'btn-toggle ' + record.location" data-toggle="button" aria-pressed="false"
                        autocomplete="off" @click="showLocationModal()">
                        <p class="home">Home</p>
                        <div class="handle">
                          <font-awesome-icon v-if="locationChanged" icon="spinner" spin></font-awesome-icon>
                        </div>
                        <p class="hospital">Hospital</p>
                      </button>
                    </div>
                  </div>
                </div>

                <!-- Fillable by -->
                <div style="display:flex;align-items:center; margin-bottom: 9pt"
                  v-if="record.has_zd && record.origin && origins[record.origin]">
                  <span><b> Fillable by patient journey:</b> {{ origins[record.origin].journey ? 'Yes' : 'No' }} </span>
                  <span style="margin-left: 12pt;"><b> Fillable by patient portal:</b>
                    {{ origins[record.origin].portal ? 'Yes' : 'No' }} </span>
                  <span style="margin-left: 12pt;"><b> Fillable by link in mail:</b>
                    {{ origins[record.origin].mail ? 'Yes' : 'No' }}
                  </span>
                </div>

                <!-- Notification modal -->
                <modal name="locationModal" :height="'auto'" @closed="clear">
                  <div class="modal-header">
                    <button style="margin-bottom: 10px" type="button" class="close" @click="hide">
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <h4>{{ header }}</h4>
                  </div>
                  <div class="modal-body clearfix">
                    <div class="checkbox" v-show="isEmailStoredOnBackend">
                      <label>
                        <input type="checkbox" v-model="useSavedEmail"> {{ $t('dashboard.modal.send_using_stored_email')
                        }}
                        <span v-if="emailFromBackend">: {{ emailFromInput }} </span>
                      </label>
                    </div>
                    <div v-show="showInput">
                      <label>{{ $t('dashboard.modal.patient_email') }}</label>
                      <input v-model="email" type="email" class="form-control">
                    </div>
                    <div class="alert alert-danger" role="alert"
                      v-if="showEmailError && (!useSavedEmail || !resendModal || !isEmailStoredOnBackend)">
                      {{ $t('validation.email', { attribute: 'email' }) }}
                    </div>
                    <div v-if="backendErrors.length">
                      <div class="alert alert-danger" role="alert" v-for="error in backendErrors" :key=error>
                        {{ error }}
                      </div>
                    </div>

                  </div>
                  <div class="modal-footer">
                    <button type="submit" class="btn" @click="hide">{{
                      $t('dashboard.modal.btn_cancel') }}
                    </button>
                    <button v-if="!resendModal" type="submit" class="btn btn-success" @click="sendIntakeLocation(false)">
                      {{ $t('dashboard.modal.not_send_email') }}
                    </button>
                    <button v-if="!resendModal" type="submit" class="btn btn-success" @click="sendIntakeLocation(true)">{{
                      $t('dashboard.modal.send_email') }}
                    </button>
                    <button v-if="resendModal" type="submit" class="btn btn-success" @click="resendInvitation()">{{
                      $t('dashboard.modal.send_email') }}
                    </button>
                  </div>
                  <div v-show="loading"
                    style="position:absolute;top:0px;left:0px;width:100%;height:100%;text-align:center;background:#000;opacity:0.5;padding-top:100px">
                    <font-awesome-icon icon="spinner" size="3x" pulse fixed-width></font-awesome-icon>
                  </div>
                </modal>
                <!-- Zoho Analytics Button and modal -->
                <div v-if="showAnalyticsPopup" class="analytics-popup">
                  <div class="analytics-content">
                    <iframe frameborder="0" class="analytics-iframe" :src="getAnalyticsUrl()"></iframe>
                    <button class="btn btn-sm btn-default resend-button close-button" @click="hideAnalytics">Close</button>
                  </div>
                </div>


                <div style="display:flex;align-items:center" v-show="options && options.length > 0">
                  <b style="width: 75px">{{ $t('tags.tags') }}:</b>
                  <add-tags :ic_id="record.id" :tags="record.tags" :ic="record" :orgSymbol="record.orgSymbol"
                    :key="'hash' + record.hash_epd" style="flex-basis:100%" :locked="locked"></add-tags>
      </div>

      <notifications-table></notifications-table>
      <form-tabs></form-tabs>
      <div v-if="weblink" @click="weblinkSendView()">
        <a href="javascript:void(0);">{{ $t('dashboard.modal.new_intake_link') }}</a>
      </div>
      <router-link v-else @click.native="navNewIntake" to="/">{{ $t('dashboard.modal.new_intake_link') }}</router-link>
      <future-notifications v-if="record.location != 'hospital'"></future-notifications>
    </div>
    <preview-form style="z-index: 10001"></preview-form>
  </div>
</template>

<script>
import VModal from 'vue-js-modal';
import Vue from 'vue';
Vue.use(VModal);
import { mapState, mapGetters } from 'vuex';
import HideIcPopover from './hideIcPopopver.vue';
import AddTags from '@/components/addTags';
import NotificationsTable from './notificationsTable';
import FormTabs from './formTabs';
import FutureNotifications from './futureNotifications';
import PreviewForm from './previewForm';

export default {
  components: {
    FutureNotifications,
    HideIcPopover,
    AddTags,
    NotificationsTable,
    FormTabs,
    PreviewForm
  },
  props: ['isParentModal'],
  data() {
    return {
      tagsLoaded: false,
      locationChanged: false,
      email: '',
      updatedEmail: null,
      loading: false,
      useSavedEmail: true,
      resendModal: false,
      showEmailError: false,
      origins: {
        'ZD-digital': { journey: true, portal: true, mail: false },
        'ZD-paper': { journey: false, portal: true, mail: true },
        'dashboard': { journey: false, portal: true, mail: true }
      },
      backendErrors: [],
      newEmail: "",
      showAnalyticsPopup: false
    };
  },
  computed: {
    ...mapState({
      hash: state => state.consentModal.selectedEpdHash,
      userAdmin: state => state.auth.user.admin
    }),
    ...mapGetters({
      record: 'consentModal/selectedRecord',
      locked: 'consentModal/locked',
    }),
    config() {
      const { orgSymbol, department: departmentSymbol } = this.record;
      return this.$store.getters['config/getConfig']({ orgSymbol, departmentSymbol });
    },
    weblink() {
      return window.location.pathname.split('?')[0] === '/weblink'
    },
    options() {
      return this.$store.getters['tags/getAllIds'](this.record.orgSymbol);
    },
    //Promise version just in case
    //async options () {
    //     let result = await this.$store.getters['tags/getAllIds'](this.record.orgSymbol);
    //     return result;
    // },


    header() {
      return this.emailSent ? this.$t('dashboard.modal.sent_email') : this.$t('dashboard.modal.first_sent_email');
    },
    emailSent() {
      let sent = false;
      this.record.rows.forEach(function (row) {
        if (row.from == 'notifications' &&
          row.type == 'invitation' &&
          row.channel == 'email' &&
          row.receiver == 'patient'
        ) {
          sent = true;
        }
      });
      return sent;
    },
    isEmailStoredOnBackend() {
      // returns true if we do have an email for this patient in the backend
      return this.record.epd.useremail && (this.record.epd.useremail == 1 || this.record.epd.useremail.indexOf('@') > -1);
    },
    emailFromBackend() {
      // returns the email address if we do have one and we have the permission to see it
      return this.record.epd.useremail.length > 1 ? this.record.epd.useremail : null;
    },
    emailFromInput() {
      // Returns the email used in resendInvitation().
      // If it hasn't been used, it returns the email from the backend.
      return this.updatedEmail ? this.updatedEmail : this.emailFromBackend;
    },
    showInput() {
      return !this.useSavedEmail || !this.isEmailStoredOnBackend;
    },
    relatedIcs() {
      return this.record.rows
        .filter(row => "log_name" in row && row.log_name == "api_intake_link_external")
        .map(row_external => {
          return {
            hash: row_external.properties.intake_external.hash,
            org: row_external.properties.intake_external.organization
          };
        });
    },
    validEmail() {
      let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(this.email);
    },
    allow_communication_email_formatted() {
      if ("allow_communication_email" in this.record) {
        return this.record.allow_communication_email == 1 ? "yes" : "no";
      } else {
        return "-";
      }
    },
  },
  methods: {
    showLocationModal() {
      this.clear();
      if (this.record.location == 'home') {
        this.sendIntakeLocation();
      } else {
        this.resendModal = false;
        this.$modal.show('locationModal');
      }
    },
    clear() {
      this.email = '';
      this.loading = false;
      this.useSavedEmail = true;
    },
    sendIntakeLocation(sendEmail) {
      let self = this;
      if (!sendEmail || self.useSavedEmail || this.validEmail) {
        self.loading = true;
        this.$store.dispatch('consentModal/toggleLocation', {
          email: self.email,
          sendEmail: sendEmail,
          useSavedEmail: self.useSavedEmail,
        }).then(response => {
          self.hide();
        }, error => {
          self.loading = false;
          self.backendErrors = error.data.errors.email.map(e => this.$t(e));
        });
      } else {
        this.showEmailError = true;
      }
    },
    triggerResendModal() {
      this.resendModal = true;
      this.$modal.show('locationModal');
    },
    resendInvitation() {
      let self = this;
      if (self.useSavedEmail || this.validEmail) {
        self.loading = true;
        this.$store.dispatch('consentModal/resendInvitation', {
          email: self.email,
          useSavedEmail: self.useSavedEmail,
        }).then(response => {
          self.newEmail = self.email;
          self.hide();
        }, error => {
          self.loading = false;
          self.backendErrors = error.data.errors.email.map(e => this.$t(e));
        });
      } else {
        this.showEmailError = true;
      }
    },
    hide() {
      this.clear();
      this.$modal.hide('locationModal');
    },
    navNewIntake() {
      // router-link needs a "to" property, however it supports this router.push as well
      const patientEmail = this.newEmail.length ? this.newEmail : this.record.epd.useremail;
      this.$router.push(`/invite?epd=${this.record.epd.epd}&useremail=${patientEmail}`);
    },
    getAnalyticsUrl() {
      var number = document.getElementById('analyticsInput').value;
      return `https://analytics.medify.eu:8443/open-view/${number}?ZOHO_CRITERIA=%22epd_string%22%20%3D%20'${this.record.epd.epd}'`;
    },
    showAnalytics() {
      this.showAnalyticsPopup = true;
    },
    hideAnalytics() {
      this.showAnalyticsPopup = false;
    },
    weblinkSendView(){
      // 1. Notify the backend that this patient card is now available to others
      this.$store.dispatch('consentModal/hideModal');
      // 2. Jump to the "send" view
      this.$store.dispatch('consentModal/toggleWeblinkSendView', true);
    }
  },
  watch: {
    record: {
      handler: function (newRecord) {
        if (newRecord && newRecord['epd']) {
          this.updatedEmail = newRecord['epd']['useremail'];
        }
      },
      deep: true
    },
    useSavedEmail(newValue) {
      if (newValue == true) {
        this.email = "";
      }
    },
    email() {
      //entering a new email hides the error message if any
      this.showEmailError = false;
      this.backendErrors = [];
    }
  }
};
</script>

<style>
.danger1 {
  color: #ff9999;
}

.danger2 {
  color: #ffcc99;
}

.danger3 {
  color: #8fcc8f;
}

.actions-patient-card {
  display: block;
  margin-top: 5px;
}

.actions-patient-card i {
  font-size: 20px;
  margin-right: 5px;
}

.locked-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  z-index: 10001;
  text-align: center;
  padding-top: 70px;
}

.consent-header-container {
  display: flex;
  justify-content: space-between;
}

.modal-procedure-title {
  width: 100%;
}

.resend-button {
  margin-right: 5px;
  font-family: "Raleway", Arial, Helvetica, sans-serif;
  color: white;
  border-color: #0071A0;
  background-color: #0083B9;
}

.btn-toggle {
  margin: 2.5rem 8rem 0 6rem;
  padding: 0;
  position: relative;
  border: none;
  height: 2.5rem;
  width: 5rem;
  border-radius: 1.5rem;
  color: #6b7381;
  background: #bdc1c8;
  top: 50%;
  transform: translateY(-50%);
}

.btn-toggle:focus,
.btn-toggle:active {
  outline: none !important;
}

.btn-toggle>.handle {
  position: absolute;
  top: 0.1675rem;
  left: 0.1875rem;
  width: 2.125rem;
  height: 2.125rem;
  border-radius: 1.125rem;
  background: #fff;
  transition: left 0.25s;
}

.btn-toggle>.handle>i {
  margin-top: 3px;
}

.btn-toggle.hospital>.handle {
  left: 2.6875rem;
  transition: left 0.25s;
}

.btn-toggle.hospital {
  background-color: #29b5a8;
}

.btn-toggle.home p.hospital,
.btn-toggle.hospital p.home {
  opacity: 0.5;
}

.btn-toggle p.home {
  left: -5rem;
}

.btn-toggle p.hospital {
  right: -8rem;
  width: 7rem;
}

.btn-toggle p {
  line-height: 1.5rem;
  width: 4rem;
  text-align: center;
  font-weight: 600;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: absolute;
  bottom: -5px;
  transition: opacity 0.25s;
}

.location-block {
  width: 100%;
  display: flex;
  align-items: center;
}

.location-block span {
  width: 10%;
}

.button-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.analytics-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  /* Higher z-index value to ensure it appears on top */
}

.analytics-content {
  width: 80%;
  height: 80%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  overflow: hidden;
}

.analytics-iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.close-button {
  margin-top: 20px;
}

.analytics-form {
  max-width: 80px;
  margin-right: 10px;
}

.input-wrapper {
  margin-right: 10px;
}

.input-wrapper input {
  width: 80px;
  height: 30px;
}
</style>
