<template>
    <div style="width: 100%;">
        <gauge-chart
                v-if="(typeof gaugeValue == 'number')"
                min="-100"
                max="100"
                :target="target || 20"
                :value="gaugeValue"
                :votes="totalResponses"
                :NPS="true">
        </gauge-chart>

        <bar-chart
                key="percentBar_nps"
                :data="npsActive"
                :name="name"
                :backgroundColor="'#fff'"
                :legendWidth="legendWidth"
                :labelFormatter="getLabelFormatter()"
                stacking="percent">
        </bar-chart>
        
        <bar-chart
                key="percentBar_nps_all"
                :data="npsAll"
                :name="name"
                :backgroundColor="'#fff'"
                :legendWidth="legendWidth"
                :labelFormatter="getLabelFormatter()"
                stacking="percent">
        </bar-chart>
    </div>
</template>

<script>
    import BarChart from './barChart.vue';
    import GaugeChart from './gaugeChart.vue';
    export default {
        props: ['data', 'name', 'colors', 'backgroundColor', 'width', 'legendWidth', 'target', 'labelFormatter'],
        components: {BarChart, GaugeChart},
        methods: {
            getLabelFormatter(){
                return this.labelFormatter;
            },
            stackedData(data) {
                let stackedData = [];
                for (let key in data) {
                    stackedData.push({
                        name: data[key].name,
                        color: data[key].color,
                        data: [data[key].y]
                    });
                }
                return stackedData;
            },
            buildData(val) {
                if (val) {
                    //Gauge data
                    let npsData = [
                        {
                            name: "Detractors",
                            color: "#ff6666",
                            y: 0
                        },
                        {
                            name: "Neutral",
                            color: "#999999",
                            y: 0
                        },
                        {
                            name: "Promotors",
                            color: "#9dffac",
                            y: 0
                        }
                    ];

                    for (let key in val) {
                        let row = val[key];
                        row.name = row.name.match(/^\d+/);
                        if (row.name < 7) {
                            npsData[0].y += row.y;
                        } else if (row.name > 8) {
                            npsData[2].y += row.y;
                        } else {
                            npsData[1].y += row.y;
                        }
                    }

                    let totalResponses = npsData[0].y + npsData[1].y + npsData[2].y;
                    this.totalResponses = totalResponses;
                    let detractors = Math.round((npsData[0].y / totalResponses) * 100);
                    let promoters = Math.round((npsData[2].y / totalResponses) * 100);
                    let npsScore = promoters - detractors;

                    this.gaugeValue = npsScore;
                    this.npsAll = this.stackedData(val).reverse();
                    for (let key in this.npsAll) {
                        this.npsAll[key].color = this.getColor((parseInt(key) + 1) / this.npsAll.length)
                    }
                    this.npsActive = this.stackedData(npsData).reverse();
                }
            },
            getColor(value) {
                //value from 0 to 1
                var hue = ((1 - value) * 120).toString(10);
                return ["hsl(", hue, ",90%,70%)"].join("");
            }
        },
        watch: {
            data: function (val) {
                this.buildData(val);
            }
        },
        data() {
            return {
                'gaugeMax': 0,
                'gaugeValue': false,
                'npsAll': [],
                'npsActive': []

            }
        },
        mounted() {
            this.$nextTick(() => {
                this.buildData(this.data);
            });
        }
    }
</script>
