<template>
    <tr>
        <td class="patient-date-cell">
            {{ row.timestamp | stdDateTimeFormat }}
            <div class="actions-patient-card" v-if="row.status == '1' && row.has_form">
                <span v-if="hasPermission('view_form_group')">
                    <font-awesome-icon icon="circle" size="lg" :class="groupClass(row.form.group, 'included')"></font-awesome-icon>
                </span>

                <span v-if="hasPermission('export_dashboard_form')" @click="downloadForm(row)">
                    <font-awesome-icon :icon="['fal', 'file-pdf']" class="pointer reportSectionGroupIcon" size="lg"></font-awesome-icon>
                </span>

                <span v-if="hasPermission('preview_dashboard_form')" @click="togglePreviewForm(row)">
                    <font-awesome-icon :icon="['fal', 'eye']" class="pointer .reportSectionGroupIcon" size="lg"></font-awesome-icon>
                </span>
            </div>
        </td>
        
        <td v-bind:class="{ canceled_appointment: row.status == 'canceled'}">
            {{ statuses[row.status] }}
            <span v-if="row.status == '0' && row.hasOwnProperty('step')"> - {{$t('dashboard.category.step') }} {{row.step}} </span>
            <span class="text-muted" v-if="row.form && row.status == '1'">(Qid: {{ row.form.form_id }})</span>
            <div class="reportSections" v-if="hasPermission('view_form_group') && row.form && row.form.report_sections && row.form.report_sections.length && row.form.report_sections_groups" >
                <div v-for="(reportSectionN, type, i) in reports">
                    <div class="excludedText" v-if="type=='excluded'&&reports.excluded.length>0"> {{$t('dashboard.modal.excluded')}} </div>
                    <div class="reportSection" v-for="reportSection in reportSectionN" v-bind:key="reportSection.id">
                        <font-awesome-icon :icon="[(type=='excluded'? 'far' : 'fas'), 'circle']" size="sm"
                            :class="'reportSectionGroupIcon danger' + row.form.report_sections_groups[reportSection.id]" 
                            ></font-awesome-icon>

                        <span v-if="hasPermission('export_dashboard_form')" @click="downloadForm(row, reportSection.id)">
                            <font-awesome-icon :icon="['fal', 'file-pdf']" class="pointer reportSectionGroupIcon" size="lg"></font-awesome-icon>
                        </span>

                        <span v-if="hasPermission('preview_dashboard_form')" @click="togglePreviewForm(row, reportSection.id)">
                            <font-awesome-icon :icon="['fal', 'eye']" class="pointer reportSectionGroupIcon" size="lg"></font-awesome-icon>

                        </span>
                        <div>{{reportSection.label}}</div>
                    </div>
                    <hr class="reportSeparator"  v-if="i==0&&reports.excluded.length>0">
                </div>
            </div>
        </td>
        <td align="right">
            <span class="notification-label text-success">{{ $t('dashboard.modal.label.status') }}</span>
        </td>
        <hr class="reportSeparator">
    </tr>
</template>

<script>
  import RowMixin from "./rowMixin";

  export default {
        mixins: [RowMixin],
        data() {
            return {
                statuses: {
                    '1': this.$t('dashboard.category.completed'),
                    '0': this.$t('dashboard.category.visited'),
                    '-1': this.$t('dashboard.category.noshow'),
                    '-2': this.$t('dashboard.category.traditional'),
                },
                reports:{
                    included: [],
                    excluded: []
                }
            }
        },
        methods:{
            groupClass(group, type) {
                return 'fa reportSectionGroupIcon danger' + group + ' fa-circle' + (type=='excluded'? "-o" : "");
            },
            downloadForm({form_id}, reportSectionId) {
                this.$store.dispatch('consentModal/downloadFormAsPdf', {form_id, reportSectionId})
            },
            togglePreviewForm({form_id}, reportSectionId) {
                this.$store.dispatch('consentModal/toggleFormPreview', {form_id, reportSectionId});
            },
        },
        mounted(){
            if (this.row && this.row.form && this.row.form.report_sections && this.row.form.report_sections.length>0){
                this.reports.included = this.row.form.report_sections.filter( v => !v.excludeRiskFromGeneral)
                this.reports.excluded = this.row.form.report_sections.filter( v => v.excludeRiskFromGeneral)
            }
        }
    }
</script>

<style>
    .reportSections {
        display: flex;
        flex-direction: column;
        margin-top: 6px;
    }
    .reportSection {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 4px;
    }
    .reportSectionGroupIcon {
        margin-right: 5px
    }
    .reportSeparator{
        border-top: 1px solid lightgrey;
        margin-top: 6px;
        margin-bottom: 4px;
    }
    .eye-icon{

    }
</style>
