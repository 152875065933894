<template>
  <div class="navbar-default sidebar" role="navigation">
    <div class="sidebar-nav navbar-collapse">
      <ul class="nav" id="side-menu">
        <li v-bind:key=item.route v-for="item in filteredMenus">
          <router-link :to="getUrl(item.route)"  v-if="getTarget(item.route)==='_self'">
            <font-awesome-icon class="fa-2-7x side-icon" :icon="item.icon"></font-awesome-icon><br>
            {{$t(item.title)}}
          </router-link>
          <a v-else :href="getUrl(item.route)" :target="getTarget(item.route)"></a>
          <div class="menu-tooltip">
            <div class="menu-title header-font">{{$t(item.title)}}</div>
            <div class="menu-description">{{$t(item.description)}}</div>
            <img class="img-responsive" :src="item.screenshot">
            <div class="menu-arrow"></div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
    import HasPermission from '../mixins/hasPermission'
    import menuItems from './menuItems'
    import { mapState } from 'vuex';

    export default {
        mixins: [HasPermission],
        data() {
            return {
                menu:menuItems.menu,
                submenu:menuItems.submenu
            }
        },
        methods: {
            getUrl(route) {
              if (route == 'settings') {
                let item =  this.getFirstSubmenuThatYouHaveAccessTo();
                return item.route
              //TODO: h4x0r, Ilinqu in the mix
              } else if(route == 'remote_consult' && !this.hasActiveThirdPartyProvider) {
                return 'https://videoconsult.zohosites.eu/';
              } else {
                return route
              }
            },
            getTarget(route) {
              // TODO: h4x0r, Ilinqu in the mix
              if(route == 'remote_consult' && !this.hasActiveThirdPartyProvider) {
                return '_blank';
              }
              return '_self'; //default;
            },
            getFirstSubmenuThatYouHaveAccessTo(){
              for (let item of this.submenu) {
                if (this.hasPermission(item.permission)) {
                  return item;
                }
              }
            },
            loadRequiredData () {
              if (this.hasPermission('view_report_charts')) {
                this.$store.dispatch('reports/loadReports')
              }
            }
        },
        computed: {
          ...mapState({
          reports: state => state.reports,
          hasActiveReports(state){
            return state.reports.reports.filter(v => v.enabled == 1).length > 0
          },
          thirdPartyProviders: state => state.thirdParty,
          hasActiveThirdPartyProvider(){
            const config = this.$store.getters['config/getCurrentConfig']()
            if (config) {
              return config.remote_consult.selectedProvider.length>0
            }
          }
          }),
          ...mapState('userOptions', {
            selectedOrganizationSymbol: state => state.options.selectedOrganizationSymbol
          }),
          // This is not as pretty as it was with separate function to check permissions
          // but at least now Vue can invalidate cache now and menu does update when you change org
          filteredMenus() {
            const filtered = []
            for (const item of this.menu) {
              const permission = item.permission
              if(!permission) { //the menuitem does not require any permissions
                filtered.push(item);
              } else if (permission == 'settings' && this.getFirstSubmenuThatYouHaveAccessTo()) { //Special case for settings
                filtered.push(item)
              } else if (permission == 'remote_consult') { //Special case for remote_consult
                if (this.hasActiveThirdPartyProvider) {
                  filtered.push(item)
                }
              } else if (permission == 'view_report_charts') { //Special case for remote_consult
                if (this.hasPermission(permission) && this.hasActiveReports) {
                  filtered.push(item)
                }
              } else if (this.hasPermission(permission)){
                filtered.push(item)
              }
            }
            return filtered
          }
        },
        watch: {
          selectedOrganizationSymbol(newValue) {
            this.loadRequiredData()
          }
        },
        created () {
          this.loadRequiredData()
        }
    }
</script>

<style>
#page-wrapper {
  margin: 0px !important;
  -webkit-box-shadow: inset 3px 3px 3px 0 hsla(0,0%,90%,.5);
  -moz-box-shadow:  inset 3px 3px 3px 0 hsla(0,0%,90%,.5);
  box-shadow:  inset 3px 3px 3px 0 hsla(0,0%,90%,.5);
  border-left: 70px solid #fff !important;
}

.sidebar {
  width: 70px !important;
  background: #fff  !important;
  text-align: center;
  font-family: 'Raleway', sans-serif;
}

.sidebar .nav > li {
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.sidebar .nav > li a .side-icon {
  color: #3479b7;
  margin-bottom: 5px;
}
.sidebar .nav > li a  {
  color:#000;
}

.sidebar .nav > li > a {
  padding: 0px;
  font-size: 10px;
}

.sidebar .nav > li > a .side-icon {
  color: #0083b9;
}

.sidebar .nav > li a:hover {
  background: transparent !important;
}

.sidebar .nav > li:hover a.router-link-exact-active .side-icon,
.sidebar .nav > li a.router-link-exact-active .side-icon {
  color: #ff6000;
}

.sidebar .nav > li:hover a, .sidebar .nav > li a.router-link-active {
  font-weight: 900;
}

.sidebar .nav > li:hover a .side-icon  {
  color: #ff6000;
}

.sidebar ul li a.router-link-exact-active {
  background-color: transparent !important;
}

.fa-2-7x {
  font-size: 2.7em;
}

#side-menu>li {
  position:relative;
}

#side-menu>li>.menu-tooltip {
  z-index:1002;
  position:absolute;
  display:none;
  width:300px;
  left:85px;
  top:0px;
  padding:12px;
  background:white;
  border-radius: 3px;
  opacity:0;
  transition: opacity 200ms;
  white-space: nowrap;
  text-align: left;
  -webkit-box-shadow: 0px 0px 3px 1px rgba(210,210,210,0.75);
  -moz-box-shadow: 0px 0px 3px 1px rgba(210,210,210,0.75);
  box-shadow: 0px 0px 3px 1px rgba(210,210,210,0.75);

}
.menu-tooltip>.menu-arrow {
  position:absolute;
  left:-15px;
  top:30px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7.5px 15px 7.5px 0;
  border-color: transparent #ee692c transparent transparent;
}

#side-menu>li:hover>.menu-tooltip {
  opacity:1;
  display:block;
}
.menu-title {
  border-bottom: 2px solid #ee692c;
  padding-bottom: 5px;
  margin-bottom: 8px;
  font-family: "Raleway";
  font-weight: 500;
}
.menu-description {
  margin-bottom: 5px;
  font-family: "Roboto Light";
  white-space: normal;
}
</style>