<template>
  <div :style="user.authByPin ? 'height: 100%': ''">
    <login-loading v-if="loading"/>
    <intake v-else-if="user && !user.authByPin && $route.name==='intake'"/>
    <intakes-layout v-else-if="user && $route.name==='weblink'"/>
    <main-layout v-else-if="user && !user.authByPin"/>
    
    <!-- This is kind of silly to use router like this but on the other hand we are 100% sure that
      nothing loaded until user are logged in and as a bonus we keep existing url after login -->
    <email v-else-if="$route.name==='email'"/>
    <reset v-else-if="$route.name==='reset'"/>
    <intakes v-else-if="$route.name==='intakes'"/>
    <choice v-else-if="$route.name==='choice'"/>
    <login v-else/>
  </div>
</template>

<script>
import Login from '@/layout/login'
import {mapState, mapActions} from 'vuex'
import MainLayout from '@/layout/mainLayout'
import LoginLoading from '@/layout/loginLoading.vue'
import Reset from '@/layout/reset'
import Email from '@/layout/email'
import Intakes from '@/layout/intakes'
import Intake from '@/layout/intake'
import Choice from '@/layout/choice'
import IntakesLayout from '@/layout/intakesLayout'
export default {
  components: { LoginLoading, Email, Reset, MainLayout, Login, Intakes, Intake, Choice, IntakesLayout },
  computed: {
    ...mapState('auth', [
        'user', 'loading'
    ])
  },
  methods: {
    ...mapActions('auth', [
      'checkAuth'
    ]),
  },
  created () {
    this.checkAuth()
  }
}
</script>