<template>
  <li style="width: 40px; margin: unset" class="dropdown">
    <a
      style="
        display: flex;
        align-items: center;
        padding: unset;
        justify-content: center;
      "
      class="dropdown-toggle"
      data-toggle="dropdown"
      href="#"
      aria-expanded="true"
    >
      <font-awesome-icon icon="user" fixed-width></font-awesome-icon>
      <font-awesome-icon icon="caret-down"></font-awesome-icon>
    </a>
    <ul class="dropdown-menu dropdown-user">
      <li>
        <a>Hello, {{ user.name }}</a>
      </li>
      <!-- <li class="divider"></li>
      <li><a :href="zohoUrl">Connect Zoho</a></li> -->
      <li class="divider"></li>
      <li>
        <a href="/auth/logout" @click.prevent="logout">
          <font-awesome-icon icon="sign-out" fixed-width> </font-awesome-icon>
          {{ $t("layout.label.logout") }}</a
        >
      </li>
    </ul>
    <!-- /.dropdown-user -->
  </li>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  methods: {
    ...mapActions("auth", ["logout"]),
  },
  computed: {
    zohoUrl() {
      return window.location.href + "zohoAuth";
      // NEW CLOUD -- return ("https://accounts.zoho.eu/oauth/v2/auth?scope=ZohoAnalytics.fullaccess.all&client_id=1000.2WBB5UDTJTF790TIHW22XQ6SFG8Y9E&state=testing&response_type=code&redirect_uri=" + window.location.href + "zohoAuth&access_type=offline");
      //Original -- return "https://accounts.zoho.eu/oauth/v2/auth?scope=ZohoMeeting.meeting.ALL,ZohoMeeting.webinar.ALL,ZohoCRM.modules.ALL,ZohoMeeting.manageOrg.ALL,ZohoAnalytics.fullaccess.all&client_id=1000.YKF6RPBF5R6883BJDLZW0E5W7BMDZP&redirect_uri=" + window.location.href + "zohoAuth&access_type=offline&response_type=code");
    },
    ...mapState("auth", ["user"]),
  },
};
</script>