<template>
    <div>
        <v-select multiple v-model="localValue" 
            :options="forceShowUnalterableTags ? options : getAlterableTags"
            label="name" 
            class="tag-select" 
            :disabled="disabled" 
            :filterBy="this.filterBy" 
            :close-on-select="forceCloseDropdown" 
            ref="vSelect"
            :map-keydown="preventBackspace"
            >
            <template slot="option" slot-scope="option">
                <tag :id="option.id?option.id:option.name" :showDelete="false" :orgSymbol="orgSymbol"></tag>
            </template>
            <template slot="selected-option-container" slot-scope="{option, deselect}">
                <tag :id="option.name" :canReplace="!disabled && getAlterableTags.includes(option.name)"
                    :orgSymbol="orgSymbol"
                     v-on:tagDropdown="tagDropdown" :showDelete="false">
                    <template slot="custom-buttons-after">
                        <div v-if="!disabled && (forceDeleteButton || getAlterableTags.includes(option.name))" class="delete" :style="buttonStyleById(option.name)"> 
                            <font-awesome-icon @mousedown.stop="deselect(option.name)" icon="times" style="margin-left: 2px; font-size: 10px; margin-top: 2px; cursor: pointer;"></font-awesome-icon>
                        </div>
                    </template>
                </tag>
            </template>
        </v-select>
        <tag-group-dropdown :selectedTag="selectedTag" :selected="localValue" v-on:hide="selectedTag=undefined" :orgSymbol="orgSymbol"
                            v-on:replaceTag="replaceTag"></tag-group-dropdown>
    </div>
</template>
<script>
    import vSelect from "vue-select";
    import Tag from "@/components/tag";
    import TagGroupDropdown from "./tagGroupDropdown";
    import tagColors from '@/mixins/tagColors';
    import Vue from 'vue';

    export default {
        mixins: [tagColors],
        components: {'v-select': vSelect, 'tag': Tag, TagGroupDropdown},
        props: {
            value: '',
            options: '',
            disabled: '',
            forceCloseDropdown: false,
            canReplace: {
                default: false
            },
            orgSymbol: {
                default: undefined
            },
            // these two are for the zorgDoc status tags, which should not be deletable in the PC 
            // for the PC and the sendIntake tab, parent is addTags and this will be false
            forceDeleteButton: {
                default: true
            },
            forceShowUnalterableTags:{
                default: true
            }

        },
        data() {
            return {
                'localValue': this.value,
                'selectedTag': ''
            };
        },
        methods: {
            tagDropdown(tag) {
                this.selectedTag = tag;
            },
            replaceTag(selectedTag, newTag) {
                // Just emit replacement to addTags.vue
                this.$emit('replaceTag', selectedTag, newTag, true);
            },
            buttonStyleById(id) {
                let tag = this.$store.getters['tags/getTagById']({id, orgSymbol: this.orgSymbol});
                if (tag) {
                    let color = tag.group ? tag.group.color: tag.color;
                    return this.buttonsStyle(tag.group.color || tag.color, '#fff')
                }
            },
            filterBy(option, label, search) {
                if (option) {
                    let tag = this.$store.getters['tags/getTagById']({id: option, orgSymbol: this.orgSymbol});
                    return tag && tag.name.toLowerCase().indexOf(search.toLowerCase()) > -1;
                }
            },
            closeDropDown() {
                const searchEl = this.$refs.vSelect.searchEl;
                if (searchEl) {
                    searchEl.blur();
                }
            },
            preventBackspace: (map, vm) => ({
                ...map, 8: (e) => {if (vm.search.length == 0){e.preventDefault()}}
            })
        },
        computed: {
            loadedOrgs(){
                return this.$store.getters['tags/loadedOrgs']();
            },
            getAlterableTags() {
                if(this.loadedOrgs.includes(this.orgSymbol)){
                    return this.$store.getters['tags/getAlterableIds'](this.orgSymbol);
                } else {
                    return [];
                }
            },
        },
        watch: {
            // whenever question changes, this function will run
            localValue: function (newVal) {
                this.$emit('input', newVal);
            },
            value: function (newVal) {
                this.localValue = newVal;
            },
            forceCloseDropdown: function (newVal) {
                if (newVal) {
                    this.closeDropDown();
                }
            }
        }
    }

</script>
<style>
    .vs__dropdown-option--selected {
        background-color: #f3f3f3;
    }

    .v-select .dropdown-menu {
        z-index: 10001;
    }
    .tag-select span.selected-tag {
        position: relative;
        background-color: transparent;
        border: none;
    }

    .dropdown-toggle {
        border: none !important
    }

    .tag-select .selected-tag .close {
        position: absolute;
        right: 0px;
        top: 0px;
        opacity: 1;
        height: 100%;
        border: none;
        padding: 0px;
        margin: 0px;
        z-index: 100;
    }

    .delete {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 20px;
        height: 18px;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
    }

    .tag-select input {
        height: 28px;
    }

    .v-select.disabled .selected-tag .close {
        background-color: #000;
    }

    .tag-select .vs__selected-options {
        align-items: center;
    }

    .tag-select .dropdown-toggle {
        padding: 0;
    }

    .v-select {
        border: 1px solid #ccc;
        border-radius: 4px;
    }
</style>
