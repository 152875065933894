<template>
    <div class="tab-pane">
        <div class="u-mt-20 u-mb-20">
            <div class="row">
                <div class="col-sm-3">
                    <div class="form-group u-mt-10">
                        <div style="display: flex; flex-direction: column">
                            <label for="appointmentDate"> {{ $t('dashboard.modal.date') }}:</label>
                            <date-picker-wrapper v-on:new-date="form.start_at = $event" id="appointmentDate"
                                :range="false" format='DD-MM-YYYY HH:mm' type='datetime'
                                :time-picker-options="{ format: 'HH:mm', start: '06:00', step: '00:30', end: '23:30' }"
                                value-type='YYYY-MM-DD HH:mm:ssZ' time-title-format="DD-MM-YYYY" width="100%"
                                :forced-corrected-date="correctedIntakeDate" @new-date="newDate"
                                @remove-date="removeDate"></date-picker-wrapper>
                            <p><span class="text-danger">{{ validationError }}</span>
                            </p>
                        </div>

                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group u-mt-10">
                        <label for="comments"> {{ $t('dashboard.modal.appointment_comments') }}</label>
                        <textarea class="form-control" id="comments" v-model="form.comments"></textarea>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="u-mt-20 u-mb-20 row">
                        <div class="col-sm-6">
                            <label class="checkbox-inline" v-show="sendsConfirmationEmail()">
                                <input type="checkbox" v-model="form.send_confirmation">
                                {{ $t('dashboard.modal.label.send_confirmation') }}
                            </label>
                        </div>
                        <div class="col-sm-6">
                            <label class="checkbox-inline" v-show="showFollowupsCheckbox()">
                                <input type="checkbox" v-model="form.enable_followups">{{
                                $t('dashboard.modal.label.enable_followups') }}
                            </label>
                        </div>
                        <div v-if="config.remote_consult.selectedProvider" class="col-sm-6">
                            <label class="checkbox-inline">
                                <input type="checkbox" v-model="form.remote_consult">{{
                                $t('dashboard.modal.label.remote_consult') }}
                            </label>
                        </div>
                    </div>

                    <button data-style="expand-left"
                            class="btn btn-primary btn-block btn-ic-set-appointment u-mt-20"
                            :disabled="disableButtons"
                            @click="saveAppointment()">
                        {{ $t('dashboard.modal.set_appointment') }}
                        <font-awesome-icon v-if="disableButtons" :icon="['fad', 'spinner']" size="lg" pulse/>
                    </button>

                    <button class="btn btn-default btn-block" v-if="!disableButtons"
                            @click="cancelEditAppointment()">
                        {{ $t('dashboard.modal.btn_cancel') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters, mapState} from 'vuex'
    import DatePickerWrapper from '@/components/datePickerWrapper'

    export default {
        components: {DatePickerWrapper},
        data() {
            return {
                correctedIntakeDate: new Date(),
                pickedATime: false,
                form: {
                    start_at: '',
                    comments: '',
                    send_confirmation: '',
                    enable_followups: true,
                    remote_consult: false
                },
                validationError: '',
                disableButtons: false,
            }
        },
        computed: {
            ...mapState({
                tab: state => state.consentModal.selectedTab
            }),
            config() {
                const {orgSymbol, department: departmentSymbol} = this.record;
                return this.$store.getters['config/getConfig']({orgSymbol, departmentSymbol})
            },
            ...mapGetters({
                record: 'consentModal/selectedRecord',
                appointment: 'consentModal/getAppointment',
            })
        },
        methods: {
            sendsConfirmationEmail() {
                return this.config.procedures.indexOf(this.record.content) > -1;
            },
            validateAppointment() {
                return !this.validationError && (!moment(this.form.start_at).isBefore(moment()) || confirm(this.$t('dashboard.modal.appointment_date_in_the_past_confirmation')));
            },
            saveAppointment() {
                if (this.validateAppointment()) {
                    this.disableButtons = true;
                    //hotfix for 1638
                    if(this.form.start_at) this.form.start_at = this.form.start_at.replace('.0', '');
                    this.$store.dispatch('consentModal/addEditAppointment', this.form).then(response => {
                        this.disableButtons = false;
                        this.$store.commit('consentModal/selectTab', '');
                    }, error => {
                        this.disableButtons = false;
                        this.validationError = error && error.error && error.error.message ? this.translate(error.error.message) : error;
                    })
                }
            },
            cancelEditAppointment() {
                this.$store.commit('consentModal/selectTab', '');
            },
            showFollowupsCheckbox() {
                for (let group_id in this.config.followups) {
                    if (Array.isArray(this.config.followups[group_id].content) &&
                        this.config.followups[group_id].content.indexOf(this.record.content) > -1) {
                        return true;
                    }
                }
            },
            translate(key) {
                return this.$t(key);
            },
            newDate(dateValue) {
                // Triggered when datepicker date changes, changes default picker automatic time to a more relevant automatic time
                var now = new Date();
                var userChosenDay = new Date(dateValue);

                // If the user hasn't picked a time yet, force default time
                if(!this.pickedATime)
                {
                    // If the appointment is made for today, preselect the first hour possible for today
                    if (now.getDay() == userChosenDay.getDay() && now.getMonth() == userChosenDay.getMonth() && now.getFullYear() == userChosenDay.getFullYear()) {
                        userChosenDay.setHours(now.getHours() + 1);
                        userChosenDay.setMinutes(0);
                        userChosenDay.setSeconds(0); 
                        this.correctedIntakeDate =  userChosenDay;
                    }
                    else {
                        // Otherwise, preselect first hour possible in general (hardcoded based on properties of datepicker)
                        userChosenDay.setHours(8);
                        userChosenDay.setMinutes(0);
                        userChosenDay.setSeconds(0);
                        this.correctedIntakeDate =  userChosenDay;
                    }
                    this.pickedATime = true;
                }
            },
            removeDate() {
                // Triggered when receiving an emit that the user unpicked their date
                this.pickedATime = false;
            }
        },
        watch: {
            'form.start_at'(start_at) {
                this.validationError = (!start_at || !moment(start_at).isValid()) ? this.$t('dashboard.modal.start_at_required') : '';
            },
            tab(val) {
                if (val == 'editAppointment') {
                    this.form = Object.assign({}, this.appointment, {remote_consult: this.appointment.type === 'remote_consult'}); //Copy the value, not ref
                } else if (val == 'appointment') {
                    this.form = {
                        start_at: '',
                        comments: '',
                        send_confirmation: '',
                        enable_followups: true,
                        remote_consult: false
                    }
                }
            },
        }
    }
</script>