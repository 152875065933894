<template>
  <div>
    <date-picker
      v-model="periodRange"
      :defaultValue="forcedCorrectedDate"
      :ref="datepicker_ref"
      :format="format"
      :lang="$i18n.locale"
      :range="range"
      :type="type"
      :placeholder="placeholder"
      :shortcuts="generatedShortcuts"
      :editable="editable"
      :appendToBody="false"
      @clear="clearCheck()"
      @close="closeCheck()"
      :time-title-format="timeTitleFormat"
      :time-picker-options="timePickerOptions"
      :valueType="valueType"
      @open="clearPartials()"
      @pick="selected"
      @mouseover.native="handleHover($event)"
      @mouseleave.native="handleHoverOut($event)"
    ></date-picker>
  </div>
</template>

<script>
  import DatePicker from 'vue2-datepicker'
  import dateRanges from '../dateRanges'
  require('vue2-datepicker/locale/en')
  require('vue2-datepicker/locale/de')
  require('vue2-datepicker/locale/nl')
  require('vue2-datepicker/locale/es')

  export default {
    data () {
      return {
        periodRange: '',
        placeholder: '',
        partialPeriodStart: false,
        partialPeriodEnd: false,
        partialPeriodStartDate: '',
        finalShortcuts: [],
      }
    },
    props: {
      datepicker_ref: {
        type: String,
        default: "",
      },
      range: {
        type: Boolean,
        default: true // overwrites vue2-datepicker default of false
      },
      type: {
        type: String, // date (only date picker, default) or datetime (date picker + hour picker)
      },
      format: {
        type: String,
        default: 'DD-MM-YYYY' // overwrites vue2-datepicker default of 'YYYY-MM-DD'
      },
      editable: {
        type: Boolean,
        default: true
      },
      // This options are used for the "appointment date" (date+hour picker, only 1 panel):
      valueType: {
        type: String // Ex: 'YYYY-MM-DD HH:mm:ssZ', 'YYYY-MM-DD'
      },
      timeTitleFormat: {
        type: String // Ex: 'DD-MM-YYYY'
      },
      timePickerOptions: {
        type: Object // Ex: "{format: 'HH:mm', start: '06:00', step:'00:30' , end: '23:30'}"
      },
      booleanShortcuts: {
        type: Boolean,
        default: false
      },
      addShortcuts: {
        type: Array,
        default: () => []
      },
      // This options are used for the "force open" option
      forceOpen: {
        type: Boolean,
        default: false
      },
      period: {
        type: String,
        default: ''
      },
      value: {
        type: Array|String
      },
      forcedCorrectedDate: {
        type: Date
      }

    },

    methods: {
      handleHover(evt) {
        if (this.partialPeriodStart && !this.partialPeriodEnd ) {
          let { target } = evt;
          if (target.tagName === 'DIV') { target = target.parentElement; }
          if (target.tagName === 'TD') {
            const xx = document.querySelectorAll(".cell").forEach(cell => {
              if (cell.hasAttribute("title")){
                let startParsed = Date.parse(this.partialPeriodStartDate)
                let hoverParsed = Date.parse(target.title)
                let cellParsed  = Date.parse(cell.title);
                if (startParsed == cellParsed) cell.classList.add("active") //for out of month cells
                if (startParsed < hoverParsed && cellParsed>startParsed && hoverParsed>=cellParsed ||
                    startParsed > hoverParsed && cellParsed<startParsed && hoverParsed<=cellParsed) {
                  cell.classList.add("in-range")
                  if (cell.classList.contains('today')){
                    cell.classList.remove("today")
                    cell.classList.add("today-hover")
                  }
                } else if (!cell.classList.contains('today')){
                  cell.classList.remove("in-range")
                  if (cell.classList.contains('today-hover')) {
                    cell.classList.remove("today-hover")
                    cell.classList.add("today")
                  }
                } 
              }
            })
          }
        }
      },
      handleHoverOut($event){
        let any_previous_date = this.periodRange.length!=0 && !this.periodRange.includes(null);
        if (!this.partialPeriodEnd && (!any_previous_date || this.partialPeriodStart)){ 
          document.querySelectorAll(".cell").forEach(cell => {
            if (cell.classList.contains("in-range")){
              cell.classList.remove("in-range")
            } else if (cell.classList.contains("today-hover")){
              cell.classList.remove("today-hover")
              cell.classList.add("today")
            } else if (cell.classList.contains("active")){
              cell.classList.remove("active")
            }
          })
        }
      },
      selected(date){
        if (this.partialPeriodEnd == true) {
          // if there's an end period, reset everything...
          this.partialPeriodStart = false
          this.partialPeriodEnd = false
        }
        if (this.partialPeriodStart == false) {
          // ...and inmediately turn start to true bc we already clicked...
          this.partialPeriodStart = true
          // ...but we keep "end" to false bc we're waiting...
        } else {
          // ...unless we had a start alredy :)
          this.partialPeriodEnd = true
        }
        let dateStripped = date.toString().split(' ').splice(0, 5);
        dateStripped.push('UTC'); // This prevents timezone related bugs
        this.partialPeriodStartDate = new Date(dateStripped.join(' ')).toISOString().slice(0,10);
      },
      clearPartials(){
        this.partialPeriodEnd = false
        if (!this.forceOpen || this.periodRange.length != 0 && !this.periodRange.includes(null)) {
          this.partialPeriodStart = false  
          this.partialPeriodStartDate = ""
        }  
      },
      closeCheck () {
        if (
          this.forceOpen &&
          (this.periodRange.length == 0 || this.periodRange.includes(null))
        ) {
          this.$refs[this.datepicker_ref].openPopup()
        }
        this.$emit('close')
      },
      clearCheck () {
        this.partialPeriodStart = false;
        if (this.forceOpen) {
          this.$refs[this.datepicker_ref].openPopup()
        }
      }
    },
    watch: {
      periodRange (newValue, oldValue) {
        if (Array.isArray(newValue) && oldValue[0] == newValue[0] && oldValue[1] == newValue[1]){
          return;
        }

        if (newValue == null) {
          this.$emit('remove-date');
          return;
        }

        if (newValue != this.forcedCorrectedDate) {
          this.$emit('new-date', newValue)
          this.$emit('input', newValue)
        }
      },
      forceOpen (newValue) {
        if (newValue == false) {
          this.$refs[this.datepicker_ref] && this.$refs[this.datepicker_ref].closePopup()
        } else {
          this.closeCheck()
        }
      },
      //Passing external changes
      value (newValue) {
        this.periodRange = [...newValue] //Copy not pass refernce
      },
      forcedCorrectedDate (newValue) {
        this.periodRange = moment(newValue).format("YYYY-MM-DD HH:mm:ss.sZ");
      }
    },
    computed: {
      generatedShortcuts () {
        let generated = []
        if (this.booleanShortcuts) {
          generated = [
            {
              text: this.$t('dashboard.filter.yes'),
              onClick: () => {
                //this.placeholder = this.$t('dashboard.filter.yes')
                this.periodRange = 'has-option'
                this.$refs[this.datepicker_ref].handleInputInput({ 'target': { 'value': this.$t('dashboard.filter.yes') } })
                this.$refs[this.datepicker_ref].closePopup()
              }
            },
            {
              text: this.$t('dashboard.filter.no'),
              onClick: () => {
                //this.placeholder = this.$t('dashboard.filter.no')
                this.periodRange = 'no-option'
                this.$refs[this.datepicker_ref].handleInputInput({ 'target': { 'value': this.$t('dashboard.filter.no') } })
                this.$refs[this.datepicker_ref].closePopup()
              }
            }
          ]
        }
        for (let range of this.addShortcuts) {
          generated.push({
            text: this.$t('dashboard.filter.period.' + range),
            onClick: () => {
              this.periodRange = [
                moment(dateRanges[range][0],'DD-MM-YYYY').toDate(),
                moment(dateRanges[range][1],'DD-MM-YYYY').toDate()
              ]
              this.$refs[this.datepicker_ref].closePopup()
            }
          })
        }

        return generated
      }
    },
    mounted () {
      if (this.value != undefined && !this.value.length == 0 && this.periodRange.includes(null)){
        this.periodRange = [
          moment(this.value[0],"YYYY-MM-DD HH:mm:ss.sssZ").toDate(), 
          moment(this.value[1],"YYYY-MM-DD HH:mm:ss.sssZ").toDate()
        ];
      }
      if (this.forceOpen && (this.periodRange.length == 0 || this.periodRange.includes(null))) {
        this.$refs[this.datepicker_ref].openPopup();
      }
    },
    components: {
      DatePicker
    }
  }
</script>

<style>
  /* FROM FILTERED TABLE */
  .mx-calendar-header {
    /* //RED ELLIPSE IN THE HEADER OF PANELS AROUND << < May 2020 > >> */
    border: 1px solid #e6e1ef;
    border-radius: 50px;
  }

  /*  */

  .mx-datepicker-main {
    display: flex;
    flex-direction: column;
  }

  .mx-datepicker-sidebar {
    display: inline-block;
    padding: 6px 12px;
    width: 100% !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }

  .mx-btn-shortcut {
    display: inline !important;
  }

  .mx-datepicker-sidebar .mx-btn-shortcut {
    padding: 0;
  }

  .mx-datepicker-sidebar .mx-btn-shortcut + .mx-btn-shortcut:before {
    content: "|";
    padding-left: 8px;
    padding-right: 5px;
    color: #73879c;
  }

  .mx-datepicker-sidebar + .mx-datepicker-content {
    margin-left: 0 !important;
    border-left: none;
  }

  .mx-datepicker-popup .mx-calendar thead tr th {
    text-align: center;
    color: #31689d;
  }

  .mx-calendar + .mx-calendar {
    border-left: none;
  }

  .mx-table-date td.cell.today.not-current-month,
  td.cell.today {
    color: white;
    font-weight: bold;
    background-color: #ff9d62;
    border-radius: 100%;
  }

  .today-hover {
      font-weight: bold;
    }

  .mx-table-date .cell.not-current-month{
    opacity: 0.5;
  }

  .mx-calendar-content .cell.active,
  .mx-calendar-content .cell.in-range {
    border-radius: inherit;
  }
</style>
