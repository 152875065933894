import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
// @ts-ignore
import Dashboard from '@/views/dashboard/index'

Vue.use(VueRouter)

// @ts-ignore
const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: Dashboard,
  },
  {
    path: '/invite',
    // @ts-ignore
    component: () => import(/* webpackChunkName: "invite" */ '@/views/invite')
  },
  {
    path: '/support',
    // @ts-ignore
    component: () => import(/* webpackChunkName: "support" */ '@/views/support')
  },
  {
    path: '/reports',
    // @ts-ignore
    component: () => import(/* webpackChunkName: "support" */ '@/views/reports')
  },
  {
    path: '/consult',
    // @ts-ignore
    component: () => import(/* webpackChunkName: "support" */ '@/views/videoconsult')
  },
  {
    path: '/settings',
    // @ts-ignore
    component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/index'),
    children: [
      {
        path: 'organization',
        // @ts-ignore
        component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/organization')
      },
      {
        path: 'role',
        // @ts-ignore
        component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/role')
      },
      {
        path: 'user',
        // @ts-ignore
        component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/user')
      },
      {
        path: 'reports',
        // @ts-ignore
        component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/reports')
      },
      {
        path: 'tags',
        // @ts-ignore
        component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/tags')
      },
      {
        path: 'whiteLists',
        // @ts-ignore
        component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/whitelist')
      },
      {
        path: 'thirdParty',
        // @ts-ignore
        component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/thirdParty')
      },
      {
        path: 'diff',
        // @ts-ignore
        component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/diff')
      },
    ]
  },
  {
    path: '/about',
    // @ts-ignore
    component: () => import(/* webpackChunkName: "support" */ '@/views/about')
  },
  { path: '/reset/:resetToken', name: 'reset'},
  { path: '/email', name: 'email'},
  { path: '/intakes', alias: '/pin_login', name: 'intakes'},
  { path: '/weblink', name: 'weblink'},
  { path: '/intake', name: 'intake'},
  { path: '/choice', name: 'choice'},
  { path: "/:catchAll(.*)", component: Dashboard}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
