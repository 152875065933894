<template>
<div :key="'dashboard_for_'+selectedOrganizationSymbol" v-if="hasDashBoardPermissions">
  <div v-if="!intakesMode">
    <!-- Nav tabs -->
    <div class="top-submenu">
      <a href="#" v-on:click.prevent="activeTab='conversion'"
         :class="{ active: activeTab=='conversion' }">{{ $t('dashboard.intake_admission') }}</a>
      <a href="#" v-if="hasAppointments" v-on:click.prevent="activeTab='appointments'"
         :class="{ active: activeTab=='appointments' }">{{ $t('dashboard.appointments') }}</a>
    </div>

    <!-- Tab panes -->
    <div style="margin-top:20px">
      <conversion-tab :statuses="statuses"></conversion-tab>
      <appointments-tab></appointments-tab>
    </div>
    <consent-modal></consent-modal>
  </div>
  <div v-else>
    <intakes-mode></intakes-mode>
  </div>
</div>
</template>

<script>
  import ConsentModal from '@/views/dashboard/components/modal/consentModal'
  import ReportContainer from '@/views/reports/report-container.vue'
  import AppointmentsTab from '@/views/dashboard/appointments/appointmentsTab'
  import ConversionTab from '@/views/dashboard/conversion/conversionTab'
  import SideFilters from '@/views/dashboard/components/sideFilters'
  import IntakesMode from '@/views/dashboard/conversion/intakesMode'
  import { mapState } from 'vuex'

  export default {
    components: {
      ConsentModal,
      'report-container': ReportContainer,
      AppointmentsTab,
      ConversionTab,
      SideFilters,
      IntakesMode,
    },
    data () {
      return {
        intakesMode:false,
        clientConfig: {
          procedures: [],
          reminders: [],
          followups: []
        },
        rawTableData: [],
        contentTypes: [''],
        reportsData: [],
        statuses: [
          {
            'key': '-',
            'title': '',
            'statusText': ''
          },
          {
            'key': 1,
            'title': this.$t('dashboard.category.completed'),
            'statusText': this.$t('dashboard.category.completed_description'),
          },
          {
            'key': 0,
            'title': this.$t('dashboard.category.visited'),
            'statusText': this.$t('dashboard.category.visited_description'),
          },
          {
            'key': -1,
            'title': this.$t('dashboard.category.noshow'),
            'statusText': this.$t('dashboard.category.noshow_description'),
          },
          {
            'key': -2,
            'title': this.$t('dashboard.category.traditional'),
            'statusText': this.$t('dashboard.category.traditional_description'),
          }
        ],
        groupBy: 'day',
        selected: {
          start: '',
          end: '',
        }
      }
    },
    computed: {
      hasDashBoardPermissions() {
        return this.hasPermission('view_dashboard_charts');
      },
      activeTab: {
        get () {
          return this.$store.state.userOptions.options.dashboardActiveTab
        },
        set (val) {
          this.$store.dispatch('userOptions/setOption', { option: 'dashboardActiveTab', value: val })
        }
      },
      ...mapState('appointments',['hasAppointments']),
      ...mapState('userOptions', {
        selectedOrganizationSymbol: state => state.options.selectedOrganizationSymbol
      })
    },
    watch: {
      hasAppointments (updatedHasAppointments) {
        if (updatedHasAppointments === false) {
          this.activeTab = 'conversion'
        }
      }
    }
  }
</script>