<template>
    <tr>
        <td class="patient-date-cell">
            {{ row.created_at | stdDateTimeFormat }}
        </td>
        <td v-bind:class="{ canceled_appointment: row.status == 'canceled'}">
            <span>{{ (row.comments) ? getRowComment(row.comments) : '' }}</span>
            <copy-icon v-if="hasCopyUrl(row.comments)" :copiable-str="extractUrl(row.comments)"></copy-icon>
            {{ (row.comments ? ' - ' : '') + 'Date: ' + this.formatDate(row.start_at, 'YYYY-MM-DD HH:mm:ss', 'DD-MM-YYYY HH:mm:ss') }}
            <span v-if="row.from == 'appointments' && row.status != 'canceled' && !isWCC(row) && this.config.enable_appointments" @click="editAppointment(row)" style="display:inline-block;">
                <font-awesome-icon icon="pencil" size="sm" class="pointer"></font-awesome-icon>
            </span>
            <span v-if="row.from == 'appointments' && row.status != 'canceled' && !isWCC(row) && this.config.enable_appointments" @click="toggleDeletion(true)" style="display:inline-block;padding-left:5px;">
                <font-awesome-icon icon="trash" size="sm" class="pointer"></font-awesome-icon>
            </span>
            <span style="margin-left:5px" v-if="deleting">
                <b>{{$t('dashboard.modal.delete_appointment_confirm')}}</b>
                <button class="btn btn-danger btn-xs" @click="deleteAppointment(row)" style="margin-left:5px;margin-right:5px" >{{$t('dashboard.modal.delete_appointment_yes')}}</button>  
                <button class="btn btn-default btn-xs" @click="toggleDeletion(false)">{{$t('dashboard.modal.delete_appointment_no')}}</button>
            </span>
            <span v-if="row.canceled" style="">
                <font-awesome-icon icon="ban" size="sm" class="text-danger" aria-hidden="true"></font-awesome-icon>
                {{trans.canceled}}
            </span>
        </td>
        <td align="right">
            <span class="notification-label label-appointment">{{ $t('dashboard.modal.label.appointment') }}</span>
            <span v-if="row.status == 'canceled'" class="notification-label label-appointment" style="padding: 0">({{$t('dashboard.modal.canceled')}}) </span>
        </td>
    </tr>
</template>

<script>
  import RowMixin from "./rowMixin";
  import CopyIcon from "@/components/copyIcon.vue"

  export default {
        components: { CopyIcon },
        mixins: [RowMixin],
        computed: {
            config() {
                const record = this.$store.getters['consentModal/selectedRecord'];
                const {orgSymbol, department: departmentSymbol} = record;
                return this.$store.getters['config/getConfig']({orgSymbol, departmentSymbol})
            },
        },
        data() {    
            return {
                deleting: false
            }
        },
        methods: {
            editAppointment(row) {
                this.$store.commit('consentModal/selectTab', 'editAppointment');
            },
            toggleDeletion(state){
                this.deleting = state;
            },
            deleteAppointment(row) {
                this.$store.dispatch('consentModal/deleteAppointment', row);
                this.deleting = false;
            },
            getRowComment(comments = '') {
                comments = this.hasCopyUrl(comments) ? `eConsult` : comments;
                if (process.env.NODE_ENV == 'development'){
                    comments = comments.replace('medify.eu/', "mex" + window.location.href.split("https://dashboard")[1]);
                }
                return comments;
            },
            extractUrl(comments = '') {
                if(this.hasCopyUrl(comments)) {
                    const parsed = JSON.parse(comments);
                    return parsed.CreateInvitationUrlResult;
                } else {    
                    return "";
                }
            },
            isWCC(row) {
                if(!row.remote_consult_context || !row.remote_consult_context.length) return false;
                const remoteConsultContext = JSON.parse(row.remote_consult_context);
                return remoteConsultContext && remoteConsultContext.provider === 'webcamconsult';
            },
            hasCopyUrl: (comments = '') => comments && comments.indexOf('CreateInvitationUrlResult') > -1
        }
    }
</script>

<style>
    .canceled_appointment {
        opacity: 0.5;
    }
    .label-appointment > .label-appointment-canceled{
        padding: 0;
    }
</style>
