<template>
    <tr>
        <td class="patient-date-cell">
            {{ row.created_at | stdDateTimeFormat }}
        </td>
        <td>
            

            <div class="row-container">
                <span>{{row.content}};</span>
                <span>{{$t(`dashboard.status.${row.status}`)}}</span>
                <span v-if="isCompleted">({{completedOnAndRisk}})</span>
                <font-awesome-icon @click="openRelatedIc(row)" icon="eye" size="lg" class="pointer"></font-awesome-icon>
            </div>

            

            <!-- {{ $t('dashboard.modal.sent_notification_to', {
                notification: row.type,
                email:this.sentTo,
                user:record.creator ? record.creator.name : '-'}) }} -->
        </td>
        <td align="right">
            <span class="notification-label label-related-ics text-success">{{ $t('menu.intake') }}</span>
        </td>
    </tr>
</template>

<style scoped>
    .row-container {
        display: flex;
    }
    .row-container > span {
        margin-right: 5px;
    }
    .label-related-ics {
        color: orange
    }
</style>

<script>
  import RowMixin from "./rowMixin";
  import {mapGetters} from 'vuex';
  import moment from 'moment'

    export default {
        mixins: [RowMixin],
        computed: {
            ...mapGetters({
                record: 'consentModal/selectedRecord',
            }),
            completedOnAndRisk: function () {  
                const status = this.row.statuses.find(({status}) => status === 1);
                if(!status) return false;
                const form = this.row.forms.find(({id}) => id === status.form_id);
                if(!form) return false;
                const riskLabel = this.$t(`dashboard.danger.${form.group}`);
                const completedOn = moment(status.timestamp).format('DD-MM-YYYY');
                return `${riskLabel} - ${completedOn}`;
            },
            isCompleted() {
                return this.row.status === 1;
            },
            
            
        },
        methods: {
            openRelatedIc(row) {
                const url = `${window.location.protocol}//${window.location.hostname}/intake?org=${this.record.orgSymbol}&dep=${row.department}&hash=${row.hash}`;
                window.open(url, '_blank');
            }
        }
    }
</script>
