import Vue from 'vue'
// initial state
const state = {
    filters:{
        hiddenEPDs:['test'],
        hideAppointmentPast: true,
        hideHiddenEPDS: true,
        hideTypedEPDS: true,
        showOnlyHiddenRecords: false,
        hideCompleted: false,
        hideNonDeleted: false
    }
}

// getters
const getters = {

}

// actions
const actions = {
    $init(store) {
        const storedFilters = JSON.parse(localStorage.getItem('filters'));
        if (storedFilters) {
            store.commit('setFilters', storedFilters);
        }
    },
    updateFilters(store, data) {

    }
}

// mutations
const mutations = {
    setFilters(state, data) {
        state.filters = data;
        localStorage.setItem('filters', JSON.stringify(data));
    },
    updateFilters(state, {field, value}) {
        Vue.set(state.filters, field, value);
        localStorage.setItem('filters', JSON.stringify(state.filters));
    },
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
