<template>
  <div class="top-filters">
    <div class="top-filters-button" @click="dropdown=!dropdown">{{ $t('dashboard.hide_options') }}  <span class="caret"></span></div>
    <div class="top-filters-dropdown" v-show="dropdown" @mouseleave="dropdown=false">
      <label for="hideAppointmentPast" class="text-normal" v-if="!pinLogin">
        <input type="checkbox" id="hideAppointmentPast" style="width:auto"
               v-model="hideAppointmentPast">
        {{ $t('dashboard.label.hideAppointmentPast') }}
        <span data-toggle="tooltip" data-placement="right"
              :title="$t('dashboard.label.hideAppointmentPast')">
                          <font-awesome-icon icon="question-circle" size="sm"></font-awesome-icon><br>
                      </span>
      </label>
      <br v-if="!pinLogin">
      <label for="hideHiddenEPDS" class="text-normal" v-if="!pinLogin">
        <input type="checkbox" id="hideHiddenEPDS" style="width:auto"
               :disabled="showOnlyHiddenRecords"
               v-model="hideHiddenEPDS">
        {{ $t('dashboard.label.hideHiddenEPDS') }}
        <span data-toggle="tooltip" data-placement="right"
              :title="$t('dashboard.label.hideHiddenEPDS')">
                          <font-awesome-icon icon="question-circle" size="sm"></font-awesome-icon><br>
                      </span>
      </label>
      <label for="hideCompleted" class="text-normal" v-if="pinLogin">
        <input type="checkbox" id="hideCompleted" style="width:auto"
               v-model="hideCompleted">
        {{ $t('dashboard.label.hideCompleted') }}
        <span data-toggle="tooltip" data-placement="right"
              :title="$t('dashboard.label.hideCompleted')">
                          <font-awesome-icon icon="question-circle" size="sm"></font-awesome-icon><br>
                      </span>
      </label>
      <br v-if="!pinLogin">
      <label for="hideNonDeleted" class="text-normal" v-if="!pinLogin">
        <input type="checkbox" id="hideNonDeleted" style="width:auto"
            v-model="hideNonDeleted">
            {{ $t('dashboard.label.hideNonDeleted') }}
        <span data-toggle="tooltip" data-placement="right"
            :title="$t('dashboard.label.hideNonDeleted')">
            <font-awesome-icon icon="question-circle" size="sm"></font-awesome-icon><br>
        </span>
      </label>
      <br>
      <label for="hideTypedEPDS" class="text-normal">
        <input type="checkbox" id="hideTypedEPDS" style="width:auto"
               v-model="hideTypedEPDS">
        {{ $t('dashboard.label.hideTypedEPDS') }}
        <span data-toggle="tooltip" data-placement="right"
              :title="$t('dashboard.label.hideTypedEPDS_tooltip')">
                          <font-awesome-icon icon="question-circle" size="sm"></font-awesome-icon><br>
                      </span>
      </label>
      <v-select taggable multiple v-model="hiddenEPDs" style="flex-grow: 2"></v-select>

      <!-- <label class="clickable" @click="showHiddenRecords()"
             :class="{'text-bold': showOnlyHiddenRecords, 'text-normal': !showOnlyHiddenRecords}">

        <font-awesome-icon icon="trash"></font-awesome-icon>
        {{ $t('dashboard.label.deleted') }}
        <span data-toggle="tooltip" data-placement="right"
              :title="$t('dashboard.label.deleted_tooltip')">
                          <font-awesome-icon icon="question-circle" size="sm"></font-awesome-icon><br>
                      </span>
      </label> -->
    </div>
  </div>
</template>

<style>
  .top-filters {
    position:relative;
    display:flex;
    align-items:baseline;
    height:32px;
    margin:4px;
    line-height: 20px;
    margin-left: 20px;

  }

  .top-filters-button {
    cursor: pointer;
    font-weight: 500;
    font-family: Raleway;
  }

  .top-filters-dropdown {
    position:absolute;
    top:34px;
    left:0px;
    background:white;
    z-index: 1000;
    -webkit-box-shadow: inset 0px -3px 3px 0 hsla(0,0%,90%,.5);
    -moz-box-shadow:  inset 0px -3px 3px 0 hsla(0,0%,90%,.5);
    box-shadow:  inset 0px -3px 3px 0 hsla(0,0%,90%,.5);
    padding:10px;
    white-space: nowrap;
  }
</style>

<script>
    import vSelect from "vue-select"
    import {mapState} from 'vuex';

    export default {
        props: {
            pinLogin: {
                type: Boolean,
                default: false
            },
        },
        components: {'v-select': vSelect},
        data() {
            return {
                dropdown:false
            }
        },
        methods: {
            showHiddenRecords() {
                let self = this;
                self.showOnlyHiddenRecords = !self.showOnlyHiddenRecords;
                self.hideHiddenEPDS = !self.showOnlyHiddenRecords;
            },
            updateState(field, value) {
                this.$store.commit('sideFilters/updateFilters', {field, value});
                if (!this.pinLogin) {
                    this.$store.dispatch('conversion/debouncedLoadData');
                    this.$store.dispatch('appointments/debouncedLoadData');
                }
            }
        },
        computed: {
            //Overall having a separated computed for all of them is not ideal but time is pressing now
            hiddenEPDs: {
                get() {
                    //Silly workaround because of https://github.com/sagalbot/vue-select/issues/529
                    return this.$store.state.sideFilters.filters.hiddenEPDs.slice();
                },
                set(val) {
                    //And for some really insane reason without this we are in infinite loop
                    if (JSON.stringify(val) !== JSON.stringify(this.$store.state.sideFilters.filters.hiddenEPDs)) {
                        this.updateState('hiddenEPDs', val);
                    }
                }
            },
            hideAppointmentPast: {
                get() {
                    return this.$store.state.sideFilters.filters.hideAppointmentPast;
                },
                set(val) {
                    this.updateState('hideAppointmentPast', val);
                }
            },
            hideHiddenEPDS: {
                get() {
                    return this.$store.state.sideFilters.filters.hideHiddenEPDS;
                },
                set(val) {
                    this.updateState('hideHiddenEPDS', val);
                }
            },
            hideTypedEPDS: {
                get() {
                    return this.$store.state.sideFilters.filters.hideTypedEPDS;
                },
                set(val) {
                    this.updateState('hideTypedEPDS', val);
                }
            },
            showOnlyHiddenRecords: {
                get() {
                    return this.$store.state.sideFilters.filters.showOnlyHiddenRecords;
                },
                set(val) {
                    this.updateState('showOnlyHiddenRecords', val);
                }
            },
            hideCompleted: {
                get() {
                    return this.$store.state.sideFilters.filters.hideCompleted;
                },
                set(val) {
                    this.updateState('hideCompleted', val);
                }
            },
            hideNonDeleted: {
                get() {
                    return this.$store.state.sideFilters.filters.hideNonDeleted;
                },
                set(val) {
                    this.updateState('hideNonDeleted', val);
                }
            },
            ...mapState({
              activeTab: state => state.userOptions.dashboardActiveTab,
            })
        },
        mounted () {
            this.hideNonDeleted = false
            if (this.pinLogin){
                this.hideAppointmentPast = false;
                this.hideHiddenEPDS = false;
            } else {
                this.hideCompleted = false;
            }
        },
    }
</script>
