<template>
    <tr>
        <td class="patient-date-cell">
            {{ row.created_at | stdDateTimeFormat }}
        </td>
        <td>
            <template v-if="row.log_name=='tag_deleted'">
                <tag :name="row.properties.tag.name" :color="row.properties.tag.color"
                     :groupColor="row.properties.tag.group?row.properties.tag.group.color:''"
                     :showDelete="false"
                     style="vertical-align: middle;"></tag>
                     <!-- {{ $t('dashboard.modal.tag_deleted', {user:row.causer.name}) }} -->
                <span v-if="row.causer != null"> {{ $t('dashboard.modal.tag_deleted', {user:row.causer.name}) }}</span>
                <span v-else> {{ $t('dashboard.modal.tag_deleted', {user:'System'}) }}</span>
            </template>
            <template v-else-if="row.log_name=='tag_added'">
                <tag :name="row.properties.tag.name" :color="row.properties.tag.color"
                     :groupColor="row.properties.tag.group?row.properties.tag.group.color:''"
                     :showDelete="false"
                     style="vertical-align: middle;"></tag>
                <span v-if="row.causer != null"> {{ $t('dashboard.modal.tag_added', {user:row.causer.name}) }}</span>
                <span v-else> {{ $t('dashboard.modal.tag_added', {user:'System'}) }}</span>
                <!-- {{ $t('dashboard.modal.tag_added', {user:row.causer.name}) }} -->
            </template>
            <template v-else-if="row.log_name=='tag_replaced'">
                <tag :name="row.properties.oldTag.name" :color="row.properties.oldTag.color"
                     :showDelete="false"
                     :groupColor="row.properties.oldTag.group?row.properties.oldTag.group.color:''"
                     style="vertical-align: middle;margin-right:0px"></tag> 
                <font-awesome-icon icon="arrow-right" aria-hidden="true"></font-awesome-icon>
                <tag :name="row.properties.newTag.name" :color="row.properties.newTag.color"
                     :groupColor="row.properties.newTag.group?row.properties.newTag.group.color:''"
                     :showDelete="false"
                     style="vertical-align: middle;"></tag>
                <span v-if="row.causer != null"> {{ $t('dashboard.modal.tag_replaced', {user:row.causer.name}) }}</span>
                <span v-else> {{ $t('dashboard.modal.tag_replaced', {user:'System'}) }}</span>
                <!-- {{ $t('dashboard.modal.tag_replaced', {user:row.causer.name}) }} -->
            </template>
            <template v-else-if="row.log_name=='tag_mail_attached' && row.properties.tag">
                {{ $t('dashboard.modal.tag_mail_template_attached') }}
                <tag :name="row.properties.tag.name" :color="row.properties.tag.color"
                     :groupColor="row.properties.tag.group?row.properties.tag.group.color:''"
                     :showDelete="false"
                     style="vertical-align: middle;"></tag>
                {{ $t('dashboard.modal.tag_mail_template_sent', {user:row.properties.patient_email}) }}
            </template>
            <template v-else-if="row.log_name=='intake_hidden' || row.log_name=='intake_restored'">
                <span v-if="row.causer != null"> {{$t('dashboard.modal.'+row.log_name, {user:row.causer.name, reason:row.description}) }}</span>
                <span v-else>  {{$t('dashboard.modal.'+row.log_name, {user:'System', reason:row.description}) }}</span>
              <!-- {{$t('dashboard.modal.'+row.log_name, {user:row.causer.name, reason:row.description}) }} -->
          </template>
          <!-- Expand this for more API hospital activity log mutations in the future -->
                   <template v-else-if="['api_new_hash', 'api_cancel_hash', 'api_update_hash', 'api_pending_mails', 'api_intake_explored'].includes(row.log_name)">
                    <p @click="flipExpansion()">
                        {{row.description + ', api_id: ' + row.causer_id}}
                    </p>
                    <p @click="getMirthMessage(row)" v-if="expanded">
                         {{'json: ' + row.causer.json}}
                    </p>
                    <p v-if="message != ''">
                        {{message}}
                    </p>
            </template>
          <template v-else-if="row.log_name == 'api_intake_link_external'">
                <span>
                    {{$t('activity.intake_link_external')}}: {{row.properties.intake_external.content}}; 
                    <span v-if="row.status">
                        {{$t(`dashboard.status.${row.status}`)}} 
                    </span>
                </span>
                <span v-if="row.status==1">
                        ({{this.$t(`dashboard.danger.${row.risk}`)}} - {{getRelatedDate(row.created_at) }})
                </span>
                <a target="_blank" :href="getIntakeLinkExternalUrl(row.properties.intake_external)">
                    <font-awesome-icon icon="eye" size="lg" class="pointer"></font-awesome-icon>
                </a>
          </template>
          <template v-else-if="row.log_name.indexOf('wcc.') == 0">
                <div v-if="userIsSuperAdmin">
                    <textarea :value="rowAsJSON(row)" rows="15" cols="80" disabled />
                </div>
          </template>
          <template v-else-if="row.log_name == 'change_preferences' && row.properties && row.properties.legacy_types && row.properties.legacy_types.length">
                {{$t(`dashboard.modal.${row.properties.action}`)}}: {{row.properties.legacy_types.join(', ')}} ({{row.causer.name}})
          </template>
            <template v-else>
                {{row.causer.name + ': ' + row.description }}
            </template>
          </td>
        <td align="right">
            <span v-if="row.log_name.search('appointment')!=-1" class="notification-label text-success">{{ $t('activity.appointment') }}</span>
            <span v-if="row.log_name.search('api')!=-1" class="notification-label text-success">API</span>
            <span v-else class="notification-label text-success">{{ $t('dashboard.modal.label.status') }}</span>
        </td>
    </tr>
</template>

<script>
    import Tag from "@/components/tag";

    import RowMixin from "./rowMixin";
    import { mapState } from 'vuex';
    import axios from 'axios'

    export default {
        components: {Tag},
        mixins: [RowMixin],
        data() {
          return {
            expanded: false,
            message: ""
          }
        },
        methods: {
            getMirthMessage(row){
                // only superadmins get mirth message (there's also a check in the backend but just to save requests)
                if (!this.userIsSuperAdmin){
                    return;
                }
                
                // get source JSON that backend needs
                const sourceJSON = JSON.parse(row.causer.json);
                axios.post('/api/ajax', {
                    'action': 'getMirthMessage',
                    'sourceJSON': sourceJSON
                }).then((result) => {
                    // show message to user. This can be error message, or the Mirth raw message
                    this.message = result.data.message;
                });
            },
            flipExpansion(){
                this.expanded = !this.expanded
            },
            getIntakeLinkExternalUrl(intakeExternal) {
                return `${document.location.protocol}//${document.location.host}/intake?org=${intakeExternal.organization}&dep=${intakeExternal.department}&hash=${intakeExternal.hash}`;
            },
            rowAsJSON(row) {
                return JSON.stringify(row, null, '\t');
            },
            getRelatedDate(date){
                return moment.utc(date, 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ').local().format('DD-MM-YYYY')
            }
        },
        computed: {
            ...mapState('auth', {
                userIsSuperAdmin: state => state.user.admin
            })
        }
    }
</script>
