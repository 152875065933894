import {passesSideFilters, passesDateRange} from '../helpers/filters'
import { debounce } from "lodash";
import axios from 'axios'
import Vue from 'vue'
// initial state
const state = {
  data: {
    bar: [],
    column: [],
    groupBy: "day",
    pie: [],
    table: [],
    tableOptions: {}
  },
  loading: false,
  filters: {},
  dates: [],
  subscribedChannels: []
}

// getters
const getters = {}

// actions
const actions = {
    subscribeToEcho(context, subscribedOrgSymbol) {
        //retrieve the related orgs:
        const orgRelations = this.getters['orgRelations/getRelations']({orgSymbol: subscribedOrgSymbol});
        const selectedOrgSymbol = context.rootState.userOptions.options.selectedOrganizationSymbol;
        [{orgSymbol: subscribedOrgSymbol}, ...orgRelations].forEach(({orgSymbol, departmentSymbols}) => {
            const channel = `dashboard.${orgSymbol}`;
            if(context.state.subscribedChannels.indexOf(channel) > -1) {
              //if already subscribed, don't subscribe again yo!
              return;
            }
            context.commit('addSubscribedChannel', channel);
            //TODO: listen per department
            Echo.private(channel).listen('.ic.updated', (e) => {

              
                // For now let's just ignore if subscribed not matches current
                if (subscribedOrgSymbol!==context.rootState.userOptions.options.selectedOrganizationSymbol) {
                  return
                }
                if (!e.editing && !e.editing_by) { //Don't trigger on lock events so it wouldn't update the table so often
                    context.commit('updateTableRow', {
                        hash: e.hash,
                        data: e
                    });
                }
            }).listen('.appointment.updated', (e) => {
                if (subscribedOrgSymbol!==context.rootState.userOptions.options.selectedOrganizationSymbol) {
                  return
                }
                context.commit('updateTableRow', {
                    hash: e.hash,
                    data: e
                });
            }).listen('.conversion.created', (e) => {
              //915 TODO find if it breaks when switching back and forth to an org ... will it add multiple times? subscription should be stopped i'd say
                if (subscribedOrgSymbol!==context.rootState.userOptions.options.selectedOrganizationSymbol) {
                  //TODO: out of scope for 915 but this is problematic for related orgs
                  return
                }
                if (passesSideFilters(context, e) && passesDateRange(context, e)) {
                  //h4x0r 915
                  e.orgSymbol = orgSymbol;
                  e.firstCompletedFormGroup = 4;
                  context.commit('addTableRow', e);
                }
            }).listen('.appointments.created', (e) => {
              if (subscribedOrgSymbol!==context.rootState.userOptions.options.selectedOrganizationSymbol) {
                //TODO: out of scope for 915 but this is problematic for related orgs
                return
              }
              context.commit('updateTableRow', {
                hash: e.hash,
                data: {
                  appointment_date: e.appointment_date
                }
              })
            });
        })
    },
    async loadData(context) {
        const self = this
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        // Check if we already have the dates, if not, skip
        if (!context.state.dates || context.state.dates.length !== 2) {
          return
        }
        context.commit('setField', {
            field: 'loading',
            value: true
        });
        //TODO: (partial) error handling
        const orgSymbol = context.rootState.userOptions.options.selectedOrganizationSymbol;
        if(!orgSymbol || !orgSymbol.length) return;
        const orgRelations = this.getters['orgRelations/getRelations']({orgSymbol});
        const orgSymbols = [{orgSymbol}, ...orgRelations].map(({orgSymbol}) => orgSymbol);
        const promises = [{orgSymbol}, ...orgRelations].map(({orgSymbol, departmentSymbols}) => {
            const params = {
                'action': 'getConsentData',
                'org': orgSymbol,
                'start': window.moment(context.state.dates[0], "DD-MM-YYYY").format("YYYY-MM-DD"),
                'end': window.moment(context.state.dates[1], "DD-MM-YYYY").format("YYYY-MM-DD"),
                'filters': context.rootState.sideFilters.filters,
                'timezone': tz,

            };
            //TODO: we can fetch the department configs immediately.
            if(!departmentSymbols) {
              departmentSymbols = context.rootState.auth.user.departments.filter(function (value) {
                return value.organizations[0].symbol === orgSymbol
              }).sort((a,b) => a.name.localeCompare(b.name)).map(dep => dep.symbol);
            }

            const apiPosts = departmentSymbols.map((dep) => {
              params.departmentSymbol = dep;         
              return axios.post('/api/ajax', params).then((result) => {
                return Promise.all([this.dispatch('tags/loadTags', {orgSymbol}), ...(result.data.tableOptions.department || []).map(departmentSymbol => this.dispatch('config/loadConfig', {orgSymbol, departmentSymbol}))]).then(() => {
                  return result.data;
                });
              });
            });
            return axios.all(apiPosts).then((data) =>{
              data.forEach(d => d.selectedOrgSymbol = orgSymbol);
              return data;
            });
        });
        Promise.all(promises).then(orgConversionDataArray => {
          //console.log("orgConversionDataArray", orgConversionDataArray);
            const mergedData = orgConversionDataArray.flat().reduce((partialResult, orgConversionData, i) => {
                const orgSymbol = orgConversionData.selectedOrgSymbol
                orgConversionData.tableOptions.tags = orgConversionData.tableOptions.tags || [];
                orgConversionData.tableOptions.tags = orgConversionData.tableOptions.tags.map(id => (`${id}_${orgSymbol}`)) //so we can actually retrieve the tags!
                orgConversionData.table.forEach(row => {
                    //TODO: prefix to prevent hash collisions
                    row.orgSymbol = orgSymbol; //TODO: add this in backend already

                    //TODO: ship this to backend yo:
                    if(row.relatedIcs && row.relatedIcs.length) row.relatedIcs = row.relatedIcs.split(',').filter((hash) => {
                        return hash !== row.hash;
                    })

                    if(row.status === 1 && row.completedFormFormIds && row.completedFormFormIds.length && row.filledForms && row.filledForms.length) {
                        const firstCompletedFormId = row.completedFormFormIds.split(',')[0];
                        const forms = row.filledForms.split(',').map(f => f.split(';'));
                        const form = forms.find(([formId]) => {
                            return firstCompletedFormId === formId;
                        });
                        if(form) {
                            const [formId, firstCompletedFormCreated, firstCompletedFormGroup] = form;
                            row.firstCompletedFormCreatedDate = moment(firstCompletedFormCreated).format('YYYY-MM-DD HH:mm:ss');
                            row.firstCompletedFormGroup = firstCompletedFormGroup;
                        }
                    } else {
                        row.firstCompletedFormGroup = 4
                    }
                })
                if(!i) return orgConversionData;
                //merge additional data:
                partialResult.table = [...partialResult.table, ...orgConversionData.table];
                const {tableOptions: tableOptionsPartialResult} = partialResult;
                const {tableOptions} = orgConversionData;

                //merging pie
                orgConversionData.pie.data.forEach((pieData) => {
                    const {id} = pieData;
                    const partialResultpieData = partialResult.pie.data.find(({id: id2}) => id === id2);
                    if (!partialResultpieData) partialResult.pie.data.push(pieData);
                    else {
                        const {y} = pieData;
                        partialResultpieData.y += y;
                    }
                });

                //merging column
                orgConversionData.column.forEach((column) => {
                    const {id} = column;
                    const partialColumn = partialResult.column.find(({id: id2}) => id === id2);
                    if(!partialColumn) partialResult.column.push(column);
                    else {
                        const {data} = column.data;
                        column.data.forEach(([timeValue, amount]) => {
                            const partialColumnData = partialColumn.data.find(([timeValue2]) => timeValue == timeValue2);
                            if(partialColumnData) partialColumnData[1] += amount;
                            else partialColumn.data.push([timeValue, amount]);
                        });
                    }
                })
                //merging bar
                partialResult.bar = partialResult.bar || [];
                orgConversionData.bar && orgConversionData.bar.forEach((bar) => {
                    const {id, data} = bar;
                    const partialBar = partialResult.bar.find(({id: id2}) => id === id2);
                    if(!partialBar) partialResult.bar.push(bar);
                    else if(data && data.length) {
                        data.forEach(([content, amount]) => {
                            partialBar.data = partialBar.data || [];
                            const partialBarData = partialBar.data.find(([content2]) => content === content2);
                            if(partialBarData) partialBarData[1] += amount;
                            else partialBar.data.push([content, amount]);
                        })
                    }
                })
                
                
                //merging tableoptions.date
                if(tableOptions.date) partialResult.tableOptions.date = [...new Set(tableOptions.date.concat(tableOptionsPartialResult.date))];
                if(tableOptions.content) partialResult.tableOptions.content = [...new Set(tableOptions.content.concat(tableOptionsPartialResult.content))];
                if(tableOptions.department) partialResult.tableOptions.department = [...new Set(tableOptions.department.concat(tableOptionsPartialResult.department))];
                if(tableOptions.tags) partialResult.tableOptions.tags = [...new Set(tableOptions.tags.concat(tableOptionsPartialResult.tags))];
                if(tableOptions.appointment_type) partialResult.tableOptions.appointment_type = [...new Set(tableOptions.appointment_type.concat(tableOptionsPartialResult.appointment_type))];
                if(tableOptions.specialty) partialResult.tableOptions.specialty = [...new Set(tableOptions.specialty.concat(tableOptionsPartialResult.specialty))];
                if(tableOptions.service_type) partialResult.tableOptions.service_type = [...new Set(tableOptions.service_type.concat(tableOptionsPartialResult.service_type))];
                if(tableOptions.origin) partialResult.tableOptions.origin = [...new Set(tableOptions.origin.concat(tableOptionsPartialResult.origin))];
                if(tableOptions.fullname) partialResult.tableOptions.fullname = true;
                return partialResult;
            }, null);

            // ugly fix tableOptions, loop over options, if array, remove all 'undefineds' to stop wrong columns showing up
            // TODO: Find out where the undefineds come from ()
            for (const option in mergedData.tableOptions){
              if (Array.isArray(mergedData.tableOptions[option])){
                mergedData.tableOptions[option] = mergedData.tableOptions[option].filter((el) => {
                  return typeof el != 'undefined'
                })
              }
            }

            // alphabetising the bar data and making sure all content is in all data arrays, so that it's consistent with the dropdown filtering on content
            if (mergedData.bar){
              mergedData.bar.forEach((bar) => {
                if(!bar.data) bar.data = [];
                mergedData.bar.forEach(siblingBar => {
                  if(siblingBar === bar || !siblingBar.data) return;
                  siblingBar.data.forEach(([siblingContent]) => {
                    if(!bar.data.find(([content]) => content == siblingContent)) {
                      bar.data.push([siblingContent, 0]);
                    }
                  })
                });
                bar.data.sort((a, b) => {
                  if (a[0] < b[0]) {
                    return -1;
                  }
                  return 1;
                })
              })
            }
            context.commit('setField', {
                field: 'data',
                value: mergedData
            });
            context.commit('setField', {
                field: 'loading',
                value: false
            })
            //subcribe to echo here yo!
            orgSymbols.forEach(orgSymbol => context.dispatch('subscribeToEcho', orgSymbol))
        });
    },
    //For now, part of the logic, including the localStorage are still in selectPeriod.vue.
    updateDates(store, dates) {
        store.commit('setField', {
            field: 'dates',
            value: dates
        });
      store.dispatch('debouncedLoadData');
    },
    debouncedLoadData: debounce(({ dispatch }) => {
      dispatch("loadData");
    }, 500),
    async sendIntake(store, form) {
      form = {
        ...form,
        org: store.rootState.userOptions.options.selectedOrganizationSymbol,
        departmentSymbol: store.rootState.userOptions.options.selectedDepartmentSymbol,
      }
      return await axios.post('/api/invite/send', form)
    }
}

// mutations
const mutations = {
  setField(state, {field, value}) {
    Vue.set(state, field, value);
  },
  updateTableRow(state, {hash, data}) {
    for (const key in state.data.table) {
      const row = state.data.table[key];
      if (hash && row.hash == hash) {
        Vue.set(state.data.table, key, {...row, ...data});
      }
    }
  },
  addTableRow(state, newRow) {
    state.data.table.unshift(newRow);
  },
  addSubscribedChannel(state, channel) {
    state.subscribedChannels.push(channel);
  }
}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
