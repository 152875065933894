import axios from 'axios'
import Vue from 'vue'
import editingTable from './editingTable'

// actions
const actions = {
  enableThirdParty (context, id) {
    const {state, commit} = context;
    const org = context.rootState.userOptions.options.selectedOrganizationSymbol;
    return axios.put(`/api/admin/thirdParty/enable/${id}?org=${org}`).then(() => {
      commit('enableData', id)
    }).catch((response) => {
      console.log('Error enabling a third party record', response)
    })
  },
  ...editingTable.actions, // Inherit standard actions
}

// mutations
const mutations = {
  enableData (state, id) {
    for (const recordKey in state.data) {
      if (state.data[recordKey].id === id) {
        Vue.set(state.data, recordKey, {...state.data[recordKey], enabled:1})
      } else {
        Vue.set(state.data, recordKey, {...state.data[recordKey], enabled:0})
      }
    }
  },
  ...editingTable.mutations, // Inherit standard mutations
}

export default {
  namespaced: true,
  state: editingTable.state,// Inherit standard state
  getters: editingTable.getters, // Inherit standard getters
  actions,
  mutations,
}
