<template>
    <div style="display: flex; flex-direction: column; width: 100%;">
        <div style="display: flex; justify-content: space-around; padding-bottom: 10px; padding-top: 10px; width: 100%">
            <div style="display: flex; justify-content: left;">
                <button
                        v-show="editMode"
                        v-bind:key=chartInfo.type
                        v-for="chartInfo in availableCharts"
                        @click="toggleChart(chartInfo.type)"
                        v-bind:class="{'btn-primary': viewInfo.chart === chartInfo.type}"
                        type="button" class="btn btn-sm btn-default "
                        style="display: flex">
                    <font-awesome-icon :icon="chartInfo.iconName" :rotation="chartInfo.iconRotation"></font-awesome-icon>
                </button>

                <button
                        v-show="reportType=='nps' && editMode"
                        @click="toggleChart('nps')"
                        v-bind:class="{'btn-primary': viewInfo.chart === 'nps'}"
                        type="button" class="btn btn-sm btn-default "
                        style="display: flex">
                    NPS
                </button>
            </div>

            <div v-show="editMode" style="display: flex; justify-content: right; flex-items: row; align-items: baseline">
                <p :style="styles.toggleText">{{translate('report.table.turn_table_off')}}</p>
                <label :style="styles.toggleLabel" class="switch">
                    <input :style="styles.toggleInput" type="checkbox" @click="toggleTable()" :checked="this.viewInfo.table">
                    <span  :style="styles.slider(getTableActive())" class="slider round"></span>
                </label>
                <p :style="styles.toggleText">{{translate('report.table.turn_table_on')}}</p>
            </div>
        </div>

        <div style="display: flex; flex-direction: row;">
            <chart style="display: flex; width: 55%; margin-top: auto; margin-bottom: auto;"
                    v-if="viewInfo.chart"
                    :data=data
                    :options=getOptions()
                    :colors=colors
                    :loadingState=loadingState
                    :type=viewInfo.chart
                    :tableActive="viewInfo.table"
                    :target="target"
                    :answers="answers"
                    :config=config
                    :rawData="getRawData()"
                    :namespaceInstance="namespaceInstance">
            </chart>
            
            <!-- space between chart and table -->
            <div style="width: 5%"></div>

            <chart style="display: flex; width: 40%;"
                    v-if="viewInfo.table"
                    :data=data
                    :options=getOptions()
                    :colors=colors
                    :loadingState=loadingState
                    :tableActive="viewInfo.table"
                    :answers="answers"
                    type="table"
                    :config=config
                    :rawData="getRawData()"
                    :namespaceInstance="namespaceInstance">
            </chart>
        </div>

    </div>
</template>

<script type='text/javascript'>
    import Chart from './chart.vue';
    import Vue from 'vue'
    export default {
        props: ['data', 'colors', 'loadingState', 'name', 'config', 'editMode', 'viewInfo', 'reportType', 'target', 'filterData', 'options', 'answers', 'namespaceInstance'],
        data() {
            return {
                availableCharts: [
                    {
                        type: 'pie',
                        iconName: 'chart-pie',
                        iconRotation: 0
                    },
                    {
                        type: 'halfPie',
                        iconName: 'magnet',
                        iconRotation: 180
                    },
                    {
                        type: 'column',
                        iconName: 'chart-bar',
                        iconRotation: 0
                    },
                    {
                        type: 'bar',
                        iconName: 'chart-bar',
                        iconRotation: 90
                    },
                    {
                        type: 'percentBar',
                        iconName: 'percent',
                        iconRotation: 0
                    },
                    {
                        type: 'gradeGauge',
                        iconName: "tachometer",
                        iconRotation: 0
                    },

                ],
                styles: {
                    toggleText: {
                        'marginTop': 'auto',
                        'marginBottom': 'auto',
                        'marginRight': '5px',
                        'marginLeft': '5px'
                    },
                    toggleLabel: {
                        'marginTop': 'auto',
                        'marginBottom': 'auto',
                        'position': 'relative',
                        'display': 'inline-block',
                        'width': '54px',
                        'height': '26px'
                    },
                    slider(active) {
                        return {
                            'position': 'absolute',
                            'cursor': 'pointer',
                            'top': '0',
                            'left': '0',
                            'right': '0',
                            'bottom': '0',
                            'background-color': active ? "#005375" : "#999",
                            'transition': '.4s',
                            'borderRadius': '34px',
                            'marginTop': "auto",
                            'marginBottom': 'auto'
                        }
                    },
                    toggleInput: {
                        'opacity': '0',
                        'width': '0',
                        'height': '0'
                    }
                }
            };
        },
        components: {
            Chart
        },
        mounted () {
            console.log('>> chartInfo', this.chartInfo);
        },
        methods: {
            toggleChart(chart) {
                // TODO: this and `toggleTable` ported to store? 
                Vue.set(this.viewInfo,'chart',this.viewInfo.chart === chart ? null : chart);
            },
            toggleTable() {
                Vue.set(this.viewInfo,'table', !this.viewInfo.table);
            },
            getRawData() {
                return this.filterData;
            },
            getOptions() {
                return this.options;
            },
            getTableActive(){
                return this.viewInfo.table;
            },
            translate(key) {
                return this.$t(key);
            }
        },
    };
</script>


<style>
    .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }

    .slider.round:before {
        border-radius: 50%;
    }
</style>
