<template>
    <div style="display: inline; z-index: 17000;" class="period-filter">

        <div class="form-inline" style="margin-right: 15px">
            <select v-model="localPeriod" class="form-control">
                <option v-bind:key="period.text" v-for="period in getPeriods()" v-bind:value="period.value">
                    {{ period.text }}
                </option>
            </select>
        </div>

        <div style="display: inline;" v-show="localPeriod == 'custom'">
            <date-picker-wrapper
                format='DD-MM-YYYY' 
                ref='datepickerBoolean'
                datepicker_ref='datepicker'
                v-on:new-date="informParent($event)"
            ></date-picker-wrapper>
        </div>

    </div>
</template>

<script type="text/javascript">

  import {VueSelect as vSelect} from "vue-select";
  import dateRanges from '@/dateRanges';
  import DatePickerWrapper from '@/components/datePickerWrapper'

  export default {
        name: 'period-filter',
        props: ["onPeriodModified", "period", "start", "end", "dateRanges", "translate", "type"],
        components: {
            vSelect,
            DatePickerWrapper
        },
        data() {
            return {
                localPeriod: this.period,
                localStart: this.start,
                localEnd: this.end,
                periods: [
                    {
                        value: 'last30days',
                        text: this.$t('dashboard.filter.period.last30days'),
                        label: this.$t('dashboard.filter.period.last30days'),
                    },
                    {
                        value: 'last7days',
                        text: this.$t('dashboard.filter.period.last7days'),
                        label: this.$t('dashboard.filter.period.last7days'),
                    },
                    {
                        value: 'last12months',
                        text: this.$t('dashboard.filter.period.last12months'),
                        label: this.$t('dashboard.filter.period.last12months'),
                    },
                    {
                        value: 'thismonth',
                        text: this.$t('dashboard.filter.period.thismonth'),
                        label: this.$t('dashboard.filter.period.thismonth'),
                    },
                    {
                        value: 'thisweek',
                        text: this.$t('dashboard.filter.period.thisweek'),
                        label: this.$t('dashboard.filter.period.thisweek'),
                    }
                 ],
                 futurePeriods: [
                     {
                        value: 'next7days',
                        text: this.$t('dashboard.filter.period.next7days'),
                        label: this.$t('dashboard.filter.period.next7days'),
                    },
                    {
                        value: 'next14days',
                        text: this.$t('dashboard.filter.period.next14days'),
                        label: this.$t('dashboard.filter.period.next14days'),
                    },
                    {
                        value: 'next30days',
                        text: this.$t('dashboard.filter.period.next30days'),
                        label: this.$t('dashboard.filter.period.next30days'),
                    },
                    {
                        value: 'next90days',
                        text: this.$t('dashboard.filter.period.next90days'),
                        label: this.$t('dashboard.filter.period.next90days'),
                    }
                 ],
                 customPeriods: [
                    {
                        value: 'custom',
                        text: this.$t('dashboard.filter.period.custom'),
                        label: this.$t('dashboard.filter.period.custom'),
                    }
                 ]

            }
        },
        methods: {
            informParent($event){
                const {localPeriod: period, onPeriodModified} = this;  
                // parse to correct formats

                if (period !== 'custom'){
                    const {localStart: start, localEnd: end} = this;
                    return onPeriodModified({period, start, end});
                }
                let start = $event[0].toLocaleString("en-GB").substring(0,10).replace(/\//g, '-');
                let end = $event[1].toLocaleString("en-GB").substring(0,10).replace(/\//g, '-');

                onPeriodModified({period, start, end});
            },
            getOptions() {
                return this.periods;
            },
            getPeriods() {
                // basic periods are always available
                let periods = this.periods;

                // appointment gets future periods
                if (this.type === "periodAppointmentDate"){
                    periods = periods.concat(this.futurePeriods);
                }

                // make sure custom is always at the bottom
                periods = periods.concat(this.customPeriods);
                return periods;
            }
        },
        watch: {
            localPeriod(period) {
                if(!period || period === 'custom') return;
                this.localStart = dateRanges[period][0];
                this.localEnd = dateRanges[period][1];
                this.informParent();
            },
            localStart() {
                if (this.period !== 'custom') return;
                this.informParent(true);
            },
            localEnd() {
                if (this.period !== 'custom') return;
                this.informParent(true);
            }
        }
    };
</script>
