<template>
    <!-- Item selector: -->
      <div class="control-selector">
        <label class="control-label">
          {{ itemLabel }}
        </label>
        <div>
          <multiselect
              v-model="multiselectSelectedItem"
              :options="items"
              track-by="name"
              label="name"
              :show-labels="false"
              :value="multiselectSelectedItem"
              class="control-select"
              :placeholder="defaultValue"
          ></multiselect> 
        </div>
      </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

require('vue-multiselect/dist/vue-multiselect.min.css')

export default {
  data () {
    return {
      multiselectSelectedItem: this.selectedItem,
      defaultValue: this.defaultItem
    }
  },

  components: {
    Multiselect
  },
  
  props: ['selectedItem', 'items', 'itemLabel', 'defaultItem'],
  emits: ['update'],

  watch: {
    multiselectSelectedItem(newValue) {
      if (newValue) {
        this.$emit('update', newValue);
      }
      else {
        this.$emit('update', this.defaultValue);
      }
    },
    defaultItem(newValue) {
      this.multiselectSelectedItem = newValue ? newValue : this.defaultValue;
    }
  },
}
</script>

<style>

.multiselect__tags {
  min-height: 0px !important;
  padding: 6px 12px !important;
}

.multiselect, .multiselect__input {
  font-size: 14px !important;
  padding: 0px !important;
 }

.multiselect__single {
  margin-bottom: 1px !important;
  font-size: 14px !important;
  padding: 0px !important;
}

.multiselect__placeholder {
  margin-bottom: 1px !important;
}

.multiselect__select {
  /* Prevents the text to overlap caret*/
  z-index: 1;
}

.multiselect__select:before {
  top: 50% !important;
}

.control-label {
  margin: 7px 0px 7px;
  margin-bottom: auto !important;
  position: relative;
  text-align: left
}

.control-selector {
  min-width: 250px;
  display: flex;
  margin: 4px 10px 0px
}

.control-select {
  position: relative;
  min-width: 200px;
  margin: 0px 10px 0px
}
</style>
