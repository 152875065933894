<template>
    <div class="form-group" style="position: relative; margin-bottom: 0px"
    :disabled="nonUniqueTags.length > 1">
        <tag-select v-model="localTags" :options="options" :key="'ic_id'+ic_id"
                    :orgSymbol="orgSymbol" v-on:replaceTag="replaceTag"
                    :forceDeleteButton="false"
                    :forceShowUnalterableTags="false"
                    :canReplace="true" 
                    :force-close-dropdown="nonResolvedIssuesExist()"
                    :disabled="nonUniqueTags.length > 1 || checkAttachedEmail()"
        ></tag-select>
        <input type="hidden" name="tags" :value="JSON.stringify(localTags)">
        <div class="alert alert-danger" :class="{ shake: errorAnimation }"
             v-if="nonUniqueTags.length > 1">
            <div v-html="$t('tags.replace_tag_warning', {tag1:nonUniqueTags[0].name, tag2:nonUniqueTags[1].name})"></div>
            <button class="btn btn-default btn-xs" @mousedown.stop="confirmReplaceExclusiveTag()">{{ $t('tags.replace') }}</button>
            <button class="btn btn-default btn-xs" @mousedown.stop="cancelTag(nonUniqueTags[1])">{{ $t('tags.cancel') }}</button>
        </div>
        <div class="alert alert-danger" :class="{ shake: errorAnimation }"
             v-if="checkAttachedEmail()">
            <div v-html="$t('tags.attached_email_warning')"></div>
            <div v-if="noUserEmail" v-html="$t('dashboard.modal.no_email_found')"></div>
            <button v-if="noUserEmail" class="btn btn-default btn-xs" @mousedown.stop="disableTagEmailWarning()">{{ $t('tags.proceed_without_email') }}</button>
            <button v-else class="btn btn-default btn-xs" @mousedown.stop="sendAttachedEmail()" :disabled="disableSendButton || noUserEmail">{{ $t('tags.send_email') }}</button>
            <button class="btn btn-default btn-xs" @mousedown.stop="cancelTag(lastEmailTriggeringTagAdded)" :disabled="disableSendButton">{{ $t('tags.cancel') }}</button>
            <div v-if="noUserEmail" v-html="$t('dashboard.modal.no_email_found')"></div>
        </div>
    </div>
</template>

<script>
    import TagSelect from './tagSelect';
    import VModal from 'vue-js-modal'
    import { mapState } from 'vuex'

    let debounce = require('lodash.debounce');

    export default {
        components: {TagSelect, VModal},
        // MED-I786
        props: ['ic_id', 'tags', 'locked', 'canReplace', 'preselectDefault', 'ic', 'userEmail', 'reset'],
        data() {
            return {
                localTags: undefined,
                detailedTags: [],
                updateBackendDebounced: debounce(this.updateBackend, 1000),
                nonUniqueTags: [],
                dataFromBackend: false,
                errorAnimation: false,
                tagEnabledEmails: {},
                lastEmailTriggeringTagAdded: null,
                attachedEmails: false,
                dropdownAddeableTag: null,
                dropdownDeleteableTag: null, 
                options: [], // this.$store.getters['tags/getAllIds'](this.orgSymbol);
                disableSendButton: false,
            }
        },
        computed: {
            loadedOrgs(){
                return this.$store.getters['tags/loadedOrgs']();
            },
            //promise version, just in case
            //     options () {
            //     if (this.orgSymbol) {
            //         this.$store.getters['tags/getAllIds'](this.orgSymbol)
            //         .then((result) => {
            //             this.options = result;
            //         });
            //     }
            // },


            ...mapState('userOptions', {
              orgSymbol: state => state.options.selectedOrganizationSymbol,
              departmentSymbol: state => state.options.selectedDepartmentSymbol
            }),
            ...mapState({
                tagsErrorAnimation: state => state.consentModal.tagsErrorAnimation,
            }),
            noUserEmail() {
                return !(this.userEmail || (this.ic && this.ic.epd.useremail));
            }
        },
        methods: {
            addTag(newTag, quickDropdown = false) {
                if (!newTag) {
                    return;
                }

                // If new tag is in an exclusive tag group, check if there already is another tag from this group
                // If so, a warning will be triggered. Don't do the check if on quickDropdown bc we don't want the warning there
                if (!quickDropdown && newTag.group && newTag.group.exclusive) {
                    this.localTags.forEach(x => {
                        let tag = this.tag(x);

                        if (tag.id != newTag.id && tag.group && tag.group.id && (tag.group.id == newTag.group.id)) {
                            this.nonUniqueTags = [tag, newTag];
                            this.updateTogglePreventClose();
                            return;
                        }
                    })
                }

                // If on quickDropdown, check if we have exclusive WITHOUT mutating nonUniqueTags, which would trigger the watcher
                if (quickDropdown && newTag.group && newTag.group.exclusive) {
                    this.dropdownAddeableTag = newTag;
                    this.localTags.forEach(x => {
                        let tag = this.tag(x);
                        if (tag.id != newTag.id && tag.group && tag.group.id && (tag.group.id == newTag.group.id)) {
                            this.dropdownDeleteableTag = tag; 
                            return;
                        }
                    })
                }

                // Add new tag to list if it hasn't been properly added yet
                if (!this.localTags.includes(newTag.id)) {
                    this.localTags.push(newTag.id);
                }
                if (!this.detailedTags.includes(newTag.id)) {
                    this.detailedTags.push(this.tag(newTag.id));
                }

                // Register the fact that the new tag triggers an email
                if (newTag.enabling_email_key && this.tagEnabledEmails[newTag.enabling_email_key]) {
                    this.lastEmailTriggeringTagAdded = newTag;
                    this.attachedEmails = true;
                }

                // Plan backend update for the change in tags
                // Only if no email warning is present. If it is, confirming the warning will do the update
                if (this.nonUniqueTags.length == 0 && !this.attachedEmails) {
                    this.updateBackendDebounced();
                }

                // Toggles prevent/allows user to close tab according to new updates
                this.updateTogglePreventClose();
            },
            updateBackend() {
                if (this.ic) {
                    let send = {tags: this.localTags};
                    send.ic_id = (this.ic ? this.ic.id : 0);
                    send.org = this.ic.orgSymbol;
                    send.departmentSymbol = this.ic.department;
                    this.updateTogglePreventClose();
                    this.axios.put('/api/ic/add_tags', send).then((result) => {
                        this.$emit('tags-updated', this.ic_id, this.detailedTags)
                    });
                }
                else {
                    // We have to send all the tags to the parent component, with their info
                    this.$emit('tags-updated', 0, this.detailedTags)
                }
            },
            replaceTag(selectedTagId, newTagId) {
                let newTag = this.tag(newTagId);
                let selectedTag = this.tag(selectedTagId);

                this.addTag(newTag, true);
                
                // only commit tag replacement right away if there's no email warning, confirming the warning will commit the replacement
                if (!this.checkAttachedEmail()){
                    // If there is an exclusive tag conflict, we will only effectively replace the tag after user confirmation
                    if (this.nonUniqueTags.length == 0) {
                        this.effectivelyReplaceTag(selectedTag, newTag, true);
                    }
    
                    this.updateTogglePreventClose();
                    this.updateBackendDebounced();
                }
            },
            confirmReplaceExclusiveTag() {
                this.effectivelyReplaceTag(this.nonUniqueTags[0], this.nonUniqueTags[1]);
                this.nonUniqueTags = [];
            },
            effectivelyReplaceTag(oldTag, newTag) {
                if (this.localTags.includes(oldTag.id)){
                    // Find index of current tag to replace with new tag in order to keep the same tag order on display
                    let oldTagArrayIndex = this.localTags.findIndex((x) => x === oldTag.id);
                    let newTagArrayIndex = this.localTags.findIndex((x) => x === newTag.id);
    
                    // Put a copy of newTag instead of oldTag in the array
                    this.localTags[oldTagArrayIndex] = newTag.id;
                    this.detailedTags[oldTagArrayIndex] = newTag;
    
                    // Remove the original newTag occurence, so that we only have the replacement left
                    this.localTags.splice(newTagArrayIndex, 1);
                    this.detailedTags.splice(newTagArrayIndex, 1);
    
                    this.updateTogglePreventClose();
                    this.updateBackendDebounced();
                }
            },
            disableTagEmailWarning() {
                this.attachedEmails = false;
                this.updateTogglePreventClose();
            },
            sendAttachedEmail() {
                this.disableSendButton = true;
                // clicking "Send email" on the email warning of the quickDropdown should also replace any exclusive tag
                // if we're in quickDropdown, dropdownDeleteableTag will be the "old" one, we can now replace it:
                if (this.dropdownDeleteableTag && this.dropdownAddeableTag){
                    this.effectivelyReplaceTag(this.dropdownDeleteableTag, this.dropdownAddeableTag, true);
                }

                // Data coming from IC form is checked first, if IC doesn't exist yet then we use the data passed via v-model
                let request = {};
                request.departmentSymbol = this.departmentSymbol;
                request.ic_id = (this.ic ? this.ic.id : 0);
                request.email_address = (this.ic ? this.ic.epd.useremail : this.userEmail);
                request.tagId = this.lastEmailTriggeringTagAdded.id;
                request.email_subject = this.tagEnabledEmails[this.lastEmailTriggeringTagAdded.enabling_email_key].subject;

                // Wtf for some reason adding this is implicitely mandatory for the dashboard backend to connect to the right DB where this request is used?!
                request.org = this.orgSymbol;

                this.axios.put('/api/tags/send_email', request).then((result) => {
                    this.attachedEmails = false;
                    this.disableSendButton = false;
                    this.updateTogglePreventClose();
                });
            },
            cancelTag(tag) {
                if (tag == this.lastEmailTriggeringTagAdded) {
                    this.attachedEmails = false;
                }

                this.dropdownAddeableTag = null;
                this.dropdownDeleteableTag = null
                
                if (this.nonUniqueTags.length > 1 && (tag == this.nonUniqueTags[1] || tag == this.nonUniqueTags[0])) {
                    this.nonUniqueTags = [];
                }

                this.removeTag(tag);
            },
            removeTag(tag, useId = false) {
                // todo: remove the useId temporary fix: removeTags should always be called with an id
                if (tag) {
                    if (!useId) {
                        tag = tag.id;
                    }
                    // remove the requested tag from the list of tags that are being displayed
                    for (let key in this.localTags) {
                        if (this.localTags[key] === tag) {
                            this.localTags.splice(key, 1);
                            break;
                        }
                    }
                    // idem for detailedTags, which we use in the sendInvite page
                    this.detailedTags.forEach((detailedTag, i) => {
                        if ('id' in detailedTag && detailedTag.id === tag) {
                            this.detailedTags.splice(i, 1);
                        }
                    })
                    this.nonUniqueTags = [];
                    this.updateTogglePreventClose();
                    this.updateBackendDebounced();
                }
            },
            tag(id) {
                return this.$store.getters['tags/getTagById']({id, orgSymbol: this.orgSymbol});
            },
            setDefault () {
              this.localTags = this.$store.getters['tags/getDefaultTags'](this.orgSymbol);
              this.detailedTags = [];

              this.localTags.forEach(x => {
                    this.detailedTags.push(this.tag(x));
                })
              this.$emit('tags-updated', 0, this.detailedTags)
            },
            animateError(){
                this.errorAnimation = true;
                setTimeout(() => {
                    this.errorAnimation = false
                }, 1500)
            },
            updateTogglePreventClose() {
                // Check if the user still has issues to resolve, if so prevents them from closng the window by clicking outside
                if (this.attachedEmails || this.nonUniqueTags.length > 1) {
                    this.$store.dispatch('consentModal/toggleModalClosePrevent', true);
                } else {
                    this.$store.dispatch('consentModal/toggleModalClosePrevent', false);
                }
            },
            checkAttachedEmail() {
                return this.attachedEmails;
            },
            nonResolvedIssuesExist() {
                return (this.attachedEmails || this.nonUniqueTags.length > 1);
            },
            async loadEmails() {
                this.tagEnabledEmails = {};
                var orgSymbol = this.orgSymbol;
                var departmentSymbol = this.departmentSymbol;

                // Retrieve tag activated emails from DB, set them in the vuex state
                await this.$store.dispatch('tags/requestTagActivatedEmails', {orgSymbol, departmentSymbol}).then(result => {
                });

                // Retrieve tag activated emails from the vuex state
                const emailJSONfiles = this.$store.getters['tags/getTagActivatedEmails'](this.orgSymbol);
                
                if (emailJSONfiles) {
                    emailJSONfiles.forEach(emailJson => {
                        var email = JSON.parse(emailJson);
                        if (email.tag_enabled) {
                            this.tagEnabledEmails[email.template] = email;
                        }
                    })
                }
            }
        },
        watch: {
            //Any changes to the this.row would result in backend request so we will update it on server
            localTags: {
                handler(newVal, oldVal) {
                    if(!oldVal && this.ic) return; 
                    if (this.locked) {
                        return;
                    }

                    if (this.dataFromBackend) {
                        this.dataFromBackend = false; //So the next event will work
                        return;
                    }
                    
                    // Get newly added tag
                    let newTagsAdded = oldVal ? newVal.filter(x => !oldVal.includes(x)) : [];

                    // Add new tag
                    if (newTagsAdded.length > 0) {
                        let newTag = this.tag(newTagsAdded[0]);
                        this.addTag(newTag);
                    }

                    // Remove tag
                    if (oldVal) {
                        let newTagsRemoved = oldVal.filter(x => !newVal.includes(x));
                        if (newTagsRemoved.length > 0) {
                            this.removeTag(newTagsRemoved[0], true)
                        }
                    }

                    // Reset tag warning disable toggle
                    this.disabledTagEmailWarning = false;
                },
                deep: true
            },
            reset(val) {
                // This is used to reset selected tags via prop.
                if (val) {
                    if (this.preselectDefault) {
                        this.setDefault();
                    }
                    else {
                        this.localTags = [];
                        this.detailedTags = [];
                    }

                    this.$emit('tags-updated', this.ic_id, this.detailedTags);
                }
            },
            orgSymbol: {
                immediate: true,
                handler: function(orgSymbol) {
                    if(!orgSymbol){return}
                    this.$store.dispatch('tags/loadTags', {orgSymbol}).then(result => {
                        if (this.tags) {   
                            this.localTags = this.tags.slice();
                        } 
                        // else if (this.preselectDefault) {
                        //     this.setDefault();
                        // }
                        if (this.preselectDefault) {
                            this.setDefault();
                        }
                    });
                    this.loadEmails();
                }
            },
            tagsErrorAnimation(animate){
                if (animate) {
                    this.errorAnimation = true;
                    setTimeout(() => {
                        this.errorAnimation = false
                        this.$store.dispatch('consentModal/toggleTagsErrorAnimation', false);
                    }, 1500)
                }
            },
            loadedOrgs(orgs){
                if (orgs.includes(this.orgSymbol)){
                    this.options = this.$store.getters['tags/getAllIds'](this.orgSymbol);
                }
            }
        },
        created () {
            this.loadEmails();
            let self = this;
            self.$eventHub.$on('set-default-tags', () => { 
                this.setDefault();
            })
        }
    }
</script>


<style scoped>
    .shake {
        animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        transform: translate3d(0, 0, 0);
    }

    @keyframes shake {
        10%,
        90% {
            transform: translate3d(-1px, 0, 0);
        }

        20%,
        80% {
            transform: translate3d(2px, 0, 0);
        }

        30%,
        50%,
        70% {
            transform: translate3d(-4px, 0, 0);
        }

        40%,
        60% {
            transform: translate3d(4px, 0, 0);
        }
    }
</style>