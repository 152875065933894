// initial state
const state = {
    relations: {
        'mst-orthopedie': [
            {orgSymbol: 'mst-anesthesie', departmentSymbols: ['anesthesie', 'pos-orthopedie']}
        ],
        'test': [
            {orgSymbol: 'test2', departmentSymbols: ['test2']}
        ],
        'mst-orthopediestaging': [
            {orgSymbol: 'mst-anesthesiestaging', departmentSymbols: ['anesthesie', 'pos-orthopedie']}
        ],
        'mst-neurochirurgie': [
            {orgSymbol: 'mst-anesthesie', departmentSymbols: ['pos-neurochirurgie']}
        ],
        'mpower-fysiostaging': [
            {orgSymbol: 'mpowerstaging', departmentSymbols: ['slokdarm']}
        ],
        'mst-kno': [
            {orgSymbol: 'mst-anesthesie', departmentSymbols: ['pos-kno']}
        ],
        'mst-urologie': [
            {orgSymbol: 'mst-anesthesie', departmentSymbols: ['pos-urologie']}
        ],
        'mst-plastische': [
            {orgSymbol: 'mst-anesthesie', departmentSymbols: ['pos-plastischechirurgie']}
        ],
        'meandermc-easycare': [
            {orgSymbol: 'meandermc-anesthesiestaging', departmentSymbols: ['easycare']},
            {orgSymbol: 'meandermc-orthopediestaging', departmentSymbols: ['easycare']},
            {orgSymbol: 'meandermc-mdlstaging', departmentSymbols: ['easycare']}
        ],
        'meandermc-orthopediestaging': [
            { orgSymbol: 'meandermc-covidpolistaging', departmentSymbols: ['pos-orthopedie'] }
        ],
        'mst-overview': [
            {orgSymbol: 'mst'},
            {orgSymbol: 'mst-anesthesie'},
            {orgSymbol: 'mst-chirurgie'},
            {orgSymbol: 'mst-geriatrie'},
            {orgSymbol: 'mst-gynaecologie'},
            {orgSymbol: 'mst-kindergeneeskunde'},
            {orgSymbol: 'mst-kno'},
            {orgSymbol: 'mst-longgeneeskunde'},
            {orgSymbol: 'mst-neurochirurgie'},
            {orgSymbol: 'mst-neurologie'},
            {orgSymbol: 'mst-nucleaire'},
            {orgSymbol: 'mst-oogheelkunde'},
            {orgSymbol: 'mst-orthopedie'},
            {orgSymbol: 'mst-plastische'},
            {orgSymbol: 'mst-radiotherapie'},
            {orgSymbol: 'mst-thoraxcentrum'},
            {orgSymbol: 'mst-urologie'},
            {orgSymbol: 'mst-verloskunde'},
            {orgSymbol: 'mst-tandheelkunde'}
        ],
        'mst-tandheelkunde': [
            {orgSymbol: 'mst-anesthesie', departmentSymbols: ['pos-tandheelkunde']}
        ],
        'meandermc-mdl': [
            {orgSymbol: 'meandermc-darmvb', departmentSymbols: ['voorbereiding']}
        ],
        'meandermc-overviewstaging': [
            {orgSymbol: "meander-dermastaging"},
            {orgSymbol: "meander-dietstaging"},
            {orgSymbol: "meander-longstaging"},
            {orgSymbol: "meander-rvgenstaging"},
            {orgSymbol: "meander-urostaging"},
            {orgSymbol: "meandermc-anesthesiestaging"},
            {orgSymbol: "meandermc-cardiostaging"},
            {orgSymbol: "meandermc-chistaging"},
            {orgSymbol: "meandermc-covid19staging"},
            {orgSymbol: "meandermc-covidpoli"},
            {orgSymbol: "meandermc-covidpolistaging"},
            {orgSymbol: "meandermc-darmvbstaging"},
            {orgSymbol: "meandermc-easycare"},
            {orgSymbol: "meandermc-gerstaging"},
            {orgSymbol: "meandermc-gynstaging"},
            {orgSymbol: "meandermc-internestaging"},
            {orgSymbol: "meandermc-kinderstaging"},
            {orgSymbol: "meandermc-mdlstaging"},
            {orgSymbol: "meandermc-mkastaging"},
            {orgSymbol: "meandermc-mtostaging"},
            {orgSymbol: "meandermc-neustaging"},
            {orgSymbol: "meandermc-oogstaging"},
            {orgSymbol: "meandermc-orthopediestaging"},
            {orgSymbol: "meandermc-pijnstaging"},
            {orgSymbol: "meandermc-plastischstaging"},
            {orgSymbol: "meandermc-polistaging"},
            {orgSymbol: "meandermc-reustaging"},
            {orgSymbol: "meandermcstaging"}
        ],
        'meandermc-overview': [
            {orgSymbol: "meander-derma"},
            {orgSymbol: "meander-diet"},
            {orgSymbol: "meander-long"},
            {orgSymbol: "meander-rvgen"},
            {orgSymbol: "meander-uro"},
            {orgSymbol: "meandermc-anesthesie"},
            {orgSymbol: "meandermc-cardio"},
            {orgSymbol: "meandermc-chi"},
            {orgSymbol: "meandermc-covid19"},
            {orgSymbol: "meandermc-darmvb"},
            {orgSymbol: "meandermc-ger"},
            {orgSymbol: "meandermc-gyn"},
            {orgSymbol: "meandermc-interne"},
            {orgSymbol: "meandermc-kinder"},
            {orgSymbol: "meandermc-mdl"},
            {orgSymbol: "meandermc-mka"},
            {orgSymbol: "meandermc-mto"},
            {orgSymbol: "meandermc-neu"},
            {orgSymbol: "meandermc-oog"},
            {orgSymbol: "meandermc-orthopedie"},
            {orgSymbol: "meandermc-pijn"},
            {orgSymbol: "meandermc-plastisch"},
            {orgSymbol: "meandermc-poli"},
            {orgSymbol: "meandermc-reu"}
        ]
    }
};

// getters
const getters = {
    getRelations: (state) => ({orgSymbol}) => state.relations[orgSymbol] || []
}

// actions
const actions = {
    
}

// mutations
const mutations = {
    
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}