<template>
    <div class="tab-pane">
        <div class="form-group u-mt-10">
            <label>{{ $t('dashboard.modal.comment') }}:</label>
            <textarea name="comment" class="form-control" rows="3"
                      v-model="comment"></textarea>
            <span class="text-danger" v-show="validationError">{{ validationError }}</span>
        </div>
        <button data-style="expand-left"
                class="btn btn-success pull-right btn-ic-leave-comment"
                @click="addComment()">
            {{ $t('dashboard.modal.comment_submit') }}
        </button>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    export default {
        data() {
            return {
                comment: '',
                validationError: '',
                ladda: ''
            }
        },
        computed: {
            ...mapState({
                tab: state => state.consentModal.selectedTab
            }),
        },
        methods: {
            addComment() {
                this.$store.dispatch('consentModal/addComment', this.comment).then(response => {
                    this.$store.commit('consentModal/selectTab', '');
                }, error => {
                    this.validationError = error.errors.body[0];
                })
            },
        },
        watch: {
            loading(val) {
                if (!this.ladda) {
                    this.ladda = Ladda.create(document.querySelector('.btn-ic-leave-comment'));
                }
                if (val) {
                    this.ladda.start();
                } else {
                    this.ladda.stop();
                }
            },
            tab(val) {
                this.comment = ''; //Always reset textarea content when tab showed or hiden
            }

        }
    }
</script>