<template>
  <div>
    <h1>{{ $t('messages.invite_form_header') }}</h1>
    <hr>
    <div v-show="sent" class="alert alert-success alert-dismissible" role="alert">
      <button type="button" class="close" @click="sent=false"><span aria-hidden="true">&times;</span></button>
      {{ $t('messages.success_message', {epd:sent}) }}
    </div>
    <div class='form-horizontal invite-form'>
      <div v-for="{field, type, options} in fields" v-show="showField(field)" class="form-group" :class="{'has-error':errors[field]}" v-bind:key="field">
        <label :for="field" class="col-sm-3 control-label header-font">{{ $t('messages.' + field) }}</label>
        <div class="col-sm-6">
          <div v-if="type=='radio'" class="col-sm-6">
            <div class="radio" v-for="option in options" v-bind:key="option">
              <label class="weight-100">
                <input v-model="form[field]" type="radio" :name="field" :value="option" :disabled="disabledField(field)">
                {{$t('messages.'+option)}}
              </label>
            </div>
          </div>
          <!-- MED-I786 -->
          <add-tags v-else-if="type=='tags'" :tags="form.tags" :preselectDefault="true" :reset="sending" style="margin:0px 1px;" :userEmail="form['useremail']" :orgSymbol="selectedOrganizationSymbol" @tags-updated="tagsUpdate"></add-tags>
          <!-- Special custom component for department -->
          <multiselect v-model="selectedDepartment" v-else-if="field=='department'" :options="availableDepartments" label="name" track-by="symbol"></multiselect>
          <!-- Special custom component for content -->
          <multiselect v-model="form[field]" v-else-if="field=='content' && groupedContent" :groupSelect="false" :options="groupedContent" :group-values="contentIsGrouped?'items':''" :group-label="contentIsGrouped?'group':''" track-by="value" label="label" :allow-empty="false" class='content'></multiselect>
          <date-picker-wrapper
            v-else-if="field=='appointment'"
            :range="false"
            format='DD-MM-YYYY HH:mm'
            type='datetime'
            time-title-format="DD-MM-YYYY"
            :time-picker-options="{format: 'HH:mm', start: '06:00', step:'00:30' , end: '23:30'}"
            value-type="YYYY-MM-DD HH:mm:ssZ"
            width="100%"
            v-on:new-date="form.date = $event"
            id="appointmentDate"
            ref="date"
            :forced-corrected-date="correctedIntakeDate"
            @new-date="newDate"
            @remove-date="removeDate"
          ></date-picker-wrapper>
          <div v-else-if="field=='dob'" class="row"> 
            <div v-for="period in ['day', 'month', 'year']" :key="period" class="col-sm-3" :style="'padding:'+period=='month'?'0px':'15px'">
              <multiselect 
                :class="{'glowy': dobCorrectedToPast[period]}"
                v-model="dob[period]" 
                :placeholder="$t('invitation.'+period)" 
                :options="dobOptions[period]" 
                @input="checkMonthAndUpdateForm()"
                :show-labels="false" 
            ></multiselect> 
            </div>
          </div>
          <!-- Special case for phone number: -->
          <div v-else-if="field=='phone_number_full'" >
            <div class="input-group">
              <div class="input-group-addon" v-if="field=='phone_number_full'">+</div>
              <input  v-model="form[field]" :type="type" :name="field" :id="field" class="form-control" :disabled="disabledField(field)">
            </div>
            <div class="checkbox">
              <label>
                <input v-model="form['no_phone']" :value="true" type="checkbox" id="no_phone">
                {{$t('invitation.no_phone')}}
              </label>
            </div>
          </div>
          <!-- For everything else there are standard input -->
          <input  v-model="form[field]" v-else :type="type" :name="field" :id="field" class="form-control" :disabled="disabledField(field)">
          <!-- Special case for email -->
          <div class="checkbox" v-if="field==='useremail'"  v-show="showField('no_email')">
            <label>
              <input v-model="form['no_email']" :value="true" type="checkbox" id="no_email">
              {{$t('messages.no_email')}}
            </label>
          </div>
          <p v-show="errors[field]" v-for="error in errorsParsed[field]" class="help-block" :key="error">{{$t(error, {
            'attribute': $t('messages.'+field).toLowerCase(),
            ...validationExtraMsgs[field]
            })}}</p>
        </div>
      </div>

      <div class="form-group">
        <div class="col-sm-offset-3 col-sm-3">
          <button type="submit" @click.prevent="validateAndSend" class="btn btn-primary submit-send-digital-intake" :disabled="sending">
            {{ $t('messages.send') }}
          </button>
        </div>
      </div>
    </div>
    <pin-modal></pin-modal>
  </div>
</template>

<script>
import datePickerWrapper from '@/components/datePickerWrapper'
import Multiselect from 'vue-multiselect'
import addTags from '@/components/addTags'
import { mapState } from 'vuex'
import Vue from 'vue'
import cloneDeep from 'lodash.clonedeep'
import PinModal from '../dashboard/conversion/pinModal.vue';
import * as Validator from 'validatorjs'
import validationRules from '../../components/validationRules.json'
const form = {
  epd: '',
  location: 'home',
  useremail: '',
  phone_number_full: '',
  no_phone: false,
  content: '',
  tags: [],
  dob: '',
  date: '',
  no_email: false,
  appointment: ''
}
export default {
  components: {datePickerWrapper, Multiselect, addTags, PinModal},
  data () {
    return {
      correctedIntakeDate: new Date(),
      pickedATime: false,
      fields: [
        {
          field: 'department',
          type: 'select',
          valType: '',
        },
        {
          field: 'content',
          type: 'select',
          valType: 'required',
        },
        {
          field: 'epd',
          type: 'text',
          valType: 'epd',
        },
        {
          field: 'first_name',
          valType: 'name',
          type: 'text'
        },
        {
          field: 'last_name',
          valType: 'name',
          type: 'text'
        },
        {
          field: 'location',
          type: 'radio',
          options: ['home', 'hospital'],
          valType: '',
        },
        {
          field: 'useremail',
          type: 'email',
          valType: 'email_required',
          valExtra: '',
          validationExtraMsgs: {'other': this.$t('messages.no_email'), 'value': this.$t('invitation.unchecked')}
        },
        {
          field: 'phone_number_full',
          type: 'text',
          valType: 'phone',
          valExtra: '',
          validationExtraMsgs: ''
        },
        {
          field: 'tags',
          type: 'tags',
          valType: '',
        },
        {
          field: 'appointment',
          type: 'date',
          valType: "date_of_birth"
        },
        {
          field: 'dob',
          type: 'date',
          valType: ""
        },
      ],
      form: cloneDeep(form),
      errors: {},
      sending: false,
      sent: false,
      ic_hash: "",
      errorVars: {
        'phone_number_full':{
          // attribute: 'number',
          // attribute: $t('phone_number_full'),
          // attribute: this.translateQuickFix('invitation.phone_number_full'),
          max:15, //this should be sent from the backend 
          min:7 //this should be sent from the backend
        },
        tags: [],
      },
      dob: {day: null, month: null, year: null},
      daysOfMonths: [31,28,31,30,31,30,31,31,30,31,30,31],
      dobCorrectedToPast: {month: false, day: false},
      valErrors: {},
      valRules: validationRules.rules,
      valMsgs: validationRules.msgKeys,
      currentDate: {day: new Date().getDate(), month: new Date().getMonth()+1, year: new Date().getFullYear()},
    }
  },
  computed: {
    contentIsGrouped() {
      if (!this.config) {
        return;
      }
      for (let row of this.config.content) {
        if (row[2] && row[2]!=='Ungrouped') {
          return true;
        }
      }
      return false;
    },
    groupedContent() {
      let tmpGrouped = {};
      if (!this.config) {
        return;
      }
      for (let row of this.config.content) {
        if (row[1]=='') { //Skip the blank one
          continue;
        }
        if (!row[2]) {
          row[2] = 'Ungrouped'
        }
        if (!tmpGrouped[row[2]]) {
          tmpGrouped[row[2]] = {group:row[2], items:[]}
        }
        tmpGrouped[row[2]].items.push({
          label:row[0],
          value:row[1]
        })
      }
      if (Object.keys(tmpGrouped).length==1) {
        return tmpGrouped[Object.keys(tmpGrouped)[0]].items;
      } else {
        return Object.keys(tmpGrouped).map(i => tmpGrouped[i])
      }
    },
    config() {
      return this.$store.getters['config/getCurrentConfig']()
    },
    ...mapState('userOptions', {
      selectedOrganizationSymbol: state => state.options.selectedOrganizationSymbol,
    }),
    selectedDepartment: {
      get () {
        let selected = this.$store.state.userOptions.options.selectedDepartmentSymbol
        if (this.availableDepartments && this.availableDepartments.length) {
          return this.availableDepartments.find(element => element && element.symbol === selected)
        }
        return []
      },
      set (newValue) {
        this.$store.dispatch('userOptions/setOption', { option: 'selectedDepartmentSymbol', value: newValue.symbol })
      }
    },
    availableDepartments () {
      let self = this
      if (self.selectedOrganizationSymbol) {
        return this.$store.state.auth.user.departments.filter(function (value) {
          return value.organizations[0].symbol === self.selectedOrganizationSymbol
        }).sort((a,b) => a.name.localeCompare(b.name));
      }
    },
    ...mapState('auth', ['user']),
    daysInMonth(){
      if (this.dob.year == this.currentDate.year && this.dob.month == this.currentDate.month){
        return this.currentDate.day // Present month: don't present days in the future
      } else if (!!this.dob.month){
        return new Date(!!this.dob.year?this.dob.year:0, this.dob.month, 0).getDate();
      } else {
        return 31 // If no month selected yet
      }
    },
    monthsInYear(){
      return this.dob.year == this.currentDate.year ? this.currentDate.month : 12;
    },
    dobOptions(){
      return {
        day: [...Array(this.daysInMonth).keys()].map(v => v+1), 
        month: [...Array(this.monthsInYear).keys()].map(v => v+1), 
        year:[...Array(121).keys()].map(v => v + this.currentDate.year-120).reverse()
      }
    },
    dobFormatted(){
      return new Date(Date.UTC(this.dob.year, this.dob.month-1, this.dob.day)).toISOString().slice(0, 10);
    },
    validationKeys(){
      // ex: name:"string_required", organization:"required"
      return this.fields.reduce((acc, v) => ({...acc, [v['field']]: v['valType']}), {});
    },
    fieldRules(){
      //name:"required|string", symbol:"required|string"
      let result = {};
      this.fields.forEach(fieldDetails => {
        let valKey = this.validationKeys[fieldDetails.field];
        if (valKey != "") {
          result[fieldDetails.field] = this.valRules[valKey];
        }
        if (fieldDetails.field in this.validationExtras) {
          for (const [rule, secondField] of Object.entries(this.validationExtras[fieldDetails.field])) {
            result[fieldDetails.field] += '|' + rule + ':' + secondField;
          }
        }
      })
      return result;
    }, 
    validationExtras(){
      let extras = {};
      this.fields.forEach(fieldDetails => {if ('valExtra' in fieldDetails) extras[fieldDetails.field]=fieldDetails.valExtra});
      return extras;
    },
    validationExtraMsgs(){
      let extras = {};
      this.fields.forEach(fieldDetails => {if ('validationExtraMsgs' in fieldDetails) extras[fieldDetails.field]= fieldDetails.validationExtraMsgs});
      Object.keys(this.errors).forEach(field => {
        if (!field in Object.keys(extras)){
          extras[field] = {}
        };
        this.errors[field].forEach(error => {
          if (error.charAt(0) == '[') {
            let untranslatedExtras = JSON.parse(error)[1];
            extras[field] = Object.entries(untranslatedExtras).reduce((acc, [valiKey, transKey]) => {
              acc[valiKey] = this.$t(transKey);
              return acc
            }, {});

          }
      })})
      return extras;
    },
    errorsParsed(){
      let result = {};
      Object.keys(this.errors).forEach(field => {
        result[field] = []
        this.errors[field].forEach(e => {
          result[field].push(e.charAt(0) == '[' ? JSON.parse(e)[0] : e);
        })
      })
      return result;
    },
    location(){
      return this.form.location;
    },
    weblink() {
      return window.location.pathname.split('?')[0] === '/weblink'
    },
  },
  methods: {
    // Normally tags are added in addTags.vue but that needs an ic to work, 
    // here there is no ic yet so we have to store tags here to add them while sending the form
    tagsUpdate (ic_id, newTags) {
      this.form.tags = JSON.stringify(newTags);

      console.log("Is now going to reload tags in vuex");

      // Reload tags in vuex
      this.$store.dispatch('tags/loadTags', {orgSymbol: this.selectedOrganizationSymbol});
    },
    showField (field) {
      if (field === 'location' && !this.config.extended_invite) {
        return false
      } else if (field === 'no_email' && this.form.location !== 'hospital') {
        return false
      } else if (field === 'appointment' && !this.config.invite_appointment) {
        return false
      } else if (field === 'phone_number_full' && !this.config.sms_check) {
        return false
      } else if (field === 'department' && this.availableDepartments.length<=1){
        return false
      } else if (field === 'dob' && !this.config.dob_check){
        return false
      } else if (field == 'first_name' && !this.config.ask_first_name){
        return false
      } else if (field == 'last_name' && !this.config.ask_last_name){
        return false
      }
      return true;
    },
    disabledField (field) {
      if (field === 'useremail' && (
        (this.form.no_email && this.form.location === 'hospital') || (this.weblink)
      )) {
        return true
      } else if ( field === 'epd' && this.weblink ) {
        return true;
      } else if (field === 'phone_number_full' && this.form.no_phone) {
        return true
      }
      if (field === 'location' && this.user.authByPin) {
        this.form.location = 'hospital';
        return true
      }
    },
    preprocessValidation(){
      // validation processing, prepares sentences for UI feedback (including error messages)
      const phone_number_full_i = this.fields.findIndex(v => v.field == 'phone_number_full');

      // phone field should only be required if no_phone if false AND sms check is true
      if (this.config.sms_check){
        this.fields[phone_number_full_i]['valExtra'] = {'required_if':['no_phone','false']};
        this.fields[phone_number_full_i]['validationExtraMsgs']  = {
          'other': this.$t('invitation.no_phone'), 
          'value': this.$t('invitation.unchecked'),
          'min': 7,
          'max': 15,
        } 
      } else {
        this.fields[phone_number_full_i]['valExtra'] = {};
        this.fields[phone_number_full_i]['validationExtraMsgs']  = {};
      }
      // first name only mandatory if ask_first_name is true in ClientConfig, idem for last name
      ['first_name', 'last_name'].forEach(v => {
        if (this.showField(v)){
          let fieldi = this.fields.findIndex( x => x.field === v );
          this.fields[fieldi].valType = 'name_required';
        }
      })
      // tags are normally added by addTags.vue, but that needs an ic, so it wasn't able to do it and we have to add the tags now
      // if (this.form.tags) {
      //   let send = {tags: this.form.tags};
      //   send.ic_id = (this.ic ? this.ic.id : 0);
      //   send.org = this.ic.orgSymbol;
      //   send.departmentSymbol = this.ic.department;
      //   this.updateTogglePreventCloseCard();
      //   console.log("Update Backend add tags");
      //   this.axios.put('/api/ic/add_tags', send).then((result) => {
      //       this.$eventHub.$emit('tags-updated', this.ic_id, this.tags)
      //   });
      // }
    },
    validateAndSend(){
      this.preprocessValidation();
      if (this.validationKeys && typeof this.fieldRules != 'undefined'){
        this.errors = {}
        let validation = new Validator(this.form, this.fieldRules, this.valMsgs);
        this.fixRequiredIfVal();
        if (validation.passes()){
          this.send();
        } else {
          this.err_buf = {};
          for (const [field, error] of Object.entries(validation.errors.all())) {
            this.err_buf[field] = error.map(v => 'validation.'+v);
          }
          this.errors = this.err_buf;
        }
      } else {
        this.send();
      }
    },
    fixRequiredIfVal(){ 
      // https://github.com/mikeerickson/validatorjs/issues/323#issuecomment-569869415
      const required_if_rule = function (val, req, attribute) {
        req = this.getParameters();
        let op = this.validator._objectPath(this.validator.input, req[0]);        
        if ((op != undefined && op != null ? op.toString() : null) === req[1]) {
          return this.validator.getRule('required').validate(val);
        }
        return true;
      }
      Validator.registerImplicit('required_if', required_if_rule, 'required_if');
    },
    send () {
      this.sending = true;
      const formToSend = { ...this.form, content: this.form.content.value } // Send only the value of content

      //hotfix for 1638
      if(formToSend.date) formToSend.date = formToSend.date.replace('.0', '');

      this.$store.dispatch('conversion/sendIntake', formToSend).then(result => {
        this.ic_hash = result.data;
        this.sent = this.form.epd // Show confirmation
        if (this.user.authByPin) {
          let pin_data = {
            department : this.selectedDepartment.symbol,
            epd : this.sent,
            hash : this.ic_hash,
          }
          this.$eventHub.$emit('show-pin-modal', pin_data)
        }
        this.clearForm()
        this.clearUrlFromParams()
        this.fillForWeblink();
      }).catch(({response}) => {
        if (response.data.errors) {
          this.errors = response.data.errors
        }
      }).finally(() => {
        this.sending = false;
      });
    },
    clearForm () {
      // Clear form and errors on success
      this.errors = {}
      this.form = cloneDeep(form)
      this.dob = {day: null, month: null, year: null};
      if (this.user.authByPin) {this.form.no_email = true}
      this.$eventHub.$emit('set-default-tags');
      // This is ugly, make it properly without using refs
      this.$refs.date[0].periodRange = []
    },
    clearUrlFromParams() {
      //Check that we are on current page but full path have some params
      if (this.$route.path === '/invite' && this.$route.fullPath!=='/invite') {
        this.$router.push('/invite')
      }
    },
    checkMonthAndUpdateForm(){
      //prevent dates in the future (days)
      if (this.dob.day > this.daysInMonth){
        this.dob.day = this.daysInMonth;
      }
      //prevent dates in the future (months)
      if (this.dob.year == this.currentDate.year) {
          this.dob.month = this.dob.month > this.currentDate.month ? this.currentDate.month : this.dob.month;
          this.dobCorrectedToPast['month'] = true;
          if (this.dob.month == this.currentDate.month){
            this.dob.day = this.dob.day > this.currentDate.day ? this.currentDate.day : this.dob.day;
            this.dobCorrectedToPast['day'] = true;
          }
          else {
            this.dobCorrectedToPast['day'] = false;
          }
        }
        else {
          this.dobCorrectedToPast['month'] = false;
        }
      this.form['dob'] = this.dobFormatted;
    },
    newDate(dateValue) {
        // Triggered when datepicker date changes, changes default picker automatic time to a more relevant automatic time
        var now = new Date();
        var userChosenDay = new Date(dateValue);

        // If the user hasn't picked a time yet, force default time
        if(!this.pickedATime)
        {
            // If the appointment is made for today, preselect the first hour possible for today
            if (now.getDay() == userChosenDay.getDay() && now.getMonth() == userChosenDay.getMonth() && now.getFullYear() == userChosenDay.getFullYear()) {
                userChosenDay.setHours(now.getHours() + 1);
                userChosenDay.setMinutes(0);
                userChosenDay.setSeconds(0); 
                this.correctedIntakeDate =  userChosenDay;
            }
            else {
                // Otherwise, preselect first hour possible in general (hardcoded based on properties of datepicker)
                userChosenDay.setHours(8);
                userChosenDay.setMinutes(0);
                userChosenDay.setSeconds(0);
                this.correctedIntakeDate =  userChosenDay;
            }
            this.pickedATime = true;
        }
    },
    removeDate() {
        // Triggered when receiving an emit that the user unpicked their date
        this.pickedATime = false;
    },
    fillForWeblink(){
      if (this.weblink){
        this.form.epd = 'epd' in this.$route.query ? this.$route.query.epd : '';
        this.form.useremail = 'email' in this.$route.query ? this.$route.query.email : '';
      }
    }
  },
  watch: {
    //If org is changed, department woule also change so it's safe to just check for department
    selectedDepartment: {
      immediate: true,
      handler: function (newDepartment, oldDepartment) {
        // We want to run this on actual change, not initial set
        if (oldDepartment && newDepartment!==oldDepartment) {
          let epd_backup = this.form.epd;
          let useremail_backup = this.form.useremail;
          this.clearForm()
          //Get epd and email from route again
          if (Object.keys(this.$route.query).length) {
            Vue.set(this.form, 'epd', this.$route.query.epd)
            let newEmail = 'useremail' in this.$route.query ? this.$route.query.useremail : "";
            newEmail = 'email' in this.$route.query ? this.$route.query.email : "";
            Vue.set(this.form, 'useremail', newEmail)
          } else {
            Vue.set(this.form, 'epd', epd_backup);
            Vue.set(this.form, 'useremail', useremail_backup);
          }
        }
        if (!newDepartment || !newDepartment.symbol) return;
        // Let's load the new config
        this.$store.dispatch('config/loadConfig', {
          orgSymbol: this.selectedOrganizationSymbol,
          departmentSymbol: newDepartment.symbol
        })
      }
    },
    location(newLocation) {
      const useremail_i = this.fields.findIndex(v => v.field == 'useremail');
      this.fields[useremail_i]['valExtra'] = newLocation == "hospital" ? {'required_if':['no_email','false']} : '';
      this.fields[useremail_i]['valType']  = newLocation == "hospital" ? 'email' : 'email_required';
    },
    "$store.state.userOptions.options.locale": {
      // Language has been changed, so we need to reprocess error messages so that they are rebuilt with words from the right language
      handler(newValue, oldValue) {
        this.preprocessValidation()
      }
    }
  },
  mounted () {
      if (this.user.authByPin) {this.form.no_email = true};
      this.fillForWeblink();
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      Vue.set(vm.form, 'epd', to.query.epd)
      Vue.set(vm.form, 'useremail', to.query.useremail)
    })
  },
  translateQuickFix(str){
    return $t('invitation.'+field)
  },
  beforeRouteLeave (to, from, next) {
    this.clearForm()
    this.sent = false // Hide message
    next()
  }
}
</script>

<style>
  .glowy .multiselect__tags {
  animation: highlight 1000ms ease-out;
  }

  @keyframes highlight {
  0% {
    box-shadow: 0 0 10px orange;
  }
}

.multiselect__placeholder {
  padding: 0px;
  margin-bottom: 0px;
}
</style>