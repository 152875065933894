<template>

    <filtered-table
            id="appointments-table"
            dusk="appointments-table"
            :columns="columns"
            :rows="tableData"
            :rowClick="getDetails"
            :rowClass="getRowClass"
            :loading="loading"
            defaultSortColumn="intake_date"
            ref="table"
            tableName="appointments"
            copiableField="epd"
    >
        <template slot="tags_column" slot-scope="slotProps">
            <tags-cell :tags="slotProps.row.tags" :orgSymbol="slotProps.row.orgSymbol"></tags-cell>
        </template>
        <template slot="tags_filter" slot-scope="slotProps">
            <tag-select :options="slotProps.options" v-model="slotProps.filters['tags']"></tag-select>
        </template>
    </filtered-table>
</template>

<script>
    import tableFunctions from '@/mixins/tableFunctions';
    import FilteredTable from '@/components/filteredTable.vue';

    import TagsCell from '../components/tagsCell';
    import TagSelect from '@/components/tagSelect';
    import dateFormatMethods from '@/mixins/dateFormatMethods';

    export default {
        props: ['tableData', 'tableOptions', 'groupBy', 'loading'],
        components: {'filtered-table': FilteredTable, TagsCell, TagSelect},
        mixins: [tableFunctions, dateFormatMethods],
        data() {
            return {
                progressFilter: {'column':'', 'pie': ''},
            };
        },
        computed: {
            selectedOrganizationSymbol() {
              return this.$store.state.userOptions.options.selectedOrganizationSymbol
            },
            // TODO: move this to the store like we did for the conversion/weblink views with "tableColumns.js"
            columns() {
                let self = this;
                var appointmentDateClassFunction = function (row, filters) {
                    if (moment(row.future_followup_date).format('x') < moment().format('x')) {
                        return 'text-light';
                    }
                }
                var appointmentValueFunction = function (row, filters) {
                    let date;
                    if (filters.future_followup_type) {
                        return row.futureAppointments[filters.future_followup_type];
                    } else if (typeof row.futureAppointments !== 'undefined') {
                        return row.futureAppointments.default;
                    }
                }
                let cols = {
                    epd: {
                        label: this.$t('dashboard.table.epd'),
                    },
                    relatedIcs: {
                        label: this.$t('dashboard.table.related_intake'),
                        value: (row) => {
                            return !row.relatedIcs && !row.relatedIcs.length ? 'No' : 'Yes';
                        },
                        options: ['Yes', 'No'],
                        filter: (row, filters) => {
                            console.log('row', row);
                            console.log('filters', filters);
                            if(filters && filters.relatedIcs) {
                                const hasRelatedIcs = row.relatedIcs && row.relatedIcs.length;
                                return (hasRelatedIcs && filters.relatedIcs === 'Yes') || (!hasRelatedIcs && filters.relatedIcs === 'No')
                            }
                            return true;
                        }
                    },
                    fullname: {
                      label: this.$t('dashboard.table.fullname'),
                    },
                    content: {
                        label: this.$t('dashboard.table.content'),
                        multipleFilter:true,
                        options: this.tableOptions && this.tableOptions['content'] ? this.tableOptions['content'] : ''
                    },
                    progress: {
                        label: this.$t('dashboard.table.status'),
                        multipleFilter: true,
                        options: [
                          this.$t('dashboard.status.-1'),
                          this.$t('dashboard.status.0'),
                          this.$t('dashboard.status.1')
                        ],
                        // Maybe we need to built in key=>value object handling to filteredTable
                        // so we wouldn't need this manual value/filter but for now this works
                        value: (row) => {
                          return self.$t('dashboard.status.' + row.progress)
                        },
                        filter: (row, filters) => {
                          if(filters && filters.progress) {
                            // Translate the row and check that it's in filters array (it's already translated)
                            return filters.progress.includes(self.$t('dashboard.status.' + row.progress))
                          }
                          return true;
                        }
                    },
                    intake_date: {
                        label: this.$t('dashboard.table.date'),
                        options: this.tableOptions && this.tableOptions['intake_date'] ? this.tableOptions['intake_date'] : '',
                        datepickerOption: true,
                        addOptions: ['last7days', 'last30days'],
                        value: function (row) {
                          return self.formatDate(row.intake_date, 'YYYY-MM-DD HH:mm:ss', 'DD-MM-YYYY')
                        },
                        title: function (row) {
                          if (row.intake_date) {
                            return self.formatDate(row.intake_date, 'YYYY-MM-DD HH:mm:ss', 'DD-MM-YYYY HH:mm:ss')
                          }
                        }
                    },
                    lastStatusCompleted: {
                        label: this.$t('dashboard.table.intake_completed_date'),
                        datepickerOption: true,
                        addOptions: ['last7days', 'last30days'],
                        value: function (row) {
                            if (row.firstCompletedFormCreated) {
                                return self.formatDate(row.lastStatusCompleted, 'YYYY-MM-DD HH:mm:ss', 'DD-MM-YYYY')
                            }
                        },
                        title: function (row) {
                          if (row.firstCompletedFormCreated) {
                            return self.formatDate(row.lastStatusCompleted, 'YYYY-MM-DD HH:mm:ss', 'DD-MM-YYYY HH:mm:ss')
                          }
                        }
                    },
                    group: {
                        label: this.$t('dashboard.table.risk'),
                        options: [
                            this.$t('dashboard.danger.0'),
                            this.$t('dashboard.danger.1'),
                            this.$t('dashboard.danger.2'),
                            this.$t('dashboard.danger.3'),
                            this.$t('dashboard.danger.4')
                        ],
                        multipleFilter:true,
                        filter: (row, filters) => {
                          if (filters && filters.group) {
                            // Translate the row and check that it's in filters array (it's already translated)
                            return filters.group.includes(self.$t('dashboard.danger.' + row.group))
                          }
                          return true;
                        }
                    },
                    appointment_date: {
                        label: this.$t('dashboard.table.appointment'),
                        options: this.tableOptions && this.tableOptions['appointment_date'] ? this.tableOptions['appointment_date'] : '',
                        addBoolOptions: true,
                        datepickerOption: true,
                        value: function (row) {
                          return self.formatDate(row.appointment_date, 'YYYY-MM-DD HH:mm:ss', 'DD-MM-YYYY')
                        },
                        title: function (row) {
                          if (row.appointment_date) {
                            return self.formatDate(row.appointment_date, 'YYYY-MM-DD HH:mm:ss', 'DD-MM-YYYY HH:mm:ss')
                          }
                        }
                    },
                    future_followup_type: {
                        label: this.$t('dashboard.table.future_followup_type'),
                        value: function (row, filters) {
                            if (filters.future_followup_type && row.futureAppointments[filters.future_followup_type]) {
                                return filters.future_followup_type.join();
                            } else if (typeof row.futureAppointments !== 'undefined') {
                                return row.futureAppointments.default_type;
                            }
                        },
                        multipleFilter: true,
                        options: this.tableOptions && this.tableOptions['future_followup_types'] ? this.tableOptions['future_followup_types'] : '',
                        filter: function (row, filters) {
                            return filters['future_followup_type'].every((filter) => row['futureAppointments'].hasOwnProperty(filter));
                        },
                        class: appointmentDateClassFunction
                    },
                    location: {
                        label: this.$t('dashboard.table.location'),
                        multipleFilter: true,
                        options: ['home', 'hospital']
                    },
                    future_followup_date: {
                        label: this.$t('dashboard.table.future_followup_date'),
                        value: appointmentValueFunction,
                        filter: function (row, filters) {
                            //After deleting there are often empty array with two elements, we don't need to filter then
                            if (!filters['future_followup_date'][0] || !filters['future_followup_date'][1])
                            {
                                return true;
                            }
                            let val = appointmentValueFunction(row, filters);
                            if (!val) {
                                return false;
                            }
                            return moment(val, 'DD-MM-YYYY').isBetween(filters['future_followup_date'][0], filters['future_followup_date'][1], null, '[]');
                        },
                        class: appointmentDateClassFunction,
                        datepickerOption: true,
                        addPastOptions: true,
                        addFutureOptions: true
                    },
                    location: {
                      label: this.$t('dashboard.table.location'),
                      options:['home','hospital']
                    },
                };
                if (this.tableOptions && this.tableOptions['tags'] && this.tableOptions['tags'].length) {
                    const tagsMappedByName = this.$store.getters['tags/getTagsMappedByName']();
                    cols.tags = {
                        label: this.$t('dashboard.table.tags'),
                        options: this.tableOptions && this.tableOptions['tags'] ? this.tableOptions['tags'] : '',
                        filter: (row, filters) => {
                            if(!filters.tags || !filters.tags.length) return true;
                            if(!row.tags || !row.tags.length) return false;
                            return filters.tags.every((tagId) => {
                                let tag = this.$store.getters['tags/getTagById']({id: tagId});
                                return row.tags.some(id => {
                                    return tagsMappedByName[tag.name].some(id2 => id2 === `${id}_${row.orgSymbol}`);
                                })
                            });
                        }
                    }
                }

                //h4x0r for now:
                // Symbolmbol?
                if(this.selectedOrganizationSymbolmbol && this.selectedOrganizationSymbolmbol.indexOf('gza') !== 0) {
                    delete cols.relatedIcs;
                }
                //this should fix the h4x0r above, and hide the column when no related ICs exists, but will leave the h4x0r for the moment.
                if (this.tableOptions && !this.tableOptions["hasRelatedIcs"]) {
                     delete cols.relatedIcs;
                }

                if (this.tableOptions && this.tableOptions['department'] && this.tableOptions['department'].length > 1) {
                    cols.department = {
                        label: this.$t('dashboard.table.department'),
                        options: this.tableOptions['department'],
                    }
                }

                if (this.tableOptions && !this.tableOptions['fullname']) {
                    delete cols.fullname;
                }

                if ((this.selectedOrganizationSymbol && this.selectedOrganizationSymbol.indexOf("polidirect") == 0)) { 
                    cols.origin = {
                        label: this.$t('dashboard.table.origin'),
                        options: this.tableOptions && this.tableOptions['origin'] ? this.tableOptions['origin'] : '',
                    }
                }
                return cols;
            },
        },
        methods: {
            getRowClass(row) {
                return [row.hidden ? 'hidden-record' : '', 'epd_'+row.epd];
            },
            group(val) {
                return this.$t('dashboard.danger.' + val);
            },
            status(val) {
                return this.$t('dashboard.status.' + val);
            },
            select(chart, group, date = '') {
                this.progressFilter[chart.split('-')[1]] = group;
                //A simple check that chart name has right prefix
                if (chart.substring(0, 5) == 'appoi') {
                  let self=this;
                  setTimeout(function() { //This is ugly workaround but it works when clicking between charts
                    self.dateFilter(date);
                    self.groupFilter(self.columns.group.options[group])
                  },10);
                }
            },
            unselect(chart) {
                this.progressFilter[chart.split('-')[1]] = '';
                //A simple check that chart name has right prefix
                if (chart.substring(0, 5) == 'appoi') {
                    if (chart.split('-')[1] == "column"){
                        this.dateFilter('')
                        this.groupFilter(this.columns.group.options[this.progressFilter['pie']]);
                    } else {
                        this.groupFilter('')
                    }
                }
            },
            // Updating filter data
            groupFilter(value) {
                this.$refs.table.updateFilters('group', value);
            },
            dateFilter(date) {
                this.$refs.table.updateFilters('appointment_date', date ? this.unixToDateRange(date, this.groupBy) : '');
            },
            getDetails(row, preventmodal) {
                if (!preventmodal){
                    this.$store.dispatch('consentModal/showModal', row);
                }
            },
        },
        created() {
            this.$eventHub.$on('selected', this.select);
            this.$eventHub.$on('unselected', this.unselect);
        },
    };
</script>


<style>
    .text-light {
        color: #aaa;
    }
</style>
