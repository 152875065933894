<template>
    <div>
        <!-- alert to show when reports are saved -->
        <transition :duration="2000" name="fade">
            <div v-show="savedReports" class="alert alert-success" role="alert">
                {{translate('report.overview.reports_saved')}}
            </div>
        </transition>

        <transition :duration="2000" name="fade">
            <div v-show="errorSavingReports" class="alert alert-danger" role="alert">
                {{translate('report.overview.reports_saved_error')}}
            </div>
        </transition>

        <!-- Selection section -->
        <div style="margin-bottom: 25px; display: flex">
            <div style="width: 25%; display: flex; flex-direction: column">
                <label style="overflow: hidden">{{ $t('layout.label.department') }}</label>
                <div class="form-inline" style="min-width: 70%; margin-right: 15px;">
                      <multiselect v-model="selectedDepartment" :options="availableDepartments" label="name" track-by="symbol" placeholder=""></multiselect>
                </div>
            </div>
            <div style="width: 25%; display: flex; flex-direction: column">
                <label style="overflow: hidden">{{translate('report.overview.procedure')}}</label>

                <div class="form-inline" style="min-width: 70%; margin-right: 15px;">
                      <multiselect v-model="activeContents" :options="getAvailableContent()" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="" label="label" track-by="value" :preselect-first="false" @input="updateSelected">
                        <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
                    </multiselect>
                </div>
            </div>

            <!-- Filter section -->
            <div style="width: 25%" v-show="activeContents.length"
                v-for="(filterParameters, filterType) in userFilters" v-bind:key="filterType">
                <div style="display: flex">
                    <input v-model="selectedFilter" :value="filterType" :id="filterType" style="width: 20px" type="radio">
                    <label :for="filterType" style="overflow: hidden">{{translate('report.overview.'+filterType)}}</label>
                </div>

                <period-filter
                        v-if="selectedFilter==filterType"
                        :onPeriodModified="getOnPeriodModified(filterType)"
                        :period="filterParameters.period"
                        :start="filterParameters.start"
                        :end="filterParameters.end"
                        :translate="translate"
                        :type="filterType"
                />
            </div>

        </div>

        <!-- Loading section -->
        <div v-show="loading"
                style="position:relative;top:0px;left:0px;width:100%;height:400px;text-align:center;background:#FFF;opacity:0.5;padding-top:13%;z-index:20000;color:black;">
            <font-awesome-icon v-show="!loadingError" icon="spinner" size="3x" pulse></font-awesome-icon>
            <p v-show="!loadingError">{{translate('report.overview.loading')}}</p>
            <div v-if="loadingError" style="opacity: 1; color: black;">{{translate('report.overview.error') + loadingError.message}}</div>
        </div>
        <div v-if="!loading && !activeContents.length">{{translate('report.overview.selectProcedure')}}</div>

        <!--  Do we need this? -->
        <div v-if="!loading && activeContents.length && !hasActivePeriodFilter()">
            {{translate('report.overview.selectAPeriodFilter')}}
        </div>

        <!-- Reports section -->
        <report v-if="!loading && activeContents.length && hasActivePeriodFilter()"
                :userHasEditPermissions=userHasEditPermissions
                :userHasExportPermissions=userHasExportPermissions
                :view=getView()
                :persistView=persistView
                :translate=translate
                :report="report"
                :namespaceInstance="namespaceInstance"
        />
    </div>
</template>

<script type='text/javascript'>
    import {VueSelect as vSelect} from "vue-select";
    import HasPermission from "@/mixins/hasPermission";
    import PeriodFilter from './views/periodFilter.vue';
    import Report from './report.vue';
    import Vue from 'vue'
    import { mapState } from 'vuex'
    import pako from 'pako';
    import axios from 'axios';

    import customReports from '@/store/modules/customReports'
    import dateRanges from '@/dateRanges'

    let namespaceInstance;

    import Multiselect from 'vue-multiselect'

    require('vue-multiselect/dist/vue-multiselect.min.css')

    // Gzips to support big big big big mega jsons
    const customReportsApi = axios.create({
        transformRequest: axios.defaults.transformRequest.concat(
            function (data, headers) {
                headers['Content-Encoding'] = 'gzip';
                return pako.gzip(data);
            }
        )
    });

    export default {
        props: ['report', 'reportId', 'view', 'availableContent'],
        mixins: [HasPermission],
        computed: {
            loading: function(){
                return this.$store.getters[namespaceInstance + '/globalLoadingState'];
            },
            ...mapState('userOptions', {
                selectedOrganizationSymbol: state => state.options.selectedOrganizationSymbol,
                selectedDepartmentSymbol: state => state.options.selectedDepartmentSymbol
            }),
            selectedDepartment: {
                get() {
                    let selected = this.$store.state.userOptions.options.selectedDepartmentSymbol;

                    if (this.availableDepartments && this.availableDepartments.length) {
                        return this.availableDepartments.find(element => element && element.symbol === selected)
                    }
                    return []
                },
                set (newValue) {
                    this.namespaceInstance = this.report.name + newValue.symbol + 'customReports';
                    this.$store.dispatch('userOptions/setOption', { option: 'selectedDepartmentSymbol', value: newValue.symbol });
                }
            },
            availableDepartments () {
                let self = this;
                if (self.selectedOrganizationSymbol) {
                    return this.$store.state.auth.user.departments.filter(function(value) {
                        return value.organizations[0].symbol === self.selectedOrganizationSymbol
                    }).sort((a,b) => a.name.localeCompare(b.name));
                }
            },
        },
        data() {
            namespaceInstance = this.report.name + this.$store.state.userOptions.options.selectedDepartmentSymbol + 'customReports';
            
            if (!this.$store.hasModule(namespaceInstance)) {
                this.$store.registerModule(namespaceInstance, $.extend(true, {}, customReports));
            }

            const currentView = (!this.view || Array.isArray(this.view)) ? {} : this.view;
            this.availableContent.forEach(([_, value]) => {
                if (!value) return;
                if (!currentView[value]) currentView[value] = [];
            });

            return {
                currentView,
                dateRanges:dateRanges,
                selectedFilter: 'periodFormSubmitted',
                loadingError: null,
                savedReports: false,
                errorSavingReports: false,
                namespaceInstance: namespaceInstance,
                activeContents: [],
                userFilters: {
                    periodFormSubmitted: {
                        active: true,
                        period: 'last30days',
                        start: null,
                        end: null
                    },
                    periodAppointmentDate: {
                        active: false,
                        period: 'last30days',
                        start: null,
                        end: null
                    },
                    periodInformedConsent: {
                        active: false,
                        period: 'last30days',
                        start: null,
                        end: null
                    }
                },
                userHasEditPermissions: this.hasPermission('edit_report_charts'),
                userHasExportPermissions: this.hasPermission('export_dashboard_data')
            }
        },
        components: {
            Report,
            vSelect,
            PeriodFilter,
            Multiselect
        },
        methods: {
            updateSelected(selected){
                const values = selected.map((s) => s.value);
                // if we just added all, keep only all. Else, remove all but 'all'. Can't use pop() here cause that mutates original array
                if (values[values.length -1] == 'all'){
                    this.activeContents = [selected[selected.length-1]];
                } else {
                    this.activeContents = this.activeContents.filter((content) => content.value != "all");
                }
                return;
            },
            persistView(overviews) {
                // save views and show success or fail to the user
                const viewData = overviews.map(({id, label, selectedQuestionairesQuestions, selected, filters, department}) => {
                    return {
                        id,
                        label,
                        selectedQuestionairesQuestions,
                        selected,
                        filters,
                        department
                    }
                });

                // remove the reports deleted by the user (todo: this should happen when the delete button is clicked)
                let newIds = viewData.map(v => v.id);
                this.currentView[this.selectedContent] = this.currentView[this.selectedContent].filter(v => newIds.includes(v.id));
                viewData.forEach((vd) => { //view data is the actual stuff on screen 
                    if ( this.selectedContent in this.currentView) {
                        let viewUpdateIndex = this.currentView[this.selectedContent].findIndex((ele => ele.id == vd.id));
                        
                        // Not found, add report (?) to object
                        if (viewUpdateIndex < 0) this.currentView[this.selectedContent].push(vd);
    
                        // Update existing report
                        else this.currentView[this.selectedContent][viewUpdateIndex] = vd;
                    }

                })

                // Use our custom axios which gzips the data
                customReportsApi.post('/api/reports/saveView', {
                    viewJSON: JSON.stringify(this.currentView),
                    reportId: this.reportId,
                    org: this.selectedOrganizationSymbol
                }).then((response) => {
                    this.savedReports = true;
                    let self = this;
                    setTimeout(function(){ self.savedReports = false; }, 5000);
                }).catch((error) => {
                    this.errorSavingReports = true;
                    let self = this;
                    setTimeout(function(){ self.errorSavingReports = false; }, 5000);
                });
            },
            getView() {
                return this.currentView[JSON.stringify(this.activeContents)];
            },
            getAvailableContent() {
                let ret = (this.availableContent || []).map(([label, value]) => ({
                    label,
                    value
                })).filter(({value}) => value && value.length);
                ret.push({label: 'All', value: 'all'});
                return ret;
            },
            getPeriods() {
                return Object.keys(this.dateRanges).map(name => {
                    return {name, label: name}
                });
            },
            // Do we need this?
            hasActivePeriodFilter() {
                // const {periodFormSubmitted, periodInformedConsent, periodAppointmentDate} = this;
                let activePeriodFilter = false;
                activePeriodFilter = this.selectedFilter == 'periodFormSubmitted' && this.userFilters.periodFormSubmitted.start && this.userFilters.periodFormSubmitted.end;
                let activeInformedConsentFilter = false;
                activeInformedConsentFilter = this.selectedFilter == 'periodInformedConsent' && this.userFilters.periodInformedConsent.start && this.userFilters.periodInformedConsent.end;
                return activePeriodFilter || activeInformedConsentFilter || this.selectedFilter == "periodAppointmentDate" && this.userFilters.periodAppointmentDate.start && this.userFilters.periodAppointmentDate.end;
            },
            fetchData() {

                const content = this.activeContents.map((ac) => ac.value);
                if (!content.length) return;
                // const {periodFormSubmitted, periodInformedConsent, periodAppointmentDate} = this;
                
                const periodFormSubmitted = this.userFilters.periodFormSubmitted
                const periodInformedConsent = this.userFilters.periodInformedConsent
                const periodAppointmentDate = this.userFilters.periodAppointmentDate

                const sideFilters = localStorage.getItem('filters') ? JSON.parse(localStorage.getItem('filters')) : null;
                const hiddenEPDs = sideFilters && sideFilters.hiddenEPDs ? sideFilters.hiddenEPDs : false;
                const hideHiddenEPDS =  sideFilters && sideFilters.hideHiddenEPDS ? sideFilters.hideHiddenEPDS : false;
                let activePeriodFilter = false;

                if (!this.hasActivePeriodFilter()) return console.info('no active period filter');
                this.$store.dispatch(this.namespaceInstance + '/setGlobalLoading', true);

                this.axios.post('/api/reports/data',
                        {
                            reportId: this.reportId,
                            content,
                            periodInformedConsent,
                            periodFormSubmitted,
                            periodAppointmentDate,
                            hiddenEPDs,
                            hideHiddenEPDS,
                            org: this.selectedOrganizationSymbol,
                            departmentSymbol: this.selectedDepartmentSymbol
                        })
                    .then((result) => {
                        let submittedQuestionnaires;
                        let questionnaires;
                        const dataset = "timTestStaging";
                        // let fakeData = require('./../data/' + dataset + '.js').data;
                        // if (dataset === "voortim"){
                        //     fakeData.submittedQuestionnaires = fakeData.submittedQuestionnaires.filter((questionnaire) => {
                        //         return questionnaire.form_id === '6723';
                        //     })
                        // }
                        // result.data = fakeData;
                        if (result.data && result.data.submittedQuestionnaires) {
                            submittedQuestionnaires = result.data.submittedQuestionnaires;
                            questionnaires = result.data.questionnaires;
                            console.log('Unused questionnaires: ', result.data.unusedQuestionnaires);
                        } else {
                            return console.error(`No custom reports functionality available for: ${result.data.org}; ${result.data.dep}; ${result.data.path}`);
                        }

                        let usableContent = [];
                        for (const content in this.currentView){
                            usableContent[content] = this.currentView[content].filter(view => view.department == this.selectedDepartmentSymbol);
                        }

                        let initialData = {
                            data: result.data,
                            overviews: usableContent[this.selectedContent],
                        }

                        this.$store.dispatch(this.namespaceInstance + '/resetOverviews');

                        // store initial data
                        this.$store.dispatch(this.namespaceInstance + '/storeInitialData', initialData).then(() => {
                            this.$store.dispatch(this.namespaceInstance + '/setGlobalLoading', false);
                        });
                    })
                    .catch(err => {
                        this.loadingError = err;
                    });
            },
            getOnPeriodModified(filterName) {
                return ({period, start, end}) => {
                    this.userFilters[filterName].period = period;
                    this.userFilters[filterName].start = start;
                    this.userFilters[filterName].end = end;
                    this.fetchData();
                }
            },
            translate(key) {
                return this.$t(key);
            }
        },
        watch: {
            'activeContents'(newValue, oldValue){
                this.fetchData();
            },
            'selectedFilter'(newValue){
                for (let filter of Object.keys(this.userFilters)) {
                    newValue == filter ? this.userFilters[filter].active = true : this.userFilters[filter].active = false
                };
                this.fetchData();
            }
        },
        mounted () {
            for (var [filterType, filterSettings] of Object.entries(this.userFilters)) {
                filterSettings.start = this.dateRanges['last30days'][0];
                filterSettings.end = this.dateRanges['last30days'][1];
            }
        },
    };
</script>

<style >

</style>