export default {
    methods: {
        overlayColor(color) {
            if (color === 'transperent') {
                return '#333';
            } else if (color) {
                //if only first half of color is defined, repeat it
                if (color.length < 5) {
                    color += color.slice(1);
                }
                return (color.replace('#', '0x')) > (0xffffff / 2) ? '#333' : '#fff';
            }
        },
        buttonsStyle(background, overlay = '') {
            background = background ? background : '#999';
            return {
                background: background,
                color: overlay ? overlay : this.overlayColor(background)
            }
        }
    }
}