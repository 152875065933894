export default {
    methods: {
        /**
         * Change date to specific format
         * @param date
         * @param currDateFormat
         * @param dateFormat
         * @returns string
         */
        formatDate(date, currDateFormat = 'YYYY-MM-DD', dateFormat = 'DD-MM-YYYY') {
            let formattedDate;
            if (!date) return ''; //If date is empty return empty string
            formattedDate = moment.utc(date, currDateFormat).local().format(dateFormat);
            return formattedDate;
        },
        stdDate(date) {
            return this.formatDate(date);
        },
        stdDateTimeFormat(date) {
            return this.formatDate(date, 'YYYY-MM-DD HH:mm:ss', 'DD-MM-YYYY HH:mm:ss');
        }
    },
    filters: {
        stdDateTimeFormat: function (date) {
            if (!date) return ''; //If date is empty return empty string
            return moment.utc(date, 'YYYY-MM-DD, HH:mm:ss').local().format('DD-MM-YYYY HH:mm:ss');
        }
    },
};
