import axios from 'axios'
import {passesDateRange} from '../helpers/filters'
import {debounce} from "lodash";
import Vue from 'vue'
// initial state
const state = {
  hasAppointments: true,
  data: {
    bar: [],
    column: [],
    groupBy: "day",
    pie: [],
    table: [],
    tableOptions: {}
  },
  loading: false,
  filters: {},
  dates: {
    start: '',
    end: ''
  },
  wasOpened: false,
  subscribedChannels: []
}

// getters
const getters = {}

// actions
const actions = {
    subscribeToEcho(context, subscribedOrgSymbol) {
        //retrieve the related orgs:
        const selectedOrgSymbol = context.rootState.userOptions.options.selectedOrganizationSymbol;
        const orgRelations = this.getters['orgRelations/getRelations']({selectedOrgSymbol});
        [{selectedOrgSymbol}, ...orgRelations].forEach(({selectedOrgSymbol, departmentSymbols}) => {
            //TODO: listen per department
            const channel = `dashboard.${selectedOrgSymbol}`;

            if(context.state.subscribedChannels.indexOf(channel) > -1) {
              //if already subscribed, don't subscribe again yo!
              return;
            }
            context.commit('addSubscribedChannel', channel);

            Echo.private(channel).listen('.ic.updated', (e) => {
                if (subscribedOrgSymbol!==context.rootState.userOptions.options.selectedOrganizationSymbol) {
                  return
                }
                if (!e.editing && !e.editing_by) { //Don't trigger on lock events so it wouldn't update the table so often
                  context.commit('updateTableRow', {
                        hash: e.hash,
                        data: e
                    });
                }
            }).listen('.appointment.updated', (e) => {
                if (subscribedOrgSymbol!==context.rootState.userOptions.options.selectedOrganizationSymbol) {
                  return
                }
                context.commit('updateTableRow', {
                    hash: e.hash,
                    data: e
                });
            }).listen('.appointments.created', (e) => {
                if (subscribedOrgSymbol!==context.rootState.userOptions.options.selectedOrganizationSymbol) {
                  return
                }
                if (passesDateRange(context, e)) {
                    e.orgSymbol = selectedOrgSymbol;
                    context.commit('addTableRow', e);
                }
            });
        });
    },
    //For now we will just trigger this action from the conversionTab. We will need to move the logic here.
    async loadData(context) {
      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (context.state.wasOpened) {
        context.commit('setField', {
          field: 'loading',
          value: true
        });
        //check if we need to load these initially for each org

        // we need a polyfill for IE: https://www.npmjs.com/package/promise-polyfill
        //TODO: (partial) error handling
        const selectedOrgSymbol = context.rootState.userOptions.options.selectedOrganizationSymbol;        
        const orgRelations = this.getters['orgRelations/getRelations']({selectedOrgSymbol});
        const selectedOrgSymbols = [{selectedOrgSymbol}, ...orgRelations].map(({selectedOrgSymbol}) => selectedOrgSymbol);
        const promises = [{selectedOrgSymbol}, ...orgRelations].map(({selectedOrgSymbol, departmentSymbols}) => {
          //If no departmentsymbols availble retrieve from user
          if(!departmentSymbols) departmentSymbols = context.rootState.auth.user.departments.filter(function (value) {
            //this aint working properly (check with meandermc-overview)
            return value.organizations[0].symbol === selectedOrgSymbol
          }).sort((a,b) => a.name.localeCompare(b.name)).map(dep => dep.symbol);


          const params = {
            'action': 'getFormsData',
            'org': selectedOrgSymbol,
            'start': window.moment(context.state.dates[0], "DD-MM-YYYY").format("YYYY-MM-DD"),
            'end': window.moment(context.state.dates[1], "DD-MM-YYYY").format("YYYY-MM-DD"),
            'filters': context.rootState.sideFilters.filters,
            'timezone': tz,
          }

          const apiPosts = departmentSymbols.map((dep) => {
            params.departmentSymbol = dep;         
            return axios.post('/api/ajax', params).then((result) => {
              //TODO: If we know all departments of the selected orgs upfront, we don't require this additional round trips:
              return Promise.all([this.dispatch('tags/loadTags', {orgSymbol: selectedOrgSymbol}), ...(result.data.tableOptions.department || []).map(departmentSymbol => this.dispatch('config/loadConfig', {
                orgSymbol: selectedOrgSymbol,
                departmentSymbol
              }))]).then(() => result.data);
            });
          })
          return axios.all(apiPosts).then((data) =>{
            //See usage below --> add this in backend and remove this yo
            data.forEach(d => d.selectedOrgSymbol = selectedOrgSymbol);
            return data;
          });
        });

        Promise.all(promises).then(orgConversionDataArray => {          
          const mergedData = orgConversionDataArray.flat().reduce((partialResult, orgConversionData, i) => {
            if (!orgConversionData.table.length) return partialResult;
            
              const selectedOrgSymbol = orgConversionData.selectedOrgSymbol
              orgConversionData.tableOptions.tags = orgConversionData.tableOptions.tags || [];
              orgConversionData.tableOptions.tags = orgConversionData.tableOptions.tags.map(id => (`${id}_${selectedOrgSymbol}`)) //so we can actually retrieve the tags!
              orgConversionData.table.forEach(row => {
                  //TODO: prefix to prevent hash collisions
                  row.orgSymbol = selectedOrgSymbol; //TODO: add this in backend already
                  //TODO: fix this in backend already yo:
                  if(row.relatedIcs && row.relatedIcs.length) row.relatedIcs = row.relatedIcs.filter(({hash}) => {
                    return hash !== row.hash;
                  })


              });

          if (!partialResult) return orgConversionData;
          //merge additional data:
          partialResult.table = [...partialResult.table, ...orgConversionData.table];
          if(orgConversionData.tableOptions.tags) partialResult.tableOptions.tags = [...new Set([...partialResult.tableOptions.tags, ...orgConversionData.tableOptions.tags])];
          // adding dep and content in case of multiple departments. Content is a set, in case of duplicate content names. Dep can't be duplicate
          if(orgConversionData.tableOptions.department) partialResult.tableOptions.department = [...new Set([...(partialResult.tableOptions.department || []), ...(orgConversionData.tableOptions.department || [])])];
          if(orgConversionData.tableOptions.content) partialResult.tableOptions.content = [...new Set([...partialResult.tableOptions.content, ...orgConversionData.tableOptions.content])];
          if(orgConversionData.tableOptions.origin) partialResult.tableOptions.origin = [...new Set([...partialResult.tableOptions.origin, ...orgConversionData.tableOptions.origin])];
          const {tableOptions: tableOptionsPartialResult} = partialResult;
          const {tableOptions} = orgConversionData;

          tableOptionsPartialResult.future_followup_types = [...new Set([...tableOptionsPartialResult.future_followup_types, ...tableOptions.future_followup_types])];

            //merging pie
            orgConversionData.pie.data.forEach((pieData) => {
              const {id} = pieData;
              const partialResultpieData = partialResult.pie.data.find(({id: id2}) => id === id2);
              if (!partialResultpieData) partialResult.pie.data.push(pieData);
              else {
                const {y} = pieData;
                partialResultpieData.y += y;
              }
            });

            //merging column
            orgConversionData.column.forEach((column) => {
              const {id} = column;
              const partialColumn = partialResult.column.find(({id: id2}) => id === id2);
              if (!partialColumn) partialResult.column.push(column);
              else {
                const {data} = column.data;
                column.data.forEach(([timeValue, amount]) => {
                  const partialColumnData = partialColumn.data.find(([timeValue2]) => timeValue == timeValue2);
                  if (partialColumnData) partialColumnData[1] += amount;
                  else partialColumn.data.push([timeValue, amount]);
                });
              }
            })
            return partialResult;
          }, null);
          if (!mergedData===null){
            mergedData.tableOptions.selectedOrgSymbols = selectedOrgSymbols;
          }
          context.commit('setField', {
            field: 'data',
            value: mergedData || {"pie":{"data":[{"name":"1","color":"#8fe599","id":"1","y":0},{"name":"2","color":"#ecc46f","id":"2","y":0},{"name":"0","color":"#ef5a59","id":"0","y":0},{"name":"3","color":"#ef5a59","id":"3","y":0},{"name":"4","color":"#ef5a59","id":"4","y":0}]},"tableOptions":[],"column":[{"name":"1","color":"#8fe599","id":"1","data":[]},{"name":"0","color":"#ecc46f","id":"0","data":[]},{"name":"-1","color":"#ef5a59","id":"-1","data":[]}],"groupBy":"day","table":[],"selectedOrgSymbol":selectedOrgSymbol,"bar":[]}
          });
          context.commit('setField', {
            field: 'loading',
            value: false
          })

          //subcribe to echo here yo!
          selectedOrgSymbols.forEach(orgSymbol => context.dispatch('subscribeToEcho', orgSymbol))

        });
      }
    },
    //For now, part of the logic, including the localStorage are still in selectPeriod.vue.
    updateDates(store, dates) {
        store.commit('setField', {
            field: 'dates',
            value: dates
        });
      store.dispatch('debouncedLoadData');
    },

    setField(state, {field, value}) {
      state.commit('setField', {field: field, value: value});
    },

    debouncedLoadData: debounce(({dispatch}) => {
      dispatch("loadData");
    }, 500),
}

// mutations
const mutations = {
  setField(state, {field, value}) {
    Vue.set(state, field, value);
  },
  updateTableRow(state, {hash, data}) {
    for (const key in state.data.table) {
      const row = state.data.table[key];
      if (hash && row.hash == hash) {
        Vue.set(state.data.table, key, {...row, ...data});
      }
    }
  },
  addTableRow(state, newRow) {
    state.data.table.unshift(newRow);
  },
  addSubscribedChannel(state, channel) {
    state.subscribedChannels.push(channel);
  }
}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
