import Vue from 'vue'
import Vuex from 'vuex'
// @ts-ignore
import config from './modules/config'
// @ts-ignore
import consentModal from './modules/consentModal'
// @ts-ignore
import sideFilters from './modules/sideFilters'
// @ts-ignore
import conversion from './modules/conversion'
// @ts-ignore
import appointments from './modules/appointments'
// @ts-ignore
import userOptions from './modules/userOptions'
// @ts-ignore
import tags from './modules/tags'
// @ts-ignore
import orgRelations from './modules/orgRelations'
// @ts-ignore
import reports from './modules/reports'
// @ts-ignore
import consult from './modules/consult'
// @ts-ignore
import editingTable from './modules/editingTable'
// @ts-ignore
import thirdParty from './modules/thirdParty'
// @ts-ignore
import auth from './modules/auth'
// @ts-ignore
import tableColumns from './modules/tableColumns'



Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    orgRelations,
    config,
    consentModal,
    sideFilters,
    conversion,
    appointments,
    tags,
    userOptions,
    reports,
    consult,
    thirdParty,
    whitelistIp:editingTable,
    whitelistDomain:editingTable,
    //TODO: This makes no sense: (diff checker is not using the editingTable vue component...)
    //Resolve / Clarify in: MED-I985
    diff:editingTable,
    organization:editingTable,
    role:editingTable,
    user:editingTable,
    auth,
    tableColumns,
  },
  strict: debug,
  plugins: [
    //Hack to add hook that would execute on init
    store => store.dispatch('sideFilters/$init', store)
  ]
})
