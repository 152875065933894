<template>
    <div>
        <filtered-table
                id="conversion-table"
                dusk="conversion-table"
                :columns="columns"
                :rows="tableData"
                :rowClick="getDetails"
                :cursorPointer="true"
                :rowClass="getRowClass"
                :loading="loading"
                defaultSortColumn="date"
                ref="table"
                tableName="conversion"
                copiableField="epd"
        >
            <template slot="buttons" v-if="!intakesMode&&hasPermission('export_dashboard_data')">
                <div class="export-container pull-right">
                    <button @click="showPopover = !showPopover" class="btn btn-primary pull-right header-font">
                        {{ $t('dashboard.table.btn_export') }}
                        <font-awesome-icon v-show="exportSpinner" icon="spinner" spin></font-awesome-icon></button>
                    <div v-if="showPopover" class="exportPopover">
                        <div class="popover-body">
                            <button v-if="this.$store.state.auth.user.admin == 1" @click="exportCsv('zoho')"
                                class="btn btn-sm btn-success">Export to Zoho</button>
                            <button @click="exportCsv('csv')" class="btn btn-sm btn-success">Export to CSV</button>
                            <button @click="showPopover = false" class="btn btn-sm btn-danger">Cancel</button>
                        </div>
                    </div>

                </div>
                <div class="alert alert-danger pull-right" style="padding:6px; margin-right: 12px; margin-bottom:0px"
                    role="alert" v-if="exportLimitMessage">
                    {{ $t('dashboard.table.export_limit') }}
                    <a href="#" class="close" @click="exportLimitMessage=false" data-dismiss="alert" aria-label="close"
                        style="margin-left:4px;">×</a>
                </div>
            </template>
            <template slot="tags_column" slot-scope="slotProps">
                <tags-cell :showDelete="false" :orgSymbol="slotProps.row.orgSymbol"
                    :tags="slotProps.row.tags"></tags-cell>
            </template>
            <template slot="tags_filter" slot-scope="slotProps">
                <tag-select :options="slotProps.options" v-model="slotProps.filters['tags']"></tag-select>
            </template>
        </filtered-table>
    </div>
</template>

<style>
    .text-light {
        color: #aaa;
    }

    .export-container {
        position: relative;
        display: inline-block;
    }

    .exportPopover {
        position: absolute;
        top: -50px;
        left: 100%;
        transform: translateX(-100%);
        padding: 10px;
        background-color: white;
        border: 2px solid #ccc;
        z-index: 1;
        margin-top: -10px;
    }

    .exportPopover .popover-body {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .exportPopover .popover-body button {
        margin: 0 10px;
        padding: 5px 10px;
    }

</style>

<script type="text/javascript">
    import tableFunctions from '@/mixins/tableFunctions';
    import FilteredTable from '@/components/filteredTable.vue';
    import HasPermission from '@/mixins/hasPermission';
    import TagsCell from '../components/tagsCell';
    import TagSelect from '@/components/tagSelect';
    import DateFormatMethods from '@/mixins/dateFormatMethods'

    export default {
        props: ['tableData', 'tableOptions', 'groupBy', 'intakesMode', 'loading', 'selected'],
        components: {'filtered-table': FilteredTable, TagsCell, TagSelect},
        mixins: [tableFunctions, HasPermission, DateFormatMethods],
        data() {
            return {
                exportSpinner: false,
                exportLimitMessage: false,
                progressFilter: {'column':'', 'pie': ''},
                showPopover: false
            }
        },
        computed: {
              selectedOrganizationSymbol() {
                return this.$store.state.userOptions.options.selectedOrganizationSymbol
              },
              dates(){
                return this.$store.state.conversion.dates
              },
              allowExportData(){
                let parsedStart = moment(this.dates[0], 'DD-MM-YYYY');
                let parsedEnd = moment(this.dates[1], 'DD-MM-YYYY');
                let delta = parsedEnd.diff(parsedStart, 'days');
                //dont really want a limit to how many days.
                return delta <= 31;
              },
              columns() {
                return this.$store.getters['tableColumns/columns'](
                  this.tableOptions,
                  this.$t('dashboard'),
                  this.formatDate,
                  'conversionTable',
                  this.selectedOrganizationSymbol,
                );
              },
        },
        methods: {
            getDetails(row, preventmodal) {
                let storePrevent = this.$store.getters['consentModal/preventingModalOpen'];
                if (!preventmodal && !storePrevent){
                    this.$store.dispatch('consentModal/toggleModalPrevent', true);
                    this.$store.dispatch('consentModal/showModal', row);
                }
            },
            getRowClass(row) {
                return [row.hidden ? 'hidden-record' : '', 'epd_'+row.epd];
            },
            select(chart, group, date = '') {
                this.progressFilter[chart.split('-')[1]] = group;
                //A simple check that chart name has right prefix
                if (chart.substring(0, 5) == 'conve') {
                  let self = this;
                  setTimeout(function() { //This is ugly workaround but it works when clicking between charts
                    if(chart == 'conversion-bar') {
                        self.contentFilter(date);
                    } else if (date) {
                      self.dateFilter(date);
                    }
                    self.groupFilter(group);
                  },10);
                }
            },
            unselect(chart) {
                this.progressFilter[chart.split('-')[1]] = '';
                //A simple check that chart name has right prefix
                if (chart.substring(0, 5) == 'conve') {
                    if (chart.split('-')[1] == "column"){
                        this.dateFilter('')
                        this.groupFilter(this.progressFilter['pie']);
                    } else {
                        this.groupFilter('')
                    }
                    if(chart == 'conversion-bar') {
                        this.$refs.table.updateFilters('content', '');
                    }
                }
            },
            contentFilter(value) {
                this.$refs.table.updateFilters('content', value);
            },
            // Updating filter data
            groupFilter(value) {
                this.$refs.table.updateFilters('status', value ? this.$t('dashboard.status.'+value) : undefined)
            },
            dateFilter(date) {
                this.$refs.table.updateFilters('date', date?this.unixToDateRange(date,this.groupBy):'');
            },

            /**
             *  Exports csv file based on selected filters
             */
            exportCsv(target) {
                
                this.showPopover = false; // close the popover
                if (target == 'zoho' || target == 'csv' && this.allowExportData) {
                
                    this.exportSpinner = true;
                    let self = this;
                    let queryString = `?org=${this.selectedOrganizationSymbol}&`;
                    let filters = JSON.parse(JSON.stringify(self.$refs.table.filters));
                    filters.start_date = self.selected && self.selected[0] ? self.selected[0] : null;
                    filters.end_date = self.selected && self.selected[1] ? self.selected[1] : null;

                    let stati = {
                        [this.$t('dashboard.status.-1')]: "-1",
                        [this.$t('dashboard.status.0')]: "0",
                        [this.$t('dashboard.status.1')]: "1"
                    }

                    if (filters.status){
                        filters.status = stati[filters.status];
                    }

                    for (let param in filters) {
                        if (Array.isArray(filters[param]) && filters[param][0] instanceof Date) {
                            for (let date in filters[param]) {
                                filters[param][date] = moment(filters[param][date]).format('DD-MM-YYYY');
                            }
                        }
                        if (filters[param] && filters[param] != '-' && filters[param][0]) {
                                queryString += param + '=' + filters[param] + '&';
                        }
                    }
        
                    for (let param in self.hideFilters) {
                        queryString += param + '=' + self.hideFilters[param] + '&';
                    }

                    queryString += "target=" + target;
    
                    var link = '/api/ic/export' + queryString;
                    window.location = link;
                } else {
                    this.exportLimitMessage = true; 
                }
            },
        },
        created() {
            this.$eventHub.$on('selected', this.select);
            this.$eventHub.$on('unselected', this.unselect);
            Echo.private('user.' + this.$store.state.auth.user.id).listen('.export.status', (e) => {
                if (e.status=='done') {
                    this.exportSpinner = false;
                }
            });
        },
    }
</script>
