<template>
  <div id="wrapper">
    <!-- Navigation -->
    <nav class="navbar navbar-default navbar-static-top clearfix" role="navigation" style="margin-bottom: 0">
      <top-menu/>
      <div class="navbar-default sidebar" role="navigation">
        <font-awesome-icon 
          v-if="choice.length != 0"
          :icon='["fad", "arrow-circle-left"]' 
          size="2x" 
          style="color: #0083b9; cursor: pointer; margin: 32px 15px;" 
          @click="choice=''"
        /> 
      </div>
    </nav>
    <!-- Page Content -->
    <div id="page-wrapper">
      <div class="container-fluid">
        <div class="main-content">
          <div v-if="notProduction" class="panel panel-danger">
            <div class="panel-heading"> {{ $t('messages.not_production') }} </div>
          </div>
          <div class="container-fluid">
            <choice @selection="choice=$event" v-if="choice.length == 0 && hasSendPermission"></choice>
            <invite v-if="choice=='invitation'"/>
            <intakes-mode v-if="choice=='intakes' || !hasSendPermission" />
          </div>
        </div> <!-- /.container-fluid -->
      </div>
    </div> <!-- /#page-wrapper -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TopMenu from '@/layout/topMenu'
import IntakesMode from '@/views/dashboard/conversion/intakesMode'
import Choice from '@/layout/choice'
import Invite from '@/views/invite'
import HasPermission from '../mixins/hasPermission'

export default {
  components: { TopMenu, IntakesMode, Choice, Invite },
  mixins: [HasPermission],
  data() {
    return {
      choice: ''
    }
  },
  computed: {
    ...mapState('auth', [
      'user'
    ]),
    ...mapState('consentModal', [
      'weblinkSendView'
    ]),
    hasSendPermission(){
      return this.hasPermission('send_invite') || this.user.admin == 1
    },
    notProduction() {
      return window.location.hostname.search('dashboard.medify') === -1
    }
  },
  watch: {
    // Jump to the send view when on the weblink patient card
    weblinkSendView(newValue) {
      // 1. Set the choice to "invitation"
      if (newValue == true && this.hasSendPermission){
        this.choice = 'invitation';
      // 2. Reset the variable so that it can be triggered again later
        this.$store.dispatch('consentModal/toggleWeblinkSendView', false);
      }
    }
  },
}
</script>

<style>


  html, body {
    height: 100%;
  }

  #wrapper {
    min-height: 100%;
    height: 100%;
  }

  .main-content{
    padding: 25px 0px 0px 50px !important;
  }

  #page-wrapper {
    margin: 0px !important;
    -webkit-box-shadow: inset 3px 3px 3px 0 hsla(0,0%,90%,.5);
    -moz-box-shadow:  inset 3px 3px 3px 0 hsla(0,0%,90%,.5);
    box-shadow:  inset 3px 3px 3px 0 hsla(0,0%,90%,.5);
    border-left: 70px solid #fff !important;
    min-height: 100%
  }
  .sidebar {
    width: 70px !important;
    background: #fff  !important;
    text-align: center;
    font-family: 'Raleway', sans-serif;
  }

  .sidebar .nav > li {
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  #side-menu>li {
    position:relative;
  }
</style> 