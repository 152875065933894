<template>
    <tr>
        <td class="patient-date-cell">
            {{ row.created_at | stdDateTimeFormat }}
        </td>
        <td v-if="row.channel=='stop'">
            {{$t(`dashboard.modal.disabled`)}}: {{row.legacy_type}}
        </td>
        <td v-else>
            {{ $t('dashboard.modal.sent_notification_to', {
                group_id: (row.type == 'reminder' || row.type == 'followup' ) ? this.getGroupId(row) : "",
                notification: row.type,
                email:this.sentTo,
                user: sentBy })}}
        </td>
        <td align="right">
            <span class="notification-label" :class="'label-' + row.type">{{ this.$t('dashboard.modal.notificationType.'+row.type) }}</span>
        </td>
    </tr>
</template>

<script>
  import RowMixin from "./rowMixin";
  import {mapGetters} from 'vuex';

    export default {
        mixins: [RowMixin],
        computed: {
            sentTo: function () {
                if (this.row.email && this.row.email.length > 1) { //It can be bool(0,1) or real email depending on permissions, if it's bool not show it
                    return this.row.email
                } else {
                    return this.$t('dashboard.modal.notification_receiver_'+this.row.receiver);
                }
            },
            ...mapGetters({
                record: 'consentModal/selectedRecord',
            }),
            config() {
                const {orgSymbol, department: departmentSymbol} = this.record;
                return this.$store.getters['config/getConfig']({orgSymbol, departmentSymbol})
            },
            sentBy(){
                // Updates to intakes created by integration, and all followups/reminders: the sender is "-"
                // Updates to intakes created manually other than followup/reminders: 
                // display sender name if we have it, otherwise display "-"
                if (this.record.origin == "API" || this.row.type == 'followup' || this.row.type == 'reminder'){
                    return "-";
                } else {
                    if (this.row.sent_by_user){
                        return this.row.sent_by_user.name;
                    } else if (this.record.creator){
                        return this.record.creator.name;
                    } else {
                        return "-"
                    }
                }
            },
        },
        methods: {
            getGroupId(row) {
                if (row.type+'s' in this.config) {
                    let result = this.config[row.type+'s'].filter(v => v.legacy_type == row.legacy_type); 
                    if (result.length > 0) {
                        if ('group_id' in result[0]){
                            return result[0].group_id;
                        } else {
                            return result[0].legacy_type;
                        }
                    } else {
                        return "";
                    }
                } else {
                    return "";
                }
            }
        }
    }
</script>
