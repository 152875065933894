<template>
    <div style="text-align:center;">
        <font-awesome-icon class="spinner" :icon="['fad', 'spinner']" size="3x" pulse/>
    </div>
</template>

<script>
import VModal from 'vue-js-modal';
import Vue from 'vue';
Vue.use(VModal);

export default {
  data() {
    return {

    };
  },

};
</script>

<style>
.spinner{
    height:300px;
    text-align:center;
}
</style>
