<template>
  <div v-show="activeTab=='appointments'" id="appointments">

    <!-- PERIOD ROW -->
    <div class="row">
      <div class="col-md-8">
        <div style="display:flex; align-items: baseline">
          <select-period group="appointments" v-on:input="dateSelected"
                        datepicker_ref="datepicker_appointment"
                        v-bind:periods="datePeriods"
                        :selectorPeriods="selectorPeriods"
                        default="next30days"></select-period>
          <side-filters :pinLogin="false"></side-filters>
        </div>

        <!-- COLS + DONUT ROW -->
        <!-- COLS -->
        <column-chart translation-prefix="dashboard.danger." :chart-data="data.column" :group-by="data.groupBy"
                    :selected="dates" chart-name="appointments-column"
                    :loading="loading" 
                    :axis-titles="{x: $t('dashboard.chart_axis.appointments'), y: $t('dashboard.chart_axis.intakes')}">
        </column-chart>
      </div>
      <!-- DONUT -->
      <div class="col-md-4">
        <donut-chart translation-prefix="dashboard.danger." :chart-data="data.pie" chart-name="appointments-pie" :loading="loading"></donut-chart>
      </div>
    </div>
    
    <appointments-table :table-data="data.table" :table-options="data.tableOptions" :groupBy="data.groupBy"
                        :loading="loading"></appointments-table>
    
  </div>
</template>

<script>
  import AppointmentsTable from './appointmentsTable.vue'
  import ColumnChart from '../components/columnChart.vue'
  import DonutChart from '../components/donutChart.vue'
  import SelectPeriod from '../components/selectPeriod.vue'
  import { mapState, mapGetters } from 'vuex'
  import SideFilters from '../components/sideFilters'

  export default {
    props: ['tab'],
    data () {
      return {
        datePeriods: [
          'next7days',
          'next14days',
          'next30days',
          'next90days',
          'thisweek',
          'thismonth',
          'thisyear',
          'custom'
        ]
      }
    },
    components: {
      AppointmentsTable, ColumnChart, DonutChart, SelectPeriod, SideFilters
    },
    methods: {
      loadAppointmentsData () {
        if (this.tab == 'appointments') {
          this.$store.dispatch('appointments/loadAppointmentsData')
        }
      },
      dateSelected (selected) {
        this.loadAppointmentsData()
      }
    },
    computed: {
      ...mapState({
        loading: state => state.appointments.loading,
        data: state => state.appointments.data,
        dates: state => state.appointments.dates,
        sideFilters: state => state.sideFilters.filters,
        activeTab: state => state.userOptions.options.dashboardActiveTab,
        wasOpened: state => state.appointments.wasOpened,
        selectedOrganizationSymbol: state => state.userOptions.options.selectedOrganizationSymbol
      }),
      filters: {
        get () {
          return this.$store.state.appointments.filters
        },
        set (val) {

        }
      },
      selectorPeriods() {
        return [
          { name: this.$t('dashboard.filter.period.next7days'), symbol: "next7days"},
          { name: this.$t('dashboard.filter.period.next14days'), symbol: "next14days"},
          { name: this.$t('dashboard.filter.period.next30days'), symbol: "next30days"},
          { name: this.$t('dashboard.filter.period.next90days'), symbol: "next90days"},
          { name: this.$t('dashboard.filter.period.thisweek'), symbol: "thisweek"},
          { name: this.$t('dashboard.filter.period.thismonth'), symbol: "thismonth"},
          { name: this.$t('dashboard.filter.period.thisyear'), symbol: "thisyear"},
          { name: this.$t('dashboard.filter.period.custom'), symbol: "custom"}
      ]},
    },
    created () {
      if (this.activeTab == 'appointments') {
        this.$store.commit('appointments/setField', { field: 'wasOpened', value: true })
      }
    },
    watch: {
      activeTab (val) {
        if (val == 'appointments') {
          this.$store.commit('appointments/setField', { field: 'wasOpened', value: true })
          this.$store.dispatch('appointments/debouncedLoadData')
        }
      },
      selectedOrganizationSymbol(newOrg, oldOrg) {
        // Let's subscribe to the new org
        // Check that we are actually switching not setting initial value
        if (oldOrg && newOrg !== oldOrg) {
          this.$store.dispatch('appointments/debouncedLoadData')
        }
      }
    }
  }

</script>
