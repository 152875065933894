<template>
 <div style="height:100%;">
   <intakes-layout v-if="user"/>
   <pin-login v-else/>
 </div>
</template>

<script>
import PinLogin from '@/layout/pinLogin'
import { mapState } from 'vuex'
import IntakesLayout from '@/layout/intakesLayout'
export default {
  components: { IntakesLayout, PinLogin },
  computed: {
    ...mapState('auth', [
      'user'
    ])
  },
}
</script>