import axios from 'axios'
import Vue from 'vue'

// initial state
const state = {
    //TODO2: after this is done, get rid of hash on clientside and use ic id instead :)
    records: {},//TODO: store the records per org, to prevent overriding duplicate hashes: records: {}, //We are caching them to not load record more then once during single session
    selectedEpdHash: '',
    selectedTab: '',
    formPreview: '',
    orgSymbol: '',
    reportSectionId: null,
    interval: '',
    subscribedChannels: [],
    preventingModalOpen: false,
    preventingModalClose: false,
    tagsErrorAnimation: false,
    lockedFix: false,
    weblinkSendView: false,
    showUserDataCheckbox: false,
    showUserDataSpinner: false,
    showApiDataCheckbox: false,
    showApiDataSpinner: false,
    modalRequested: false,

}

// getters
const getters = {
    selectedRecord: (state, getters, rootState) => {
        return state.records[state.selectedEpdHash];
    },
    getModalRequested: (state) => {
        return state.modalRequested;
    },
    getAppointment: (state, getters, rootState) => {
        if (getters.selectedRecord) {
            let appointment;
            getters.selectedRecord.rows.forEach(function (row) {
                if (row.from == 'appointments') {
                    appointment = row;
                }
            });
            return appointment;
        }
    },
    getFormPreview: (state) => {
        return state.formPreview;
    },
    getReportSectionId: (state) => {
        return state.reportSectionId;
    },
    locked (state, getters, rootState) {
      const now = Math.floor(Date.now() / 1000);

      const record =  state.records[state.selectedEpdHash]
      if (record &&
        ((record.editing + 180 > now && record.editing_by != rootState.auth.user.id ) ||
        // To prevent users having multiple tabs with the same pc, front and back session must match
        (record.editing && rootState.userOptions.sessionId != record.session_id))
      ) {
        return true;
      }
    },
    relatedIcs(state, getters){
        return getters.selectedRecord.rows
            .filter(row => "log_name" in row && row.log_name == "api_intake_link_external")
            .map( row_external => {
                return {
                    hash: row_external.properties.intake_external.hash,
                    org: row_external.properties.intake_external.organization
                }
            })
    },
    preventingModalOpen(state){
        return state.preventingModalOpen;
    },
    preventingModalClose(state){
        return state.preventingModalClose;
    },
    tagsErrorAnimation(state){
        return state.tagsErrorAnimation;
    },
    lockedFix(state){
        return state.lockedFix;
    },
    userId(state, getters, rootState){
        return rootState.auth.user.id;
    },
    getWeblinkSendView: (state) => {
        return state.weblinkSendView;
    },
    getUserSpinner: (state) => {
        return state.showUserDataSpinner;
    },
    getApiSpinner: (state) => {
        return state.showApiDataSpinner;
    },
}

// actions
const actions = {
    subscribeToEcho(store) {
        //TODO: listen per department
        const orgSymbol = store.rootState.userOptions.options.selectedOrganizationSymbol;
        const orgRelations = this.getters['orgRelations/getRelations']({orgSymbol});
        [{orgSymbol}, ...orgRelations].forEach(({orgSymbol, departmentSymbols}) => {
            const channel = `dashboard.${orgSymbol}`;
            if(store.state.subscribedChannels.indexOf(channel) > -1) return; //already subscribed
            store.commit('addSubscribedChannel', channel); //subscribe to channel

            Echo.private(channel).listen('.ic.updated', (e) => {
                if (store.state.records[`${orgSymbol}_${e.hash}`]) {
                    //If we have this opened and someone just freed the record
                    // signal backend and start editing
                    if (`${orgSymbol}_${e.hash}` ==  store.state.selectedEpdHash &&
                        e.editing_by === null ) {
                        axios.post('/api/ic/start-editing-record', {
                            org: orgSymbol,
                            departmentSymbol: store.getters.selectedRecord.department,
                            hash: e.hash,
                            sessionId: store.rootState.userOptions.sessionId,
                        });
                    }

                store.commit( e.newRows ? 'addRowToRecord' : 'addRecord' , {
                        hash: e.hash,
                        data: { //ES6 trick to merge them together
                            ...store.state.records[`${orgSymbol}_${e.hash}`],
                            ...e
                        }
                    });
                }
            });
        });
    },
    toggleModalPrevent(context, preventing) {
        context.commit('toggleModalPrevention', preventing);
    },
    toggleModalClosePrevent(context, preventing) {
        context.commit('toggleModalClosePrevention', preventing);
    },
    toggleTagsErrorAnimation(context, preventing) {
        context.commit('toggleTagsErrorAnimate', preventing);
    },
    toggleLockedFix(context, newValue) {
        context.commit('toggleLockedFixed', newValue);
    },
    showModal(context, {hash, orgSymbol, department}) {
        context.commit('setModalRequested', true);

        // Disable the "Display options" checkbox
        context.commit('toggleShowUserDataCheckbox', false);
        context.commit('toggleShowApiDataCheckbox', false)
        
        if (!context.state.records[hash]) {
            context.dispatch('loadRecord', {hash, orgSymbol, departmentSymbol: department, patientCardOpened : true});
        }
        context.commit('setSelectedEpdHash', `${orgSymbol}_${hash}`);
        context.dispatch('subscribeToEcho');
        axios.post('/api/ic/start-editing-record', {
            org: orgSymbol,
            departmentSymbol: department,
            hash,
            sessionId: context.rootState.userOptions.sessionId,
        });

        // Just in case for some reason it's not yet cleared
        if (context.state.interval) {
          clearInterval(context.state.interval)
        }
        const interval = setInterval(function() {
          const record = context.getters.selectedRecord
          const locked = context.getters.locked
          if (record && !locked) {
            axios.post('/api/ic/still-editing-record', {
              hash: record.hash,
              org: record.orgSymbol,
              departmentSymbol: record.department,
              sessionId: context.rootState.userOptions.sessionId,
            })
          } else if (record && record.editing_by == context.rootState.auth.user.id 
            && context.rootState.userOptions.sessionId != record.session_id){ 
              // if same user but diff sesh, we keep checking in case the other session times out
              axios.post('/api/ic/start-editing-record', {
                org: record.orgSymbol,
                departmentSymbol: record.department,
                hash: record.hash,
                sessionId: context.rootState.userOptions.sessionId,
            });
          }
        }, 10000)
        context.commit('setInterval', interval);
    },
    updateModal(context) {
        context.dispatch('loadRecord', {
            hash: context.state.selectedEpdHash.split('_')[1], 
            orgSymbol: context.state.selectedEpdHash.split('_')[0], 
            departmentSymbol: context.getters.selectedRecord.department, 
            patientCardOpened: false,
        }).then(() => {
            // Disable the "Display options" menu "loading" animations:
            context.commit('toggleUserDataSpinner', false);
            context.commit('toggleApiDataSpinner', false);
        });
    },
    hideModal(context) {
        const record = context.getters.selectedRecord;
        // Clear interval
        if (context.state.interval) {
          clearInterval(context.state.interval)
          context.commit('setInterval', '');
        }
        if(!record) return;
        axios.post('/api/ic/stop-editing-record', {
            hash: record.hash,
            org: record.orgSymbol,
            departmentSymbol: record.department
        });
        context.commit('setSelectedEpdHash', '');
        context.commit('selectTab', '');
        context.commit('setFormPreview');
        context.commit('setModalRequested', false)
    },
    loadRecord(context, {hash, orgSymbol, departmentSymbol, patientCardOpened}) {
        let extraRequest = [];
        if (context.state.showUserDataCheckbox) extraRequest.push('user');
        if (context.state.showApiDataCheckbox) extraRequest.push('api');

        return axios.post('/api/ajax', {
            'action': 'getEpdDetails',
            'org': orgSymbol,
            'departmentSymbol': departmentSymbol,
            'nonce': window._nonce,
            'sessionId': context.rootState.userOptions.sessionId,
            'hash': hash,
            'patientCardOpened': patientCardOpened,
            'extraRequest': extraRequest,
            'activeTab': context.rootState.userOptions.options.dashboardActiveTab,
        }).then((result) => {
            context.commit('addRecord', {hash, data: {...result.data, orgSymbol}});
            // Wait! pls also check the progress of relatedIcs
            for (const ic of context.getters.relatedIcs){
                context.dispatch('checkRelatedIcs', {hash: ic.hash, org: ic.org})
            }   
        });
    },
    toggleRecordVisibility(context, reason) {
        return new Promise((resolve, reject) => {
            axios.post('/api/toggle_ic', {
                'id': context.getters.selectedRecord.id,
                'org': context.getters.selectedRecord.orgSymbol,
                'departmentSymbol': context.getters.selectedRecord.department,
                'reason': reason
            }).then(result => {
                resolve(result);
            }, error => {
                reject(error.data);
            });
        });
    },
    toggleLocation(context, {email, sendEmail, useSavedEmail}) {
        return new Promise((resolve, reject) => {
            axios.post('/api/ic/toggle-location', {
                id: context.getters.selectedRecord.id,
                org: context.getters.selectedRecord.orgSymbol,
                departmentSymbol: context.getters.selectedRecord.department,
                email: email,
                sendEmail: sendEmail,
                useSavedEmail: useSavedEmail,
            }).then(result => {
                resolve(result);
            }, error => {
                reject(error.response);
            });
        });
    },
    addComment(context, body) {
        return new Promise((resolve, reject) => {
            axios.post('/api/ic/comment', {
                commentable_id: context.getters.selectedRecord.id,
                org: context.getters.selectedRecord.orgSymbol,
                departmentSymbol: context.getters.selectedRecord.department,
                body: body
            }).then(result => {
                resolve(result);
            }, error => {
                reject(error.data);
            });
        });
    },
    addEditAppointment(context, form) {
        return new Promise((resolve, reject) => {
            axios.post('/api/ic/appointment', {
                ...form,
                ic_id: context.getters.selectedRecord.id,
                org: context.getters.selectedRecord.orgSymbol,
                departmentSymbol: context.getters.selectedRecord.department
            }).then(result => {
                resolve(result);
            }, error => {
                reject(error.data);
            });
        });
    },
    toggleNotification(context, {notification: {type, group_id, legacy_type}}) {
        return new Promise((resolve, reject) => {
            axios.post('/api/ic/toggle-notification', {
                'id': context.getters.selectedRecord.id,
                org: context.getters.selectedRecord.orgSymbol,
                departmentSymbol: context.getters.selectedRecord.department,
                'type': type,
                'group_id': group_id,
                'legacy_type': legacy_type
            }).then(result => {
                resolve(result);
            }, error => {
                reject(error.data);
            });
        });
    },
    toggleFormPreview(context, {form_id, reportSectionId}) {
        const record = context.getters.selectedRecord;
        context.commit('setFormPreview', {form: context.state.formPreview == form_id ? '' : form_id, reportSectionId});
    },
    downloadFormAsPdf(context, {form_id, reportSectionId}) {
        const record = context.getters.selectedRecord;
        window.open('/api/ic/form/download_as_pdf/'+ record.orgSymbol + '/' + form_id + '?departmentSymbol=' + record.department + (reportSectionId ? '&reportSectionId=' + reportSectionId : ''));
    },
    resendInvitation(context, {email, useSavedEmail}){
        return new Promise((resolve, reject) => {
            axios.post('/api/ic/resend-invitation', {
                id: context.getters.selectedRecord.id,
                org: context.getters.selectedRecord.orgSymbol,
                departmentSymbol: context.getters.selectedRecord.department,
                email: email,
                useSavedEmail: useSavedEmail,
            }).then(result => {
                resolve(result);
            }, error => {
                reject(error.response);
            });
        });
    },
    checkRelatedIcs(context, {hash, org}){
        if (!context.state.records[hash]) { 
            return new Promise((resolve, reject) => {
                axios.get('/api/ic/progress', {params: {
                    id: hash,
                    org: org,
                }}).then(result => {
                    if(result.data.status == 1 && result.data.completedFormFormIds && result.data.completedFormFormIds.length && result.data.filledForms && result.data.filledForms.length) {
                        const firstCompletedFormId = result.data.completedFormFormIds.split(',')[0];
                        const forms = result.data.filledForms.split(',').map(f => f.split(';'));
                        const form = forms.find(([formId]) => {
                            return firstCompletedFormId === formId;
                        });
                        if(form) {
                            const [formId, firstCompletedFormCreated, firstCompletedFormGroup] = form;
                            result.data.firstCompletedFormCreatedDate = moment(firstCompletedFormCreated).format('YYYY-MM-DD HH:mm:ss');
                            result.data.firstCompletedFormGroup = firstCompletedFormGroup;
                        }
                    } else {
                        result.data.firstCompletedFormGroup = 4
                    }
                    if (result.data.firstCompletedFormGroup == undefined) {result.data.firstCompletedFormGroup = ""}
                    context.commit('addDataToRecord', {status: result.data.status, risk: result.data.firstCompletedFormGroup, hash: hash});
                    resolve(result);
                }, error => {
                    reject(error.data);
                });
            });
        }
    },
    deleteAppointment(context, row){
        return new Promise((resolve, reject) => {
            axios.post('/api/ic/delete-appointment', {
                app_id: row.id,
                ic_id: context.getters.selectedRecord.id,
                org: context.getters.selectedRecord.orgSymbol,
                departmentSymbol: context.getters.selectedRecord.department
            }).then(result => {
                resolve(result);
            }, error => {
                reject(error.response);
            });
        });

    },
    toggleWeblinkSendView(context, newStatus) {
        context.commit('setweblinkSendView', newStatus);
    },
}

// mutations
const mutations = {
    // setOrgSymbol(state, orgSymbol) {
    //     state.orgSymbol = orgSymbol;
    // },
    setInterval(state, interval) {
      state.interval = interval
    },
    setSelectedEpdHash(state, epdHash) {
        state.selectedEpdHash = epdHash;
    },
    setModalRequested(state, newVal){
        state.modalRequested = newVal;
    },
    setFormPreview(state, {form, reportSectionId} = {form: ''}) {
        state.reportSectionId = reportSectionId || null;
        state.formPreview = form;
    },
    addRecord(state, {hash, data}) {
        //TODO: move this to backend:
        if(data && data.rows) {
            data.rows = data.rows.filter(({from, hash}) => {
                if(from !== 'related-ics') return true;
                return hash != data.hash;
            });
        }

        Vue.set(state.records, `${data.orgSymbol}_${hash}`, data);
    },
    addRowToRecord(state, {hash, data}){
        data.newRows.forEach(row => {
            data.rows.push(row);
        })
        delete data.newRows;
        Vue.set(state.records, `${data.orgSymbol}_${hash}`, data);
    },
    addDataToRecord(state, {status, risk, hash}){
        let selectedRec = state.records[state.selectedEpdHash]
        selectedRec.rows.forEach(row => {
            if ("log_name" in row && row.log_name == "api_intake_link_external" && row.properties.intake_external.hash == hash){
                Vue.set(row, "status" , status);
                Vue.set(row, "risk" , risk);
            }
          })
    },
    selectTab(state, tab) {
        state.selectedTab = state.selectedTab == tab ? '' : tab; //If this tab already selected hide
    },
    addSubscribedChannel(state, channel) {
        state.subscribedChannels.push(channel);
    },
    toggleModalClosePrevention(state, prevention){
        state.preventingModalClose = prevention;
    },
    toggleTagsErrorAnimate(state, value){
        state.tagsErrorAnimation = value;
    },
    toggleLockedFixed(state, newValue){
        state.lockedFix = newValue;
    },
    setweblinkSendView(state, newStatus) {
        state.weblinkSendView = newStatus;
    },
    toggleShowUserDataCheckbox(state, newStatus){
        state.showUserDataCheckbox = newStatus;
    },
    toggleShowApiDataCheckbox(state, newStatus){
        state.showApiDataCheckbox = newStatus;
    },
    toggleUserDataSpinner(state, newStatus){
        state.showUserDataSpinner = newStatus;
    },
    toggleApiDataSpinner(state, newStatus){
        state.showApiDataSpinner  = newStatus;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
