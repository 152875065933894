<template>
    <div style="width: 100%">
        <div style="height: 50px">
            <p :style="styles.contentReport">
                {{translate('report.overview.overview')}}
            <button v-show="!editMode && userHasEditPermissions" @click="addOverview()" :style="styles.overviewHeaderControl">
                <font-awesome-icon icon="plus" style="margin-right: 5px;"></font-awesome-icon>
                {{translate('report.overview.new_report')}}
            </button>
            </p>
        </div>

        <!-- Adding all questionnaires loading section -->
        <div v-show="reportLoading"
                style="position:absolute;top:0px;left:0px;width:100%;height:500vh;text-align:center;overflow:hidden;background:#FFF;opacity:0.5;padding-top:25%;z-index:20000;color:black;">
            <font-awesome-icon icon="spinner" size="3x" pulse style="position: fixed; top:50%; left:50%"></font-awesome-icon>
        </div>
 
        <!-- Reports section; TODO: draggable is broken, fix -->
        <div :style="styles.overviews">
            <draggable
                v-model="overviews"
                :disabled="!editMode"
                :style="styles.overviews"
                ghost-class="ghost"
                :move="checkMove"
                :component-data="getComponentData()"
            >
                <div @click="selectOverview(overview.id)" v-bind:key="overview.id" :style="styles.overview(overview.selected, editMode)" v-for="overview in overviews">
                    <div :style="styles.overviewHeaderContainer">
                        <div v-show="editMode" style="width: 17%"></div>
                        <div @click="setOverviewLabel(overview.id)" :style="styles.overviewHeaderLabel" :title="overview.label">{{overview.label}}</div>
                        <div v-show="editMode" :style="styles.overviewHeaderButtons" >
                            <div :style="styles.overviewHeaderButton">
                                <font-awesome-icon icon="copy" @click.stop="cloneOverview(overview.id)" style="margin-right: 5px;"></font-awesome-icon>
                            </div>
                            <div :style="styles.overviewHeaderButton">
                                <font-awesome-icon v-show="!overview.immutable" icon="trash" @click.stop="removeOverview(overview.id)"></font-awesome-icon>
                            </div>
                        </div>
                    </div>

                    <div :style="styles.chartContainer">
                        <chart
                            :data="overview.categoryDivision"
                            :summary=true
                            :name="'Categorie'"
                            :type="'pie'"
                            :namespaceInstance="namespaceInstance"
                            v-show="!pieUpdating"
                            :exportButton="false"
                            >
                        </chart>
                    </div>
                    <p>{{translate('report.overview.total_patients') + ": " + (overview.activePopulation ? overview.activePopulation : "0") + " | " + translate('report.overview.filtered') + ": " + (overview.filteredPopulation ? overview.filteredPopulation : (overview.activePopulation ? overview.activePopulation : "0")) }}</p>
                </div>
            </draggable>
        </div>

        <div style="height: 50px">
          <p :style="styles.contentReport">
            {{translate('report.overview.content_report')}}
            <button v-show="!editMode && userHasEditPermissions" @click="enterEditMode()" :style="styles.overviewHeaderControl">
                <font-awesome-icon icon="edit"></font-awesome-icon>
                {{translate('report.overview.edit_reports')}}
            </button>
          </p>
        </div>
 
        <!-- Report/editbar section --> 
        <div>
            <div :style="styles.reportBar(editMode)" v-if="!editMode">
                <div style="display: flex; flex-direction: row; width:80%;">
                    <p :style="styles.filterText">{{translate('report.overview.active_filters') + ": "}}</p>
                    <p :style="styles.filterText" v-if="!$store.getters[namespaceInstance + '/activeFilters'].length && !addingOrFilter.length">{{translate('report.overview.none')}}</p>
                    <filter-tag v-for="(filter, index) in $store.getters[namespaceInstance + '/activeFilters']"
                        :filter="filter"
                        :index="index"
                        :translate=translate
                        :isNotFinalTag="index < $store.getters[namespaceInstance + '/activeFilters'].length - 1"
                        @remove="removeFilter"
                        >
                    </filter-tag>
                    <div :style="styles.orFilterBuilding" class="buildingOrFilter" v-if="addingOrFilter.length">
                        <div style="margin-left: 2px" v-for="filter in addingOrFilter">
                            {{filter.answer + " " + filter.questionId + " " + filter.questionnaireId}} 
                            <font-awesome-icon icon="trash" @click="removeOrFilter(filter)"></font-awesome-icon>
                        </div>
                        <font-awesome-icon icon="check" @click="addOrFilter()"></font-awesome-icon>
                        <font-awesome-icon icon="times" @click="resetOrFilterBuilding(filter)"></font-awesome-icon>
                    </div>
                </div>
                <div style='display: flex; flex-direction: row; justify-content: space-between;'>
                    <button v-if="userHasExportPermissions" class="btn" :style="styles.filterBarButton" @click="openPatientModal">
                        <font-awesome-icon icon="eye" aria-hidden="true"></font-awesome-icon>
                        {{ translate('report.overview.individual_answers')}}
                    </button>
                    <button class="btn" :style="styles.filterBarButton" @click="resetFilters" :disabled="!$store.getters[namespaceInstance + '/activeFilters'].length">
                        <font-awesome-icon icon="trash" aria-hidden="true"></font-awesome-icon>
                        {{ translate('report.overview.remove_filters')}}
                    </button> 
                </div>
            </div>

            <div v-if="editMode" :style="styles.reportBar(editMode)">
                <div style="display: flex; flex-direction: row; width:80%;">
                    <p :style="styles.filterText">{{ translate('report.overview.editmode_active')}}</p>
                </div>

                <div style="display: flex; align-items: center; margin-right: 20px;">
                    <button class="btn" :style="styles.editBarButton" @click="cancelEditMode">
                        <font-awesome-icon icon="trash" style="margin-right: 5px;"></font-awesome-icon>
                        {{ translate('report.actions.cancel')}}
                    </button>
                    <div style="width: 15px"></div>
                    <button class="btn" :style="styles.editBarButton" @click="persistEdit">
                        <font-awesome-icon icon="save" style="margin-right: 5px;"></font-awesome-icon>
                        {{ translate('report.actions.save')}}
                    </button>
                </div>
            </div>

          <!-- Patient Information Modal -->
           <patient-modal v-if="getSelectedOverview()"
                ref="patientModal"
                :patients="this.$store.getters[this.namespaceInstance + '/selectedOverview'].submittedQuestionnairesByHash"
                :activeQuestionnaires="this.getActiveQuestionnaires()"
                :activeFilters="this.$store.getters[this.namespaceInstance + '/activeFilters']"
                :namespaceInstance="namespaceInstance"
                >
            </patient-modal>

            <!-- Question entries of the report section -->
            <!-- TODO: fix redundancy -->
            <div v-if="isSectionAvailable({selected: true})" :style="styles.sectionContainer">
            
                <h2 v-if="editMode" style="margin-left: 15px">{{translate('report.overview.selection')}}</h2>
                <div v-if="isSectionAvailable({selected: true, questionnaireId: questionnaire.id})" style="width: 100%" v-bind:key="questionnaire.id" v-for="(questionnaire, index) in $store.getters[namespaceInstance + '/selectedOverview'].questionnaires">
                    <questionnaire-selection
                        :questionnaire=questionnaire
                        :questionnaires=questionnaires
                        :selection="getQuestionSelection(questionnaire.id)"
                        :selected=true
                        :immutable=getOverviewImmutable()
                        :onSelectionModified=onQuestionSelectionModified
                        :onQuestionnaireSelectionModified=onQuestionnaireSelectionModified
                        :showRaw=showRaw 
                        :editMode=editMode
                        :translate=translate
                        :report=report
                        :index="index"
                        :namespaceInstance="namespaceInstance"
                        :userHasExportPermissions="userHasExportPermissions"
                    >    
                    </questionnaire-selection>
                </div>

            </div>

            <div v-if="editMode && isSectionAvailable({selected: false})" :style="styles.sectionContainer">
                <h2 style="margin-left: 10px">{{translate('report.overview.available')}}</h2>
                <div v-if="isSectionAvailable({selected: false, questionnaireId: questionnaire.id})" style="width: 100%" v-bind:key="questionnaire.id" v-for="questionnaire in $store.getters[namespaceInstance + '/selectedOverview'].questionnaires">
                    <questionnaire-selection
                        :questionnaire=questionnaire
                        :questionnaires=questionnaires
                        :immutable=getOverviewImmutable()
                        :selection="getQuestionSelection(questionnaire.id)"
                        :selected=false
                        :onSelectionModified=onQuestionSelectionModified
                        :onQuestionnaireSelectionModified=onQuestionnaireSelectionModified
                        :showRaw=showRaw
                        :editMode=editMode
                        :translate=translate
                        :report="report"
                        :userHasExportPermissions="userHasExportPermissions"
                    >    
                    </questionnaire-selection>
                </div>
            </div>
        </div>
    </div>
</template>

<script type='text/javascript'>
    import QuestionnaireSelection from "./questionnaire-selection";
    import draggable from 'vuedraggable'

    import PatientModal from './views/patientModal';
    import FilterTag from './views/tag.vue';
    import Chart from './views/chart.vue';

    export default {
        props: ["userHasEditPermissions", "userHasExportPermissions", "persistView", "view", "translate", "reportForms", "report", "namespaceInstance"],
        computed: {
            addingOrFilter() {
                return this.$store.getters[this.namespaceInstance + '/orFilterBuilding'];
            },
            editMode() {
                // editMode is computed instead of in `data()` for reactivity
                return this.$store.getters[this.namespaceInstance + '/editMode'];
            },
            overviews: {
                get() {
                    return this.$store.getters[this.namespaceInstance + '/overviews']
                },
                set(value) {
                    this.$store.dispatch(this.namespaceInstance + '/setOverviews', value);
                }
            },
            questionnaires() {
                if (this.$store.getters[this.namespaceInstance + '/selectedOverview'])
                    return this.$store.getters[this.namespaceInstance + '/selectedOverview'].questionnaires;
            }
        },
        data() {
            return {
                showRaw: false,
                reportLoading: false,
                pieUpdating: false,
                backupOverviews: {},
                styles: {
                    orFilterBuilding: {
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        verticalAlign: "center",
                        height: "26px",
                        fontSize: "14px",
                        border: "3px solid #FFFFFF",
                        borderRadius: "3px",
                        color: "white",
                        marginTop: "auto", 
                        marginBottom: "auto",
                        marginLeft: "5px"

                    },
                    overviews: {
                        display: 'flex',
                        width: '100%',
                        flexFlow: 'row wrap',
                        alignContent: 'stretch'
                    },
                    overview(selected, em) {
                        return {
                            flexBasis: "250px",
                            width: "100%",
                            minWidth: "200px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "left",
                            alignItems: "center",
                            borderRadius: "2px",
                            marginBottom: "20px",
                            marginRight: "20px",
                            height: "160px",
                            backgroundColor: 'white',
                            border: selected && em ? "3px solid #FF6600" : selected ? "3px solid #005375" : "2px solid #eee",
                            boxShadow: "0px 2px 5px #D3D3D3"
                        }
                    },
                    overviewHeaderContainer: {
                        marginTop: "5px",
                        paddingLeft: "5px",
                        display: "flex",
                        justifyContent: "center",
                        width: "100%"
                    },
                    overviewHeaderLabel: {
                        width: "66%",
                        maxWidth: "83%",
                        overflow: 'hidden',
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap"
                    },
                    overviewHeaderButtons: {
                        display: "flex",
                        width: "17%",
                        justifyContent: "flex-end",
                        paddingRight: "5px"
                    },
                    overviewHeaderButton: {
                        width: "100%",
                        display: "flex",
                        justifyContent: "center"
                    },
                    chartContainer: {
                        width: "90%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        minHeight: "100px"
                    },
                    overviewHeaderControl: {
                        backgroundColor: "#FF6600",
                        color: "white",
                        borderRadius: "4px",
                        borderColor: "transparent",
                        fontWeight: "normal",
                        fontSize: "14px",
                        height: "30px"
                    },
                    overviewHeaderControlIcon: {
                        fontSize: "xx-large"
                    },
                    sectionContainer: {
                        backgroundColor: "WhiteSmoke",
                        borderRadius: "10px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start"
                    },
                    reportBar(editing) {
                        return {
                            position: "sticky",
                            top: "0px",
                            width: "100%",
                            backgroundColor: editing ? "#FF6600" : "#005375",
                            fontSize: "large",
                            zIndex: "15000",
                            opacity: "0.8",
                            display: "flex",
                            height: "50px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between"
                        }
                    },
                    filterText: {
                        marginTop: "auto",
                        marginBottom: "auto",
                        marginLeft: "20px",
                        color: "white",
                        opacity: "0.8"
                    },
                    filterBarButton: {
                        height: "30px",
                        position: "relative",
                        backgroundColor: "#FF6600",
                        color: "white",
                        marginTop: "auto",
                        marginBottom: "auto",
                        marginRight: "15px"
                    },
                    orFilterButton: {
                        backgroundColor: "#FF6600",
                        color: "white",
                    },
                    contentReport: {
                        fontSize: "large",
                        paddingBottom: "5px",
                        paddingTop: "5px"
                    },
                    editBarButton:{
                        height: "30px",
                        backgroundColor: "#005375",
                        color: "white"
                    }
                }
            }
        },
        components: {
            Chart,
            QuestionnaireSelection,
            PatientModal,
            FilterTag,
            draggable
        },
        methods: {
            addOrFilter(){
                this.$store.dispatch(this.namespaceInstance + "/createOrFilter", this.addingOrFilter).then(() => {
                    this.resetOrFilterBuilding();
                });
            },
            resetOrFilterBuilding(){
                this.$store.dispatch(this.namespaceInstance + "/resetOrFilterBuilding");
            },
            removeOrFilter(filter){
                this.$store.dispatch(this.namespaceInstance + "/removeOrFilter", filter);
            },
            getComponentData(){
                return {
                    on: {
                        change: this.handleChange
                    }
                }
            },
            handleChange(event){
                // TODO: the overviews being saved are not in the new order, in the old one. So in reality you can drag now
                // but it won't save the order you want
                console.log(event);
            },
            checkMove(e){
                console.log(e);
            },
            setOverviewLabel(overviewId) {
                // Change label (title) of a report, unless an empty string is given or the alert is cancelled
                const overview = this.overviews.find(({id}) => id === overviewId);
                if (overview.immutable) return;

                const newLabel = prompt(this.translate('report.overview.new_label'));
                if (!newLabel){
                    return;
                }
                overview.label = newLabel;
            },
            isSectionAvailable({selected, questionnaireId}) {
                const selectedOverview = this.getSelectedOverview();
                if(!selectedOverview) return false;
                if(questionnaireId && !selectedOverview.selectedQuestionairesQuestions[questionnaireId]) return false;
                const questionnaireIds = questionnaireId ? [questionnaireId] : Object.keys(selectedOverview.selectedQuestionairesQuestions);
                const result = questionnaireIds.find(questionnaireId => {
                    return Object.keys(selectedOverview.selectedQuestionairesQuestions[questionnaireId]).find(questionId => {
                        return selected === selectedOverview.selectedQuestionairesQuestions[questionnaireId][questionId].selected
                    });
                });
                return result;
            },
            getOverviewImmutable() {
                return this.getSelectedOverview().immutable;
            },
            async onQuestionSelectionModified(questionnaireId, questionId) {
                let data = {
                    questionnaireId: questionnaireId,
                    questionId: questionId
                }

                await this.setReportLoading(true);
                await this.$store.dispatch(this.namespaceInstance + '/changeQuestionSelection', data);
                await this.setReportLoading(false);
            },
            async onQuestionnaireSelectionModified(questionnaireId, selectedness){
                // changing the selectedness of all questions in a questionnaire
                let data = {
                    questionnaireId: questionnaireId,
                    selectedness: selectedness
                }

                await this.setReportLoading(true);
                await this.$store.dispatch(this.namespaceInstance + '/changeQuestionnaireSelection', data);
                await this.setReportLoading(false);
            },
            setReportLoading(state){
                return new Promise(resolve => {
                    this.reportLoading = state;
                    this.pieUpdating = state;

                    // In order to actually have the visible DOM include the overlay, a timeout of 40 ms is set
                    setTimeout(() => {
                        this.$nextTick(function(){
                            resolve();
                        });
                    }, 40)
                })
                
            },
            getQuestionSelection(questionnaireId, selected) {
                return this.getSelectedOverview().selectedQuestionairesQuestions[questionnaireId];
            },
            getSelectedOverview() {
                return this.$store.getters[this.namespaceInstance + '/selectedOverview'];
            },
            selectOverview(overviewId) {
                this.$store.dispatch(this.namespaceInstance +  '/setSelectedOverview', overviewId);
            },
            async removeFilter(filter){
                await this.setReportLoading(true);
                await this.$store.dispatch(this.namespaceInstance + '/removeFilter', filter);
                await this.setReportLoading(false);
            },
            removeOverview(overviewId) {
                // removing the overview on which was clicked
                this.$store.dispatch(this.namespaceInstance + '/removeOverview', overviewId);
            },
            cloneOverview(overviewId) {
                // get label of overview to clone, and create new overview with that label + (clone)
                const overview = this.overviews.find(({id}) => id === overviewId);
                const label = overview.label + ' (clone)';
                const dep = this.$store.state.userOptions.options.selectedDepartmentSymbol;
                const payload = {dep: dep, label: label};
                this.$store.dispatch(this.namespaceInstance + '/createOverview', payload).then((newOverview) => {
                    this.selectOverview(newOverview.id);
                });
            },
            addOverview() {
                let label = prompt(this.translate('report.overview.new_label'));
                
                // if cancelled or label is an empty string, don't add overview
                if (!label){
                    return;
                }

                const dep = this.$store.state.userOptions.options.selectedDepartmentSymbol;
                const payload = {dep: dep, label: label};
                this.$store.dispatch(this.namespaceInstance + '/createOverview', payload).then((newOverview) => {
                    this.selectOverview(newOverview.id);
                    this.persistView(this.overviews);
                    this.enterEditMode();
                });
            },
            async enterEditMode() {
                // set loading as entering editmode can take a while with all new visuals being rendered
                await this.setReportLoading(true);

                this.backupOverviews = JSON.parse(JSON.stringify(this.overviews));
                await this.$store.dispatch(this.namespaceInstance + '/changeEditMode', true);

                await this.setReportLoading(false);
            },
            cancelEditMode() {
                // restore viewInfo from when editMode was activated
                this.$store.dispatch(this.namespaceInstance + '/setOverviews', this.backupOverviews);
                this.$store.dispatch(this.namespaceInstance + '/changeEditMode', false);
            },
            persistEdit() {
                this.persistView(this.overviews);
                this.$store.dispatch(this.namespaceInstance + '/changeEditMode', false);
            },
            async resetFilters() {
                await this.setReportLoading(true);
                await this.$store.dispatch(this.namespaceInstance + '/resetFilters');
                await this.setReportLoading(false);
            },
            openPatientModal() {
                this.$refs.patientModal.show();
            },
            getActiveQuestionnaires() {
                // TODO: port to store
                // if there's no overviews, there's no active questionnaires
                if (!this.overviews.length){
                    return;
                }
                let activeQuestionnaires = {};

                // find all active questions per questionnaire
                if (!this.questionnaires){
                    return;
                }

                for (let questionnaire in this.questionnaires) {
                    let activeNrs = [];
                    let questions = this.getQuestionSelection(questionnaire);
                    if (!questions){
                        return;
                    }
                    Object.keys(questions).forEach((key) => {
                        if (questions[key].selected) {
                            let activeQuestion = this.questionnaires[questionnaire].questionAnswerPairs.find(function(q){return q._id == key});
                            if (activeQuestion){
                                activeQuestion.form_id = questionnaire;
                                activeNrs.push(activeQuestion);
                            }
                        }
                    })
                    activeQuestionnaires[questionnaire] = activeNrs;
                }

                return activeQuestionnaires;
            }
        }
    };
</script>

<style scoped>
    .ghost {
    opacity: 0.5;
    background: #c8ebfb;
    }

    .buildingOrFilter { 
        animation: pulsatingOrFilter 5s infinite;
    }

    @keyframes pulsatingOrFilter {
        0%   {opacity: 1;}
        50%  {opacity: 0.5;}
        100% {opacity: 1;}
    }
</style>