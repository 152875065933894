<template>
    <div style="margin-top:auto; margin-bottom: auto;">
        <div :style="styles.filterContainer(this.inModal)"
        @mouseover="hover = true"
        @mouseleave="hover = false"
        data-toggle="tooltip" data-placement="bottom" data-html="true" :title="getTooltipContent()"
        >
            <div :style="styles.filterTag(this.inModal)">
                <div :style="styles.filterLabel(this.inModal)" v-html="getFilterText()">
                </div>
                <div v-if="!inModal" :style="styles.delete(hover)" v-on:click="$emit('remove', filter)">
                    <font-awesome-icon :style="styles.filterCross" icon="times"></font-awesome-icon>
                </div>
                <div :style="styles.patientsLeft(filter.patientsLeft)">
                    {{getPatientsLeft()}}
                </div>
            </div>
        </div>

        <!-- If there's another filter after this, add arrow between filters -->
        <font-awesome-icon v-if="isNotFinalTag" icon="arrow-right" :style="styles.arrowTweenTags(this.inModal)"></font-awesome-icon>
    </div>
</template>


<script type="text/javascript">
import chartTogglerVue from './chart-toggler.vue';

    $(document).ready(function(){
        $('[data-toggle="tooltip"]').tooltip();   
    });

    export default {
        name: "filter-tag",
        props: ['filter', 'index', 'inModal', 'isNotFinalTag', 'translate'],
        methods: {
            getFilterText() {
                // TODO: Make better (also getTooltipContent with orfiltering) shouod use reduce probs
                if (this.filter.length){
                    let resultingString = "(";
                        this.filter.forEach((f, key, filter) => {
                            // as long as not last, we add the OR div in between
                            let last = Object.is(filter.length - 1, key);
                            resultingString += f.questionId + ": " + f.answer + (!last ? " <div style='background-color: white; color: #005375; padding: 0px 2px 0px 2px'> " + this.translate("of") + " </div> " : "")
                        })
                    return resultingString + ")"; 
                }
                const identifier = this.filter.type === 'summary' ? 'Totaal' : this.filter.questionId;
                const answer = this.filter.answer;
                return identifier + ": " + answer;
            },
            getTooltipContent() {
                if (this.filter.length){
                    return "or filter";
                }
                // filter set on the total summary does not need extra explanation
                if (this.filter.type === 'summary'){
                    return;
                }
                const questionnaire = "Questionnaire: " + this.filter.questionnaireId + "<br>";
                const question = "Question number: " + this.filter.questionId + "<br>";
                const answer = "Filter: " + this.filter.answer + "<br>";

                $(document).ready(function(){
                    $('[data-toggle="tooltip"]').tooltip();   
                });
                return questionnaire + question + answer;
            },
            getPatientsLeft() {
                return this.filter.patientsLeft;
            },
            getOffset(len){
                // TODO: refactor this and getWidth to something more "normal"
                return len == 1 ? "-9px" : -9 - (4 * (len - 1)) + "px";
            },
            getWidth(len){
                return (20 + (4 * (len - 1) * 2)) + "px";
            }
        },
        data() {
            let self = this;
            return {
                hover: false,
                styles: {
                    arrowTweenTags(inModal) {
                        return {
                            marginLeft: "10px",
                            color: inModal ? "black" : "white",
                            opacity: "1",
                            fontSize: "12pt"
                        }
                    },
                    filterTag(inModal) {
                        return {
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            verticalAlign: "top",
                            fontSize: "14px",
                            paddingLeft: "5px",
                            borderStyle: "solid",
                            borderRadius: "3px",
                            borderColor: inModal ? "black" : "white",
                            marginRight: "0px"
                        }
                    },
                    filterLabel(inModal) {
                        return {
                            color: inModal ? "black" : "white",
                            minWidth: "10px",
                            minHeight: "1em",
                            marginRight: "5px",
                            display: "flex",
                            flexDirection: "row" 
                        }
                    },
                    filterCross: {
                        color: "#005375",
                        paddingLeft: "2px",
                        paddingTop: "3px"
                        
                    },
                    filterContainer(inModal) {
                        return {
                            position: "relative",
                            display: "inline-block",
                            marginLeft: "10px",
                            // marginTop: inModal ? "1px" : "10px",
                            marginTop: "auto",
                            // marginBottom: "10px"
                            marginBottom: "auto"
                        }
                    },
                    delete(hover) {
                        return {
                            visibility: hover ? "visible" : "hidden",
                            backgroundColor: "white"
                        }
                    },
                    patientsLeft(chars){
                        let len = chars.toString().length;
                        return {
                            backgroundColor: "red",
                            color: "#FFFFFF",
                            padding: "0px 2px 0px 2px",
                            position: "absolute",
                            borderRadius: "50%",
                            marginTop: "-13px",
                            marginRight: "0px",
                            marginBottom: "13px",
                            marginLeft: "-100px",
                            right: self.getOffset(len),
                            minWidth: self.getWidth(len),
                            textAlign: "center"
                        }
                    }
                }
            }
        }
    }
</script>
