<template>
  <div>
    <lang-selector style="width:70px;float:right;list-style: none; margin: 1em"/>
    <div class="container">
      <div class="row">
        <div class="col-md-4 col-md-offset-4">
          <img src="/img/medify.png" style="width:70%;margin:25% 15% 15% 15%">
          <div class="login-panel panel panel-default" style="margin-top: 5%;">
            <div class="panel-heading">
              <h3 class="panel-title">{{ $t('editing_table.auth.sign_in_title') }}</h3>
            </div>
            <div class="panel-body">
              <div class="alert alert-info" role="alert">
                {{ $t('editing_table.auth.info') }}
              </div>
              <div class="alert alert-info" role="alert" v-if="this.errors['whitelist']">
                {{ $t('editing_table.auth.ipIsNotWhiteListedMessage')}}
              </div>
              <universal-form :fields="fields" module="auth" @input="model=$event" :errors="errors">
                <template v-slot:submitButton="{ submit }">
                  <div v-if="email_sent">
                    <div class="alert alert-success" role="alert">
                      {{ $t('editing_table.auth.sent') }}
                    </div>
                  </div>
                  <div v-if="!email_sent && !weblink">
                    <input id="login-password-submit" @click="doLogin(model)" type="submit" :value="$t('editing_table.auth.login_btn')" class="btn btn-lg btn-success btn-block">
                    <div style="width: 100%; display: flex; align-items: center">
                      <a id="login-auth0" class="btn btn-link" :href="auth0Path">
                        <img style="width: 45px; height: 45px" src="/img/SSO-Icon.png">
                      </a>
                      <img src="/img/ce-logo.png" style="width: 25px; opacity: 60%;">
                      <router-link to="/email"  class="btn btn-link" style="margin-left: auto;">
                        {{ $t('editing_table.auth.forgot_password') }}
                      </router-link>
                    </div>
                  </div>
                  <div v-if="!email_sent && weblink">
                    <div style="display: flex; justify-content: center; flex-direction: row">
                      <img style="width: 140px; height: 140px;" src="/img/SSO-Icon.png">
                    </div>
                    <a :href="auth0PathWeblink">
                      <input id="login-password-submit" :value="$t('editing_table.auth.login_btn')" class="btn btn-lg btn-success btn-block btn-weblink">
                    </a>
                    <div style="display: flex; align-items: center; justify-content: center; flex-direction: row">
                      <img src="/img/ce-logo.png" style="width: 25px; opacity: 60%; margin-top: 15px;">
                    </div>
                  </div>
                </template>
              </universal-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import universalForm from '@/components/universalForm'
import LangSelector from '@/layout/langSelector'
export default {
  components: { universalForm, LangSelector },
  mounted () {
    this.error_code = localStorage.getItem('error_code');
    if (this.error_code == 419) {
      this.errors['whitelist'] = true;
    }
    localStorage.removeItem('error_code');
  },
  
  data() {
    return {
      'model': {},
      'errors': {},
      'error_code' : '',
      'email_sent': false,
    }
  },
  methods: {
    ...mapActions('auth', [
        'login', 'checkAuth', 'email'
    ]),
    doLogin(form) {
      let self = this
      this.login(form).then(function() {
        self.checkAuth()
      }).catch(({response}) => {
        if (response.data && response.data.password_expired) {
          this.$router.push('/email?password_expired=true')
        }
        if (response.data && response.data.errors) {
          this.errors = response.data.errors
          if ('password' in response.data.errors){
            if (response.data.errors.password == "editing_table.auth.password_expired_sent"){
              this.emailReset(this.model); 
            }
          }
        }
      })
    },
    emailReset(form) {
      this.email(form).then(() => {
        this.email_sent = true;
      }).catch(({response}) => {
        if (response.data && response.data.errors) {
          this.errors = response.data.errors
        }
      })
    },
  },
  computed: {
    auth0Path() {
      let urlBits = window.location.host.split('.');
      if (urlBits[urlBits.length-2] == 'medify-sandbox'){
        return '/loginAuth0?connection=ZOHO'
      } else {
        return '/loginAuth0'
      }
    },
    auth0PathWeblink() {
      let queryParams = window.location.search.substring(1);
      return `/loginAuth0?redirect=weblink&${queryParams}`;
    },
    weblink() {
      return window.location.pathname.split('?')[0] === '/weblink'
    },
    fields(){
      return this.weblink ? [] : ['email','password'];
    }
  },
}
</script>

<style scoped>
  .btn-weblink {
    background-color: #ff6000;
    border-color: #ff6000;
  }
</style>