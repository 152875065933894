<template>
    <highcharts class="chart" :options="chartOptions" :callback="created"></highcharts>
</template>


<script type="text/javascript">
    import Highcharts from 'highcharts';
    import exportingInit from 'highcharts/modules/exporting'
    import offlineExportingInit from 'highcharts/modules/offline-exporting'
    import exportDataInit from "highcharts/modules/export-data";
    import cloneDeep from 'lodash.clonedeep'

    exportingInit(Highcharts)
    offlineExportingInit(Highcharts)
    exportDataInit(Highcharts);
    import Vue from 'vue'
    import {icon} from '@/downloadIcon.js'
    Highcharts.SVGRenderer.prototype.symbols.download = icon.iconPath
    import {mapState} from 'vuex';

    export default {
        props: ['chartData', 'chartName', 'loading', 'groupBy', 'selected', 'translationPrefix', 'axisTitles'],
        data() {
            var vm = this;
            return {
                tooltipDates: [
                    {firstDay: '', lastDay: '', month: ''},
                    {firstDay: '', lastDay: '', month: ''}
                ],
                chartOptions: {
                    chartType: 'column',
                    colorInputIsSupported: null,
                    chart: {
                        type: 'column',
                        backgroundColor: 'none',
                    },
                    credits: {
                        enabled: false
                    },
                    title: {
                        text: ''
                    },
                    subtitle: {
                        text: ''
                    },
                    xAxis: {
                        alignTicks: true,
                        title: {
                            text: this.axisTitles ? this.axisTitles.x : ""
                        },
                        type: 'datetime',
                        dateTimeLabelFormats: {
                            month: '%b \'%y',
                            year: '%b'
                        },
                        labels: {
                            align: 'center'
                        }
                    },
                    yAxis: {
                        title: {
                            text: this.axisTitles ? this.axisTitles.y : ""
                        },
                        reversedStacks: false,
                        min: 0,
                        stackLabels: {
                            enabled: true,
                            defer: true,
                            style: {
                                fontWeight: 'bold',
                                color: 'gray'
                            }
                        }
                    },
                    legend: {
                        enabled: false
                    },
                    tooltip: {
                        formatter: function (tooltip) {
                            let tooltipDates = vm._data.tooltipDates;
                            if (vm._props.groupBy == "month"){
                                let header = "";
                                if (this.x == this.series.processedXData[0] && tooltipDates[0].firstDay != 1){
                                    header = tooltipDates[0]['firstDay'] + " to " + tooltipDates[0]['lastDay'] + ' ' + tooltipDates[0]['month'];
                                } else if (this.x == this.series.processedXData[this.series.processedXData.length - 1] &&
                                           tooltipDates[1].lastDay != moment(this.x).endOf('month').format('DD')) {
                                    header = tooltipDates[1]['firstDay'] + " to " + tooltipDates[1]['lastDay'] + ' ' + tooltipDates[1]['month'];
                                } else {
                                    header = moment(this.x).format("MMM 'YY")
                                }
                                return header + '<br><span style="color:' + this.point.color+ '">\u25CF</span> ' + this.series.name + ': <b>' + this.point.y +' (' +this.point.percentage.toFixed(0) + '%)</b>';
                            } else {
                                return tooltip.defaultFormatter.call(this, tooltip);
                            }
                        }
                    },
                    plotOptions: {
                        series: {
                            stacking: 'normal',
                            pointPadding: 0.1,
                            groupPadding: 0,
                            borderWidth: 0,
                            shadow: false
                        },
                        column: {
                            borderWidth: 0,
                            allowPointSelect: true,
                            states: {
                                select: {
                                    borderWidth: 2,
                                    lineWidth: 2,
                                    enabled: true,
                                    halo: {
                                        opacity: 0.3,
                                        size: 10
                                    },
                                    color: '#eee'
                                },
                            },
                            point: {
                                events: {
                                    //We will replace it later dynamically with real callbacks.
                                    // That way we could have ref to self.
                                    select(event) {
                                    },
                                    unselect(event) {
                                    }
                                }
                            }
                        }
                    },
                    //For some reason without this predefined structure newly loaded data do not apply
                    series: [
                        {"name": "", "color": "", "data": []},
                        {"name": "", "color": "", "data": []},
                        {"name": "", "color": "", "data": []},
                        {"name": "", "color": "", "data": []},
                        {"name": "", "color": "", "borderColor": "", "borderWidth": -2, "data": []},
                    ],
                    exporting: {
                        chartOptions: { // specific options for the exported image
                            chart: {
                                backgroundColor: '#FFFFFF'
                            },
                            legend: {
                                enabled: true,
                            }
                        },
                        fallbackToExportServer : false,
                        buttons: {
                            contextButton: {
                                symbol: "download",
                                symbolStrokeWidth: 1, //default: 3
                                menuItems: [
                                    'downloadCSV',
                                    'downloadPDF',
                                    'downloadPNG',
                                    'downloadJPEG',
                                ]
                            },
                        }
                    }
                }
            }
        },
        computed: {
            ...mapState({
                locale: state => state.userOptions.options.locale,
            })
        },
        methods: {
            created(chart) {
                let self = this;
                this.chartOptions.plotOptions.column.point.events = {
                    select(event) {
                        self.lastTarget = event.target;
                        self.$eventHub.$emit('selected', self.chartName, this.series.userOptions.id, event.target.category);
                    },
                    unselect(event) {
                        //That way we will trigger this event only when we are really
                        // just unselecting this and not selecting anything new
                        if (self.lastTarget == event.target) {
                            self.$eventHub.$emit('unselected', self.chartName);
                            self.lastTarget='';
                        }
                    }
                };
            },
            unselectAll(chartName) {
                if (chartName!=this.chartName) {
                    let selectedPoints = this.$children[0].chart.getSelectedPoints();
                    if (selectedPoints.length) {
                        selectedPoints.forEach((point, index) => point.select());
                    }
                }
            }
        },

        //Force update when prop changes
        watch: {
            chartData(newValue) {
              let filteredData = cloneDeep(newValue)
              for (const key in filteredData) {
                filteredData[key].name = this.$t(this.translationPrefix+filteredData[key].name)
              }
              Vue.set(this.chartOptions, 'series', filteredData);
            },
            loading(newValue) {
                if (newValue) {
                    this.$children[0].chart.showLoading();
                } else {
                    this.$children[0].chart.hideLoading();
                }
            },
            selected(newValue){
                if ( newValue && newValue.length && ( newValue[0] != 'Invalid date' || newValue[1] != 'Invalid date' )) {
                    this.tooltipDates[0]['firstDay'] = moment(newValue[0], 'DD-MM-YYYY').format('DD'); 
                    this.tooltipDates[0]['lastDay'] = moment(moment(newValue[0], 'DD-MM-YYYY')).endOf('month').format('DD'), 
                    this.tooltipDates[0]['month'] = moment(newValue[0], 'DD-MM-YYYY').format("MMM 'YY");
                    this.tooltipDates[1]['firstDay'] = moment(moment(newValue[1], 'DD-MM-YYYY')).startOf('month').format('DD'),  
                    this.tooltipDates[1]['lastDay'] = moment(newValue[1], 'DD-MM-YYYY').format('DD'); 
                    this.tooltipDates[1]['month'] = moment(newValue[1], 'DD-MM-YYYY').format("MMM 'YY");
                }
                Vue.prototype.$tooltipDates = this.tooltipDates;
            },
            locale(){
                Vue.set(this.chartOptions.yAxis.title, 'text', this.axisTitles ? this.axisTitles.y : "")
                Vue.set(this.chartOptions.xAxis.title, 'text', this.axisTitles ? this.axisTitles.x : "")
            } 
        },
        mounted() {
            this.$nextTick(() => {
                this.$children[0].chart.showLoading();
                this.$eventHub.$on('selected', this.unselectAll);
            });
        },
    }
</script>