import moment from 'moment'
export default {
  'last7days': [moment().subtract(6, 'days').format('DD-MM-YYYY'), moment().format('DD-MM-YYYY')],
  'last30days': [moment().subtract(29, 'days').format('DD-MM-YYYY'), moment().format('DD-MM-YYYY')],
  'last90days': [moment().subtract(89, 'days').format('DD-MM-YYYY'), moment().format('DD-MM-YYYY')],
  'last3months': [moment().subtract(3, 'months').format('DD-MM-YYYY'), moment().format('DD-MM-YYYY')],
  'last6months': [moment().subtract(6, 'months').format('DD-MM-YYYY'), moment().format('DD-MM-YYYY')],
  'last9months': [moment().subtract(9, 'months').format('DD-MM-YYYY'), moment().format('DD-MM-YYYY')],
  'last12months': [moment().subtract(11, 'months').format('DD-MM-YYYY'), moment().format('DD-MM-YYYY')],
  'thismonth': [moment().startOf('month').format('DD-MM-YYYY'), moment().endOf('month').format('DD-MM-YYYY')],
  'thisweek': [moment().startOf('isoWeek').format('DD-MM-YYYY'), moment().endOf('isoWeek').format('DD-MM-YYYY')],
  'thisyear': [moment().startOf('year').format('DD-MM-YYYY'), moment().endOf('year').format('DD-MM-YYYY')],
  'next7days': [moment().format('DD-MM-YYYY'), moment().add(6, 'days').format('DD-MM-YYYY')],
  'next14days': [moment().format('DD-MM-YYYY'), moment().add(13, 'days').format('DD-MM-YYYY')],
  'next30days': [moment().format('DD-MM-YYYY'), moment().add(29, 'days').format('DD-MM-YYYY')],
  'next90days': [moment().format('DD-MM-YYYY'), moment().add(89, 'days').format('DD-MM-YYYY')]
}
