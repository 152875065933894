<template>
  <consent-content/>
</template>

<script>
import ConsentContent from '@/views/dashboard/components/modal/consentContent'
export default {
  components: { ConsentContent },
  methods: {
    openModal(org, dep, hash) {
      this.$store.dispatch('consentModal/showModal', {hash, orgSymbol:org, department: dep})
      this.$store.dispatch('config/loadConfig', {orgSymbol: org, departmentSymbol: dep})
    }
  },
  created () {
    this.openModal(this.$route.query.org, this.$route.query.dep, this.$route.query.hash)
  }
}
</script>