export default {
    methods: {
        //Grab and sort unique fields
        uniqueFields(field) {
            let results = [];
            if (typeof this.tableData != 'undefined') {
                this.tableData.forEach((item) => {
                    //Support for fields that are arrays themselves
                    let items_array = [];
                    if (item[field].isArray) {
                        items_array = item[field];
                    } else {
                        items_array = [item[field]];
                    }
                    items_array.forEach((sub) => {
                        results.push(sub);
                    });
                });
            } else {
                console.log('tableData is indefined')
            }

            results = Array.from(new Set(results))
            results = results.sort();

            return results;
        },
        unixToDateRange(time, groupBy) {
            const date = moment(time, "x").toDate()
            let date2;
            if (groupBy == 'day') {
                date2 = date;
            } else {
                date2 = moment(time, "x").endOf('month').toDate();
            }
            return [date, date2];
        }
    },
}