<template>
  <div v-show="activeTab=='conversion'" id="intake">
    <div id="dashboard-widgets" class="metabox-holder">
      <div id="postbox-container-1" class="postbox-container">
        <div id="highchart_widget" class="postbox ">
          <div class="inside">
            <div>
                
              <!-- PERIOD ROW -->
              <div class="row" id="conversie-container">
                <div class="col-md-8">
                  <div style="display:flex; align-items: baseline">
                      <select-period group="conversion" datepicker_ref="datepicker_conversion"
                                    v-bind:periods="selectorPeriods"
                                    :selectorPeriods="selectorPeriods"></select-period>
                      <side-filters :pinLogin="false"></side-filters>
                  </div>

                  <!-- COLS + DONUT ROW -->
                  <!-- COLS -->
                  <column-chart :chart-data="data.column" 
                                :group-by="data.groupBy"
                                :selected="dates"
                                chart-name="conversion-column" 
                                :loading="loading"
                                translation-prefix="dashboard.status."
                                :axis-titles="{x: $t('dashboard.chart_axis.date'), y: $t('dashboard.chart_axis.intakes')}"
                  >
                  </column-chart>
                </div>

                <!-- DONUT -->
                <div class="col-md-4">
                  <donut-chart 
                    :chart-data="data.pie" 
                    chart-name="conversion-pie"
                    :loading="loading"
                    translation-prefix="dashboard.status."
                  >
                  </donut-chart>
                </div>
              </div>
              <div style="margin-top: 20px">
                <div id="filter-bar"></div>
                <conversion-table :table-data="data.table"
                                  :table-options="data.tableOptions"
                                  :groupBy="data.groupBy"
                                  :loading="loading" 
                                  :selected="dates">
                </conversion-table>
              </div>
              <bar-chart :chart-data="data.bar" chart-name="conversion-bar"
                         :loading="loading"
                         :axis-titles="{x: $t('dashboard.chart_axis.module'), y: $t('dashboard.chart_axis.intakes')}"
                         >
              </bar-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SelectPeriod from '../components/selectPeriod.vue';
  import ColumnChart from '../components/columnChart.vue';
  import DonutChart from '../components/donutChart.vue';
  import BarChart from '../components/barChart.vue';
  import ConversionTable from './conversionTable';
  import SideFilters from '../components/sideFilters';
  import {mapState, mapGetters} from 'vuex';

  // initial state
import axios from 'axios'

    export default {
        props: ['intakesMode', 'extendedInvite', 'statuses'],
        components: {
          SideFilters, SelectPeriod, ColumnChart, DonutChart, BarChart, ConversionTable
        },
        created() {
            let self = this;
            self.$eventHub.$on('side-filters-update', (event) => {
                self.filters = event;
            });
        },
        computed: {
            ...mapState({
                loading: state => state.conversion.loading,
                data:  state => state.conversion.data,
                dates:  state => state.conversion.dates,
                sideFilters:  state => state.sideFilters.filters,
                activeTab:  state => state.userOptions.options.dashboardActiveTab,
                selectedOrganizationSymbol: state => state.userOptions.options.selectedOrganizationSymbol
            }),
            filters: {
                get() {
                    return this.$store.state.conversion.filters;
                },
                set(val) {

                }
            },
            selectorPeriods() {
              return [
                { name: this.$t('dashboard.filter.period.last7days'), symbol: "last7days"},
                { name: this.$t('dashboard.filter.period.last30days'), symbol: "last30days"},
                { name: this.$t('dashboard.filter.period.last3months'), symbol: "last3months"},
                { name: this.$t('dashboard.filter.period.last6months'), symbol: "last6months"},
                { name: this.$t('dashboard.filter.period.last9months'), symbol: "last9months"},
                { name: this.$t('dashboard.filter.period.last12months'), symbol: "last12months"},
                { name: this.$t('dashboard.filter.period.thismonth'), symbol: "thismonth"},
                { name: this.$t('dashboard.filter.period.thisweek'), symbol: "thisweek"},
                { name: this.$t('dashboard.filter.period.custom'), symbol: "custom"}
              ]},
        },
        watch: {
            selectedOrganizationSymbol(newOrg, oldOrg) {
              // Let's subscribe to the new org
              // Check that we are actually switching not setting initial value
              if (oldOrg && newOrg !== oldOrg) {
                this.$store.dispatch('conversion/debouncedLoadData')
              }
            }
        },
        mounted() {
          //h4x0r 915
          this.$store.dispatch('conversion/debouncedLoadData')
        }
    }

</script>
