<template>
  <div id="wrapper">
    <!-- Navigation -->
    <nav class="navbar navbar-default navbar-static-top clearfix" role="navigation" style="margin-bottom: 0">
      <top-menu/>
      <side-menu/>
    </nav>
    <!-- Page Content -->
    <div id="page-wrapper">
      <div class="container-fluid main-content">
        <div v-if="notProduction" class="panel panel-danger">
          <div class="panel-heading"> {{ $t('messages.not_production') }} </div>
        </div>
        <div class="container-fluid">
          <keep-alive>
            <router-view/>
          </keep-alive>
        </div>
      </div>
      <!-- /.container-fluid -->
    </div>
    <!-- /#page-wrapper -->
  </div>
</template>

<script>
import sideMenu from '@/layout/sideMenu'
import topMenu from '@/layout/topMenu'
export default {
  components: {sideMenu, topMenu},
  computed: {
    notProduction() {
      return window.location.hostname.search('dashboard.medify') === -1
    }
  }
}
</script>