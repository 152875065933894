<template>
    <div>
        <side-filters :pinLogin="true" v-if="!weblink"/><br>
        <filtered-table
                :columns="weblink ? weblinkColumns : columns"
                :rows="intakesData"
                :rowClick="weblink ? getDetails : function(){}"
                ref="table"
                :loading="loadingData"
                defaultSortColumn="date"
                :showClearButton="false"
                :showHeaderFilters="this.$route.name!='weblink'"
        >
            <template slot="tags_column" slot-scope="slotProps">
                <tags-cell :showDelete="false" :orgSymbol="selectedOrganizationSymbol" :tags="slotProps.row.tags"></tags-cell>
            </template>
        </filtered-table>
        <consent-modal></consent-modal>
        <pin-modal></pin-modal>
    </div>

</template>

<script>
    import ConsentModal from '@/views/dashboard/components/modal/consentModal'
    import FilteredTable from '@/components/filteredTable.vue';
    import TagsCell from '../components/tagsCell';
    import PinModal from './pinModal.vue';
    import SideFilters from '../components/sideFilters'
    import axios from 'axios'
    import { mapGetters, mapState } from 'vuex';
    import _ from 'lodash';
    import Vue from 'vue'
    //TODO: test this one
    export default {
        data() {
            return {
                loadingData: true,
                intakesData: [],
                tableOptions:'',
            }
        },
        methods: {
            async loadData() {
                // TODO: move this to the store
                this.loadingData = true;
                axios.post('/api/ajax', {
                'action': 'getConsentData',
                'filters': this.sideFilters,
                'epd': 'epd' in this.$route.query ? this.$route.query.epd : '',
                org: this.selectedOrganizationSymbol
            }).then((result) => {
                if (result.status == 200) {
                    let dataWithRisk = this.addRiskToData(result.data.table);
                    this.intakesData = dataWithRisk;
                    this.$store.dispatch('tags/loadTags', {orgSymbol : this.selectedOrganizationSymbol}).then(_ => { 
                        result.data.tableOptions.tags = Object.values(this.tags()).map(v => v[0]);
                        this.tableOptions = result.data.tableOptions;
                        this.tableOptions.fullname = true;
                        this.loadingData = false;
                    });
                }
            });
            },
            addRiskToData(intakes){
                // TODO: this function is copied from conversion.js. Once loadData is moved to the the store, 
                // move this function to the store as well and use it from conversion.js
                intakes.forEach(row => {
                    if(row.status === 1 && row.completedFormFormIds && row.completedFormFormIds.length && row.filledForms && row.filledForms.length) {
                        const firstCompletedFormId = row.completedFormFormIds.split(',')[0];
                        const forms = row.filledForms.split(',').map(f => f.split(';'));
                        const form = forms.find(([formId]) => {
                            return firstCompletedFormId === formId;
                        });
                        if(form) {
                            const [formId, firstCompletedFormCreated, firstCompletedFormGroup] = form;
                            row.firstCompletedFormCreatedDate = moment(firstCompletedFormCreated).format('YYYY-MM-DD HH:mm:ss');
                            row.firstCompletedFormGroup = firstCompletedFormGroup;
                        }
                    } else {
                        row.firstCompletedFormGroup = 4
                    }
                })
                return intakes;
            },
            getDetails(row, preventmodal) {
                this.$store.dispatch('consentModal/showModal', {hash:row.hash, orgSymbol: this.selectedOrganizationSymbol, department: row.department});
                this.$store.dispatch('config/loadConfig', {orgSymbol: this.selectedOrganizationSymbol, departmentSymbol: row.department})
            },
            debouncedLoadData: _.debounce(function() {
                this.loadData()
                }, 1500)
        },
        computed: {
            // TODO: move this to tableColumns.js
            columns() {
                let self = this;
                return {
                    epd: {
                        label: this.$t('dashboard.table.epd'),
                    },
                    content: {
                        label: this.$t('dashboard.table.content'),
                        options: this.tableOptions && this.tableOptions['content'] ? this.tableOptions['content'] : ''
                    },
                    status: {
                        label: this.$t('dashboard.table.status'),
                        options: [
                            this.$t('dashboard.status.-1'),
                            this.$t('dashboard.status.0'),
                            this.$t('dashboard.status.1')
                        ],
                        // Maybe we need to built in key=>value object handling to filteredTable
                        // so we wouldn't need this manual value/filter but for now this works
                        value: (row) => {
                          return self.$t('dashboard.status.' + row.status)
                        },
                        filter: (row, filters) => {
                          if(filters && filters.status) {
                            // Translate the row and check that it's in filters array (it's already translated)
                            return filters.status.includes(self.$t('dashboard.status.' + row.status))
                          }
                          return true;
                        }
                    },
                    date: {
                        label: this.$t('dashboard.table.date'),
                        title: function (row) {
                            return row.date+' '+row.time;
                        },
                        datepickerOption: true
                    },
                    open: {
                        label: this.$t('dashboard.table.open_app'),
                        value: this.$t('dashboard.table.open_app'),
                        click: function(row, field, self) {
                            self.$eventHub.$emit('show-pin-modal', row);
                        },
                        class:'click-cell'
                    },
                    appointment_date: {
                        label: this.$t('dashboard.table.appointment'),
                        title: function (row) {
                            return row.appointment_date+' '+row.appointment_time;
                        },
                        datepickerOption: true
                    },
                    phone_number_full: {
                        label: this.$t('dashboard.table.phone_number_full'),
                    },
                };
            },
            weblinkColumns() {
                return this.$store.getters['tableColumns/columns'](
                    this.tableOptions,
                    this.$t('dashboard'),
                    (x) => x, // formatDate is not needed here, so we default to this generic function
                    'weblink',
                );
              },
            weblink() {
               return window.location.pathname.split('?')[0] === '/weblink'
            },
            ...mapState({
                sideFilters:  state => state.sideFilters.filters,
                selectedOrganizationSymbol: state => state.userOptions.options.selectedOrganizationSymbol
            }),
            ...mapGetters({
                record: 'consentModal/selectedRecord',
                tags : 'tags/getTagsMappedByName'
            }),
        },
        components: {
            FilteredTable, PinModal, SideFilters, ConsentModal, TagsCell
        },
        created () {
            this.loadData();
        },
        watch: {
            sideFilters: {
                handler: function (after, before) {
                    this.debouncedLoadData()
                },
                deep: true
            },
            selectedOrganizationSymbol(newOrg, oldOrg) {
                if(!oldOrg || !oldOrg.length) return;
                this.debouncedLoadData();
            }
        }
    }

</script>

<style>
    .click-cell {
        color: #337ab7;
        cursor:pointer;
    }
    .click-cell:hover {
        text-decoration: underline;
    }
</style>
