import Popper from 'popper.js';
export default {
    data: function () {
        return {
            lastShowed: 0,
            popper:false
        }
    },
    methods: {
        align(alignTo) {
            if (!this.popper) {
                this.popper = new Popper(alignTo, this.$el, {
                    placement: 'bottom-start',
                });
            } else {
                this.popper.reference=alignTo;
                this.popper.update();
            }
        },
        show() {
            this.$el.style.display = 'block';
            this.lastShowed = Date.now();
        },
        hide() {
            //Disable for 500ms after opening so it will not close right after open
            if (this.lastShowed + 500 < Date.now()) {
                this.$el.style.display = 'none';
                this.$emit('hide');
            }
        },
        //We have to check that this click is not only outside this element but also
        // not the other element (for example tag or modal)
        eventOutsideElement(event, element) {
            let outside = 1;
            for (const key in event.path) {
                //Limit search by 6 steps to not have lags on clicks
                if (key > 6) {
                    break;
                }
                if (element == event.path[key]) {
                    outside = 0;
                    break;
                }
            }
            return outside;
        }
    }
}