<template>
  <highcharts class="chart" :options="chartOptions" :callback="created"></highcharts>
</template>

<script type="text/javascript">
    import Highcharts from 'highcharts';
    import exportingInit from 'highcharts/modules/exporting'
    import offlineExportingInit from 'highcharts/modules/offline-exporting'
    import exportDataInit from "highcharts/modules/export-data";
    import cloneDeep from 'lodash.clonedeep'

    exportingInit(Highcharts)
    offlineExportingInit(Highcharts)
    exportDataInit(Highcharts);

    import Vue from 'vue'
    import {icon} from '@/downloadIcon.js'
    Highcharts.SVGRenderer.prototype.symbols.download = icon.iconPath

    export default {
        props: ['chartData', 'chartName', 'loading', 'translationPrefix'],
        data() {
            let self = this;
            return {
                fontSizePercentage: "42px",
                selected: '',
                selectedPieChart: {},
                chartOptions: {
                    chart: {
                        type: 'pie',
                        height: 446,
                        backgroundColor: 'none',
                        events: {
                            //Total counter on top of chart
                            redraw(event) {
                                if (typeof this.series[0].total == 'number') {
                                    this.setTitle({
                                        text: self.$t('dashboard.pie_chart.total') + '<br><p style="font-size:' + self.fontSizePercentage + '">' + this.series[0].total + '<br> ' + self.$t('dashboard.pie_chart.intakes'),
                                        style: {color: '#0000000', lineHeight:"42px"}
                                    });
                                    // Wee need it from the point event, and series is the only place to get it from
                                    this.series[0].chart = this;
                                }
                            }
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    title: {
                        text: 'Total',
                        align: 'center',
                        verticalAlign: 'middle',
                        margin: 0,
                        floating: true,
                        y: 35,
                        style: {
                            'fontSize': '24px'
                        }
                    },
                    subtitle: {
                        text: ''
                    },
                    xAxis: {
                        title: {
                            text: ''
                        },
                    },
                    yAxis: {
                        title: {
                            text: ''
                        },
                        min: 0,
                    },
                    tooltip: {
                        enabled: false,
                    },
                    legend: {
                        enabled: true,
                        verticalAlign: 'top',
                        align: 'center',
                        x: 0,
                        layout: 'horizontal'
                    },
                    plotOptions: {
                        pie: {
                            innerSize: '55%',
                            allowPointSelect: true,
                            cursor: 'pointer',
                            lineWidth: 0,
                            showInLegend: true,
                            slicedOffset: 12, // Didn't find a way to force it to move without making a gap like in design
                            dataLabels: {
                                enabled: false
                            },
                            states: {
                                hover: {
                                    halo: {
                                        opacity: 1,
                                        size: 10
                                    },
                                    brightness: 0
                                },
                                select: {
                                    halo: {
                                        opacity: 1,
                                        size: 10
                                    },
                                    brightness: 0
                                },
                            },
                            borderWidth: 0,
                            point: {
                                events: {
                                    //We will replace it later dynamically with real callbacks.
                                    // That way we could have ref to self.
                                    click(event){
                                    },
                                    mouseOver(event){
                                    },
                                    mouseOut(event){
                                    }
                                }
                            }
                        }
                    },
                    //For some reason without this predefined structure newly loaded data do not apply
                    series: [{
                        data: [
                            {name: "", y: 0, color: "", sliced: true},
                            {name: "", y: 0, color: "", sliced: true},
                            {name: "", y: 0, color: "", sliced: true},
                            {name: "", y: 0, color: "", sliced: true},
                        ]
                    }],
                    exporting: {
                        chartOptions: { // specific options for the exported image
                            chart: {
                                backgroundColor: '#FFFFFF'
                            },
                            legend: {
                                margin: 0,
                            }
                        },
                        fallbackToExportServer : false,
                        buttons: {
                            contextButton: {
                                enabled: false,
                                symbol: "download",
                                y: 44,
                                symbolStrokeWidth: 1, //default: 3
                                menuItems: [
                                    'downloadCSV',
                                    'downloadPDF',
                                    'downloadPNG',
                                    'downloadJPEG',
                                ]
                            },
                        }
                    }

                }
            }
        },
        methods: {
            created(chart) {
                let self = this;
                this.chartOptions.plotOptions.pie.point.events = {
                    click(event){
                        event.preventDefault();

                        // if we click on the already selected, reset to totals
                        if (self.selectedPieChart.id == event.target.point.id){
                            self.$eventHub.$emit('unselected', self.chartName);
                            self.selectedPieChart = {};
                            this.series.circle.element.remove();
                            this.series.customHalo.element.remove();
                            this.series.circle = this.series.chart.renderer.circle(this.shapeArgs.x + chart.plotLeft, this.shapeArgs.y + chart.plotTop, this.shapeArgs.innerR).attr({
                                fill: '#FFFFFF',
                                stroke: 'white',
                                'stroke-width': 6,
                                zIndex: 3
                            });
                            this.series.circle.id = event.target.id;
                            this.series.circle.add();
                            this.series.chart.setTitle({
                                text: self.$t('dashboard.pie_chart.total') + '<br><p style="font-size:' + self.fontSizePercentage + '">' + this.series.total + '</p><br> ' + self.$t('dashboard.pie_chart.intakes'),
                                style: {color: '#0000000', lineHeight:"42px"}
                            });
                            return;
                        }

                        // set selected
                        self.selectedPieChart = {
                            'id': event.target.point.id,
                            'y': event.target.point.y,
                            'percentage': event.target.point.percentage,
                            'color': this.color
                        }
                        
                        // select clickable in table
                        // TODO: bind this two-ways? Clearing the table column filters does not reset donut chart atm
                        self.$eventHub.$emit('selected', self.chartName, event.target.point.id);
                        
                        // set visual elements to the selected pie chart slice
                        this.series.chart.setTitle({
                            text: (isNaN(event.target.point.id) ? event.target.point.id : self.$t(`${self.translationPrefix}${event.target.point.id}`))  + '<br><p style="font-size:' + self.fontSizePercentage + '">' + event.target.point.percentage.toFixed(0) + '</p>%<br>' + event.target.point.y + ' ' + self.$t('dashboard.pie_chart.intakes'),
                            style: {color: '#0000000', lineHeight:"42px"}
                        });

                        if (this.series.circle) {
                            this.series.circle.element.remove();
                        }
                        if (this.series.customHalo) {
                            this.series.customHalo.element.remove();
                        }
                        //Draw a circle in the middle with the same colour
                        this.series.circle = this.series.chart.renderer.circle(this.shapeArgs.x + chart.plotLeft, this.shapeArgs.y + chart.plotTop, this.shapeArgs.innerR).attr({
                            fill: this.color,
                            stroke: 'white',
                            'stroke-width': 6,
                            zIndex: 3
                        });
                        this.series.circle.id = event.target.id;
                        this.series.circle.add();

                        //Draw custom halo, because highcharts doesn't support one for selected state
                        this.series.customHalo = this.series.chart.renderer.arc(
                            this.shapeArgs.x + chart.plotLeft,
                            this.shapeArgs.y + chart.plotTop,
                            this.shapeArgs.r + 11,
                            this.shapeArgs.r - 1,
                            this.shapeArgs.start,
                            this.shapeArgs.end
                        ).attr({
                            fill: this.color,
                            zIndex: 1
                        });
                        this.series.customHalo.id = event.target.id;
                        this.series.customHalo.add();
                    },
                    mouseOver(event){
                        event.preventDefault();
                        // if we're mousing over something which is selected, nothing has to be done
                        if (self.selectedPieChart && self.selectedPieChart.id == event.target.id){
                            return;
                        }
                        
                        // change title to info of the part we're mousing over
                        this.series.chart.setTitle({
                            text: event.target.name + '<br><p style="font-size:' + self.fontSizePercentage + '">' + event.target.percentage.toFixed(0) + '</p>%<br>' + event.target.y + ' ' + self.$t('dashboard.pie_chart.intakes'),
                            style: {color: '#0000000', lineHeight:"42px"}
                        });
                        
                        //Draw a circle in the middle with the same colour
                        this.series.circle = this.series.chart.renderer.circle(this.shapeArgs.x + chart.plotLeft, this.shapeArgs.y + chart.plotTop, this.shapeArgs.innerR).attr({
                            fill: this.color,
                            stroke: 'white',
                            'stroke-width': 6,
                            zIndex: 3
                        });
                        this.series.circle.id = event.target.id;
                        this.series.circle.add();
                    },
                    mouseOut(event){
                        // if we're not mousing out of the selected
                        if (event.target.id == this.series.circle.id && this.series.circle.id !== self.selectedPieChart.id) {
                            if (this.series.circle) {
                                this.series.circle.element.remove();
                            }
                            
                            // if anything is selected, render that circle and text, else total
                            if (self.selectedPieChart.id || self.selectedPieChart.id == 0){
                                //Draw a circle in the middle with the same colour
                                this.series.circle = this.series.chart.renderer.circle(this.shapeArgs.x + chart.plotLeft, this.shapeArgs.y + chart.plotTop, this.shapeArgs.innerR).attr({
                                    fill: self.selectedPieChart.color,
                                    stroke: 'white',
                                    'stroke-width': 6,
                                    zIndex: 3
                                });
                                this.series.circle.id = event.target.id;
                                this.series.circle.add();
                                this.series.chart.setTitle({
                                    text: (isNaN(self.selectedPieChart.id) ? self.selectedPieChart.id : self.$t(`${self.translationPrefix}${self.selectedPieChart.id}`)) + '<br><p style="font-size:' + self.fontSizePercentage + '">' + self.selectedPieChart.percentage.toFixed(0) + '</p>%<br>' + self.selectedPieChart.y + ' ' + self.$t('dashboard.pie_chart.intakes'),
                                    style: {color: '#0000000', lineHeight:"42px"}
                                });
                            } else {
                                this.series.chart.setTitle({
                                    text: self.$t('dashboard.pie_chart.total') + '<br><p style="font-size:' + self.fontSizePercentage + '">' + this.series.total + '</p><br> ' + self.$t('dashboard.pie_chart.intakes'),
                                    style: {color: '#0000000', lineHeight:"42px"}
                                });
                            }
                        }
                    }
                }
            },
            unselectAll(chartName) {
                if (chartName != this.chartName) {
                    let selectedPoints = this.$children[0].chart.getSelectedPoints();
                    if (selectedPoints.length) {
                        selectedPoints.forEach((point, index) => point.select());
                    }
                }
            }
        },
        computed: {
            locale: {
                get: function () {
                    return this.$store.state.userOptions.options.locale
                },
            },
        },
        //Force update when prop changes
        watch: {
            chartData(newValue) {
                let filteredData = {data: []}
                filteredData.data = cloneDeep(newValue.data)

                for (const key in filteredData.data) {
                  filteredData.data[key].name = this.$t(this.translationPrefix+filteredData.data[key].name)
                }
                Vue.set(this.chartOptions, 'series', filteredData);
                if ('data' in newValue && newValue.data.length > 4){
                    Vue.set(this.chartOptions, 'title', {y: 50})
                }
            },
            loading(newValue) {
                if (newValue) {
                    this.$children[0].chart.showLoading();
                } else {
                    this.$children[0].chart.hideLoading();
                }
            },
            locale(newValue){
                let result = {data: []} //prevents Vuex complaining about mutating state on the fly
                for (const key in this.chartData.data) {
                    result.data[key] = cloneDeep(this.chartData.data[key]);
                    result.data[key].name = this.$t(this.translationPrefix+this.chartData.data[key].name)
                }
                Vue.set(this.chartOptions, 'series', result);
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.$children[0].chart.showLoading();
                this.$eventHub.$on('selected', this.unselectAll);
            });
        },
    }
</script>

<style>
  .donut-center {
    width: 100%;
    height: 100%;
  }

  .highcharts-title {
      font-family: 'Raleway', sans-serif;
  }

</style>