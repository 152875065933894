<template>
    <div class="view-pie-chart" style="width: 100%">
    </div>  
</template>

<script type="text/javascript">
    import Highcharts from 'highcharts';
    import exportingInit from 'highcharts/modules/exporting'
    import offlineExportingInit from 'highcharts/modules/offline-exporting'
    import exportDataInit from "highcharts/modules/export-data";
    import Vue from 'vue'

    import { debounce } from "lodash";

    exportingInit(Highcharts)
    offlineExportingInit(Highcharts)
    exportDataInit(Highcharts);

    import {icon} from '@/downloadIcon'
    Highcharts.SVGRenderer.prototype.symbols.download = icon.iconPath

    export default {
        props: ['data', 'loadingState', 'name', 'colors', 'width', 'legendWidth', 'half', 'summary', 'labelFormatter', 'tableActive', 'exportButton'],

        data() {
            return {
                highChart: null
            }
        },

        mounted() {
            this.$nextTick(() => {
                this.renderChart();
                this.setChartData();
            });
        },

        methods: {
            getTableActive(){
                return this.tableActive;
            },
            renderChart() {
                let self = this;
                let chartOptions = {
                    chart: {
                        height: this.$props.summary ? "120px" : "230px",
                        backgroundColor: 'rgba(255, 255, 255, 0.0)',
                        renderTo: self.$el,
                        plotBackgroundColor: 'rgba(255, 255, 255, 0.0)',
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'pie',
                        marginLeft: 0,
                        spacingLeft: 0
                    },
                    credits: {
                        enabled: false
                    },
                    title: {
                        text: ''
                    },
                    subtitle: {
                        text: ''
                    },
                    xAxis: {
                        title: {
                            text: ''
                        },
                    },
                    yAxis: {
                        title: {
                            text: ''
                        },
                        min: 0,
                    },
                    legend: {
                        enabled: !this.getTableActive(),
                        verticalAlign: 'middle',
                        align: 'right',
                        x: 0,
                        layout: 'vertical',
                        labelFormatter: function() {
                            return self.labelFormatter(this.name);
                        }
                    },
                    tooltip: {
                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: false,
                            },
                            showInLegend: true,
                            states: {
                                select: {
                                    borderWidth: 2,
                                    lineWidth: 2,
                                    enabled: true,
                                    halo: {
                                        opacity: 0.3,
                                        size: 10
                                    },
                                    color: '#eee'
                                }
                            },
                            borderWidth: 0,
                            events: {
                                click: function(event){
                                    event.preventDefault();
                                    if (event.shiftKey){
                                        self.$emit('addOrFilter', event.point.name, self.summary ? 'summary' : null);
                                        return;
                                    }
                                    if (self.summary){
                                        self.$emit('addFilter', event.point.name, 'summary');
                                        return;
                                    }
                                    self.$emit('addFilter', event.point.name);
                                }
                            },
                            point: {
                                events: {
                                    legendItemClick: function(event) {
                                        event.preventDefault();
                                        let name = {"point": {"name": event.target.name}};
                                        self.$emit('addFilter', name, self, 'summary');
                                    }
                                }
                            }
                        }
                    },
                    series: [],
                    exporting: {
                        chartOptions: { // specific options for the exported image
                            chart: {
                                backgroundColor: '#FFFFFF'
                            },
                            legend: {
                                enabled: true,
                                x: -20,
                                labelFormatter: function() {
                                    return this.name + ' (' + this.groupName + '): ' + this.y;
                                }
                            },
                        },
                        fallbackToExportServer : false,
                        buttons: {
                            contextButton: {
                                enabled: this.exportButton,
                                symbol: "download",
                                symbolStrokeWidth: 1, //default: 3
                                menuItems: [
                                    'downloadCSV',
                                    'downloadPDF',
                                    'downloadPNG',
                                    'downloadJPEG',
                                ]
                            }
                        }
                    }
                };

                if (self.colors && self.colors.length) {
                    chartOptions.colors = self.colors;
                }

                if (self.half) {
                    chartOptions.plotOptions.pie.startAngle = -90;
                    chartOptions.plotOptions.pie.endAngle = 90;
                    chartOptions.plotOptions.pie.center = ['50%', '75%'];
                    chartOptions.plotOptions.pie.innerSize = '50%';
                }

                self.highChart = new Highcharts.Chart(chartOptions);
            },

            removeChartData() {
                if (this.highChart.series) {
                    for (let i = this.highChart.series.length - 1; i >= 0; i--) {
                        this.highChart.series[i].remove();
                    }
                }
            },

            setChartData() {
                this.highChart.addSeries({
                    name: this.name,
                    colorByPoint: true,
                    data: this.data
                });
            }
        },

        watch: {
            /**
             * Watch chart data to trigger set series and redraw the chart if it hasn't been drawed yet
             */
            data() {
                Vue.set(this, 'tableActive', this.tableActive);
                if (this.loadingState) return;
                let self = this;
                self.removeChartData();
                self.setChartData();
                debounce(() => {
                    self.highChart.redraw();
                }, 200)();
            },

            /**
             * Check if data is in loading process to show/hide loading message on chart
             */
            loadingState(loading) {
                if (loading) {
                    this.highChart.showLoading();
                } else {
                    this.highChart.hideLoading();
                }
            }
        },
        beforeDestroy: function() {
            this.highChart.destroy();
        }
    };

</script>
