<template>
  <div>
    <lang-selector style="width:70px;float:right;list-style: none; margin: 1em"/>
    <div class="container">
      <div class="row">
        <div class="col-md-4 col-md-offset-4">
          <img src="/img/medify.png" style="width:70%;margin:25% 15% 15% 15%; filter: invert(100%)">
          <h4 class="text-center text-info text-uppercase" style="color: lightgrey;">
            {{ $t('editing_table.auth.pincode_page') }}<br>
            {{ $t('editing_table.auth.use_only_for_hospital_intakes') }}
          </h4>
          <div class="login-panel panel panel-default" style="margin-top: 5%;">
            <div class="panel-heading">
              <h3 class="panel-title">{{ $t('editing_table.auth.sign_in_title') }}</h3>
            </div>
            <div class="panel-body">
              <div class="alert alert-info" role="alert">
                {{ $t('editing_table.auth.info') }}
              </div>
              <universal-form :fields="['email','pin']" module="auth" @input="model=$event" :errors="errors">
                <template v-slot:submitButton="{ submit }">
                  <input @click="doLogin(model)" type="submit" :value="$t('editing_table.auth.login_btn')" class="btn btn-lg btn-success btn-block">
                </template>
                <template v-slot:email_input="{model, errors}">
                  <div class="input-group" v-show="lastEmail">
                    <input disabled type="text" class="form-control" :value="lastEmail">
                    <span class="input-group-btn">
                      <button class="btn btn-default" type="button" @click.prevent="clearEmail"><font-awesome-icon icon="sync"/></button>
                    </span>
                  </div>
                  <input v-show="!lastEmail" type="text" class="form-control" v-model="model.email" :class="{ 'has-errors': errors['email']}">
                </template>
              </universal-form>
              <img src="/img/ce-logo.png" style="width: 25px; opacity: 60%; display: block; margin: 10px auto;">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import universalForm from '@/components/universalForm'
import LangSelector from '@/layout/langSelector'
import Cookies from 'js-cookie'
import Vue from 'vue'
export default {
  components: { LangSelector, universalForm },
  data() {
    return {
      'model': {
        email: Cookies.get('last_email')
      },
      'errors': {},
      'lastEmail': Cookies.get('last_email'),

    }
  },
  methods: {
    ...mapActions('auth', [
        'pinLogin', 'checkAuth'
    ]),
    doLogin(form) {
      let self = this
      this.pinLogin({pin: form.pin, email: (form.email && form.email.length) ? form.email : this.lastEmail}).then(function() {
        self.checkAuth()
      }).catch(({response}) => {
        if (response.data && response.data.errors) {
          this.errors = response.data.errors
        }
      })
    },
    clearEmail () {
      this.lastEmail = ''
      Vue.set(this.model,'email', '')
    }
  },
  created() {
    document.body.classList.add('pin-login-bg')
  },
  destroyed() {
    document.body.classList.remove('pin-login-bg')
  }
}
</script>

<style>
.pin-login-bg {
  background-color: #005375;
}
</style>
