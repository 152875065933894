/*
*   Helper file for customReports.js. Contains functions which calculate statistics/
*   metrics needed for the dashboard.
*/ 

// TODO: Efficiency: handle
// TODO: remove setting in handle
import Vue from 'vue'
/**
 * This function creates and applies a filter on the dataset given as parameter
 * 
 * @param {Object} data - Data concerning what should be filtered
 * @param {Object} submittedQuestionnairesGroupedByHash - The original submitted questionnaires, by hash
 * @param {Array} backupForms - The forms in the selection before filtering
 * 
 * @returns {Object} filter - The set filter
 * @returns {Array} submittedQuestionnaires - The questionnaires left after filtering
 * @returns {Object} newSubmit - The new submitted questionnaires, grouped by hash
 */
export const addFilter = function(filter, submittedQuestionnairesGroupedByHash, backupForms, hashesGroupId){
    // getting what we need to check, either the category as number, the category as String or the answer to the question
    const name = getName(filter);

    // questionnaireId and uniqueId of the question in case we filter not based on summary
    const {questionnaireId, uniqueId} = filter;
    // filter the hashes in the current selection to only include the ones we want, either through summary or question in report
    const wantedHashes = filter.type === 'summary' ? summaryHashes(hashesGroupId, name, submittedQuestionnairesGroupedByHash) : hashes(submittedQuestionnairesGroupedByHash, name, questionnaireId, uniqueId, filter.type);
    // if filtering somehow removes all data or does nothing, don't filter
    if (wantedHashes.length === 0 || (Object.keys(submittedQuestionnairesGroupedByHash).length === wantedHashes.length)){
        return;
    }

    // creating new submit by copying the content of the wanted hashes to a new Object
    const newSubmit = {};
    wantedHashes.forEach((hash) => {
        newSubmit[hash] = submittedQuestionnairesGroupedByHash[hash];
    })
    
    // next up, the submitted questionnaires, let's filter the previous selection based on the hashes that should be kept
    const submittedQuestionnaires = backupForms.filter((baf) => {
        return wantedHashes.includes(baf.hash)
    })

    // and done! We return the filter, questionnaires left and the filtered SQGBH
    return {
        submittedQuestionnaires: submittedQuestionnaires,
        newSubmit: newSubmit
    };
}

/**
 * This function finds all hashes which fit with a filter set on the summary
 * 
 * @param {Object} hashesGroupId - Hashes and result in the current category division
 * @param {Number} name - category that we are looking for
 * 
 * @returns resultArray with all hashes that fit the filter
 */
function summaryHashes(hashesGroupId, category, submittedQuestionnairesGroupedByHash){
    // TODO: comment this function
    const hashesFilteredPopulation = Object.keys(submittedQuestionnairesGroupedByHash);
    const hashesInCurrentPopulation = Object.keys(hashesGroupId).filter(hash => hashesFilteredPopulation.includes(hash));
    return hashesInCurrentPopulation.filter(hash => hashesGroupId[hash] === category);
}

/**
 * This function is called when a non-summary filter is added, it returns the hashes still in the population
 * after filtering
 * 
 * TODO: efficiency
 * TODO: IDK maybe not ship four strings seperately and maybe put it in an Object? Check out tomorrow
 * 
 * @param {Object} submittedQuestionnairesGroupedByHash 
 * @param {String} name 
 * @param {String} questionnaireId 
 * @param {String} uniqueId 
 * @param {String} type 
 * 
 * @returns {Arrray} resultArray - hashes which are still in the population after filtering
 */
function hashes(submittedQuestionnairesGroupedByHash, name, questionnaireId, uniqueId, type){
    const resultArray = [];
    for (const hash in submittedQuestionnairesGroupedByHash){

        // if they haven't filled out the questionnaire currently being filtered, skip
        if (!submittedQuestionnairesGroupedByHash[hash][questionnaireId]){
            continue;
        }
        const questions = submittedQuestionnairesGroupedByHash[hash][questionnaireId].orderedQuestions;

        // find question we're filtering
        const answerArray = questions.filter(({uniqueId: uId}) => uId === uniqueId);

        if (!answerArray){
            continue;
        }

        // find all answers which fit the current filter
        const correct = answerArray.filter((a) => {
            return a[type] === name;
        })

        // if at least one answer was given which fits filter set, the hash is a patient we want
        if (correct.length){
            resultArray.push(hash);
        }
    }
    return resultArray;
}


/**
 * 
 * @param {*} filters 
 * @param {*} submittedQuestionnairesGroupedByHash 
 * @param {*} backupForms 
 */
export const addOrFilter = function(filters, submittedQuestionnairesGroupedByHash, backupForms, hashesGroupId){
    // TODO: rename vars
    // TODO: make this work for categories
    const resultHashes = [];
    filters.forEach(function(filter){
        const name = getName(filter);
        const result = hashes(submittedQuestionnairesGroupedByHash, name, filter.questionnaireId, filter.uniqueId, filter.type);
        resultHashes.push(result);
    })

    const wantedHashes = Array.from(new Set(resultHashes.flat()));

    // TODO: this part is the same as addFilter, merge
    const newSubmit = {};
    wantedHashes.forEach((hash) => {
        newSubmit[hash] = submittedQuestionnairesGroupedByHash[hash];
    })
    
    // next up, the submitted questionnaires, let's filter the previous selection based on the hashes that should be kept
    const submittedQuestionnaires = backupForms.filter((baf) => {
        return wantedHashes.includes(baf.hash)
    })

    // and done! We return the filter, questionnaires left and the filtered SQGBH
    return {
        submittedQuestionnaires: submittedQuestionnaires,
        newSubmit: newSubmit
    };
}

/**
 * 
 * @param {*} filter 
 */
export const getName = function(filter){
    // check (based on type) how to translate the answer to somnething usable for filtering
    switch (filter.type){
        case 'groupId':
            return translate(filter.answer);
        case 'summary':
            return Number(translate(filter.answer));
        default:
            return filter.answer;
    }
}


/**
 * This function creates a new overview
 * 
 * @param {Object} state - The state of the store when created
 * @param {String} label - The title chosen by the user in the prompt
 * 
 * @returns {Object} newOverview - New standard overview
 */
export const createOverview = function(state, dep, label){
    const selectable = JSON.parse(JSON.stringify(state.selectableQuestionnairesQuestions));
    const newOverview = {
        label: label,
        id: Math.random(),
        categoryDivision: {},
        selected: true,
        filters: [],
        selectedQuestionairesQuestions: selectable,
        submittedQuestionnaires: state.initialSubmittedQuestionnaires,
        submittedQuestionnairesByHash: state.initialHandledHashes,
        questionnaires: state.initialHandledQuestionnaires,
        department: dep
    }

    return newOverview;
}

/**
 * TODO: JSDOC
 * @param {*} data 
 */
export const conversionFilter = function(data){
    data.data.submittedQuestionnaires = data.data.submittedQuestionnaires.filter(sq => data.conversionHashes.includes(sq.hash));
    return data;
}

/**
 * This function gets the number of patients in the population (every patient which has filled in at least
 * one question of one active questionnaire)
 * 
 * @param {Array} activeQuestionnaires - Array of Strings of the activeQuestionnaires
 * @param {Object} initialPopulation - Object of hashes (keys) and filled in questionnaire of that hash (values)
 * 
 * @returns {Number} - Amount of unique patients which meet in-population-criteria
 */
export const getActivePopulation = function(activeQuestionnaires, initialPopulation){
    // find all patients who have filled in at least one active questionnaire, returns length
    return Object.keys(initialPopulation).filter(patient => Object.keys(initialPopulation[patient]).filter(key => activeQuestionnaires.includes(key).length)).length;
}

/**
 * This function finds all questionnaires which have at least a single question active in the overview
 * @param {Object} questionnaires - Selectedness Objects of questionnaires
 * 
 * @returns {Array} - Array of Strings being the formIds of questionnaires which have at least
 * one question active in the overview
 */
export const getActiveQuestionnaires = function(questionnaires){
    const activeQuestionnaires = [];
    const selection = questionnaires;
    for (const questionnaire in selection){
        for (const question in selection[questionnaire]){
            if (selection[questionnaire][question].selected){
                activeQuestionnaires.push(questionnaire);
                break;
            }
        }
    }
    return activeQuestionnaires
}

/**
 * Calculates the category division of a report. A patient is given the worst score it ever reaches in
 * a question currently selected in the report. Worst is ranked from "Red" -> "Orange" -> "Green" -> "Grey"
 * 
 * @param {Object} data - Object containing overview, questionnaireIdsQuestionIds & questionnaires
 * 
 *                    overview - Object of the overview of which we want to calculate the category div
 * questionnaireIdsQuestionIds - Object with questionnaires and the calculated data per question
 *              questionnaires - Object containing entire questionnaires
 * 
 * @returns {Array} categoryDivision - Contains the data per color for the pie chart
 */
export const getCategoriesDivision = function(data){
    // ported from the old reportsDataHandler
    const questionnaireIdsQuestionIds = data.questionnaireIdsQuestionIds;
    const questionnaires = data.questionnaires;
    const hashesGroupId = {};

    // for every questionnaire possible
    Object.keys(questionnaireIdsQuestionIds).forEach(questionnaireId => {
        const questionIds = questionnaireIdsQuestionIds[questionnaireId];

        // backup check if questionnaire exists
        if (typeof questionnaires[questionnaireId] == 'undefined'){
            return;
        }

        // for each questionAnswerPair in that questionnaire
        questionnaires[questionnaireId].questionAnswerPairs.forEach(questionAnswerPair => {

            // another backup check
            if (!questionIds[questionAnswerPair._id] || !questionIds[questionAnswerPair._id].selected) return;

            // found questionnaire, question(AnswerPair), now see what patients answered
            // pushes patients toward the 'worst' score they have for any question answered
            // TODO: IDK man maybe Math.max check that out
            Object.keys(questionAnswerPair._groupsHashes).forEach(groupId => {
                if (groupId.indexOf('_') === 0) return;
                Object.keys(questionAnswerPair._groupsHashes[groupId]).forEach(hash => {
                    hashesGroupId[hash] = hashesGroupId[hash] || Number(groupId);
                    if (groupId === '1') hashesGroupId[hash] = 1;
                    if (groupId === '2' && hashesGroupId[hash] !== 1) hashesGroupId[hash] = 2;
                    if (groupId === '3' && hashesGroupId[hash] !== 1 && hashesGroupId[hash] !== 2) hashesGroupId[hash] = 3
                });
            });
        });
    });

    // TODO: name should be translated
    const resultArray = [
        {"groupId": "0", "name": "Overig", "color": "#f0f0f0", "borderColor": "#cccccc", "y": 0},  //White
        {"groupId": "1", "name": "Kritiek", "color": "#ff9999", "borderColor": "#ff0000", "y": 0},  //Red
        {"groupId": "2", "name": "Afwijkend", "color": "#ffcc99", "borderColor": "#ff8000", "y": 0}, //Orange
        {"groupId": "3", "name": "Normaal", "color": "#8fcc8f", "borderColor": "#20c020", "y": 0}  //Green
    ];

    // transforming previous data to totals
    Object.keys(hashesGroupId).forEach(hash => {
        if (!isNaN(Number(hashesGroupId[hash])))
            resultArray[Number(hashesGroupId[hash])].y++;
    });
    return {
        resultArray: resultArray,
        hashesGroupId: hashesGroupId
    };
}

/**
 * Function which returns possible selectable questions, all set to not selected unless specified in the preset
 * 
 * @param {Object} preset - If certain questions are standardly active for a questionnaire, preset contains them 
 * @param {Object} questionnaires - The 'handled' filled in questionnaires
 * 
 * @returns {Object} selectable - Object with every single question set to a bar chart in the answers, a pie chart
 *                                for categories and no tables
 */
export const getSelectableQuestionnairesQuestions = function({preset} = {}, questionnaires){
    // set all questions to their required selected state and standard chart + table
    // standard configuration
    const standard = {
        selected: preset && preset[questionnaireId] || false,
        questionViewInfo: {
            chart: 'column',
            table: true
        },
        categoryViewInfo: {
            chart: 'pie',
            table: true
        }
    };

    // TODO: could be better/more one-linery
    const selectable = {};
    for (const questionnaire in questionnaires){
        if (preset && !preset[questionnaire]) return;
        selectable[questionnaire] = {};
        questionnaires[questionnaire].questionAnswerPairs.forEach((_, index) => {
            selectable[questionnaire][index] = Object.assign({}, standard);
        })
    }
    return selectable;
}

/**
 * This function adds all answers and categories to the questions of each questionnaire
 * 
 * @param {Object} data - Contains questionnaires, submittedQuestionnaires & setter
 * 
 *          questionnaires - Object with the reset questionnaires, as given by resetQuestionnaires
 * submittedQuestionnaires - Array of the submittedQuestionnaires in current (filtered) population
 *                  setter - function to help Vue.set the answers so that they are reactive
 * @returns {Object} submittedQuestionnairesGroupedByHash - 
 * @returns {Object} questionnaires - Questionnaires where the total results of answers
 *                                    and categories have been added
 */
function setAnswers(data){
    const submittedQuestionnairesGroupedByHash = {};

    const {questionnaires} = data;

    // for each submittedQuestionnaire, get questionnaire
    data.submittedQuestionnaires.forEach(submittedQuestionnaire => {

        const questionnaire = questionnaires[submittedQuestionnaire.form_id];
        
        if (!questionnaire){
            // return;
            return console.error(`No questionnaire found for ${submittedQuestionnaire.form_id}`)
        }

        // if patient does not already exist, add as empty object
        if (!submittedQuestionnairesGroupedByHash[submittedQuestionnaire.hash]){
            submittedQuestionnairesGroupedByHash[submittedQuestionnaire.hash] = {};
        }

        // if submitted questionnaire has (correct) data
        if (submittedQuestionnaire.data && typeof submittedQuestionnaire.data === 'object' && Object.keys(submittedQuestionnaire.data).length){
            submittedQuestionnaire.orderedQuestions.forEach(({answer, type, uniqueId}) => {
                // this way with the uniqueId should be the standard, however as of now uniqueIds are added in JS
                const questionAnswerPair = questionnaire.questionAnswerPairs.find(questionAnswerPair =>  questionAnswerPair.uniqueId === uniqueId);

                if (!questionAnswerPair){
                    // TODO: more descriptiveness
                    return console.error('No question found!');
                }
                
                let result = questionAnswerPair._answers[answer] ? questionAnswerPair._answers[answer] + 1 : 1;

                // changing order of answer (answer) and result (hash) if textArea
                if (type === "textArea") {
                    const temp = answer;
                    answer = submittedQuestionnaire.hash;
                    result = temp;
                }

                if (type === "scoring") {
                    // TODO: make this more efficient

                    const options = questionAnswerPair.input.options;

                    const correctOption = options.find((o) => {
                        const numbers = o.answerPossibilities[0].split('-');
                        return Number(answer) > Number(numbers[0]) && Number(answer) < Number(numbers[1]);
                    })

                    if (!correctOption){
                        return false;
                    }
                    
                    answer = correctOption.answerPossibilities[0];
                    result = questionAnswerPair._answers[answer] ? questionAnswerPair._answers[answer] + 1 : 1;

                    Vue.set(questionAnswerPair._answers, answer, result);
                    Vue.set(questionAnswerPair._groupsHashes[correctOption.groupId], submittedQuestionnaire.hash, 1);
                    return true;
                }

                // TODO: we can't (well we can be Vuex doesn't like it) set stuff here, this should be moved
                // to a mutation
                Vue.set(questionAnswerPair._answers, answer, result);
                if (questionAnswerPair.input && questionAnswerPair.input.options && questionAnswerPair.input.options.length){
                    questionAnswerPair.input.options.find(({answer: optionAnswer, groupId}) => {
                        if (answer !== optionAnswer) return false;
                        // TODO change this to array instead of setting and stuff 
                        Vue.set(questionAnswerPair._groupsHashes[groupId], submittedQuestionnaire.hash, 1);
                        return true;
                    });
                }
            });
        }
        submittedQuestionnairesGroupedByHash[submittedQuestionnaire.hash][submittedQuestionnaire.form_id] = submittedQuestionnaire;
    });
    return {
        submittedQuestionnairesGroupedByHash: submittedQuestionnairesGroupedByHash,
        questionnaires: questionnaires
    }
}

/**
 * This functions adds uniqueIds to the questionaires and submittedQuestionnaires' questions
 * 
 * @param {Object} data - formIds Array not used
 *                      - questionnaires 
 * 
 *                      - submittedQuestionnaires Array of submitted questionnaires
 * 
 * @returns {Object}    - as was passed in, but with `uniqueId`s
 */
export const preprocess = function(data){
    const questionnaires = $.extend(true, [], data.data.questionnaires);
    const submittedQuestionnaires = $.extend(true, [], data.data.submittedQuestionnaires);

    const allFilters = [];
    if (data.overviews && data.overviews.length){
        data.overviews.forEach((overview) => {
            overview.filters && overview.filters.forEach((filter) => allFilters.push(filter));
        })
    }

    // orders questions. However, they are not really ordered because there's no way of ordering them,
    // however the orderedQuestions Array can be sorted if the data ever contains something like that
    submittedQuestionnaires.forEach((submittedQuestionnaire) => {
        submittedQuestionnaire.orderedQuestions = [];
        if (!submittedQuestionnaire.data){
            return;
        }
        // TODO: flatten uitzoeken 
        // submittedQuestionnaire.orderedQuestions.push(...group)
        Object.values(submittedQuestionnaire.data.questions).forEach((group) => {
            group.forEach((question) => {
                submittedQuestionnaire.orderedQuestions.push(question);
            })
        })
    })

    const generatedUniqueIds = [];
    // setting uniqueId if not there, storing those set so there's no duplicates (even though change of duplicate is astronomically low,
    // better safe then sorry)
    questionnaires.forEach((questionnaire) => {questionnaire.questionAnswerPairs.forEach((questionAnswerPair, index) => {
        if (!questionAnswerPair.uniqueId){
            // check if previously filtered, if that is the case then grab the uniqueId autogenned assigned back then
            const filterAssociated = allFilters.find((filter) => {
                return (filter.questionnaireId === questionnaire.id && filter.questionId === index);
            })
            
            if (filterAssociated) {questionAnswerPair.uniqueId = filterAssociated.uniqueId}

            else {
                let uniqueId = String(Math.random()) + "auto_gen";
                while (generatedUniqueIds.includes(uniqueId)){
                    uniqueId = String(Math.random()) + "auto_gen";
                }
                generatedUniqueIds.push(uniqueId);
                questionAnswerPair.uniqueId = uniqueId;
            }
        }
    })});

    // wow this should be better but eerst werkend dan mooi
    // TODO: this should .find() instead of looping over both
    // TODO: this (preprocess) now only works for PoliDirect (new?) data, check if all data is like this now

    const regexReplacements = [
        {regex: /(^\d+. )/g, replacement: ''}, // Question numbers without section ids
        {regex: /(^s\d+:\d+. )/g, replacement: ''}, //Section and question numbers
        {regex: /(r \/_<br\/>)|(<br\/><br\/>)/g, replacement: '\n'}, // Linebreaks
        {regex: /_a href=/g, replacement: '<a href='}, // <a element opening start
        {regex: /__/g, replacement: '">'}, // <a element opening end
        // changed this because questions were wrongly not showing up in reports
        {regex: /_/g, replacement: "'"}, // replace underscores with double quotes
        {regex: /(<a href=.*>)(.*)(<\/a>)/, replacement: ''} //TODO: support hyperlinks!
    ];

    submittedQuestionnaires.forEach((submittedQuestionnaire) => {
        submittedQuestionnaire.orderedQuestions.forEach((question) => {

            questionnaires.forEach((questionnaire) => {
                questionnaire.questionAnswerPairs.forEach((questionAnswerPair) => {

                    if (questionAnswerPair.input.type === 'radio'){
                        // todo: regex instead of substring
                        let modifiedpseudoQuestion = question.question.substring(6).trim();
                        regexReplacements.forEach(({regex, replacement}) => {
                            modifiedpseudoQuestion = modifiedpseudoQuestion.replace(regex, replacement);
                        });

                        const fixedQuestion = questionAnswerPair.question.replace(/<br ?\/><br\/>/g, '\n').replace(/(<a href=.*>)(.*)(<\/a>)/g, '').trim();

                        if (fixedQuestion === modifiedpseudoQuestion && questionnaire.id === submittedQuestionnaire.form_id){
                            if (!question['uniqueId'])
                                question['uniqueId'] = questionAnswerPair.uniqueId;
                        }
                    } else if (questionAnswerPair.input.type === 'textArea' || questionAnswerPair.input.type === 'scoring'){
                        const fixedQuestion = questionAnswerPair.question.replace(/<br ?\/><br\/>/g, '\n').replace(/(<a href=.*>)(.*)(<\/a>)/g, '').trim();
                        const questionio = question.question.replace(/s\d.\d../, "").trim();
                        

                        if (questionio === fixedQuestion && questionnaire.id === submittedQuestionnaire.form_id){
                            if (!question['uniqueId'])
                                question['uniqueId'] = questionAnswerPair.uniqueId;
                        }
                    } else if (questionAnswerPair.input.type === 'checkbox'){
                        // TODO: regex instead of substring dude come on
                        let modifiedpseudoQuestion = question.question.substring(6).trim();
                        regexReplacements.forEach(({regex, replacement}) => {
                            modifiedpseudoQuestion = modifiedpseudoQuestion.replace(regex, replacement);
                        });

                        const fixedQuestion = questionAnswerPair.question.replace(/<br ?\/><br\/>/g, '\n').replace(/(<a href=.*>)(.*)(<\/a>)/g, '').trim();

                        if (modifiedpseudoQuestion === fixedQuestion && questionnaire.id === submittedQuestionnaire.form_id){
                            if (!question['uniqueId'])
                                question['uniqueId'] = questionAnswerPair.uniqueId;
                        }
                    }
                })
            })
        })
    })

    return {
        formIds: data.data.formIds,
        questionnaires: questionnaires,
        submittedQuestionnaires: submittedQuestionnaires
    };
}

/**
 * This function refilters based on filters which are left after deleting one.
 * 
 * TODO: this should be done more efficient than the entire idea of refiltering as this but hey
 * 
 * @param {Array} filters - Contains all filters remaining
 * @param {Object} submittedQuestionnairesGroupedByHash - Initial SQGBH
 * @param {Array} backupForms - Initial submitted forms
 * 
 * @returns {Object} - submittedQuestionnaires: {Array} containing submitted forms after all filters
 *                   - newSubmit: {Object} new submitted questionnaires grouped by hash
 */
export const refilter = function(filters, submittedQuestionnairesGroupedByHash, backupForms, hashesGroupId){
    let filteredData;

    // TODO: let's not set patientsLeft in this helperfile but instead in the store    
    filters.forEach(function(filter){
        if (filter.length){
            filteredData = addOrFilter(filter, filteredData ? filteredData.newSubmit : submittedQuestionnairesGroupedByHash, filteredData ? filteredData.submittedQuestionnaires : backupForms, hashesGroupId);
        } else {
            filteredData = addFilter(filter, filteredData ? filteredData.newSubmit : submittedQuestionnairesGroupedByHash, filteredData ? filteredData.submittedQuestionnaires : backupForms, hashesGroupId);
        }
        Vue.set(filter, 'patientsLeft', Object.keys(filteredData.newSubmit).length);
    })

    return {
        submittedQuestionnaires: filteredData.submittedQuestionnaires,
        newSubmit: filteredData.newSubmit
    }
}

/**
 * Resets questionnaires, sets _answers to 0 for each option, 
 * and _groupsHashes to {} for each category
 * 
 * @param {Array} questionnaires - Questionnaires to be reset
 * 
 * @returns {Object} result - Keys are form_ids, values are resetted questionnaires
 */
function resetQuestionnaires(questionnaires){
    // set questionnaires to standard, so empty
    const result = {};

    // for each questionnaire and question(AnswerPair)
    questionnaires.forEach(questionnaire => {
        questionnaire.questionAnswerPairs.forEach((questionAnswerPair, index) => {

            // add id, answers to _answers and _groupsHashes (will be results)
            questionAnswerPair._id = index;
            questionAnswerPair._answers = {};
            questionAnswerPair.input.options.forEach(({answer}) => {
                questionAnswerPair._answers[answer] = 0;
            });
            questionAnswerPair._groupsHashes = {
                0: {},
                1: {},
                2: {},
                3: {}
            };
            questionnaire.groups.forEach(({groupId}) => questionAnswerPair._groupsHashes[groupId] = {});
            questionAnswerPair.question = questionAnswerPair.question.replace(/<br ?\/><br\/>/g, '\n').replace(/(<a href=.*>)(.*)(<\/a>)/g, '');
        });
        result[questionnaire.id] = questionnaire;
    });
    return result;
}

/**
 * This function calls the resetting of questionnaires and the handling afterwards with the new data,
 * since those two things are always done in tandem
 * 
 * @param {Array} resetData - Questionnaires
 * @param {Array} handlingData - Submitted questionnaires
 * 
 * @returns {Object} - {Object} resettedQuestionnaires 
 *                   - {Object} handledData contains SQGBH and filled in (QAP)questionnaires
 */
export const setQuestionnairesAndAnswers = function(resetData, handlingData){
    const resettedQuestionnaires = resetQuestionnaires(resetData);
    const handledData = setAnswers({
        submittedQuestionnaires: handlingData,
        questionnaires: resettedQuestionnaires
    });

    return {
        resettedQuestionnaires: resettedQuestionnaires,
        handledData: handledData
    }
}
/**
 * 
 * @param {String} category -- Category to be translated into number related
 * 
 * @returns {String} -- Number associated with category
 */
function translate(category){
    switch(category){
        case "Normaal":
            return "3";
        case "Afwijkend":
            return "2";
        case "Kritiek":
            return "1";
        default:
            return "0";
    }
}