<template>
    <modal name="previewForm" :width="1200" :height="800" :classes="'v--modal preview-form'"
           @contextmenu.stop.prevent="()=>{}" @closed="closed">
        <div style="width:100%; height: 100%;" class="modal-body clearfix preview-form">
            <button style="margin-bottom: 10px" type="button" class="close" @click="closed">
                <span aria-hidden="true">&times;</span>
            </button>

            <iframe :src="pdfSource" style="width: 100%; height: 95%; border-width: 1px;"/>
        </div>
    </modal>
</template>

<script>
  import {mapState, mapGetters} from 'vuex';
  import axios from 'axios'

    export default {
        data() {
            return {
                pdfSource: "data:text/html;charset=utf-8,%3Chtml%3E%3Cbody%3ELoading PDF...%3C/body%3E%3C/html%3E"
            }
        },
        computed: {
            ...mapState({
                formPreview: state => state.consentModal.formPreview,
                selectedRecord: state => state.consentModal.selectedRecord,
                reportSectionId: state => state.consentModal.reportSectionId
            })
        },
        methods: {
            closed() {
                this.$store.dispatch('consentModal/toggleFormPreview', {form_id: ''});
            },
            getPreviewFormSrc(){
                this.pdfSource = "data:text/html;charset=utf-8,%3Chtml%3E%3Cbody%3ELoading PDF...%3C/body%3E%3C/html%3E";
                const form_id = this.$store.getters['consentModal/getFormPreview'];
                const reportSectionId = this.reportSectionId;
                if(typeof form_id !== 'number') return;
                const {orgSymbol: org, department} = this.$store.getters['consentModal/selectedRecord'];
                const url = 'api/ic/form/preview/' + org + '/' + form_id + '?departmentSymbol=' + department + (reportSectionId ? '&reportSectionId=' + reportSectionId : '');                
                axios.get(url).then((result) => {
                    if (result.data && result.data.success) this.pdfSource = "data:application/pdf;base64," + result.data.base64;
                    else this.pdfSource ="data:text/html;charset=utf-8,%3Chtml%3E%3Cbody%3EFailure fetching PDF, please contact Medify support (support@medify.com)%3C/body%3E%3C/html%3E"
                }).catch((response) => {
                    console.log('Couldnt load preview', response)
                })
            }
        },
        watch: {
            formPreview: function (val) {
                if (val) {
                    this.getPreviewFormSrc();
                    this.$modal.show('previewForm');
                } else {
                    this.$modal.hide('previewForm');
                }
            }
        },
    }
</script>
