<template>
    <highcharts class="chart" v-show="chartData" :options="chartOptions" :callback="created"></highcharts>
</template>


<script type="text/javascript">
    import Highcharts from 'highcharts';
    import exportingInit from 'highcharts/modules/exporting'
    import offlineExportingInit from 'highcharts/modules/offline-exporting'
    import exportDataInit from "highcharts/modules/export-data";
    import cloneDeep from 'lodash.clonedeep'

    exportingInit(Highcharts)
    offlineExportingInit(Highcharts)
    exportDataInit(Highcharts);

    import {icon} from '@/downloadIcon.js'
    import Vue from 'vue'
    Highcharts.SVGRenderer.prototype.symbols.download = icon.iconPath
    import {mapState} from 'vuex';

    export default {
        props: ['chartData', 'chartName', 'loading', 'axisTitles'],
        data() {
            return {
                chartOptions: {
                    chartType: 'bar',
                    colorInputIsSupported: null,
                    chart: {
                        type: 'bar',
                        backgroundColor: 'none',
                    },
                    credits: {
                        enabled: false
                    },
                    title: {
                        text: ''
                    },
                    subtitle: {
                        text: ''
                    },
                    xAxis: {
                        title: {
                            text: this.axisTitles ? this.axisTitles.x : ""
                        },
                        type:"category",
                        labels: {
                            formatter: v => v.value.length > 15 ? v.value.slice(0,15) + '...' : v.value,
                        }
                    },
                    yAxis: {
                        title: {
                            text: this.axisTitles ? this.axisTitles.y : ""
                        },
                        reversedStacks: false,
                        min: 0,
                        stackLabels: {
                            enabled: true,
                            defer: true,
                            style: {
                                fontWeight: 'bold',
                                color: 'gray'
                            }
                        },
                    },
                    legend: {
                        enabled: false
                    },
                    tooltip: {
                        pointFormat: '<span style="color:{point.color}">\u25CF</span> {point.name}: <b>{point.y} ({point.percentage:.0f}%)</b>',
                    },
                    plotOptions: {
                        series: {
                            stacking: 'normal',
                        },
                        bar: {
                            borderWidth: 0,
                            allowPointSelect: true,
                            states: {
                                select: {
                                    borderWidth: 2,
                                    lineWidth: 2,
                                    enabled: true,

                                    halo: {
                                        opacity: 0.3,
                                        size: 10
                                    },
                                    color: '#eee'
                                }
                            },
                            point: {
                                events: {
                                    //We will replace it later dynamically with real callbacks.
                                    // That way we could have ref to self.
                                    select(event) {
                                    },
                                    unselect(event) {
                                    }
                                }
                            }
                        }
                    },
                    //For some reason without this predefined structure newly loaded data do not apply
                    series: [
                        {"name": "", "color": "", "data": []},
                        {"name": "", "color": "", "data": []},
                        {"name": "", "color": "", "data": []},
                        {"name": "", "color": "", "data": []},
                        {"name": "", "color": "", "borderColor": "", "borderWidth": -2, "data": []},
                    ],
                    exporting: {
                        chartOptions: { // specific options for the exported image
                            chart: {
                                backgroundColor: '#FFFFFF'
                            },
                            legend: {
                                enabled: true,
                            }
                        },
                        fallbackToExportServer : false,
                        buttons: {
                            contextButton: {
                                symbol: "download",
                                symbolStrokeWidth: 1, //default: 3
                                menuItems: [
                                    'downloadCSV',
                                    'downloadPDF',
                                    'downloadPNG',
                                    'downloadJPEG',
                                ]
                            },
                        }
                    }

                }
            }
        },
        methods: {
            created(chart) {
                let self = this;
                this.chartOptions.plotOptions.bar.point.events = {
                    select(event) {
                        self.lastTarget = event.target;
                        self.$eventHub.$emit('selected', self.chartName, this.series.userOptions.id, event.target.name);
                    },
                    unselect(event) {
                        //That way we will trigger this event only when we are really
                        // just unselecting this and not selecting anything new
                        if (self.lastTarget == event.target) {
                            self.$eventHub.$emit('unselected', self.chartName);
                            self.lastTarget='';
                        }
                    }
                };
            },
            unselectAll(chartName) {
                if (chartName!=this.chartName) {
                    let selectedPoints = this.$children[0].chart.getSelectedPoints();
                    if (selectedPoints.length) {
                        selectedPoints.forEach((point, index) => point.select());
                    }
                }
            }
        },
        computed: {
            ...mapState({
                locale: state => state.userOptions.options.locale,
            })
        },

        //Force update when prop changes
        watch: {
            chartData(newValue) {
              if (newValue) {
                let filteredData = cloneDeep(newValue)
                for (const key in filteredData) {
                  filteredData[key].name = this.$t('dashboard.status.' + filteredData[key].name)
                }
                Vue.set(this.chartOptions, 'series', filteredData);
                let points = Math.max(...this.chartOptions.series.map(serie => serie.data ? serie.data.length : ''))
                this.chartOptions.chart.height = Math.max(400 + (20*(points-20)), 400);
              }
            },
            loading(newValue) {
                if (newValue) {
                    this.$children[0].chart.showLoading();
                } else {
                    this.$children[0].chart.hideLoading();
                }
            },
            locale(){
                Vue.set(this.chartOptions.yAxis.title, 'text', this.axisTitles ? this.axisTitles.y : "")
                Vue.set(this.chartOptions.xAxis.title, 'text', this.axisTitles ? this.axisTitles.x : "")
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.$children[0].chart.showLoading();
                this.$eventHub.$on('selected', this.unselectAll);
            });
        },
    }
</script>
