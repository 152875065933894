// initial state
import axios from 'axios'

const state = {
  reports: [],
  available_content: []
}

// getters
const getters = {}

// actions
const actions = {
  loadReports (context) {
   const org = context.rootState.userOptions.options.selectedOrganizationSymbol;
   const departmentSymbol = context.rootState.userOptions.options.selectedDepartmentSymbol;
   axios.get('/api/reports/enabled', {
     params: {
       org,
       departmentSymbol
     },
     headers: {
      "Accept-Encoding": "gzip,deflate"
     }
   }).then((result) => {
      context.commit('setReports', result.data)
    }).catch((response) => {
      console.log('Couldnt load reports', response)
    })
  },
  resetstate({commit}){
    commit('setReports', {reports:[], available_content: []});
  },
}

// mutations
const mutations = {
  setReports (state, data) {
    state.reports = data.reports
    state.available_content = data.available_content
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
