<template>
    <button tabindex="0" class="btn btn-sm btn-default pull-right modal-hide-record-btn">
        {{ record.hidden ? $t('dashboard.modal.unhide') : $t('dashboard.modal.hide')}}
        <div style="display:none">
            <div id="popover-content" class="clearfix">
                <p>{{record.hidden ? $t('dashboard.modal.unhide_popover_text') :
                    $t('dashboard.modal.hide_popover_text')}}</p>
                <div class="form-group u-mt-10">
                    <label>{{ $t('dashboard.modal.reason') }}</label>
                    <textarea name="reason" id="hide_reason" class="form-control" rows="2" v-model="reason" style="resize: none;"></textarea>
                </div>
                <button data-style="expand-left"
                        class="btn btn-success pull-right btn-toggle-visibility"
                        id="toggle_hide_submit"
                        @click="toggleRecordVisibility()">
                    {{ record.hidden ? $t('dashboard.modal.unhide_submit') :
                    $t('dashboard.modal.hide_submit') }}
                </button>
                <span v-show="error" class="text-danger">{{error}}</span>
            </div>
        </div>
    </button>
</template>

<script type='text/javascript'>
    import {mapState, mapGetters} from 'vuex';
    import * as Ladda from 'ladda';

    export default {
        data() {
            return {
                reason: '',
                ladda: '',
                error: ''
            }
        },
        computed: {
            ...mapGetters({
                record: 'consentModal/selectedRecord'
            })
        },

        mounted() {
            this.$nextTick(() => {
                this.ladda = Ladda.create(document.querySelector('.btn-toggle-visibility'));
                $(this.$el).popover({
                    content: $("#popover-content"),
                    placement: 'bottom',
                    trigger: 'click',
                    html: true
                });
            });
        },

        methods: {
            toggleRecordVisibility() {
                let self = this;
                self.ladda.start();
                this.$store.dispatch('consentModal/toggleRecordVisibility', this.reason).then(response => {
                    $(self.$el).popover('hide');
                    self.reason = '';
                    self.error = '';
                    self.ladda.stop();
                }, error => {
                    self.ladda.stop();
                    error.error.messages.reason.forEach((reason) => {
                        self.error += reason;
                    });
                })
            },
        },
    };
</script>

<style scoped>
    button{
        font-family: "Raleway", Arial, Helvetica, sans-serif;   
    }
</style>